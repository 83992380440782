import React from "react";
import { Provider } from 'react-redux';

import store from '../store';
import { AuthProvider } from "./AuthContext";
import { ThemeProvider } from "./theme.context";
import { NotyBarProvider } from "./notybar.context";
import { RecoilRoot } from 'recoil';

export type ContextProviderProps = { children: React.ReactNode }

const ContextProvider = ({children}: ContextProviderProps) => {
  return (
    <Provider store={store}>
      <RecoilRoot>
        <AuthProvider>
          <ThemeProvider>
            <NotyBarProvider>
              {children}
            </NotyBarProvider>
          </ThemeProvider>
        </AuthProvider>
      </RecoilRoot>
    </Provider>
  );
};

export default ContextProvider;
