export enum BadgeType {
  ThankYou = 'ThankYou',
  Teamwork = 'Teamwork',
  GreatJob = 'GreatJob',
  Inspiring = 'Inspiring',
  GreatInterview = 'GreatInterview',
  MeetupMaster = 'MeetupMaster',
  ExceptionalPerformance = 'ExceptionalPerformance',
  HighFive = 'HighFive',
}
