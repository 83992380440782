import { FormFieldGrid } from "@data-driven-forms/mui-component-mapper";
import { TextField } from '@mui/material';
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";

function DateSelector({FormFieldGridProps, ...props}: any) {
  const {
    label,
    input,
  } = useFieldApi(props);

  return (
    <>
      <FormFieldGrid {...FormFieldGridProps}>
        <TextField
          label={label}
          type="date"
          value={input.value}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={input.onChange}
        />
      </FormFieldGrid>
    </>
  );
}

export default DateSelector;
