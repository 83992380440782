import { MedalType } from '@bb-sanctuary/common';
import { ReactComponent as BadgeTeamworkIcon } from '../../assets/badge/badge_teamwork.svg';
import { ReactComponent as BadgeGreatInterviewIcon } from '../../assets/badge/badge_great_interview.svg';
import { ReactComponent as BadgeGreatJobIcon } from '../../assets/badge/badge_great_job.svg';
import { ReactComponent as BadgeInspiringIcon } from '../../assets/badge/badge_inspiring.svg';
import { ReactComponent as BadgeThankYouIcon } from '../../assets/badge/badge_thankyou.svg';
import { ReactComponent as BadgeHighFiveIcon } from '../../assets/badge/badge_high_five.svg';
import { ReactComponent as MedalIcon } from '../../assets/badge/medal.svg';
import { ReactElement } from 'react';
import Translation from './translation';

export type BsMedalKitProps = {
  [key in keyof typeof MedalType]: () => ReactElement;
}

export const BsMedalKit: BsMedalKitProps = {
  [MedalType.HelpfulHero]: () => {
    return <>
      <BadgeThankYouIcon className="bs-medal" />
      <MedalIcon className="bs-medal-flare" />
    </>;
  },
  [MedalType.CommunityMaster]: () => {
    return <>
      <BadgeTeamworkIcon className="bs-medal" />
      <MedalIcon className="bs-medal-flare" />
    </>;
  },
  [MedalType.ExcellentPerformer]: () => {
    return <>
      <BadgeGreatJobIcon className="bs-medal" />
      <MedalIcon className="bs-medal-flare" />
    </>;
  },
  [MedalType.MasterOfInterviews]: () => {
    return <>
      <BadgeGreatInterviewIcon className="bs-medal" />
      <MedalIcon className="bs-medal-flare" />
    </>;
  },
  [MedalType.TopInfluencer]: () => {
    return <>
      <BadgeInspiringIcon className="bs-medal" />
      <MedalIcon className="bs-medal-flare" />
    </>;
    },
  [MedalType.MostSociableNinja]: () => {
    return <>
      <BadgeHighFiveIcon className="bs-medal" />
      <MedalIcon className="bs-medal-flare" />
    </>;
    },
};

export const getMedalLabel = (medal: MedalType) => Translation.hu.medal.type[medal];
