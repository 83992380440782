import { Fragment, ReactElement, useState } from 'react';
import { Tooltip } from '@mui/material';
import { AddLink } from '@mui/icons-material';
import { ColleagueDetailsDTO, UserChat } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import BsSetChat, { blackbeltGoogleChatUrl, blackbeltSlackUrl, teamsUrl } from '../set-chat/set-chat';
import BsButton from '../../ui/button/button';
import { ReactComponent as SlackIcon } from './../../../../assets/social/slack.svg';
import { ReactComponent as TeamsIcon } from './../../../../assets/social/teams.svg';
import { ReactComponent as DiscordIcon } from './../../../../assets/social/discord.svg';
import { ReactComponent as HangoutsIcon } from './../../../../assets/social/hangouts.svg';
import { useCreateConfirmModal } from '../../../utils/useConfirm.hook';

export interface ColleagueChatProps {
  selectedColleague: ColleagueDetailsDTO,
  myProfile?: boolean;
}

export type ChatOption = 'slack' | 'hangouts' | 'discord' | 'teams' | 'other';

export interface ChatService {
  key: ChatOption,
  name: string,
  icon: ReactElement,
}

export const chatServices: Array<ChatService> = [
  {
    key: 'slack',
    name: 'Slack',
    icon: <SlackIcon />,
  },
  {
    key: 'teams',
    name: 'Teams',
    icon: <TeamsIcon />,
  },
  {
    key: 'discord',
    name: 'Discord',
    icon: <DiscordIcon/>,
  },
  {
    key: 'hangouts',
    name: 'Google Chat',
    icon: <HangoutsIcon/>,
  },
  {
    key: 'other',
    name: 'Egyéb',
    icon: <AddLink />,
  },
];

function ColleagueChat({
  selectedColleague,
  myProfile = false,
}: ColleagueChatProps) {
  const [isSetChatActive, setIsSetChatActive] = useState(false);
  const {dialog, openConfirmModal} = useCreateConfirmModal();

  const isChatSet = (chatKey: ChatOption): boolean => !!selectedColleague.chat![chatKey];

  const navigateChat = async (link: string) => {
    if (link === blackbeltSlackUrl || link === teamsUrl || link === blackbeltGoogleChatUrl) {
      window.open((link.includes(`://`) ? link : `https://${link}` ), '_blank');
    } else {
      try {
        await openConfirmModal({
          displayConfirmOnly: true,
          title: Translation.hu.label.openChatLinkConfirmTitle,
          confirmation: `${Translation.hu.label.openChatLinkConfirmContent}`,
          subContent: link,
        });
      } catch (err) {
      }
    }
  };

  const handleSetChatClose = (update?: UserChat) => {
    if (update) {
      selectedColleague.chat = {...selectedColleague.chat, ...update};
    }
    setIsSetChatActive(false);
  };

  const editChat = () => {
    setIsSetChatActive(!isSetChatActive);
  };

  return (
    <Fragment>
      <div className="bs-colleague__chat">
        <ul className="bs-colleague__chat__options">
          {chatServices.map((service, index) =>
            <Tooltip 
              key={index} 
              title={selectedColleague.chat![service.key] || Translation.hu.label.userDoesNotUseSelectedChat(selectedColleague.firstName)}
              enterDelay={isChatSet(service.key) ? 100 : 1000}
              enterNextDelay={isChatSet(service.key) ? 0 : 1000}
            >
              <li
                className={`bs-colleague__chat__item${isChatSet(service.key) ? ` bs-colleague__chat__item--active` : ``}`}
                {...(isChatSet(service.key) && { onClick: () => navigateChat(selectedColleague.chat![service.key] || ``)})}
              >
                <i className="bs-colleague__chat__icon">{service.icon} </i>
              </li>
            </Tooltip>,
          )}
        </ul>
        {myProfile &&
          <BsButton 
            className="bs-colleague__chat__edit" 
            color="secondary" 
            size="small"
            onClick={editChat}
          >
            {Translation.hu.label.editChat}
          </BsButton>
        }
      </div>
      {isSetChatActive &&
        <BsSetChat
          userChat={selectedColleague.chat}
          open={isSetChatActive}
          onClose={handleSetChatClose}
          selectedColleague={selectedColleague}
        />
      }
      {dialog}
    </Fragment>
  );
}

export default ColleagueChat;
