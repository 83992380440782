import { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { AxiosResponse } from 'axios';
import { ColleagueDetailsDTO, ColleagueDetailsListPageDTO, ColleagueDTO } from '@bb-sanctuary/common';
import { Search, FilterAlt, FilterAltOff, CloseOutlined } from '@mui/icons-material';

import BsInput from '../shared/components/ui/input/input';
import BsTag from '../shared/components/ui/tag/tag';
import BsButton from '../shared/components/ui/button/button';
import BsProfileCard, { BsProfileCardType } from '../shared/components/ui/profile-card/profile-card';
import { Translation } from '../shared/data/translation';
import { ReactComponent as IconLeftArrow } from '../assets/icon/left-arrow.svg';
import { ReactComponent as IconRightArrow } from '../assets/icon/right-arrow.svg';
import { colleaguePageSize, ColleagueService } from '../shared/service/colleagueService';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';
import BsSendBadge from '../shared/components/custom/send-badge/send-badge';
import { BsRoutes } from '../shared/data/bsRoutes';
import { searchOnEnter } from '../shared/utils/searchOnEnter';
import { useQuery } from '../shared/utils/userQuery';
import { selectCurrentUser } from "../contexts/currentUserSlicer";
import { useLoading } from '../shared/utils/useLoader.hook';
import BsColleaguesFilter from '../shared/components/custom/colleagues-filter/colleagues-filter';
import { ReactComponent as ImagePlaceholder } from '../assets/anim/image-placeholder.svg';


function encodeHTML(str?: string|null): string {
  if (!str) {
    return '';
  }
  return encodeURIComponent(str);
}

function decodeHTML(str: string|null): string|null {
  if (str === null) {
    return null;
  }
  return decodeURIComponent(str);
}

enum FilterView {
  Csapatom = 'csapatom',
  MesterekCsarnoka = 'mesterek-csarnoka',
  Mindenki = 'mindenki',
  Uzletagam = 'uzletagam',
}

interface ActiveFilter {
  view: FilterView,
  page: number,
  searchTerm: string,
  searchUnit: string | null,
  searchTeam: string | null,
  searchPosition: string | null,
  searchTechnologyId: Array<number> | null
}

interface FilterItem {
  view: FilterView;
  label: string;
}

const filters: Array<FilterItem> = [
  {
    view: FilterView.Csapatom,
    label: 'Csapatom',
  },
  {
    view: FilterView.Uzletagam,
    label: 'Szervezeti egységem',
  },
  {
    view: FilterView.Mindenki,
    label: 'Mindenki',
  },
  {
    view: FilterView.MesterekCsarnoka,
    label: 'Mesterek csarnoka',
  },
];

function ColleaguesPage() {
  const navigate = useNavigate();
  const query = useQuery();
  const currentUser = useSelector(selectCurrentUser);
  const setLoading = useLoading();
  const [isColleaguesLoading, setIsColleaguesLoading] = useState(false);
  const [colleagues, setColleagues] = useState<ColleagueDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalColleagues, setTotalColleagues] = useState(0);
  const [isSendBadgeActive, setIsSendBadgeActive] = useState(false);
  const [userToSendBadge, setUserToSendBadge] = useState<ColleagueDTO | undefined>(undefined);
  const [activeView, setActiveView] = useState<ActiveFilter| undefined>();

  const handleSendBadgeState = (user: ColleagueDTO) => {
    setIsSendBadgeActive(true);
    setUserToSendBadge(user);
  };

  const handleSandBadgeClose = () => {
    setIsSendBadgeActive(false);
    getColleagues();
  };

  const getColleaguesByView = useCallback(async (): Promise<AxiosResponse<ColleagueDetailsListPageDTO>> => {
    if (!activeView) {
      throw new Error();
    }
    if (activeView.view === FilterView.Uzletagam) {
      return ColleagueService.getMyDepartmentColleagues(activeView.searchTerm, activeView.page, false);
    }
    if (activeView.view === FilterView.Csapatom) {
      return ColleagueService.getMyTeamColleagues(activeView.searchTerm, activeView.page, false);
    }
    return ColleagueService.getColleagues(activeView.searchTerm,
      activeView.page,
      activeView.view === FilterView.MesterekCsarnoka,
      undefined,
      undefined,
      activeView.searchTeam,
      activeView.searchUnit,
      activeView.searchTechnologyId,
      activeView.searchPosition,
      );
  }, [activeView]);

  const getColleagues = useCallback(async () => {
    if (!activeView) {
      return;
    }
    setLoading(true);
    setIsColleaguesLoading(true);
    try {
      const res = await getColleaguesByView();
      setColleagues(res.data.colleagueList.resultList);
      setTotalColleagues(res.data.colleagueList.totalCount as number);
    } finally {
      setLoading(false);
      setIsColleaguesLoading(false);
    }
  }, [activeView, setLoading, getColleaguesByView]);

  const isFiltersVisible = () => {
    return activeView?.searchUnit !== null ||
      activeView?.searchTeam !== null ||
      activeView?.searchPosition !== null ||
      (activeView?.searchTechnologyId && activeView?.searchTechnologyId?.length > 0);
  };

  const openMindenkiView = useCallback(() => {
    navigate({
      search: `?view=${FilterView.Mindenki}`,
    });
  }, [navigate]);

  const clearSearch = useCallback(() => {
    setActiveView(undefined);
    openMindenkiView();
  }, []);

  const searchColleagues = useCallback(() => {
    navigate({
      search: `?view=${FilterView.Mindenki}&q=${encodeURIComponent(searchTerm)}`,
    });
  }, [searchTerm, navigate]);

  const onFilterSelected = (filter: FilterItem) => {
    setSearchTerm('');
    if (filter.view) {
      navigate({
        search: `?view=${filter.view}`,
      });
    } else {
      navigate({
        search: `?`,
      });
    }
  };

  const createSearchQuery = (viewConfig: Partial<ActiveFilter>): string => {
    const query = [];
    if (viewConfig?.view) {
      query.push(`view=${viewConfig.view}`);
    }
    if (viewConfig?.searchTerm) {
      query.push(`q=${viewConfig.searchTerm}`);
    }
    if (viewConfig?.searchUnit) {
      query.push(`unit=${encodeHTML(viewConfig.searchUnit)}`);
    }
    if (viewConfig?.searchTeam) {
      query.push(`team=${encodeHTML(viewConfig.searchTeam)}`);
    }
    if (viewConfig?.searchTechnologyId) {
      query.push(`technology=${encodeHTML(`${viewConfig.searchTechnologyId}`)}`);
    }
    if (viewConfig?.searchPosition) {
      query.push(`pos=${viewConfig.searchPosition}`);
    }
    if (viewConfig?.page) {
      query.push(`page=${viewConfig.page}`);
    }
    return query.length === 0 ? '' : `?${query.join('&')}`;
  };

  const onPaginate = (step: number) => {
    navigate(createSearchQuery({...activeView, page: (activeView?.page || 0) + step}));
  };

  const handleFilterSection = () => {
    if (!isFiltersVisible()) {
      navigate({
        search: `?view=${FilterView.Mindenki}&unit=`,
      });
    } else {
      openMindenkiView();
    }
  };

  const setSearchUnit = (unit: string) => {
    if (unit || activeView?.searchTechnologyId || activeView?.searchTeam || activeView?.searchPosition) {
      navigate(createSearchQuery({...activeView, view: FilterView.Mindenki, searchUnit: unit, searchTeam: undefined, page: 0 }));
    } else {
      openMindenkiView();
    }
  };

  const setSearchTeam = (team: string) => {
    if (team || activeView?.searchTechnologyId || activeView?.searchUnit || activeView?.searchPosition) {
      navigate(createSearchQuery({...activeView, view: FilterView.Mindenki, searchTeam: team, page: 0 }));
    } else {
      openMindenkiView();
    }
  };

  const setSearchTech = (tech: Array<number>) => {
    if (tech || activeView?.searchTeam || activeView?.searchUnit || activeView?.searchPosition) {
      navigate(createSearchQuery({...activeView, view: FilterView.Mindenki, searchTechnologyId: tech, page: 0 }));
    } else {
      openMindenkiView();
    }
  };

  const setSearchPos = (pos: string) => {
    if (pos || activeView?.searchTeam || activeView?.searchUnit || activeView?.searchTechnologyId) {
      navigate(createSearchQuery({...activeView, view: FilterView.Mindenki, searchPosition: pos, page: 0 }));
    } else {
      openMindenkiView();
    }
  };

  useEffect(() => {
    if (activeView) {
      getColleagues();
    }
  }, [activeView, getColleagues]);

  useEffect(() => {
    const qView = query.get('view');
    const qPage = query.get('page');
    const qSearchTerm = query.get('q');
    const page = qPage && !isNaN(parseInt(qPage)) ? parseInt(qPage) : 0;
    const pSearchTerm: string = (qSearchTerm && qSearchTerm.trim() !== '') ? decodeURIComponent(qSearchTerm) : '';
    const qSearchUnit = decodeHTML(query.get('unit'));
    const qSearchTeam = decodeHTML(query.get('team'));
    const qSearchPosition = decodeHTML(query.get('pos'));
    const qSearchTech = (query.get('technology') || '').length > 0 ? ((decodeHTML(query.get('technology')) as string).split(`,`).map(t => +t)) : null;
    setSearchTerm(pSearchTerm);
    if (qView && Object.values(FilterView).includes(qView as FilterView)) {
      setActiveView(() => ({
        view: qView as FilterView,
        page,
        searchTerm: pSearchTerm,
        searchTeam: qSearchTeam,
        searchUnit: qSearchUnit,
        searchTechnologyId: qSearchTech,
        searchPosition: qSearchPosition,
      }));
    } else {
      setActiveView(() => ({
        view: FilterView.Csapatom,
        page: 0,
        searchTerm: pSearchTerm,
        searchTeam: null,
        searchUnit: null,
        searchTechnologyId: null,
        searchPosition: null,
      }));
    }
  }, [query]);

  useEffect(() => {
    setIsColleaguesLoading(true);
    const timeout = setTimeout(() => {
      if (searchTerm && searchTerm.length > 1) {
        searchColleagues();
      }
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchTerm]);

  return (
    <>{activeView &&
    <div className="bs-colleagues">
      <BsContentHeader>
        <BsInput
          value={searchTerm}
          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (e.target.value.length === 0) {
              clearSearch();
            }
            setSearchTerm(e.target.value);
          }}
          onKeyUp={searchOnEnter(searchColleagues)}
          placeholder={Translation.hu.placeholder.searchColleagues}
          endAdornment={
            <>
              {searchTerm.length > 0 &&
                <div className="bs-button__group">
                  <BsButton onClick={searchColleagues} color="secondary" className="bs-helper-mobile-icon">
                    <span className="text">{Translation.hu.common.search}</span>
                    <Search className="icon"/>
                  </BsButton>
                  <BsButton color="secondary" onClick={clearSearch}><CloseOutlined/></BsButton>
                </div>
              }
              {searchTerm.length === 0 &&
                <BsButton onClick={searchColleagues} color="secondary" className="bs-helper-mobile-icon">
                  <span className="text">{Translation.hu.common.search}</span>
                  <Search className="icon"/>
                </BsButton>
              }
            </>
          }
        />
      </BsContentHeader>

      <div className="bs-colleagues__filters">
        { filters.map((filter, index) =>
          <BsTag key={index}
                 onClick={() => onFilterSelected(filter)}
                 label={filter.label}
                 active={activeView.view === filter.view} />,
        )}
        <BsButton
          onClick={handleFilterSection}
          size={'small'}
          color={'secondary'}
          >
          {isFiltersVisible() ? 
            <FilterAltOff className="icon icon--in-tag" /> : 
            <FilterAlt className="icon icon--in-tag" />
          }
        </BsButton>

        <div className={`bs-colleagues__filters__options${isFiltersVisible() ? ` bs-colleagues__filters__options--active` : ``}`}>
          <BsColleaguesFilter
            searchUnit={activeView.searchUnit}
            searchTeam={activeView.searchTeam}
            searchTechnologyId={activeView.searchTechnologyId}
            searchPosition={activeView.searchPosition}
            setSearchUnit={setSearchUnit}
            setSearchTeam={setSearchTeam}
            setSearchTech={setSearchTech}
            setSearchPos={setSearchPos}
            />
        </div>
      </div>

      <div className="bs-colleagues__profile-cards">
        { colleagues.length > 0 ?
          colleagues.map((colleague: ColleagueDTO, index: number) =>
            <BsProfileCard
              key={index}
              slug={colleague.slug}
              link={BsRoutes.munkatarsak.munkatars(colleague.slug)}
              sendBadge={() => handleSendBadgeState(colleague)}
              className="bs-colleagues__profile-card"
              image={colleague.image}
              tech={colleague.highlightedTechnology}
              email={colleague.email}
              firstName={colleague.firstName}
              lastName={colleague.lastName}
              positionTitle={colleague.positionTitle}
              unit={colleague.unit}
              phone={colleague.phone}
              team={colleague.team}
              sex={colleague.sex}
              masterPoints={(colleague as ColleagueDetailsDTO).allPoints}
              masterPosition={(colleague as ColleagueDetailsDTO).order}
              type={(activeView.view === FilterView.MesterekCsarnoka) ? BsProfileCardType.MASTER : BsProfileCardType.DEFAULT}
              medals={colleague.medals}
              isSendBadgeButtonAvailable={colleague.email !== currentUser?.email}
            />,
          ) :
          <div className="bs-no-result bs-no-result--spacing">
            {isColleaguesLoading ?
              <h4>
                <ImagePlaceholder className={`bs-profile-card__image bs-profile-card__image--placeholder`}/>
              </h4>
              :
              <h4 className="bs-no-result__label">
                {Translation.hu.label.noColleaguesFound}
              </h4>
            }
          </div>
        }
        <BsSendBadge
          selectedUser={userToSendBadge}
          open={isSendBadgeActive}
          onClose={handleSandBadgeClose}
        />
      </div>

      { colleagues.length > 0 &&
        <div className="bs-colleagues__navigation">
          <div className={`bs-navigation${isColleaguesLoading ? ` bs-helper--disabled` : ``}`}>
            {(activeView.page > 0) &&
              <div className="bs-navigation__prev" onClick={() => onPaginate(-1)}><span><IconLeftArrow/></span></div>
            }

            <div className="bs-navigation__page">{activeView.page === 0 ? activeView.page + 1 : (activeView.page + 1) * colleaguePageSize - 7} - {Math.min((activeView.page+1) * colleaguePageSize, totalColleagues)} / {totalColleagues}</div>

            {((activeView.page + 1) * colleaguePageSize < totalColleagues) &&
              <div className="bs-navigation__next" onClick={() => onPaginate(1)}><span><IconRightArrow/></span></div>
            }
          </div>
        </div>
      }
    </div>
    }</>
  );
}

export default ColleaguesPage;
