import FroalaEditorComponent from 'react-froala-wysiwyg';
import { WysiwygConfig } from './wysiwygConfig';
import { useState } from 'react';

export interface BaseWysiwygEditorProps {
  value: string;
  height?: number;
  onChange: (value: string) => void;
}

const BaseWysiwygEditor = ({ value, onChange, height }: BaseWysiwygEditorProps) => {
  const config = WysiwygConfig({height});
  const [modelValue, setModelValue] = useState(value);
  const updateValue = (value: any) => {
    setModelValue(value);
    onChange(value);
  };

  return (
    <>
      <FroalaEditorComponent tag="textarea"
                             config={config}
                             model={modelValue}
                             onModelChange={updateValue}/>
    </>
  );
};

export default BaseWysiwygEditor;
