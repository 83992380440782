import { Link, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowBackIos } from '@mui/icons-material';
import { AuthRight, ColleagueDetailsDTO, ColleagueDTO, ProjectDetailDTO, ProjectStatus } from '@bb-sanctuary/common';

import BsProfilePointTransactionModal from '../shared/components/custom/profile-point-transaction-modal/profile-point-transaction-modal';
import BsSendBadge from '../shared/components/custom/send-badge/send-badge';
import BsButton from '../shared/components/ui/button/button';
import Translation from '../shared/data/translation';
import { BsRoutes } from '../shared/data/bsRoutes';
import { useLoading } from '../shared/utils/useLoader.hook';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { useCurrentUserRights } from '../shared/utils/useRight.hook';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';
import { ColleagueService } from '../shared/service/colleagueService';
import { ColleagueBadges } from '../shared/components/custom/colleague-badges/ColleagueBadges';
import { ColleagueMedals } from '../shared/components/custom/colleague-medals/ColleagueMedals';
import ColleagueProjects from "../shared/components/custom/colleague-projects/ColleagueProjects";
import ColleagueTechs from '../shared/components/custom/colleague-techs/ColleagueTechs';
import ColleagueInfo from '../shared/components/custom/colleague-info/ColleagueInfo';
import ColleagueChat from '../shared/components/custom/colleague-chat/ColleagueChat';
import ColleagueProjectsHeader from '../shared/components/custom/colleague-projects/colleague-projects-header';

function ColleaguePage() {
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const [selectedColleague, setSelectedColleague] = useState<ColleagueDetailsDTO>();
  const [displayedProjects, setDisplayedProjects] = useState<Array<ProjectDetailDTO>>([]);
  const [isPointManagerActive, setPointManagerActive] = useState(false);
  const [isSendBadgeActive, setIsSendBadgeActive] = useState(false);
  const [userToSendBadge, setUserToSendBadge] = useState<ColleagueDTO | undefined>(undefined);
  const setLoading = useLoading();
  const [hasRight] = useCurrentUserRights();

  const navigateBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`${BsRoutes.kollegak}?view=mindenki`);
    }
  };

  const handleSendBadgeState = (user: ColleagueDTO) => {
    setIsSendBadgeActive(true);
    setUserToSendBadge(user);
  };

  const handleSendBadgeClose = (updated: boolean) => {
    setIsSendBadgeActive(false);
    if (updated) {
      getColleague();
    }
  };

  const handlePointManagerState = () => {
    setPointManagerActive(!isPointManagerActive);
  };

  const handlePointManagerClose = (update?: boolean) => {
    setPointManagerActive(false);
    if (update) {
      getColleague();
    }
  };

  const handleProjectStatusSelected = (selectedStatus?: ProjectStatus) => {
    if (!selectedStatus) {
      setDisplayedProjects(selectedColleague?.projects || []);
    } else {
      const filtered = (selectedColleague?.projects || []).filter(project => project.status === selectedStatus);
      setDisplayedProjects(filtered);
    }
  };

  const getColleague = async () => {
    const colleagueId = params.colleague;
    if (!colleagueId) {
      navigate(BsRoutes.notFound);
      return;
    }
    setLoading(true);
    try {
      const response = await ColleagueService.getColleagueByEmail(colleagueId);
      if (response) {
        setSelectedColleague(response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const download = () => {
    if(params.colleague){
      return `${BsRoutes.admin.cv._}/list/${params.colleague}`;
    }
    return '';
  };
  useEffect(() => {
    getColleague();
  }, []);
  
  useEffect(() => {
    handleProjectStatusSelected(ProjectStatus.Active);
  }, [selectedColleague]);

  return (
    <div className="bs-colleague">
      <BsContentHeader></BsContentHeader>
      {selectedColleague &&
        <Fragment>
          <div className="bs-colleague__header">
            <BsButton color="secondary" size="small" onClick={navigateBack}>
              <ArrowBackIos /> {Translation.hu.common.backToColleagues}
            </BsButton>

            <ColleagueChat selectedColleague={selectedColleague} />
          </div>
          <ColleagueInfo selectedColleague={selectedColleague} />
          <div className="bs-colleague__actions">
            {hasRight(AuthRight.PointAdministrator) &&
              <BsButton 
                size="normal"
                color="secondary"
                onClick={() => handlePointManagerState()}
              >
                  {Translation.hu.profile.pointTransaction}
              </BsButton>
            }
            {currentUser?.email !== selectedColleague.email &&
              <BsButton
                size="normal"
                color="secondary"
                {...(selectedColleague && { onClick: () => handleSendBadgeState(selectedColleague) })}
              >
                {Translation.hu.label.sendBadge}
              </BsButton>
            }
            {hasRight(AuthRight.AdminOfCv) &&
               <Link to={download()} ><BsButton size="normal" color='secondary' > {Translation.hu.cv.download}</BsButton></Link>
            }
          </div>
          {selectedColleague.medals !== undefined && selectedColleague.medals?.length > 0 &&
            <ColleagueMedals selectedColleague={selectedColleague} />
          }
          <ColleagueBadges selectedColleague={selectedColleague} />
          <ColleagueTechs selectedColleague={selectedColleague} />
          <ColleagueProjectsHeader
            allProjectsCount={(selectedColleague?.projects || []).length}
            displayedProjectsCount={displayedProjects.length} 
            onStatusFilterSelected={handleProjectStatusSelected}/>
          <ColleagueProjects 
            selectedColleague={selectedColleague}
            userProjectList={displayedProjects}
            myProfile={false}
            projectPage={false}
          />

          <BsProfilePointTransactionModal 
            open={isPointManagerActive} 
            onClose={handlePointManagerClose} 
            selectedColleague={selectedColleague}
          />
          {isSendBadgeActive &&
            <BsSendBadge
              selectedUser={userToSendBadge}
              open={isSendBadgeActive}
              onClose={handleSendBadgeClose}
            />
          }
        </Fragment>
      }
    </div>
  );
}

export default ColleaguePage;
