import {useState} from "react";
import {Dialog} from '@mui/material';
import {ReactComponent as CloseIcon} from './../../../../assets/icon/plus.svg';
import {ReactComponent as IconLeftArrow} from "../../../../assets/icon/left-arrow.svg";
import {ReactComponent as IconRightArrow} from "../../../../assets/icon/right-arrow.svg";
import Badges from '../../../../assets/images/badges.png';
import Feed from '../../../../assets/images/feed.png';
import Profile from '../../../../assets/images/profile.png';
import Projects from '../../../../assets/images/projects.png';
import Faq from '../../../../assets/images/faq.png';
import BsButton from '../../ui/button/button';
import Translation from '../../../data/translation';
import { AccountService } from '../../../service/accountService';
import { useDispatch } from 'react-redux';
import { setWelcomeDialogDisplayed } from '../../../../contexts/currentUserSlicer';

export interface BsWelcomeTutorialProps {
  open: boolean;
  onClose: (updated: boolean) => void;
}

/**
 * @deprecated
 * @param props
 * @constructor
 */
function BsWelcomeTutorial(props: BsWelcomeTutorialProps) {
  const { onClose, open } = props;
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const tutorialSteps = 6;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setWelcomeDialogDisplayed(null));
    AccountService.updateWelcomeDialogDisplayed();
    onClose(false);
  };

  const onNext = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const onPrevious = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={open} className="bs-dialog bs-dialog--welcome">
      <div className="bs-dialog__close" onClick={handleClose}><CloseIcon/></div>
      <div className="bs-welcome-tutorial">
        {currentStepIndex === 0 &&
          <div className="bs-welcome-tutorial__content bs-welcome-tutorial__content--center">
            <p className="bs-welcome-tutorial__title">Üdvözlünk az új Szentélyben!</p>
            <div>
              <p className="bs-welcome-tutorial__text">Ismerd meg az új funkciókat:</p>
              <ol className="bs-welcome-tutorial__list">
                <li className="bs-welcome-tutorial__list-item">Saját profil</li>
                <li className="bs-welcome-tutorial__list-item">A Mesterek Csarnoka pontgyűjtő verseny egy elismerésküldő rendszerrel egészült ki</li>
                <li className="bs-welcome-tutorial__list-item">Hírfolyam</li>
                <li className="bs-welcome-tutorial__list-item">Projektek</li>
                <li className="bs-welcome-tutorial__list-item">Általános tudnivalók, Master of Technology új tartalmakkal</li>
              </ol>
            </div>

          </div>
        }

        {currentStepIndex === 1 &&
          <div className="bs-welcome-tutorial__content">
            <p className="bs-welcome-tutorial__title">1. Saját profil</p>
            <img src={Profile} alt="" className="bs-welcome-tutorial__photo" />
            <p className="bs-welcome-tutorial__text">Bal felül kattints a nevedre vagy a képedre, így jutsz el a profilod oldalára. Az elérhetőségi adataidon kívül beállíthatod magadnak, milyen chat platformon vagy elérhető, milyen technológiákban vagy jártas. Látszik, hogy milyen Badge-ket kaptál, vagy melyik projektben veszel részt. Értesítéseidet is itt találod.</p>
          </div>
        }

        {currentStepIndex === 2 &&
          <div className="bs-welcome-tutorial__content">
            <p className="bs-welcome-tutorial__title">2. Mesterek Csarnoka</p>
            <p className="bs-welcome-tutorial__text">Az egyik legnagyobb változás a régihez képest, hogy lehetőség van egymásnak különböző Badge-eket küldeni. A munkatársad profilképe alatt a Badge küldése gombra kattintva kiválaszthatod, melyik Badge-dzsel szeretnéd elismerni a munkáját</p>
            <img src={Badges} alt="" className="bs-welcome-tutorial__photo" />
            <p className="bs-welcome-tutorial__text">
              Badge-ket küldhet a HR is, ebben az esetben pontot érnek, és beszámít a Mesterek Csarnoka pontverseny pontjaiba. Arról, hogy hány pontot érnek a Badge-ek, milyen tevekénységért kaphatod, vagy hogy egy hónapban hány darabot küldhetsz másoknak, és vajon mik azok a Medálok, a <a target="_blank" href="https://drive.google.com/file/d/15hUvm_dQYeyQenLQdFsKjO0NG6MacQ48/view" rel="noopener noreferrer">Játékszabályzatból</a> megtudhatod.
            </p>
          </div>
        }

        {currentStepIndex === 3 &&
          <div className="bs-welcome-tutorial__content">
            <p className="bs-welcome-tutorial__title">3. Hírfolyam</p>
            <img src={Feed} alt="" className="bs-welcome-tutorial__photo bs-welcome-tutorial__photo--with-border" />
            <p className="bs-welcome-tutorial__text">
              Szintén új funkció a Hírfolyam, ahol különböző céges hírekkel, havi hírlevelekkel fogsz találkozni és ide került a BB Háza Tája rovat is.
            </p>
          </div>
        }

        {currentStepIndex === 4 &&
          <div className="bs-welcome-tutorial__content">
            <p className="bs-welcome-tutorial__title">4. Projektek</p>
            <img src={Projects} alt="" className="bs-welcome-tutorial__photo bs-welcome-tutorial__photo--with-border" />
            <p className="bs-welcome-tutorial__text">
              A Projektek fülön listázzuk a régi és jelenlegi projekteket, a benne résztvevőkkel.
            </p>
          </div>
        }

        {currentStepIndex === 5 &&
          <div className="bs-welcome-tutorial__content">
            <p className="bs-welcome-tutorial__title">5. Általános tudnivalók, Master of Technology</p>
            <img src={Faq} alt="" className="bs-welcome-tutorial__photo bs-welcome-tutorial__photo--with-border" />
            <p className="bs-welcome-tutorial__text">
              Új tartalmak, könnyebb kereshetőség.
            </p>
            <p className="bs-welcome-tutorial__text">
              MOT: Itt találod az Orgchartot, mely dinamikusan változik attól függően, hogy kire kattintasz. Nézd meg, kik, melyik szervezetben dolgoznak a cégen belül. További új témák: BB Essentials, Üzletágak, BB története
            </p>
          </div>
        }

        <div className="bs-colleagues__navigation bs-welcome-tutorial__navigation">
          <div className="bs-navigation">
            {(currentStepIndex > 0) &&
              <div className="bs-navigation__prev" onClick={onPrevious}><span><IconLeftArrow/></span></div>
            }

            <div className="bs-welcome-tutorial__dots">
              {[...Array(tutorialSteps)].map((_step, i) =>
                <div key={i}
                     className={i === currentStepIndex ?
                       `bs-welcome-tutorial__dot bs-welcome-tutorial__dot--active` :
                       `bs-welcome-tutorial__dot`}>
                </div>,
              )}
            </div>

            {tutorialSteps - 1 > currentStepIndex &&
              <div className="bs-navigation__next" onClick={onNext}><span><IconRightArrow/></span></div>
            }
            {currentStepIndex === tutorialSteps - 1 &&
              <BsButton
                className="bs-welcome-tutorial__start-button"
                size={'small'}
                onClick={handleClose}>
                {Translation.hu.label.indulas}
              </BsButton>
            }
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default BsWelcomeTutorial;
