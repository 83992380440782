import {OrgChart} from 'd3-org-chart';
import {json} from 'd3-fetch';
// @ts-ignore
import {HierarchyNode} from "d3-hierarchy";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {OrganizationMember, SexEnum} from '@bb-sanctuary/common';
import {BsRoutes} from "../../../data/bsRoutes";
import BsButton from '../../ui/button/button';
import { ZoomIn, ZoomOut, ZoomInMap, ZoomOutMap, CenterFocusStrong } from "@mui/icons-material";
import translation from '../../../data/translation';
import { ReactComponent as SadIcon } from './../../../../assets/svg/sad.svg';

function getPhoto(data: OrganizationMember) {
  if (data.image) {
    return data.image;
  }
  return data.sex === SexEnum.Female ?
    '/assets/svg/default-avatar-female.svg' :
    '/assets/svg/default-avatar-male.svg';
}

export function BsOrgChart() {
  const [orgChartData, setOrgChartData] = useState<any>();
  const [isFullscreen, setFullScreen] = useState(false);
  const [originalWidth, setOriginalWidth] = useState<number>();
  const [originalHeight, setOriginalHeight] = useState<number>();
  const d3Container = useRef(null);
  const chartRef = useRef<null | OrgChart<OrganizationMember>>(null);
  const [isSafari] = useState(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

  const expandAll = () => {
    if (!chartRef.current) {
      return;
    }

    chartRef.current.expandAll();
  };

  const toggleToTop = () => {
    if (!chartRef.current) {
      return;
    }

    chartRef.current.collapseAll();
  };

  const toggleFullscreen = () => {
    if (!d3Container.current || !chartRef.current) {
      return;
    }

    if (!isFullscreen) {
      const width = chartRef.current.svgWidth();
      const height = chartRef.current.svgHeight();
      setOriginalWidth(width);
      setOriginalHeight(height);
      chartRef.current.svgWidth(window.innerWidth);
      chartRef.current.svgHeight(window.innerHeight);
      (((d3Container.current as HTMLDivElement).children[0] as any).width as SVGAnimatedLength).baseVal.value = window.innerWidth;
      (((d3Container.current as HTMLDivElement).children[0] as any).height as SVGAnimatedLength).baseVal.value = window.innerHeight;
    } else {
      chartRef.current.svgWidth(originalWidth!);
      chartRef.current.svgHeight(originalHeight!);

      (((d3Container.current as HTMLDivElement).children[0] as any).width as SVGAnimatedLength).baseVal.value = originalWidth!;
      (((d3Container.current as HTMLDivElement).children[0] as any).height as SVGAnimatedLength).baseVal.value = originalHeight!;
    }

    chartRef.current.fit({scale: true});
    setFullScreen(!isFullscreen);
  };

  const setToCenter = () => {
    if (!d3Container.current || !chartRef.current) {
      return;
    }

    chartRef.current.zoomTreeBounds({x0: 0, x1: 0, y0: 0, y1: 1000, params: {scale: true}});
  };

  useEffect(() => {
    if (!isSafari) {
      json(`/api/colleague/org-tree`).then((res: any) => {
        setOrgChartData(res);
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (orgChartData && d3Container.current) {
      if (!chartRef.current) {
        chartRef.current = new OrgChart();
      }
      chartRef.current
        .container(d3Container.current)
        .data(orgChartData)
        .nodeWidth(() => 220)
        .nodeHeight(() => 60)
        .childrenMargin(() => 100)
        .compactMarginBetween(() => 20)
        .buttonContent(({ node}) => {
          return `<div class="bs-org-chart__action-container"> <span class="bs-org-chart__action-wrapper">${
            node.children
              ? `<span class="bs-org-chart__action bs-org-chart__action--close">-</span>`
              : `<span class="bs-org-chart__action bs-org-chart__action--open">+</span>`
          }</span> </div>`;
        })
        .nodeContent((node: HierarchyNode<OrganizationMember>) => {
          if (node.data.isTeam) {
            return `<div class="bs-org-chart__member">
  <div class="bs-org-chart__member__details-wrapper bs-org-chart__member__details-wrapper--team">
    <p class="bs-org-chart__member__name">${node.data.name}</p>
  </div>
</div>`;
          }
          return `<div class="bs-org-chart__member">
  <img src="${getPhoto(node.data)}" alt="${node.data.name}" class="bs-org-chart__member__photo" />
  <div class="bs-org-chart__member__details-wrapper">
    <p class="bs-org-chart__member__name">${node.data.name}</p>
    <p class="bs-org-chart__member__team">${node.data.team}</p>
    <p class="bs-org-chart__member__title">${node.data.title}</p>
  </div>
</div>`;
        })
        .onNodeClick((node: unknown) => {
          window.open(BsRoutes.munkatarsak.munkatars(node as string), '_blank');
        })
        .render();

      chartRef.current.expandLevel(2);
    }
  }, [orgChartData, d3Container.current]);

  return (
    <>
      {!isSafari &&
        <div className={`bs-org-chart${isFullscreen ? ' bs-org-chart--fullscreen' : ''}`}>
          {chartRef &&
            <div className="bs-org-chart__controls">
              <BsButton size="small" color="secondary" onClick={() => setToCenter()}>
                <CenterFocusStrong/>
              </BsButton>
              <BsButton size="small" color="secondary" onClick={() => expandAll()}>
                <ZoomIn/>
              </BsButton>
              <BsButton size="small" color="secondary" onClick={toggleToTop}>
                <ZoomOut/>
              </BsButton>
              <BsButton size="small" color="secondary" onClick={toggleFullscreen}>
                {isFullscreen && <ZoomInMap/>}
                {!isFullscreen && <ZoomOutMap/>}
              </BsButton>
            </div>
          }
          <div ref={d3Container}/>
        </div>
      }
      {isSafari && <div className={`bs-org-chart bs-org-chart--error`}>
        <p><SadIcon /></p>
        <p>{translation.hu.error.orgChartNotAvailableInSafari}</p>
      </div>}
    </>
  );
}
