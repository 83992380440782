import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { AuthRight, CreateRoleDTO, UpdateRoleRightsDTO } from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityEditor from "../../shared/components/admin/GenericEntityEditor";
import { createRequiredField, createSelectField } from "../../shared/utils/formUtils";

const ADMIN_ROLES_URL = `/admin/roles`;

const formSchema = {
  fields: [
    createRequiredField('name')
      .label('Name of the role', '')
      .sizeColumns(6)
      .build(),
    createSelectField('rights',
      'Rights of the role',
      Object.keys(AuthRight).map((right) => ({
        label: right,
        value: AuthRight[right as keyof typeof AuthRight],
      })),
      6)
      .build({multiple: true}),
  ],
};

function AdminRolesEditPage() {
  const params = useParams();
  const id = params.id;

  const loadData = async () => {
    if (id) {
      const result = await AdminService.getRole(id);
      return {...result.role, rights: result.role?.rights?.map(r => r.name)};
    }
    return {};
  };
  const saveRole = async (params: CreateRoleDTO | UpdateRoleRightsDTO ) => {
    if(!params.rights){
      params.rights = [];
    }
    if (id) {
      return AdminService.updateRole(params as UpdateRoleRightsDTO);
    } else {
      return AdminService.createRole(params as CreateRoleDTO);
    }
  };

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__editor">
        {formSchema ?
          <GenericEntityEditor
            id={id}
            saveFn={saveRole}
            entityLabel={Translation.hu.label.roles}
            loadEntityFn={loadData}
            listingUrl={ADMIN_ROLES_URL}
            formSchema={formSchema}
            hideCancel={false}
          />
        :
          <Fragment>
            {Translation.hu.common.loading}
          </Fragment>
        }
      </div>
    </Fragment>
  );
}

export default AdminRolesEditPage;
