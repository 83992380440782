import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Translation from '../../../data/translation';
import CvCompetenceForm from '../cv-form/CvCompetenceForm';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { CvCompetenceDTO } from '@bb-sanctuary/common';

export type CvGeneratorAccordionCompetenceProps = {
  competencies: CvCompetenceDTO[];
  maxCvItemsCompetence: number;
  onAddNewCompetence: (newCompetence: CvCompetenceDTO[]) => void;
  onUpdateCompetence: (updatedCompetence: CvCompetenceDTO) => void;
  onRemoveCompetence: (id: number) => void;
};

export const CvGeneratorAccordionCompetence = ({
                                                 competencies,
                                                 maxCvItemsCompetence,
                                                 onAddNewCompetence,
                                                 onUpdateCompetence,
                                                 onRemoveCompetence,
                                               }: CvGeneratorAccordionCompetenceProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.competence}</p>
          <p>{Translation.hu.cv.accordionTitle.max(competencies.length, 10)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="bs-cv-generator__form">
          {competencies.length >= maxCvItemsCompetence && <p className="warning">{Translation.hu.cv.form.maxWarning}</p>
          }
          <CvCompetenceForm
            competencies={competencies}
            onSetNewCompetence={onAddNewCompetence}
            onRemoveCompetence={onRemoveCompetence}
            onUpdateCompetence={onUpdateCompetence}
            disabled={competencies.length >= maxCvItemsCompetence}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
