import React, { ReactElement, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { ButtonBase } from '@mui/material';
import {
  AdminPanelSettings,
  AssignmentIndRounded,
  CloseRounded,
  Forum,
  LiveHelp,
  PeopleAlt,
  Star,
  SupervisedUserCircleOutlined,
  Workspaces,
} from '@mui/icons-material';
import {
  selectActiveMenu,
  selectIsAdminMenuVisible,
  setActiveMenu,
  setAdminMenuVisible,
} from '../../../../contexts/menuSlice';
import Translation from '../../../data/translation';
import { BsRoutes } from '../../../data/bsRoutes';
import { setNewsDefaults, setNewsPageDefaults } from '../../../../contexts/appSlicer';
import { selectAdminAccessibleForUser } from '../../../../contexts/currentUserSlicer';
import { AuthRight } from '@bb-sanctuary/common';
import { useCurrentUserRights } from '../../../utils/useRight.hook';

interface BsMenuItemProps {
  text: string;
  link: string;
  icon?: React.ReactElement;
  external?: boolean;
  requiredRights?: Array<AuthRight>;
}

export interface BsMenuProps {
  overriddenMenuPoints?: BsMenuItemProps[];
  onNavigate?: () => void;
}

const defaultMenuPoints: Array<BsMenuItemProps> = [
  {
    text: Translation.hu.menu.news,
    link: BsRoutes.hirek,
    icon: <Forum/>,
  },
  {
    text: Translation.hu.menu.colleagues,
    link: BsRoutes.kollegak,
    icon: <PeopleAlt/>,
  },
  {
    text: Translation.hu.menu.projects,
    link: BsRoutes.projektek,
    icon: <Workspaces/>,
  },
  {
    text: Translation.hu.menu.faq,
    link: BsRoutes.altalanosTudnivalok._,
    icon: <LiveHelp/>,
  },
  {
    text: Translation.hu.menu.masterOfTechnology,
    link: BsRoutes.masterOfTechnology._,
    icon: <Star/>,
  },
];
const adminMenuPoints: Array<BsMenuItemProps> = [
  {
    text: Translation.hu.menu.admin,
    link: BsRoutes.admin.admin,
    icon: <AdminPanelSettings/>,
  },
  {
    text: Translation.hu.menu.users,
    link: BsRoutes.admin.user._,
    icon: <SupervisedUserCircleOutlined/>,
    requiredRights: [AuthRight.AdminOfUsers],
  },
  {
    text: Translation.hu.menu.projects,
    link: BsRoutes.admin.projects._,
    icon: <Workspaces/>,
    requiredRights: [AuthRight.ProjectMaintenance, AuthRight.ProjectCreate],
  },
  {
    text: Translation.hu.menu.cvAdmin,
    link: BsRoutes.admin.cv._,
    icon: <AssignmentIndRounded/>,
    requiredRights: [AuthRight.AdminOfCv],
  },
];

const BsMenu = ({
                  overriddenMenuPoints,
                  onNavigate,
                }: BsMenuProps): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdminAccessibleForUser = useSelector(selectAdminAccessibleForUser);
  const isAdminMenuVisible = useSelector(selectIsAdminMenuVisible);
  const [hasRight] = useCurrentUserRights();
  const activeMenu = useSelector(selectActiveMenu);
  const menuPoints = useMemo(() => overriddenMenuPoints ?
      overriddenMenuPoints :
      (isAdminMenuVisible ?
          adminMenuPoints : defaultMenuPoints
      ),
    [isAdminMenuVisible, overriddenMenuPoints]);

  const openAdminMenu = () => {
    navigate(BsRoutes.admin.admin);
    dispatch(setAdminMenuVisible(true));
  };

  const closeAdminMenu = () => {
    navigate(BsRoutes.hirek);
    dispatch(setAdminMenuVisible(false));
  };

  const onMenuSelected = (item: BsMenuItemProps) => {
    if (item.link !== BsRoutes.hirek) {
      dispatch(setNewsDefaults(''));
      dispatch(setNewsPageDefaults(''));
    }
    dispatch(setActiveMenu(item.link));
    onNavigate && onNavigate();
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    if(currentPath === BsRoutes.cvGenerator) {
      dispatch(setActiveMenu(''));
    }
    const menu = [...defaultMenuPoints, ...adminMenuPoints].find(_ => _.link.startsWith(currentPath));
    if (menu) {
      dispatch(setActiveMenu(menu.link));
    }
    if (!currentPath.includes(`admin`)) {
      dispatch(setAdminMenuVisible(false));
    }
  }, [dispatch, window.location.pathname]);

  return (
    <ul className="bs-menu">
      {isAdminMenuVisible &&
        <li className="bs-menu__item">
          <ButtonBase onClick={closeAdminMenu}>
            <span className="bs-menu__link">
              <span>{Translation.hu.menu.exitAdmin}</span>
              <i><CloseRounded/></i>
            </span>
          </ButtonBase>
        </li>
      }
      {menuPoints?.map((item, index) => (
        <Fragment key={index}>
          {(!item.requiredRights || hasRight(item.requiredRights)) &&
            <li className={`bs-menu__item${item.link === activeMenu ? ` bs-menu__item--active` : ``}`}>
              <ButtonBase>
                <Link
                  className="bs-menu__link"
                  to={item.link}
                  {...(item.external ?
                      {target: '_blank', rel: 'noopener noreferrer'} : {}
                  )}
                  onClick={() => onMenuSelected(item)}
                >
                  <span>{item.text}</span>
                  {item.icon && <i>{item.icon}</i>}
                </Link>
              </ButtonBase>
            </li>}
        </Fragment>
      ))}
      {!isAdminMenuVisible && isAdminAccessibleForUser &&
        <li className="bs-menu__item">
          <ButtonBase
            className="bs-menu__item"
            onClick={openAdminMenu}>
            <Link
              className="bs-menu__link"
              to={adminMenuPoints[0].link}
              {...(adminMenuPoints[0].external ?
                  {target: '_blank', rel: 'noopener noreferrer'} : {}
              )}
              onClick={() => onMenuSelected(adminMenuPoints[0])}
            >
              <span>{adminMenuPoints[0].text}</span>
              {adminMenuPoints[0].icon && <i>{adminMenuPoints[0].icon}</i>}
            </Link>
          </ButtonBase>
        </li>
      }
    </ul>
  );
};

export default BsMenu;
