import {Fragment } from 'react';
import { ColleagueDetailsDTO, ProjectDetailDTO } from "@bb-sanctuary/common";

import Translation from '../../../data/translation';
import BsProjectCard from "../../ui/project-card/project-card";

export interface UserProjectsProps {
  selectedColleague?: ColleagueDetailsDTO;
  userProjectList: Array<ProjectDetailDTO>;
  myProfile?: boolean;
  projectPage?: boolean;
}

function ColleagueProjects({
   userProjectList,
   selectedColleague,
   myProfile = false,
   projectPage,
}: UserProjectsProps) {

  return (
    <Fragment>
      <div className="bs-colleague__projects">

        <div className="bs-colleague__projects__list">
          {userProjectList.length > 0 ?
            userProjectList.map((project, index) =>
              <BsProjectCard
                key={index}
                className="bs-colleague__projects__item"
                title={project.name}
                image={project.logo || ''}
                tags={project.technologies || []}
                members={project.users || []}
                description={project.description}
                status={project.status}
              />,
            )
          :
            <div className="bs-no-result">
              <h4 className="bs-no-result__label">
                {(!projectPage && myProfile) ?
                  Translation.hu.label.noOwnProjectYet :
                  Translation.hu.label.noProjectYet(selectedColleague ? selectedColleague.firstName : '') ?
                    Translation.hu.label.noProjectsFound :
                    ''
                }
              </h4>
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
}

export default ColleagueProjects;
