export const BsRoutes = {
  login: '/login',
  hirek: '/hirfolyam',
  kollegak: '/munkatarsak',
  munkatarsak: {
    _: '/munkatarsak',
    munkatars: (email: string) => `/munkatarsak/${email}`,
  },
  ertesitesek: '/ertesitesek',
  profil: {
    _: '/profil',
  },
  hir: (newsId: number) => `/hirfolyam/${newsId}`,
  altalanosTudnivalok: {
    _: `/altalanos-tudnivalok`,
    bejegyzes: (faqEntryId: number) => `/altalanos-tudnivalok/${faqEntryId}`,
  },
  masterOfTechnology: {
    _: `/master-of-technology`,
    bejegyzes: (faqEntryId: number) => `/altalanos-tudnivalok/${faqEntryId}`,
  },
  projektek: `/projektek`,
  innovacio: `/innovacio`,
  notFound: '/404',
  cvGenerator: '/cv',

  admin: {
    _: `/admin`,
    admin: `/admin/general`,
    users: `/admin/users`,
    tech: {
      _: `/admin/tech`,
    },
    projects: {
      _: `/admin/projects`,
    },
    faq: {
      _: `/admin/faq`,
    },
    faqCategory: {
      _: `/admin/faq-category`,
    },
    bbFaq: {
      _: `/admin/bb-faq`,
    },
    bbFaqCategory: {
      _: `/admin/bb-faq-category`,
    },
    bbMedals: {
      _: `/admin/bb-medals`,
    },
    roles: `/admin/roles`,
    user: {
      _: `/admin/user`,
    },
    cv: {
      _: `/admin/cv`,
    },
  },
};
