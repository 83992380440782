import { Accordion, AccordionDetails, AccordionSummary, Collapse } from '@mui/material';
import Translation from '../../../data/translation';
import CvProjectForm from '../cv-form/CvProjectForm';
import CvProject from '../cv-form/CvProjectSummary';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { CvProjectDTO, ProjectDetailDTO } from '@bb-sanctuary/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../contexts/currentUserSlicer';
import { ProjectService } from '../../../service/projectService';
import { SnackbarIsOpen, SnackbarStatus } from '../../../../pages/CvGeneratorPage';
import BsButton from '../../ui/button/button';

export type CvGeneratorAccordionProjectProps = {
  projects: CvProjectDTO[];
  onAddNewProject: (newProject?: CvProjectDTO) => void;
  editedProj?: CvProjectDTO;
  onRemoveProject: (id: number) => void;
  onEditProject: (id: number) => void;
  setSnackbarIsOpen: (val: SnackbarIsOpen) => void;
};

export const CvGeneratorAccordionProject = ({
                                              projects,
                                              onAddNewProject,
                                              editedProj,
                                              onRemoveProject,
                                              onEditProject,
                                              setSnackbarIsOpen,
                                            }: CvGeneratorAccordionProjectProps) => {
  const currentUser = useSelector(selectCurrentUser);
  const [currentUserProjectList, setCurrentUserProjectList] = useState<ProjectDetailDTO[]>();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const addProject = (newProject?: CvProjectDTO) => {
    onAddNewProject(newProject);
    setIsEditorOpen(false);
  };

  const editProject = (id: number) => {
    onEditProject(id);
    setIsEditorOpen(true);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    (async () => {
      try {
        const projectList = await ProjectService.getCurrentUserProjectsForCv();
        setCurrentUserProjectList(projectList.data);
      } catch (err) {
        setSnackbarIsOpen({isOpen: true, message: 'Failed to retrieve project list', status: SnackbarStatus.warning});
      }
    })();
  }, [currentUser]);

  return (
    <Accordion onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
      <AccordionSummary
        expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.projectSummary}</p>
          {isAccordionOpen && !isEditorOpen &&
            <BsButton
              size={'small'}
              onClick={(e) => {
                setIsEditorOpen(true);
                e.stopPropagation();
              }}>
              {Translation.hu.cv.project.addProject}
            </BsButton>
          }
          <p>{Translation.hu.cv.accordionTitle.max(projects.length)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isEditorOpen && <div className="bs-cv-generator__form-backdrop" />}
        <div className={`bs-cv-generator__form`}>
          <Collapse in={isEditorOpen}>
            <div className={`bs-cv-generator__form__modal bs-cv-generator__form--open`}>
              <CvProjectForm
                onSetNewProject={(newProject) => addProject(newProject)}
                editedProj={editedProj}
                currentUserProjectList={currentUserProjectList}
                setSnackBarIsOpen={setSnackbarIsOpen}
                onCancel={() => setIsEditorOpen(false)}
              />
            </div>
          </Collapse>

          <div className="project-list-grid project-list-grid__header">
            <p>{Translation.hu.cv.form.header.startDate}</p>
            <p>{Translation.hu.cv.form.header.endDate}</p>
            <p>{Translation.hu.cv.form.header.company}</p>
            <p>{Translation.hu.cv.form.header.projectName}</p>
            <p>{Translation.hu.cv.form.header.description}</p>
            <p></p>
          </div>
          {
            projects
              .sort((l, r) => new Date(r.fromDate).getTime() - new Date(l.fromDate).getTime())
              .map(project => {
                return (
                  <CvProject
                    onDelete={() => onRemoveProject(project.id as number)}
                    onEdit={() => editProject(project.id as number)}
                    project={project}
                    key={project.id}
                  />
                );
              })
          }

        </div>
      </AccordionDetails>
    </Accordion>
  );
};
