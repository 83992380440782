import { Accordion, AccordionDetails, AccordionSummary, Collapse } from '@mui/material';
import Translation from '../../../data/translation';
import CvGeneratorEducationForm from '../cv-form/CvEducationForm';
import CvEducation from '../cv-form/CvEducation';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { CvEducationDTO } from '@bb-sanctuary/common';
import { useState } from 'react';
import BsButton from '../../ui/button/button';

export type CvGeneratorAccordionEducationProps = {
  educations: CvEducationDTO[];
  editedEdu?: CvEducationDTO;
  onAddNewEducation: (newEducation?: CvEducationDTO) => boolean;
  onRemoveEducation: (id: number) => void;
  onEditEducation: (id: number) => void;
};

export const CvGeneratorAccordionEducation = ({
                                                educations,
                                                editedEdu,
                                                onAddNewEducation,
                                                onRemoveEducation,
                                                onEditEducation,
                                              }: CvGeneratorAccordionEducationProps) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const addEducation = (newEducation?: CvEducationDTO): boolean => {
    const res = onAddNewEducation(newEducation);
    if (res) {
      setIsEditorOpen(false);
    }
    return res;
  };

  const editEducation = (id: number) => {
    onEditEducation(id);
    setIsEditorOpen(true);
  };

  return (
    <Accordion onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.education}</p>
          {isAccordionOpen && !isEditorOpen &&
            <BsButton
              size={'small'}
              onClick={(e) => {
                setIsEditorOpen(true);
                e.stopPropagation();
              }}>
              {Translation.hu.cv.accordionTitle.addEducation}
            </BsButton>
          }
          <p>{Translation.hu.cv.accordionTitle.max(educations.length)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="bs-cv-generator__form">
          {isEditorOpen && <div className="bs-cv-generator__form-backdrop"/>}

          <Collapse in={isEditorOpen}>
            <div className={`bs-cv-generator__form__modal bs-cv-generator__form--open`}>
              <CvGeneratorEducationForm
                onSetNewEducation={addEducation}
                editedEdu={editedEdu}
                onCancel={() => setIsEditorOpen(false)}
              />
            </div>
          </Collapse>

          <div className="education-grid education-grid__header">
            <p>{Translation.hu.cv.form.header.startDate}</p>
            <p>{Translation.hu.cv.form.header.endDate}</p>
            <p>{Translation.hu.cv.form.header.educationalInstitutionName}</p>
            <p>{Translation.hu.cv.form.header.faculty}</p>
            <p>{Translation.hu.cv.form.header.educationType}</p>
            <p></p>
          </div>
          {
            educations.map(education => {
              return (
                <CvEducation
                  onDelete={onRemoveEducation}
                  onEdit={editEducation}
                  education={education}
                  key={education.id}
                />
              );
            })
          }
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
