import { FormFieldGrid } from "@data-driven-forms/mui-component-mapper";
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { Autocomplete, AutocompleteRenderGetTagProps, Chip, TextField } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

function AutocompleteField<T extends {value?: string|number, label?: string}>({FormFieldGridProps, ...props}: any) {
  const [selection, setSelection] = useState<T | T[]>(props.multiple ? [] as T[] : {} as T);
  const {
    label,
    input,
  } = useFieldApi(props);

  const getValue = (option: T) => {
    if (props.getValue) {
      return props.getValue(option);
    }
    return option?.value;
  };

  const isEqual = (option: T, value: T) => {
    if (props.isEqual) {
      return props.isEqual(option, value);
    }
    return getValue(option) === value;
  };

  const updateSelection = (_: SyntheticEvent, val: T | T[]) => {
    setSelection(val);
    if (props.multiple) {
      input.onChange(val as T[]);
    } else {
      input.onChange(val as T);
    }
  };

  const customRenderOptions = useCallback((rOProps: React.HTMLAttributes<HTMLLIElement>,
                                      option: T,
                                      selected: { selected: boolean }) => {
    return props.renderOption(rOProps, option, selected);
  }, [props]);

  const getOptionLabel = useCallback((option: T) => {
    if (props.getOptionLabel) {
      return props.getOptionLabel(option);
    }
    return option.label || '';
  }, [props]);

  const customRenderTags = useCallback((option: T,
                                        index: number,
                                        getTagProps: AutocompleteRenderGetTagProps) => {
    if (props.renderTags) {
      return props.renderTags(option, index, getTagProps);
    }
    return <Chip variant="outlined" label={getOptionLabel(option)} {...getTagProps({ index })} />;
  }, [props, getOptionLabel]);

  useEffect(() => {
    if (input.value) {
      setSelection(input.value);
    }
  }, [input.value]);

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <Autocomplete
        {...(props.multiple ? {multiple: true} : {})}
        disablePortal
        id="combo-box-demo"
        options={props.options || []}
        sx={{}}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderTags={(value: readonly T[], getTagProps) =>
          value.map((option: T, index: number) => (customRenderTags(option, index, getTagProps)))
        }
        {... (props.renderOption ? {renderOption: customRenderOptions} : {})}
        value={selection as any}
        isOptionEqualToValue={isEqual}
        onChange={updateSelection}
        getOptionLabel={getOptionLabel}
      />
    </FormFieldGrid>
  );
}

export default AutocompleteField;
