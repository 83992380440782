import { DevelopmentMethodology } from "./DevelopmentMethodologies";
import { EducationLevel } from "./generatedCv";
import { LanguageLevel } from "./LanguageLevels";
import { ProjectDetailDTO } from '../project';

export interface CvEducationDTO {
  id?: number;
  title: string;
  level: EducationLevel;
  startDate: Date;
  endDate?: Date;
  faculty: string;
}

export interface CvLanguageDTO {
  id: number;
  language: string;
  level: LanguageLevel;
}
export interface CvProfessionalBackgroundDTO {
  id?: number;
  description: string[];
  company: string;
  title: string;
  fromDate: Date;
  toDate?: Date;
}

export interface CvCompetenceDTO {
  id: number;
  description: string;
  level: number;
}

export interface CvCourseDTO {
  id?: number;
  description: string;
  type?: string;
  startDate: Date;
  endDate?: Date;
  itSecurityCertificate: string;
}

export interface CvProjectDTO {
  id?: number;
  company: string;
  description: string;
  title: string;
  fromDate: Date;
  toDate?: Date;
  project?: ProjectDetailDTO;
}

export interface SaveNewCVDTO {
  projects: CvProjectDTO[];
  competencies: CvCompetenceDTO[];
  languages: CvLanguageDTO[];
  educations: CvEducationDTO[];
  backgrounds: CvProfessionalBackgroundDTO[];
  courses: CvCourseDTO[];
  shortSummary: string;
  domainKnowledges: string[];
  developmentMethodologies: DevelopmentMethodology[];
}

export interface CvDTO extends SaveNewCVDTO {
  id: number;
}

export interface CvListItemDTO {
  id:number;
  createdAt: Date;
  email: string;
  registrationCode: string;
}

export enum CvFileType {
  pdf = 'PDF',
  docx = 'DOCX',
}

export interface GenerateCvParam {
 withPersonalCode: boolean;
 withCompanyName: boolean;
 cvFileType: CvFileType;
}

export interface GenerateCvsParams extends GenerateCvParam {
  emails: string[];
}
