import { DevelopmentMethodology } from "@bb-sanctuary/common";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export interface DevelopmentMtdFormProps {
  data: DevelopmentMethodology[],
  onChangeHandle: (developmentMtds: DevelopmentMethodology[]) => void,
}

export const CvDevelopmentMtdForm = ({data,onChangeHandle}: DevelopmentMtdFormProps) => {
  const [developmentMtds, setDevelopmentMtds] = useState<DevelopmentMethodology[]>([]);

  const onAddNewDevelopmentMtd = (val: DevelopmentMethodology[]) => {
    setDevelopmentMtds(val);
    onChangeHandle(val);
  };

  useEffect(()=>{
    setDevelopmentMtds(data);
  },[data]);

  return (
    <div>
      <Autocomplete
        multiple
        className="bs-input"
        data-label={`Methodology`}
        value={developmentMtds}
        options={Object.values(DevelopmentMethodology)}
        onChange={(_, value) => {
          if (value !== null) {
            onAddNewDevelopmentMtd(value);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
    </div>
  );
};

export default CvDevelopmentMtdForm;
