import { ReactElement } from 'react';

export interface BsTagProps {
  label: string | ReactElement;
  active?: boolean;
  readonly?: boolean;
  className?: string;
  onClick?: () => void;
}

const BsTag = ({
  label,
  active = false,
  className = ``,
  readonly = false,
  ...props
}: BsTagProps): ReactElement => {
  return (
    <label className={`bs-tag${active ? ` bs-tag--active` : ``}${readonly ? ` bs-tag--readonly` : ``} ${className}`} {...props}>
      {label}
    </label>
  );
};

export default BsTag;
