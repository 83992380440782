import { useCallback, useMemo, useState } from 'react';
import BsConfirm from '../components/custom/confirm/confirm';

export type CreateConfirmModalProps = {
  confirmation: string;
  title?: string;
  subContent?: string;
  displayConfirmOnly?: boolean;
};

export const useCreateConfirmModal = () => {
  const [confirmation, setConfirmation] = useState<string>('');
  const [title, setTitle] = useState<string>();
  const [isOpened, setOpened] = useState(false);
  const [displayConfirmOnly, setDisplayConfirmOnly] = useState<boolean>();
  const [subContent, setSubContent] = useState<string>();
  const [promiseCb, setPromiseCb] = useState<{resolve: (res: boolean) => void, reject: ()=> void}|null>(null);
  const onClose = useCallback((res?: boolean) => {
    if (!promiseCb) {
      return;
    }
    setOpened(false);
    if (res) {
      promiseCb.resolve(res);
    } else {
      promiseCb.reject();
    }
  }, [promiseCb]);
  const dialog = useMemo(() => {
    return <BsConfirm
      open={isOpened}
      confirmation={confirmation}
      title={title}
      onClose={onClose}
      subContent={subContent}
      displayConfirmOnly={displayConfirmOnly}
    />;
  }, [isOpened, confirmation, title, onClose, subContent, displayConfirmOnly]);
  
  return {
    dialog,
    openConfirmModal: (props: CreateConfirmModalProps) => {
      setOpened(true);
      setConfirmation(props.confirmation);
      setTitle(props.title);
      setSubContent(props.subContent);
      setDisplayConfirmOnly(props.displayConfirmOnly);
      return new Promise((resolve, reject) => {
        setPromiseCb({resolve, reject});
      });
    },
  } as {
    dialog: JSX.Element,
    openConfirmModal: (props: CreateConfirmModalProps) => Promise<boolean>,
  };
};
