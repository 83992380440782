import React, { createContext, useEffect, useState } from "react";

export enum ThemeState {
  LIGHT = `light`,
  DARK = `dark`
}

type ThemeContextProps =
  | { themeState: ThemeState; setThemeState: (state: ThemeState) => void }
  | undefined
type ThemeProviderProps = { children: React.ReactNode }

export const ThemeContext = createContext<ThemeContextProps>(undefined);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [themeState, setThemeState] = useState(ThemeState.LIGHT);
  useEffect(() => {
    const storedValue: ThemeState =
      window.localStorage.getItem(`themeState`) === ThemeState.DARK ? ThemeState.DARK : ThemeState.LIGHT;
    setThemeState(storedValue);
  }, []);
  useEffect(() => {
    window.localStorage.setItem(`themeState`, themeState);
  }, [themeState]);
  return <ThemeContext.Provider value={{ themeState, setThemeState }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error(`useTheme must be used within a ThemeProvider`);
  }
  return context;
};
