import { ReactElement } from 'react';
import { ButtonBase, ButtonBaseProps } from "@mui/material";

export interface BsButtonProps extends ButtonBaseProps {
  color?: `primary` | `secondary` | `secondary-alt`;
  size?: `small` | `normal` | `large`;
  iconPosition?: `start` | `end`;
  iconSide?: ReactElement;
  iconOnly?: boolean;
}

const BsButtonIcon = (icon: ReactElement, iconPosition: string): ReactElement => (
  <i className={`bs-button__icon bs-button__icon--${iconPosition}`}>{icon}</i>
);

const BsButton = ({
  color = `primary`,
  size = `normal`,
  iconPosition = `start`,
  iconOnly = false,
  iconSide,
  className,
  ...props
}: BsButtonProps): ReactElement => {
  return (
    <ButtonBase
      type="button"
      className={`
        bs-button
        bs-button--${size}
        bs-button--${color}
        ${iconOnly ? ` bs-button--icon` : ``}
        ${className ? ` ${className}` : ``}
      `}
      color={color}
      {...props}
    >
      {(iconSide && iconPosition === `start`) && BsButtonIcon(iconSide, iconPosition)}
      {props.children}
      {(iconSide && iconPosition === `end`) && BsButtonIcon(iconSide, iconPosition)}
    </ButtonBase>
  );
};

export default BsButton;
