import { useSelector } from 'react-redux';
import { AuthRight } from '@bb-sanctuary/common';

import { selectCurrentUser } from '../../contexts/currentUserSlicer';

function createHasRight(userRights: AuthRight[]) {
  return (right: AuthRight | AuthRight[]): boolean => {
    if (typeof right !== 'string') {
      return (right as AuthRight[]).some(right => userRights.includes(right));
    }
    return userRights.includes(right as AuthRight);
  };
}

export const useCurrentUserRights = (): [(right: AuthRight | AuthRight[]) => boolean, AuthRight[]] => {
  const currentUser = useSelector(selectCurrentUser);
  const userRights: AuthRight[] = currentUser?.rights || [];
  const hasRight = createHasRight(userRights);
  return [hasRight, userRights];
};
