import { useEffect, useState } from 'react';
import { BaseColleagueDTO, getUserImage } from '@bb-sanctuary/common';

import { formatLocaleDateTime } from '../../../utils/formatters';
import { ReactComponent as BbIcon } from '../../../../assets/svg/blackbelt-logo-icon.svg';

export enum BsUploaderType {
  USER = `user`,
  SYSTEM = `system`,
}

export interface BsUploaderProps {
  name: string;
  time: string;
  user?: BaseColleagueDTO;
  type?: BsUploaderType;
  explicitTime?: Date;
}

const BsUploader = ({
  type = BsUploaderType.USER,
  name,
  time,
  explicitTime,
  user,
  ...props
}: BsUploaderProps) => {
  const [image, setImage] = useState<string | undefined>();

  useEffect(() => {
    setImage(getUserImage(user));
  }, [user]);

  return (
    <div 
      className={`bs-uploader bs-uploader--${type}`} {...props}
      title={explicitTime ? formatLocaleDateTime(explicitTime) : time}
    >
      {image &&
        <div className="bs-uploader__avatar">
          {type === BsUploaderType.SYSTEM ?
            <BbIcon className="bs-uploader__image bs-uploader__image--bb" />
          :
            <img className={`bs-uploader__image${image.includes(`default-avatar`) ? ' bs-fallback-avatar' : ''}`} src={image} alt={name}/>
          }
        </div>
      }
      <div className="bs-uploader__info">
        <div className="bs-uploader__name">
          {name}
        </div>
        <div className="bs-uploader__time">
          {time}
        </div>
      </div>
    </div>
  );
};

export default BsUploader;
