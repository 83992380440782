import { LanguageLevel } from "@bb-sanctuary/common";
import { Autocomplete, TextField } from "@mui/material";
import { useRef, useState } from "react";
import Translation from "../../../data/translation";
import BsButton from "../../ui/button/button";
import { searchOnEnter } from '../../../utils/searchOnEnter';

export interface NewLanguage {
  level: LanguageLevel;
  title: string;
}


export interface LanguageFormProps {
  onAdd: (language: NewLanguage) => boolean,
  languageLength: number,
}

const languages = [
  'Hungarian',
  'English',
  'German',
  'Spanish',
  'Italian',
  'French',
  'Portuguese',
  'Russian',
  'Japanese',
  'Mandarin',
  'Cantonese',
  'Vietnamese',
  'Turkish',
  'Korean',
];


export const LanguageLevelMap = {
  [LanguageLevel.Basic]: Translation.hu.cv.languageLevel.basic,
  [LanguageLevel.Intermediate]: Translation.hu.cv.languageLevel.intermediate,
  [LanguageLevel.UpperIntermediate]: Translation.hu.cv.languageLevel.upperIntermediate,
  [LanguageLevel.Fluent]: Translation.hu.cv.languageLevel.fluent,
  [LanguageLevel.Native]: Translation.hu.cv.languageLevel.nativ,
};

export const CvLanguageForm = ({ onAdd, languageLength }: LanguageFormProps) => {
  const [newLanguage, setNewLanguage] = useState<NewLanguage>({ title: '', level: LanguageLevel.Native });
  const newLanguageRef = useRef<HTMLInputElement|null>(null);
  const languageLevelRef = useRef<HTMLInputElement|null>(null);

  const onAddNewlanguage = () => {
    if (!newLanguage) {
      return;
    }

    if (onAdd(newLanguage)) {
      setNewLanguage({ title: '', level: LanguageLevel.Native });
      newLanguageRef.current?.focus();
    }
  };

  const languageLevelOptions = () => {
    const options = Object.values(LanguageLevel);
    return languageLength < 1 ? [LanguageLevel.Native] : options;
  };

  return (
    <div className="bs-cv-form">
      <Autocomplete
        className="bs-cv-form-autocomplete bs-cv-form-autocomplete__language"
        data-label={Translation.hu.cv.form.header.language}
        options={languages}
        freeSolo={true}
        value={newLanguage?.title}
        onChange={(_, value) => {
          if (value) {
            setNewLanguage({ title: value, level: newLanguage.level });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={Translation.hu.cv.form.header.language}
            onChange={(e) => setNewLanguage({title: e.target.value,level: newLanguage.level})}
            onKeyUp={searchOnEnter(() => languageLevelRef.current?.focus())}
            inputRef={newLanguageRef}
          />
        )}
      />
      <Autocomplete
        className="bs-cv-form-autocomplete bs-cv-form-autocomplete__language"
        data-label={Translation.hu.cv.form.header.languageLevel}
        options={languageLevelOptions()}
        renderOption={(props, option) => (
          <li {...props} >{LanguageLevelMap[option]}</li>
        )}
        value={newLanguage?.level}
        onChange={(_, value) => {
          if (value !== null) {
            setNewLanguage({ title: newLanguage?.title, level: value });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyPress={e => e.preventDefault()}
            onKeyDown={e => e.preventDefault()}
            onKeyUp={searchOnEnter(() => onAddNewlanguage())}
            value={LanguageLevelMap[newLanguage.level]}
            label={Translation.hu.cv.form.header.languageLevel}
            inputRef={languageLevelRef}
          />
        )}
        disableClearable
      />
      <BsButton
        disabled={!newLanguage.title}
        size="small"
        onClick={onAddNewlanguage}
      >{Translation.hu.cv.form.add}</BsButton>
    </div>
  );
};

export default CvLanguageForm;