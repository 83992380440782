import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCurrentUserData } from './currentUserSlicer';
import { GoogleOAuthProvider } from '@react-oauth/google';

export interface AuthContextData {
  isAuthenticated: boolean | null;
}


const isDev = window.location.origin === 'http://localhost:3000';
const clientId = isDev ? '508976101237-huo48pu2gag5tnibcfsne11lj3icfn1t.apps.googleusercontent.com' :
  '508976101237-gqgt5ns4ftshmjmkshma525cqhqal86g.apps.googleusercontent.com';

const AuthProvider = (props: any) => {
  const dispatch = useDispatch();

  const checkSanctuaryLogin = async () => {
    try {
      const sanctuaryAccountData = await axios.get('/api/account');
      dispatch(setCurrentUserData(sanctuaryAccountData.data));
    } catch {
      dispatch(setCurrentUserData(null));
    }
  };

  useEffect(() => {
    checkSanctuaryLogin();
  }, []);

  return (
  <>
    <GoogleOAuthProvider clientId={clientId}>
      {props.children}
    </GoogleOAuthProvider>;
  </>
  );
};

export { AuthProvider };
