import { Accordion, AccordionDetails, AccordionSummary, Chip } from '@mui/material';
import Translation from '../../../data/translation';
import CvLanguageForm, { LanguageLevelMap, NewLanguage } from '../cv-form/CvLanguageForm';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { CvLanguageDTO } from '@bb-sanctuary/common';

export type CvGeneratorAccordionLanguageProps = {
  languages: CvLanguageDTO[],
  onAddNewlanguage: (newLanguage: NewLanguage) => boolean,
  onRemoveLanguage: (id: number) => void,
};

export const CvGeneratorAccordionLanguage = ({
                                               languages,
                                               onAddNewlanguage,
                                               onRemoveLanguage,
                                             }: CvGeneratorAccordionLanguageProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.language}</p>
          <p>{Translation.hu.cv.accordionTitle.max(languages.length)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CvLanguageForm languageLength={languages.length} onAdd={onAddNewlanguage}/>
        <div className="bs-cv-form">
          <div className="bs-cv-generator__flex-list">
            {
              languages?.map(language => {
                return (
                  <div className="" key={language.id}>
                    <Chip label={`${language.language} ${LanguageLevelMap[language.level]}`}
                          onDelete={() => onRemoveLanguage(language.id)}/>
                  </div>
                );
              })
            }
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
