import { useState } from "react";
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { FormFieldGrid } from "@data-driven-forms/mui-component-mapper";

import { convertBase64, uploadFile } from "../../../utils/page-resource";
import BsButton from '../../ui/button/button';
import { NoAccounts } from '@mui/icons-material';

const DefaultRatioTolerance = 0.05;

const ImageSelector = (props: any) => {
  const {
    label,
    input,
    meta: { error, touched },
    helperText,
  } = useFieldApi(props);

  const maxSize = 5120000;
  const imageType = props.imageType || 'general';
  const ratio = props.ratio;
  const id = input.name;
  const photo = input.value || '';
  const onPhotoChange = input.onChange;
  const [isOver, setIsOver] = useState(false);
  const [imageValidationError, setImageValidationError] = useState<string | undefined>();
  const disableUploadOnSelect = !!props.disableUploadOnSelect;

  function updatePhoto() {
    const inputElement: any = document.getElementById(id);
    const _imageValidationError: any = getImageValidationError(inputElement.files);
    setImageValidationError(_imageValidationError);
    if (!_imageValidationError) {
      uploadAndSetPhoto();
    }
  }

  function getImageValidationError(files: any) {
    if (!files || files.length === 0) {
      return 'Not found file';
    }

    const name = files[0].name;
    const fileSize = files[0].size;
    const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    if (maxSize !== undefined && fileSize > maxSize) {
      return `File size is too large (max ${Math.floor(maxSize / 1024)} KB)`;
    }
    if (!['gif', 'jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
      return 'Invalid file extension';
    }
    return false;
  }

  function uploadAndSetPhoto() {
    const inputElement: any = document.getElementById(id);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const imgPreview: any = document.getElementById(`${id}Preview`);
      if (ratio && imgPreview) {
        imgPreview.src = e.target.result;
        imgPreview.onload = () => {
          imgPreview.onload = null;
          const loadedImageRatio = imgPreview.naturalWidth / imgPreview.naturalHeight;
          const expectedRatioMin = ratio.length === 1 ? (ratio[0] - DefaultRatioTolerance) : (ratio[0] - ratio[1]);
          const expectedRatioMax = ratio.length === 1 ? (ratio[0] - DefaultRatioTolerance) : (ratio[0] + ratio[1]);

          if (!(loadedImageRatio >= expectedRatioMin && loadedImageRatio <= expectedRatioMax)) {
            setImageValidationError(`The selected image doesn't match the expected image ratio. This image resolution is ${imgPreview.naturalWidth}x${imgPreview.naturalHeight}px, with a ratio of ${loadedImageRatio.toFixed(2)}. It should have a ratio of ${ratio[0]}, meaning the selected image height should be ${Math.round(imgPreview.naturalWidth / ratio[0])}px`);
          } else {
            uploadSelectedFile();
          }
        };
      } else {
        imgPreview.src = e.target.result;
        uploadSelectedFile();
      }
    };
    reader.readAsDataURL(inputElement.files[0]);
  }

  async function uploadSelectedFile() {
    if (!disableUploadOnSelect) {
      uploadFile(id, imageType)
        .then((path) => {
          onPhotoChange(path);
          setImageValidationError(undefined);
        })
        .catch((err) => {
          console.error('Error during upload', err);
          onPhotoChange(undefined);
          setImageValidationError('Failed to upload the file');
        });
    } else {
      const value = await convertBase64((document.getElementById(id) as any).files[0]);
      if (!value) {
        throw new Error('Failed to parse image!');
      }
      input.onChange(value);
    }
  }

  function browseFile() {
    document.getElementById(id)?.click();
  }

  function deleteImage() {
    const image: any = document.getElementById(`${id}Preview`);
    if (image && window.prompt('Are you sure you want to delete this image? Type delete to confirm') === 'delete') {
      onPhotoChange(null);
      image.src = null;
    }
  }

  function handleDragEnter(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragLeave(e: any) {
    setIsOver(false);
    e.preventDefault();
    e.stopPropagation();
  }
  function handleDragOver(e: any) {
    setIsOver(true);
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDrop(e: any) {
    setIsOver(false);
    e.preventDefault();
    e.stopPropagation();
    const inputElement: any = document.getElementById(id);
    inputElement.files = e.dataTransfer.files;
    const _imageValidationError: any = getImageValidationError(inputElement.files);
    setImageValidationError(_imageValidationError);
    if (!_imageValidationError) {
      uploadAndSetPhoto();
    }
  }

  return (
    <FormFieldGrid {...props.FormFieldGridProps}>
      <div className="image-selector">
        <div className={'image-selector__container ' + (isOver ? 'image-selector__container--active-dropzone' : '') }
             onDrop={e => handleDrop(e)}
             onDragOver={e => handleDragOver(e)}
             onDragEnter={e => handleDragEnter(e)}
             onDragLeave={e => handleDragLeave(e)}>
          <label>{label}</label>

          <div className="image-selector__image-browser">
            {!photo && <NoAccounts /> }
            <div className="image-selector__image-browser__img-wrapper">
              <img src={photo.startsWith('data:image/') ? photo : `/api/file/${photo}`}
                   className={!!photo ? '' : 'image-selector__image-empty'}
                   style={{height: 350}}
                   id={id + 'Preview'}
                   alt="cover"/>
            </div>

            <p className="image-selector__browse">Drop your image here, or
              <BsButton onClick={browseFile} size="small">browse</BsButton> or
              <BsButton onClick={deleteImage} size="small" color="secondary">delete</BsButton></p>
            <p className="image-selector__browse-helper">Supports JPG or PNG</p>
          </div>

          <label className="image-selector__file-selector">
            <input className="file-input"
                   type="file"
                   name="coverImage"
                   onChange={updatePhoto}
                   id={id} />
          </label>
        </div>
        {helperText && <p className="image-selector__helper-text">{helperText}</p>}
        {touched && error && <p className="image-selector__error">{error}</p>}
        {imageValidationError && <p className="image-selector__error">{imageValidationError}</p>}
      </div>
    </FormFieldGrid>
  );
};

export default ImageSelector;
