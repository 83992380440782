import { useEffect, useRef, useState } from 'react';
import { FAQCategoryDTO, FaqCategoryGroup, FAQEntryDTO } from '@bb-sanctuary/common';
import { Search } from '@mui/icons-material';

import { Translation } from '../shared/data/translation';
import BsInput from '../shared/components/ui/input/input';
import BsButton from '../shared/components/ui/button/button';
import { FaqService } from '../shared/service/faqService';
import FaqListing from '../shared/components/custom/faq-listing/FaqListing';
import FaqSearchListing from '../shared/components/custom/faq-search-listing/FaqSearchListing';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';
import { useIsInViewport } from '../shared/utils/inViewport';
import { useLoading } from '../shared/utils/useLoader.hook';
import { searchOnEnter } from '../shared/utils/searchOnEnter';

function FaqPage({subject}: { subject: FaqCategoryGroup }) {
  const setLoading = useLoading();
  const [faqData, setFaqData] = useState<Array<FAQCategoryDTO> | undefined>();
  const [topicName, setTopicName] = useState<string | undefined>('');
  const [topicData, setTopicData] = useState<FAQCategoryDTO | undefined>();
  const [subtopicData, setSubtopicData] = useState<FAQEntryDTO>();
  const [subtopicName, setSubtopicName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [faqSearchResult, setFaqSearchResult] = useState<Array<FAQEntryDTO> | undefined>();
  const refFaqCategorySelector = useRef() as React.MutableRefObject<HTMLInputElement>;
  const isFaqCategorySelectorInViewport = useIsInViewport(refFaqCategorySelector);

  const getFaq = async (forceReload?: boolean) => {
    try {
      setLoading(true);
      const query = (forceReload || !searchText) ? FaqService.getFaq(subject) : FaqService.searchFaq(subject, searchText);

      const res = await query;
      setFaqData(res.data.faqList?.sort((a, b) => a.order - b.order));
      setFaqSearchResult(res.data.searchResult?.sort((a, b) => a.order - b.order));
    } finally {
      setLoading(false);
    }
  };

  const getSelectedTopicData = (topic?: string) => {
    setTopicName(topic);

    const foundFaq = (faqData || []).find((subtop) => subtop.title === topic);
    setTopicData(foundFaq);
    setSubtopicData(undefined);
    setSubtopicName('');
  };

  const getSelectedSubtopicData = (subtopic: string) => {
    const foundEntry = topicData?.entries.find((entry) => entry.title === subtopic);
    setSubtopicData(foundEntry);
    setSubtopicName(subtopic);
  };

  const scrollToTop = () => {
    const element = document.getElementById('bs-faq');
    element && element.scrollIntoView();
  };

  useEffect(() => {
    setSubtopicName('');
    setTopicName('');
    setSearchText('');
    getFaq(true);
  }, [subject]);

  useEffect(() => {
    getSelectedTopicData(topicName);
  }, [topicName]);

  useEffect(() => {
    getSelectedSubtopicData(subtopicName);
  }, [subtopicName]);

  return (
    <div className="bs-faq" id="bs-faq">
      <div ref={refFaqCategorySelector}>
        <BsContentHeader>
          <BsInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyUp={searchOnEnter(() => getFaq())}
            placeholder={Translation.hu.placeholder.searchFaq}
            endAdornment={
              <BsButton onClick={() => getFaq()} color="secondary" className="bs-helper-mobile-icon">
                <span className="text">{Translation.hu.common.search}</span>
                <Search className="icon" />
              </BsButton>
            }
          />
        </BsContentHeader>
      </div>

      {!faqSearchResult && faqData &&
        <FaqListing
          faqData={faqData}
          getSelectedSubtopicData={getSelectedSubtopicData}
          subtopicName={subtopicName}
          getSelectedTopicData={getSelectedTopicData}
          subtopicData={subtopicData}
          topicData={topicData}
          topicName={topicName}
          isScrollToTopActive={!isFaqCategorySelectorInViewport}
          scrollToTop={scrollToTop}
          type={subject}
        />
      }

      {faqSearchResult &&
        <FaqSearchListing
          subject={subject}
          searchResult={faqSearchResult} />
      }

    </div>
  );
}

export default FaqPage;
