import React from 'react';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import { CloseOutlined } from "@mui/icons-material";

import AppRouting from './AppRouting';
import ContextProvider from './contexts';

function App() {
  const snackBarRef = React.createRef<any>();
  const onClickDismiss = (key: any) => () => {
    snackBarRef.current.closeSnackbar(key);
  };

  return (
    <ContextProvider>
      <SnackbarProvider
        maxSnack={5}
        ref={snackBarRef}
        action={(key) => (
          <IconButton aria-label="delete" onClick={onClickDismiss(key)}>
            <CloseOutlined color="success" />
          </IconButton>
        )}
      >
        <AppRouting />
      </SnackbarProvider>
    </ContextProvider>
  );
}

export default App;
