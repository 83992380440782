import BsInput from "../shared/components/ui/input/input";
import { ChangeEvent, useEffect, useState } from "react";
import { searchOnEnter } from "../shared/utils/searchOnEnter";
import Translations, { Translation } from "../shared/data/translation";
import BsButton from "../shared/components/ui/button/button";
import { FilterAlt, FilterAltOff, Search } from "@mui/icons-material";
import { BsContentHeader } from "../shared/components/layout/header/bsContentHeader";
import { ProjectService } from "../shared/service/projectService";
import ColleagueProjects from "../shared/components/custom/colleague-projects/ColleagueProjects";
import { CloseOutlined } from "@mui/icons-material";
import { ProjectDetailDTO, ProjectStatus } from "@bb-sanctuary/common";
import { useLoading } from "../shared/utils/useLoader.hook";
import BsTag from "../shared/components/ui/tag/tag";
import BsProjectsFilter from "../shared/components/custom/projects-filter/projects-filter";
import { ReactComponent as ImagePlaceholder } from '../assets/anim/image-placeholder.svg';

interface FilterItem {
  label: string;
  key?: ProjectStatus;
}

const statusFilterOptions: Array<FilterItem> = [
  { label: "Mind" },
  {
    label: "Aktív",
    key: ProjectStatus.Active,
  },
  {
    label: "Inaktív",
    key: ProjectStatus.Inactive,
  },
];

function ProjectsPage() {
  const [projectsUpdated, setProjectsUpdated] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const setLoading = useLoading();
  const [isProjectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<Array<ProjectDetailDTO>>([]);
  const [totalSize, setTotalSize] = useState<number | undefined>(undefined);
  const [membersFilter, setMembersFilter] = useState<string[]>([]);
  const [techsFilter, setTechsFilter] = useState<Array<number>>([]);
  const [statusFilter, setStatusFilter] = useState<ProjectStatus | undefined>();
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const [pageSize] = useState<number>(8);
  const [searchParams, setSearchParams] = useState({page: 0, searchTerm: ''});

  const getProjects = async (searchMode: boolean) => {
    setLoading(true);
    setProjectsLoading(true);
    try {
      const params = {
        searchText: searchParams.searchTerm,
        fromPage: searchParams.page,
        includeTotalCount: true,
        pageSize,
        searchMembers: membersFilter,
        searchTech: techsFilter,
        status: statusFilter,
      };
      const res = await ProjectService.searchProject(params);
      if (searchMode) {
        setProjectList(res.data.resultList);
      } else {
        setProjectList([...projectList, ...res.data.resultList]);
      }
      setTotalSize(res.data.totalCount);
    } finally {
      setLoading(false);
      setProjectsLoading(false);
      setProjectsUpdated(projectsUpdated + 1);
    }
  };

  const searchProject = () => {
    setSearchParams({...searchParams, searchTerm, page: 0});
    setProjectList([]);
  };

  useEffect(() => {
    getProjects(false);
  }, [searchParams]);

  useEffect(() => {
    getProjects(true);
  }, [techsFilter, membersFilter, statusFilter]);

  const isMoreToLoad = (): boolean => {
    return !!totalSize && totalSize > (searchParams.page + 1) * pageSize;
  };

  const loadMore = () => {
    if (isMoreToLoad()) {
      setSearchParams({...searchParams, page: searchParams.page + 1});
    }
  };

  const onDeleteSearchTerm = () => {
    setSearchParams({searchTerm: '', page: 0});
    setSearchTerm('');
  };

  const clearFilters = () => {
    setSearchParams({...searchParams, page: 0});
    setProjectList([]);
    setTechsFilter([]);
    setMembersFilter([]);
    setStatusFilter(undefined);
  };

  const handleFilterSection = () => {
    clearFilters();
    setIsFiltersVisible(!isFiltersVisible);
  };

  return (
    <div className="bs-projects">
      <BsContentHeader>
        <BsInput
          value={searchTerm}
          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchTerm(e.target.value)}
          onKeyUp={searchOnEnter(searchProject)}
          placeholder={Translations.hu.placeholder.searchProjects}
          endAdornment={
          <div>
            {searchParams.searchTerm.length > 0 &&
              <div className="bs-button__group">
                <BsButton onClick={searchProject} color="secondary" className="bs-helper-mobile-icon">
                  <span className="text">{Translations.hu.common.search}</span>
                  <Search className="icon" />
                </BsButton>
                <BsButton color="secondary" onClick={onDeleteSearchTerm}><CloseOutlined/></BsButton>
              </div>
            }
            {searchParams.searchTerm.length === 0 &&
              <BsButton onClick={searchProject} color="secondary" className="bs-helper-mobile-icon">
                <span className="text">{Translations.hu.common.search}</span>
                <Search className="icon" />
              </BsButton>}
          </div>
          }
        />
      </BsContentHeader>

      <div className="bs-projects__filters">
        { statusFilterOptions.map((filter, index) => (
          <BsTag
            key={index}
            label={filter.label}
            active={statusFilter === filter.key}
            onClick={() => setStatusFilter(filter.key)} />
        )) }
        <BsButton
          onClick={handleFilterSection}
          size={"small"}
          color={"secondary"}>
          {isFiltersVisible ? (
            <FilterAltOff className="icon icon--in-tag" />
          ) : (
            <FilterAlt className="icon icon--in-tag" />
          )}
        </BsButton>
        <div className={`bs-projects__filters__options${ isFiltersVisible ? ` bs-projects__filters__options--active` : `` }`}>
          <BsProjectsFilter
            searchMembers={membersFilter}
            searchTechnologyIds={techsFilter}
            onSearchMembers={setMembersFilter}
            onSearchTechs={setTechsFilter}
          />
        </div>
      </div>

      {(projectsUpdated > 0 && projectList?.length > 0) ?
        <ColleagueProjects userProjectList={projectList} projectPage={true} />
        :
        <div className="bs-no-result bs-no-result--spacing">
          {isProjectsLoading ?
            <h4>
              <ImagePlaceholder className={`bs-profile-card__image bs-profile-card__image--placeholder`}/>
            </h4>
            :
            <h4 className="bs-no-result__label">
              {Translation.hu.label.noProjectsFound}
            </h4>
          }
        </div>
      }

      <div className="bs-news-feed__navigation">
        {isMoreToLoad() &&
          <div className="bs-load-more">
            <BsButton color="secondary" onClick={loadMore}>{Translation.hu.common.loadMore}</BsButton>
          </div>
        }
      </div>

    </div>
  );
}

export default ProjectsPage;
