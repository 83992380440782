import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { Drawer, Tooltip } from '@mui/material';
import { Notifications, ArrowBack } from '@mui/icons-material';
import { BadgeHistoryDTO, BadgeHistoryType } from '@bb-sanctuary/common';

import BsNotificationCard from '../../ui/notification-card/notification-card';
import { getBadgeIcon, getBadgeReceivedText } from '../../../data/badge-kit';
import { selectCurrentUser } from '../../../../contexts/currentUserSlicer';
import { useNotyBar } from '../../../../contexts/notybar.context';
import { formatLocaleDate } from '../../../utils/formatters';
import Loading from '../../ui/loading/loading';
import BsButton from '../../ui/button/button';
import Translation from '../../../data/translation';
import { IStore } from '../../../../store';
import { fetchNotifications, selectNotifications } from '../../../../contexts/appSlicer';

function BsNotifications(): ReactElement {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const notificationsStatus = useSelector((state: IStore) => state.app.notifications.status);
  const loadedNotifications = useSelector(selectNotifications);

  const {notyBarState, setNotyBarState} = useNotyBar();

  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState<Array<BadgeHistoryDTO>>([]);

  const getNotifications = useCallback( async (forceUpdate?: boolean) => {
    if (currentUser && (notificationsStatus === 'idle' || forceUpdate)) {
      setLoading(true);
      // unfortunate casting because of dependency conflicts
      dispatch(fetchNotifications(currentUser.email) as unknown as AnyAction);
    }
  }, [currentUser, dispatch, notificationsStatus]);

  useEffect(() => {
    if (notificationsStatus === 'error') {
      setLoading(false);
    }
    if (notificationsStatus === 'fulfilled') {
      setLoading(false);
      setNotificationList(loadedNotifications);
    }
    if (notificationsStatus === 'error') {
      setLoading(false);
      // TODO handle error
    }
  }, [notificationsStatus, setLoading, loadedNotifications]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    if (notyBarState) {
      getNotifications(true);
    };
  }, [notyBarState]);

  return (
    <div className="bs-notifications">
      <Tooltip title={Translation.hu.label.myNotifications}>
        <div className="bs-notifications__handler" onClick={() => setNotyBarState(true)}>
          <Notifications />
        </div>
      </Tooltip>
      <div className="bs-notifications__container">
        <Drawer
          anchor={`right`}
          open={notyBarState}
          onClose={() => setNotyBarState(false)}
        >
          <div className="bs-notifications__header">
            <BsButton size={`small`} color={`secondary`} iconSide={<ArrowBack />} onClick={() => setNotyBarState(false)}>
              {Translation.hu.common.back}
            </BsButton>
            <h2 className="bs-notifications__title">{Translation.hu.label.myNotifications}</h2>
          </div>
          {loading &&
            <div className="bs-notifications__loading">
              <Loading />
            </div>
          }
          {notificationList.length > 0 ?
            <ul className="bs-notifications__list">
              {notificationList.map((item, index) => (
                <BsNotificationCard
                  key={index}
                  text={getBadgeReceivedText(item)}
                  icon={getBadgeIcon(item)}
                  uploader={item.from}
                  uploaderName={`${item.from.lastName} ${item.from.firstName}`}
                  uploaderTime={formatLocaleDate(item.createdAt)}
                  explicitTime={item.createdAt}
                  hideUploader={item.type === BadgeHistoryType.Sent}
                  message={item.message}
                />
              ))}
            </ul>
          :
            <div className="bs-notifications__list">
              <div className="bs-no-result">
                <h4 className="bs-no-result__label">
                  {Translation.hu.label.noNotificationsYet}
                </h4>
              </div>
            </div>
          }
        </Drawer>
      </div>
    </div>
  );
}

export default BsNotifications;
