import {useState} from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
// @ts-ignore
import FroalaEditor from "froala-editor";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { WysiwygConfig } from '../../ui/base-wysiwyg-editor/wysiwygConfig';

function clearElementStyles(element: HTMLStyleElement) {
  element.removeAttribute('style');
  if (element.children && element.children.length > 0) {
    for(let i = 0; i < element.children.length; i++) {
      clearElementStyles(element.children[i] as HTMLStyleElement);
    }
  }
}
function clearColorStyles(element: HTMLStyleElement) {
  element.style.background = '';
  element.style.color = '';
  if (element.children && element.children.length > 0) {
    for(let i = 0; i < element.children.length; i++) {
      clearColorStyles(element.children[i] as HTMLStyleElement);
    }
  }
}

FroalaEditor.RegisterCommand('Stílustanalítás', {
  title: 'Egyedi stílusbeállítások törlése',
  refreshAfterCallback: true,
  callback: function () {
    this.html.blocks()
      .forEach((block: HTMLStyleElement) => {
        clearElementStyles(block);
      });
  },
});
FroalaEditor.RegisterCommand('Színtelenítés', {
  title: 'Háttérszínek, és betűszínek törlése',
  refreshAfterCallback: true,
  callback: function () {
    this.html.blocks()
      .forEach((block: HTMLStyleElement) => {
        clearColorStyles(block);
      });
  },
});

const WysiwygEditor = (props: any) => {
  const {
    input,
  } = useFieldApi(props);

  const config = WysiwygConfig();
  const [value, setValue] = useState(input.value);
  const updateValue = (value: any) => {
    setValue(value);
    input.onChange(value);
  };

  return (
    <div className="MuiGrid-root makeStyles-grid-1 MuiGrid-item MuiGrid-grid-xs-12">
      <FroalaEditorComponent tag="textarea"
                             config={config}
                             model={value}
                             onModelChange={updateValue}/>
    </div>
  );
};

export default WysiwygEditor;
