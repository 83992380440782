import {Fragment, useState} from 'react';
import {ReactComponent as InnovationBoard} from './../../../../assets/svg/innovation_board.svg';
import {ReactComponent as InnovationHead} from './../../../../assets/svg/innovation_head.svg';
import {ReactComponent as InnovationCoordinator} from './../../../../assets/svg/innovation_coordinator.svg';
import {ReactComponent as InnovationGroup} from './../../../../assets/svg/innovation_group.svg';
import {ReactComponent as InnovationFlow1} from './../../../../assets/svg/innovation_flow_1.svg';
import {ReactComponent as InnovationFlow2} from './../../../../assets/svg/innovation_flow_2.svg';
import {ReactComponent as InnovationFlow3} from './../../../../assets/svg/innovation_flow_3.svg';
import {ReactComponent as InnovationFlow4} from './../../../../assets/svg/innovation_flow_4.svg';
import InnovationStructure from './../../../../assets/images/innovation_structure.png';
import InnovationStructure2 from './../../../../assets/images/innovation_structure2.png';
import BsButton from '../../ui/button/button';

const innovationFlowData = [
  {
    name: 'Döntés a Stream-ekről',
    id: 'stream',
    order: 1,
    image: <InnovationFlow1/>,
    contentList: ['Az Innovation Board döntése alapján az Innovation Head koordinációjával szakmai és üzleti szempontok alapján a szakmai és a sales csapat döntéselőkészítő anyagot hoz létre', 'Minden Stream-hez definiálni kell egy üzleti (mivel, hogyan és miért akarunk foglalkozni) és egy kereskedelmi stratégiát is (kinek és hogyan akarjuk értékesíteni).', 'Az adott Stream mindig az IB döntése alapján jön létre (vagy módosul, vagy szűnik meg)'],
  },
  {
    name: 'Topic-ok kialakítása',
    id: 'topic',
    order: 2,
    image: <InnovationFlow2/>,
    contentList: ['Minden Stream-en belül Topic-okat alakítunk ki (IB döntés), amelyek tipikusan valamilyen szakmai/technológiai szempont alapján jönnek létre', 'Minden Topic-hoz definiálni kell egy üzleti (mivel, hogyan és miért akarunk foglalkozni) és egy kereskedelmi stratégiát is (kinek és hogyan akarjuk értékesíteni).', 'Minden Topic-hoz egy-egy felelős kerül kijelölésre', 'A Topic létrehozása (módosulása, megszűnése) az IB döntése alapján történik'],
  },
  {
    name: 'Döntés a projektek indításáról',
    id: 'launch',
    order: 3,
    image: <InnovationFlow3/>,
    contentList: ['Már korábban meglévő ötletek, illetve külső szereplőktől érkező ötletek alapján jönnek létre a projektek, amelyek már konkrét, üzletileg is értékelhető, fejlesztési/megvalósítási egységet jelentenek', 'Az ötletek az üzleti (és kereskedelmi) stratégia alapján kerülnek értékelésre', 'IB dönt a projektek létrehozásáról (módosításáról, megszüntetéséről)'],
  },
  {
    name: ' Fejlesztési / megvalósítási fázis',
    id: 'phases',
    order: 4,
    subtitle: 'A fejlesztési és/vagy egyéb megvalósítási folyamatokat az Innovation Head vezeti, akinek az alábbi feladatai vannak:',
    image: <InnovationFlow4/>,
    contentList: ['az IB döntése alapján létrejött projektek megvalósítása,', 'a vonatkozó szakmai csapatok létrehozása,', 'a csapatok felé a fejlesztési sztorik definiálása,', 'technikai demók és minden eredménytermék értékelése', 'backlog vezetése', 'IB felé az üzleti demó megtartásáról gondoskodni'],
  },
];

const yourIdeaContent = [
  'Töltsd le az Innovációs ötleted bemutatásához használandó template-t.',
  'Készítsd el a prezidet az ötleted alapján.',
  'Nevezd el a prezit a neveddel és az ötleted nevével (pl. Gipsz Jakab_útiköltségelszámolás_2.0.ppt)',
  'Töltsd ki ezt az űrlapot, és töltsd fel a prezidet, majd küldd el nekünk.',
];

const InnovationContent = () => {
  const [selectedFlowTopic, setSelectedFlowTopic] = useState('stream');

  const onSelectedTopic = (topic: string) => {
    setSelectedFlowTopic(topic);
  };

  let filteredContent = innovationFlowData.find(topic => topic.id === selectedFlowTopic);

  return (
    <Fragment>
      <div className="bs-innovation-content">
        <div className="bs-innovation-content__block" id="roles">
          <h3 className="bs-innovation-content__block__title">Innovációs szervezet szerepkörök</h3>
          <div className="bs-innovation-content__row">
            <div className="bs-innovation-content__row__image">
              <p>Innovation Board</p>
              <div className="bs-innovation-content__row__image__svg">
                <InnovationBoard/>
              </div>
            </div>
            <div className="bs-innovation-content__row__text">
              <ul className="bs-innovation-content__row__text__items">
                <li className="bs-innovation-content__row__text__item">„Business” szerepkör, egyfajta belső megrendelő
                </li>
                <li className="bs-innovation-content__row__text__item">Mivel kell foglalkozni és mennyit szán erre?</li>
                <li className="bs-innovation-content__row__text__item">Stratégiai döntések, streamek, projektek, büdzsé
                  meghatározása
                </li>
                <li className="bs-innovation-content__row__text__item">Tagok: CEO, Sales Manager, Innovation Head,
                  Business Development Manager, egy-egy BU képviselő
                </li>
              </ul>
            </div>
          </div>

          <div className="bs-innovation-content__row">
            <div className="bs-innovation-content__row__text">
              <ul className="bs-innovation-content__row__text__items">
                <li className="bs-innovation-content__row__text__item">„Product Owner” szerepkör</li>
                <li className="bs-innovation-content__row__text__item">Kapcsolat az üzleti és a technológiai oldal
                  között
                </li>
                <li className="bs-innovation-content__row__text__item">Story-k kialakítása, Backlog vezetése</li>
                <li className="bs-innovation-content__row__text__item">Motiváció fenntartása, kapcsolat a szakmai
                  vezetőkkel
                </li>
              </ul>
            </div>
            <div className="bs-innovation-content__row__image">
              <p>Innovation Head</p>
              <div className="bs-innovation-content__row__image__svg">
                <InnovationHead/>
              </div>
            </div>
          </div>

          <div className="bs-innovation-content__row">
            <div className="bs-innovation-content__row__image">
              <p>Innovation Coordinator</p>
              <div className="bs-innovation-content__row__image__svg">
                <InnovationCoordinator/>
              </div>
            </div>
            <div className="bs-innovation-content__row__text">
              <ul className="bs-innovation-content__row__text__items">
                <li className="bs-innovation-content__row__text__item">„Project Manager” (Scrum Master) szerepkör</li>
                <li className="bs-innovation-content__row__text__item">A folyamatok kézben tartása, követése</li>
                <li className="bs-innovation-content__row__text__item">Innovation események (Innovation Day,
                  technológiai és üzleti demók stb.) szervezése, lebonyolítása
                </li>
                <li className="bs-innovation-content__row__text__item">Koordináció az ötletgazdák és az Innovációs
                  szervezet között
                </li>
              </ul>
            </div>
          </div>

          <div className="bs-innovation-content__row">
            <div className="bs-innovation-content__row__text">
              <ul className="bs-innovation-content__row__text__items">
                <li className="bs-innovation-content__row__text__item">Szakmai munka végzése tematikus alapon, a tagok
                  az R&D/PDU/SDU/Sales unitokból kerülnek ki, illetve döntés alapján külsős együttműködő is részt
                  vállalhat.
                </li>
                <li className="bs-innovation-content__row__text__item">Minden csoportnak van szakmai vezetője, aki adott
                  esetben több csoport vezetője is lehet
                </li>
                <li className="bs-innovation-content__row__text__item">A szakmai vezető munkaideje biztosított a feladat
                  végrehajtására
                </li>
                <li className="bs-innovation-content__row__text__item">Szakmai munka: vagy munkaidőn belül (BU vezető
                  jóváhagyása kell), vagy munkaidőn túl
                </li>
                <li className="bs-innovation-content__row__text__item">Taskok kialakítása, riportok készítése, demók
                  megtartása
                </li>
              </ul>
            </div>
            <div className="bs-innovation-content__row__image">
              <p>Innovation Group</p>
              <div className="bs-innovation-content__row__image__svg">
                <InnovationGroup/>
              </div>
            </div>
          </div>
        </div>

        <div className="bs-innovation-content__block" id="structure">
          <h3 className="bs-innovation-content__block__title">Innovációs szervezet felépítése</h3>
          <div className="bs-innovation-content__block__photo">
            <img src={InnovationStructure} alt="Innovációs szervezet felépítése"/>
          </div>
        </div>

        <div className="bs-innovation-content__block bs-innovation-content__block--orange" id="flow">
          <h3 className="bs-innovation-content__block__title bs-innovation-content__block__title--orange">Innovációs
            folyamat</h3>
          <div className="bs-innovation-content__block__buttons">
            <BsButton
              color={selectedFlowTopic === 'stream' ? 'secondary' : 'secondary-alt'}
              size="small"
              className="bs-innovation-content__block__button"
              onClick={() => onSelectedTopic('stream')}>Döntés a Stream-ekről
            </BsButton>
            <BsButton
              color={selectedFlowTopic === 'topic' ? 'secondary' : 'secondary-alt'}
              size="small"
              className="bs-innovation-content__block__button"
              onClick={() => onSelectedTopic('topic')}>Topic-ok kialakítása
            </BsButton>
            <BsButton
              color={selectedFlowTopic === 'launch' ? 'secondary' : 'secondary-alt'}
              size="small"
              className="bs-innovation-content__block__button"
              onClick={() => onSelectedTopic('launch')}>Döntés a projektek indításáról
            </BsButton>
            <BsButton
              color={selectedFlowTopic === 'phases' ? 'secondary' : 'secondary-alt'}
              size="small"
              className="bs-innovation-content__block__button"
              onClick={() => onSelectedTopic('phases')}>Fejlesztési / megvalósítási fázis
            </BsButton>
          </div>


          {filteredContent &&
            <div className="bs-innovation-content__block__flow-content">
              <div className="bs-innovation-content__block__flow-content__image">
                {filteredContent.image}
              </div>

              <div className="bs-innovation-content__block__flow-content__text">
                <h4 className="bs-innovation-content__block__flow-content__text__title">{filteredContent.order}.
                  szint</h4>
                <h4 className="bs-innovation-content__block__flow-content__text__title">{filteredContent.name}</h4>
                {filteredContent.subtitle && <p
                  className="bs-innovation-content__block__flow-content__text__subtitle">{filteredContent.subtitle}</p>}
                <ul className="bs-innovation-content__block__flow-content__text__list">
                  {filteredContent.contentList.map((item, index) =>
                    <li key={index} className="bs-innovation-content__block__flow-content__text__list-item">{item}</li>,
                  )}

                </ul>
              </div>
            </div>
          }

        </div>

        <div className="bs-innovation-content__block">
          <div className="bs-innovation-content__block__photo">
            <img src={InnovationStructure2} alt="Innovációs szervezet felépítése"/>
          </div>
        </div>

        <div className="bs-innovation-content__block" id="bs-innovation__form">
          <h3 className="bs-innovation-content__block__title">Mutasd be saját ötleted!</h3>
          <div className="bs-innovation-content__row">
            <div className="bs-innovation-content__row__text">
              <div className="bs-innovation-content__row__text__container">
                <div>
                  <ul className="bs-innovation-content__row__text__items">
                    {yourIdeaContent.map((idea, index) =>
                      <li key={index} className="bs-innovation-content__row__text__item">{idea}</li>,
                    )}
                  </ul>
                </div>

                <div className="bs-innovation-content__row__text__buttons">
                  <BsButton size="small" color="primary">
                    <a href="https://drive.google.com/file/d/1anpzi26gtkVU-ay8dVMJxthUlaFz_Avd/view" target="_blank">
                      Template letöltése
                    </a>
                  </BsButton>
                  <BsButton size="small" color="primary">
                    <a href="https://drive.google.com/file/d/1F61fYGyym5v93e1Zuc-I8RcbJFId8PFI/view" target="_blank">
                      Hogyan mutassam be az ötletemet?
                    </a>
                  </BsButton>
                </div>
              </div>
            </div>


            <div className="bs-innovation-content__row__form">
              <iframe src="https://share.hsforms.com/1qDfbHFnARjqxD2ffiwoKCQ2zpnn" width={'100%'}
                      frameBorder="1"></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InnovationContent;
