import { Fragment } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FAQCategoryAdminDTO, FaqCategoryGroup } from "@bb-sanctuary/common";

import Translation from "../../shared/data/translation";
import GenericEntityEditor from "../../shared/components/admin/GenericEntityEditor";
import { createRequiredField, createSelectField, patternValidation } from "../../shared/utils/formUtils";
import { BsRoutes } from "../../shared/data/bsRoutes";

const FAQ_API = `/api/admin/faq/`;

const formSchema = {
  fields: [
    createRequiredField('title')
      .label('Title', '')
      .sizeColumns(6)
      .build(),
    createRequiredField('order')
      .label('Order', '')
      .sizeColumns(6)
      .validate(patternValidation(/[0-9]+/))
      .build(),
    createSelectField('categoryGroup',
      'Category Group',
      [{label: 'BlackBelt', value: FaqCategoryGroup.BB_SITE}, {label: 'Általános', value: FaqCategoryGroup.GENERAL}],
      6)
      .build({disabled: true}),
  ],
};

interface AdminFaqCategoryEditPageProps {
  subject: FaqCategoryGroup;
}

function AdminFaqCategoryEditPage({subject}: AdminFaqCategoryEditPageProps) {
  const params = useParams();
  const id = params.id;

  const loadData = async () => {
    if (id) {
      const result = await axios.get<Array<FAQCategoryAdminDTO>>(`${FAQ_API}category`);
      return result.data.find((e) => e.id === +id);
    }
    return {};
  };
  const createEntity = () => {
    return {
      categoryGroup: subject,
    };
  };
  const saveFaq = async (params: FAQCategoryAdminDTO) => {
    return axios.post(`${FAQ_API}category`, params);
  };

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__editor">
        {formSchema ?
          <GenericEntityEditor
            id={id}
            saveFn={saveFaq}
            entityLabel={subject === FaqCategoryGroup.GENERAL ? Translation.hu.label.faqCategory : Translation.hu.label.bbFaqCategory}
            loadEntityFn={loadData}
            listingUrl={subject === FaqCategoryGroup.GENERAL ? BsRoutes.admin.faqCategory._ : BsRoutes.admin.bbFaqCategory._}
            formSchema={formSchema}
            hideCancel={false}
            newEntity={createEntity}
          />
        :
          <Fragment>
            {Translation.hu.common.loading}
          </Fragment>
        }
      </div>
    </Fragment>
  );
}

export default AdminFaqCategoryEditPage;
