import { Fragment, useEffect, useState } from 'react';

import BsButton from '../../shared/components/ui/button/button';
import Translation from '../../shared/data/translation';
import { AdminService } from './AdminService';
import { BsContentHeader } from '../../shared/components/layout/header/bsContentHeader';
import { useQuery } from '../../shared/utils/userQuery';
import { AuthRight, ScheduledJobDTO } from '@bb-sanctuary/common';
import { useCurrentUserRights } from '../../shared/utils/useRight.hook';
import { Link } from 'react-router-dom';
import { BsRoutes } from '../../shared/data/bsRoutes';
import { formatLocaleDateTime } from '../../shared/utils/formatters';
import { useSnackbar } from 'notistack';
import { useLoading } from '../../shared/utils/useLoader.hook';

const GeneralAdminPage = () => {
  const query = useQuery();
  const [hubspotSyncHistory, setHubspotSyncHistory] = useState('');
  const [googleAuthUrl, setGoogleAuthUrl] = useState<string>();
  const [scheduledJobs, setScheduledJobs] = useState<Array<ScheduledJobDTO>|undefined>();
  const {enqueueSnackbar} = useSnackbar();
  const setLoading = useLoading();

  const startHubspotMigration = async () => {
    setLoading(true);
    try {
      await AdminService.startHubspotMigration();
      await showHubspotSyncHistory();
      enqueueSnackbar(Translation.hu.admin.response.done);
    } catch (err) {
      enqueueSnackbar(Translation.hu.admin.response.err);
    } finally {
      setLoading(false);
    }
  };


  const showHubspotSyncHistory = async () => {
    const history = await AdminService.showHubspotSyncHistory();
    setHubspotSyncHistory(history);
  };

  const reindexNews = async () => {
    setLoading(true);
    try {
      await AdminService.reindexNews();
      enqueueSnackbar(Translation.hu.admin.response.done);
    } catch (err) {
      enqueueSnackbar(Translation.hu.admin.response.err);
    } finally {
      setLoading(false);
    }
  };

  const reindexFAQ = async () => {
    setLoading(true);
    try {
      await AdminService.reindexFAQ();
      enqueueSnackbar(Translation.hu.admin.response.done);
    } catch (err) {
      enqueueSnackbar(Translation.hu.admin.response.err);
    } finally {
      setLoading(false);
    }
  };

  const scheduledJobsRunForce = async () => {
    setLoading(true);
    try {
      await AdminService.runScheduledJobsForce();
      enqueueSnackbar(Translation.hu.admin.response.done);
    } catch (err) {
      enqueueSnackbar(Translation.hu.admin.response.err);
    } finally {
      setLoading(false);
    }
  };

  const getScheduledJobsList = async () => {
    setScheduledJobs(await AdminService.getScheduledJobsList());
  };

  const clearUserPhotos = async () => {
    setLoading(true);
    try {
      await AdminService.clearUserPhotos();
      enqueueSnackbar(Translation.hu.admin.response.done);
    } catch (err) {
      enqueueSnackbar(Translation.hu.admin.response.err);
    } finally {
      setLoading(false);
    }
  };

  const openGoogleAuth = () => {
    window.location.href = googleAuthUrl as string;
  };

  const [hasRight] = useCurrentUserRights();

  useEffect(() => {
    const showHistory = query.get('show-history');
    if (showHistory) {
      showHubspotSyncHistory();
    }
    if (hasRight(AuthRight.AdminOfGoogleSync)) {
      AdminService.getGoogleAuthUrl()
        .then((res) => setGoogleAuthUrl(res.data));
    }
  }, []);

  return (
    <Fragment>
      <BsContentHeader></BsContentHeader>
      {<Fragment>
        {(hasRight(AuthRight.AdminOfHubspotMigration) || hasRight(AuthRight.AdminOfGoogleSync))&&
          <div className="bs-admin__section">
            <h3>{Translation.hu.admin.users.title}</h3>
            <div className="bs-admin__actions">
              {hasRight(AuthRight.AdminOfHubspotMigration) &&
                <BsButton size="small"
                          onClick={startHubspotMigration}>{Translation.hu.admin.users.updateFromHubspot}</BsButton>
              }
              {hasRight(AuthRight.Administrator) &&
                <BsButton size="small"
                          onClick={clearUserPhotos}>{Translation.hu.admin.users.clearUserPhotos}</BsButton>
              }
            </div>
            {hubspotSyncHistory &&
              <div className="bs-admin__sub-section">
                <textarea value={hubspotSyncHistory} readOnly={true} style={{width: '100%'}} rows={20}/>
              </div>
            }

            {hasRight(AuthRight.AdminOfGoogleSync) &&
              <>
                {!googleAuthUrl && <>Betöltés</> }
                {googleAuthUrl &&
                  <BsButton
                    size="small"
                    onClick={openGoogleAuth}>{Translation.hu.admin.users.syncGooglePhone}</BsButton>
                }
              </>
            }
          </div>
        }

        {hasRight([AuthRight.AdminOfRole, AuthRight.AdminOfUsers]) &&
          <div className="bs-admin__section">
            <h3>{Translation.hu.admin.user.title}</h3>
            <div className="bs-admin__actions">
              {hasRight(AuthRight.AdminOfRole) &&
                <Link to={BsRoutes.admin.roles}><BsButton
                  size="small">{Translation.hu.admin.user.handleRoles}</BsButton></Link>
              }
              {hasRight(AuthRight.AdminOfUsers) &&
                <Link to={BsRoutes.admin.user._}><BsButton
                  size="small">{Translation.hu.admin.user.manageUsers}</BsButton></Link>
              }
            </div>
          </div>
        }

        {hasRight(AuthRight.AdminOfScheduledJobs) &&
          <div className="bs-admin__section">
            <h3>{Translation.hu.admin.jobs.title}</h3>
            <div className="bs-admin__actions">
              <BsButton size="small" onClick={scheduledJobsRunForce}>{Translation.hu.admin.jobs.runForce}</BsButton>
              <BsButton size="small" onClick={getScheduledJobsList}
                        color="secondary">{Translation.hu.admin.jobs.list}</BsButton>
            </div>
            {scheduledJobs &&
              <div className="bs-admin__sub-section">
                <table className="table is-hoverable is-fullwidth">
                  <thead>
                  <tr>
                    <td>Status</td>
                    <td>Type</td>
                    <td>Started</td>
                    <td>Finished</td>
                    <td></td>
                  </tr>
                  </thead>
                  {scheduledJobs.map(job => (
                    <>
                      <tr>
                        <td>{job.status}</td>
                        <td>{job.jobType}</td>
                        <td>{formatLocaleDateTime(job.started)}</td>
                        <td>{formatLocaleDateTime(job.finished)}</td>
                        <td>{job.summary}</td>
                      </tr>
                    </>))
                  }
                </table>
              </div>
            }
          </div>
        }

        {hasRight(AuthRight.AdminOfSolrReindex) &&
          <div className="bs-admin__section">
            <h3>{Translation.hu.admin.news.title}</h3>
            <div className="bs-admin__actions">
              <BsButton size="small" onClick={reindexNews}>{Translation.hu.admin.news.reindex}</BsButton>
            </div>
          </div>
        }

        {hasRight([AuthRight.AdminOfGeneralFaq, AuthRight.AdminOfBlackbeltFaq, AuthRight.AdminOfSolrReindex]) &&
          <div className="bs-admin__section">
            {hasRight(AuthRight.AdminOfGeneralFaq) &&
              <Fragment>
                <h3>{Translation.hu.admin.faq.title}</h3>
                <h5>{Translation.hu.menu.faq}</h5>
                <div className="bs-admin__actions">
                  <Link to={BsRoutes.admin.faq._}><BsButton
                    size="small">{Translation.hu.admin.faq.entries}</BsButton></Link>
                  <Link to={BsRoutes.admin.faqCategory._}><BsButton
                    size="small">{Translation.hu.admin.faq.categories}</BsButton></Link>
                </div>
              </Fragment>
            }
            {hasRight(AuthRight.AdminOfBlackbeltFaq) &&
              <Fragment>
                <h5>{Translation.hu.menu.masterOfTechnology}</h5>
                <div className="bs-admin__actions">
                  <Link to={BsRoutes.admin.bbFaq._}><BsButton size="small">{Translation.hu.admin.faq.entries}</BsButton></Link>
                  <Link to={BsRoutes.admin.bbFaqCategory._}><BsButton
                    size="small">{Translation.hu.admin.faq.categories}</BsButton></Link>
                </div>
              </Fragment>
            }
            {hasRight(AuthRight.AdminOfSolrReindex) &&
              <Fragment>
                <h5>Reindex</h5>
                <div className="bs-admin__actions">
                  <BsButton size="small" onClick={reindexFAQ}>Reindex faq</BsButton>
                </div>
              </Fragment>
            }
          </div>
        }

        {hasRight([AuthRight.Administrator]) &&
          <div className="bs-admin__section">
            <h3>{Translation.hu.admin.tech.title}</h3>
            <div className="bs-admin__actions">
              <Link to={BsRoutes.admin.tech._} download><BsButton
                size="small">{Translation.hu.admin.tech.techList}</BsButton></Link>
            </div>
          </div>
        }

        {hasRight([AuthRight.ProjectCreate, AuthRight.ProjectMaintenance]) &&
          <div className="bs-admin__section">
            <h3>{Translation.hu.admin.projects.title}</h3>
            <div className="bs-admin__actions">
              <Link to={BsRoutes.admin.projects._} download><BsButton
                size="small">{Translation.hu.admin.projects.projectList}</BsButton></Link>
            </div>
          </div>
        }

        {hasRight(AuthRight.AdminOfBadges) &&
          <div className="bs-admin__section">
            <h3>Badge-k</h3>
            <div className="bs-admin__actions">
              <Link to={BsRoutes.admin.bbMedals._} download><BsButton size="small">Medál lista</BsButton></Link>
              <Link to={AdminService.getRiportUrl()} target="_blank" download><BsButton size="small">Riport
                letöltés</BsButton></Link>
            </div>
          </div>
        }
      </Fragment>
      }
    </Fragment>
  );
};

export default GeneralAdminPage;
