let requests = 0;

export function interceptorAuthFulfilled<V, T>(res: V): T {
  requests++;
  return res as unknown as T;
}

export function interceptorAuthRejected(err: any) {
  requests++;
  if (err.response.status === 401 && requests > 1) {
    const host = document.location.hostname;
    if (host === 'localhost') {
      document.location = '/';
    } else {
      document.location = '/admin';
    }
  }
  return Promise.reject(err);
}
