import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Translation from '../../../data/translation';
import CvDevelopmentMtdForm from '../cv-form/CvDevelopmentMtdForm';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { DevelopmentMethodology } from '@bb-sanctuary/common';

export type CvGeneratorAccordionDevelopmentMtdProps = {
  developmentMtd: DevelopmentMethodology[];
  setDevelopmentMtd: (val: DevelopmentMethodology[]) => void;
};

export const CvGeneratorAccordionDevelopmentMtd = ({
                                                     developmentMtd,
                                                     setDevelopmentMtd,
                                                   }: CvGeneratorAccordionDevelopmentMtdProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.developmentMethodology}</p>
          <p>{Translation.hu.cv.accordionTitle.max(developmentMtd.length)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="bs-cv-generator__form">
          <CvDevelopmentMtdForm data={developmentMtd}
                                onChangeHandle={(developmentMtds) => setDevelopmentMtd(developmentMtds)}/>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
