import { useRecoilState } from 'recoil';
import { BadgeConfigListDTO } from '@bb-sanctuary/common';
import { sendableBadgesAtom } from '../../state/sendableBadges.atom';
import { BadgeService } from './badgeService';
import { useEffect } from 'react';

export const useSendableBadges = () => {
  const [sendableBadges, setSendableBadges] = useRecoilState<BadgeConfigListDTO>(sendableBadgesAtom);

  const updateSendableBadges = async () => {
    try {
      const res = await BadgeService.getBadgesForColleagues();
      setSendableBadges(res);
    } catch (err) {
      console.error(`Failed to get badges for colleagues`, err);
    }
  };

  useEffect(() => {
    if (!sendableBadges) {
      updateSendableBadges();
    }
  }, []);

  return {
    sendableBadges,
    updateSendableBadges,
  };
};
