import {useEffect, useState} from "react";
import {TechnologyDTO, UserDTO} from "@bb-sanctuary/common";

import BsTag from '../tag/tag';
import BsProfileTag from "../profile-tag/profile-tag";
import DefaultProjectImage from './../../../../assets/images/default-project-image.png';
import {ReactComponent as BsArrow} from '../../../../assets/svg/arrow-down.svg';
import Translation from "../../../data/translation";

export interface BsProjectCardProps {
  image: string;
  title: string;
  avatarPhoto?: string;
  tags: Array<TechnologyDTO>;
  members: Array<UserDTO>;
  className?: string;
  description?: string;
  status?: string;
}

const BsProjectCard = ({
  title,
  image,
  tags,
  members,
  description,
  status,
  className,
  ...props
}: BsProjectCardProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const [firstTenMembers, setFirstTenMembers] = useState<Array<UserDTO>>([]);

  const projectImage = () => image ? `/api/file/${image}?size=thumbnail` : DefaultProjectImage;

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setFirstTenMembers(members.slice(0, 10));
  }, [members]);

  return (
    <div className={`bs-project-card ${className ? ` ${className}` : ``}`} {...props}>
      <div className="bs-project-card__wrapper">
        <div className={!isOpen ? "bs-project-card__photo" : "bs-project-card__photo bs-project-card__photo--open"}>
          <img src={projectImage()} alt={title}/>
        </div>
        <div className="bs-project-card__content">
          <h2 className="bs-project-card__content__title">{title}</h2>
          {status === 'Inactive' &&
            <span className="bs-project-card__content__status">{Translation.hu.profile.inactive}</span>
          }
          <div className="bs-project-card__content__tags">
            {tags.map((tag, index) =>
              <div key={index} className="bs-project-card__content__tags__item">
                <BsTag label={tag.name} active={false}/>
              </div>,
            )}
          </div>
          <div className={!isOpen ? "bs-project-card__content__avatars" : "bs-project-card__content__avatars bs-project-card__content__avatars--open"}>
            {!isOpen &&
              firstTenMembers.map((member, index) =>
                  <div key={index} className="bs-project-card__content__avatars__item">
                    <BsProfileTag name={`${member.lastName} ${member.firstName}`} email={member.email} size={'small'}
                                  user={member} hideName={true}/>
                  </div>,
                )
            }

            {!isOpen && members.length >= 10 && <span className="bs-project-card__content__avatars__more">...</span>}

            {isOpen &&
              members.map((member, index) =>
                <div key={index} className="bs-project-card__content__avatars__item">
                  <BsProfileTag name={`${member.lastName} ${member.firstName}`} email={member.email} size={'small'}
                                user={member} hideName={true}/>
                </div>,
              )
            }
          </div>
          <div
            className={!isOpen ? "bs-project-card__accordion-icon" : "bs-project-card__accordion-icon bs-project-card__accordion-icon--open"}
            onClick={onOpen}>
            <BsArrow/>
          </div>
        </div>
      </div>

      <div
        className={`bs-project-card__content__description ${isOpen ? 'bs-project-card__content__description--open' : ''}`}>
        <p>{description}</p>
      </div>

    </div>
  );
};

export default BsProjectCard;
