import { Fragment } from 'react';
import { ColleagueBadgeDTO, ColleagueDetailsDTO } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import { BsBadgeKit, getBadgeLabel } from '../../../data/badge-kit';
import BsBadge from '../../ui/badge/badge';

export interface ColleagueBadgesProps {
  selectedColleague: ColleagueDetailsDTO;
  myProfile?: boolean;
}

export function ColleagueBadges({
  selectedColleague,
  myProfile = false,
}: ColleagueBadgesProps) {
  return (
    <Fragment>
      <div className="bs-colleague__badges">
        <div className="bs-colleague__badges__header">
          <h2 className="bs-colleague__badges__title">
            {Translation.hu.profile.badges}
          </h2>
          <span className="bs-colleague__badges__count">
            {selectedColleague.badges?.thisYear.length} {Translation.hu.common.piece}
          </span>
        </div>
        { (selectedColleague.badges !== undefined &&
          (selectedColleague.badges.thisYear.length > 0 || selectedColleague.badges.recently.length > 0)) ?
          <Fragment>
            {(selectedColleague?.badges?.recently || []).length > 0 &&
              <>
                <h4>{Translation.hu.profile.recently}</h4>
                <div className="bs-colleague__badges__list">
                  {selectedColleague?.badges?.recently.map((badge: ColleagueBadgeDTO, index: number) =>
                    <div className="bs-colleague__badges__item" key={index}>
                      <BsBadge key={index} icon={BsBadgeKit[badge.type].icon} label={getBadgeLabel(badge.type)}
                               count={badge.count} receivedFrom={badge.receivedFromGroup}/>
                    </div>,
                  )}
                </div>
              </>
            }
            <h4>{Translation.hu.profile.thisYear}</h4>
            <div className="bs-colleague__badges__list">
              {selectedColleague?.badges?.thisYear.map((badge: ColleagueBadgeDTO, index: number) =>
                <div className="bs-colleague__badges__item" key={index}>
                  <BsBadge key={index} icon={BsBadgeKit[badge.type].icon} label={getBadgeLabel(badge.type)} count={badge.count} receivedFrom={badge.receivedFromGroup} />
                </div>,
              )}
            </div>
          </Fragment>
          :
          <div className="bs-no-result">
            <h4 className="bs-no-result__label">
              {myProfile ? Translation.hu.label.noOwnBadgesYet : Translation.hu.label.noBadgesYet(selectedColleague.firstName)}
            </h4>
          </div>
        }
        {!!selectedColleague?.allPoints && selectedColleague?.allPoints > 0 &&
          <h4 className="bs-colleague__badges__note">{Translation.hu.profile.hallOfMastersPoints}: {selectedColleague?.allPoints}</h4>
        }
      </div>
    </Fragment>
  );
}
