export enum ScheduledJobType {
  BlackbeltHazaTaja = 'BlackbeltHazaTaja',
}

export enum ScheduledJobStatus {
  Started = 'Started',
  Finished = 'Finished',
  Failed = 'Failed',
}

export interface ScheduledJobDTO {
  id: number;
  jobType: ScheduledJobType;
  status: ScheduledJobStatus;
  started: Date;
  finished: Date;
  summary: string;
}
