import { AxiosResponse } from 'axios';
import {
  CreateTechnologyDTO,
  TechnologyDTO,
  TechnologyListWithCountDTO,
} from '@bb-sanctuary/common';

import { axiosInstance } from './axiosInstance';

export const TechService = {
  getTechList(): Promise<AxiosResponse<TechnologyListWithCountDTO>> {
    return axiosInstance.get(`/api/technology`);
  },
  postTech(createTechnology: CreateTechnologyDTO): Promise<AxiosResponse<TechnologyDTO>> {
    return axiosInstance.post(`/api/technology`, {...createTechnology});
  },
};
