import { Fragment } from 'react';
import Translations from '../../../data/translation';
import BsButton from '../../ui/button/button';

const menuItems = [
  {
    name: Translations.hu.innovation.menu1Roles,
    id: 'roles',
  },
  {
    name: Translations.hu.innovation.menu2Structure,
    id: 'structure',
  },
  {
    name: Translations.hu.innovation.menu3Flow,
    id: 'flow',
  },
];

const scrollToMenuItem = (id: string) => {
  const element = document.getElementById(id);
  element && element.scrollIntoView();
};

const navigateToForm = () => {
  const element = document.getElementById('bs-innovation__form');
  element && element.scrollIntoView();
};

const InnovationMenu = () => {
  return (
    <Fragment>
      <div className="bs-innovation-menu">
        <ul className="bs-innovation-menu__list">
          {menuItems.map((item, index) =>
            <li key={index} className="bs-innovation-menu__list__item" onClick={() => scrollToMenuItem(item.id)}>{item.name}</li>,
          )}
          <BsButton color="primary" size="small" onClick={navigateToForm}>
            {Translations.hu.innovation.showYourIdea}
          </BsButton>
        </ul>
      </div>
    </Fragment>
  );
};

export default InnovationMenu;
