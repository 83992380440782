import {MedalType} from '@bb-sanctuary/common';
import {Fragment, useEffect, useState} from 'react';
import {AdminService} from './AdminService';
import Translation from "../../shared/data/translation";

function AdminMedalListPage() {
  const [medalList, setMedalList] = useState<Record<string, MedalType[]>>();
  const medalTypes = Object.values(MedalType);
  const [medalOwners, setMedalOwners] = useState<Array<{ medalName: string, medalOwner: string }>>();

  useEffect(() => {
    AdminService.getMedalList().then(result => setMedalList(result));
  }, []);

  useEffect(() => {
    if (!medalList) {
      return;
    }

    const res = medalTypes.map((medal) => {
      const medalOwners = Object.entries(medalList).reduce((acc, person) => {
        const [personName, personMedals] = person;
        if (personMedals.includes(medal)) {
          acc.push(personName);
        }
        return acc;
      }, [] as Array<string>);

      return {medalName: medal, medalOwner: medalOwners.join(", ")};
    });
    setMedalOwners(res);
  }, [medalList]);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        <h3>{Translation.hu.admin.medals.medalList}</h3>
        <h5>{Translation.hu.admin.medals.medalOwners}</h5>
        <div className="bs-admin__grid">
          {medalOwners && medalOwners.map((medal, index) =>
            <div key={index} className="bs-admin__grid__row">
              <div className="bs-admin__grid__row__medal">{medal.medalName}</div>
              <div className="bs-admin__grid__row__owner">{medal.medalOwner.length > 0 ? medal.medalOwner : '-'}</div>
            </div>,
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default AdminMedalListPage;
