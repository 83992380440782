/**
 * Authorization rights enum
 *
 * ⚠️ WARNING ⚠️
 * When this file changes you need to update the database, since this is generated into a database enum.
 *
 * Run `typeorm:migration:generate` script to apply changes into the database, otherwise you wont be able
 * to modify roles!!!
 *
 */
export enum AuthRight {
  Login = 'right_login',
  TesterUser = 'right_tester_user',
  ArticleCreate = 'right_article_create',
  ArticleModerator = 'right_article_moderator',
  CvCreate = 'right_cv_create',
  Administrator = 'right_administrator',
  AdminOfGoogleSync = 'right_admin_of_google_sync',
  AdminOfRole = 'right_admin_of_role',
  AdminOfUserRole = 'right_admin_of_user_role',
  AdminOfHubspotMigration = 'right_admin_of_hubspot_migration',
  AdminOfSolrReindex = 'right_admin_of_solr_reindex',
  AdminOfGeneralFaq = 'right_admin_of_general_faq',
  AdminOfBlackbeltFaq = 'right_admin_of_blackbelt_faq',
  /** Can edit user */
  AdminOfUsers = 'right_admin_of_users',
  /** Can edit user */
  AdminOfScheduledJobs = 'right_admin_of_scheduled_jobs',
  AdminOfBadges = 'right_admin_of_badges',
  PointAdministrator = 'right_point_administrator',
  ProjectCreate = 'right_project_create',
  ProjectMaintenance = 'right_project_maintenance',
  ProjectDelete = 'right_project_delete',
  AdminOfCv = 'right_admin_of_cv',
}
