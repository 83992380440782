import { AxiosResponse } from 'axios';

import { axiosInstance } from './axiosInstance';
import {PageableResponse, ProjectDetailDTO, ProjectFilterDTO} from "@bb-sanctuary/common";

const PROJECT_API = `/api/project`;

export const ProjectService = {
  getProjectList(email: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${PROJECT_API}/byemail/${email}`);
  },

  getAllProjects(): Promise<AxiosResponse<ProjectDetailDTO[]>> {
    return axiosInstance.get(`${PROJECT_API}`);
  },

  searchProject(params: ProjectFilterDTO): Promise<AxiosResponse<PageableResponse<ProjectDetailDTO>>> {
    return axiosInstance.post(`${PROJECT_API}/search`, params);
  },

  getCurrentUserProjectsForCv(): Promise<AxiosResponse<ProjectDetailDTO[]>> {
    return axiosInstance.get(`${PROJECT_API}/for-cv`);
  },
};
