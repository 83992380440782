import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaqCategoryGroup, FAQEntryDTO } from '@bb-sanctuary/common';

import { FaqService } from '../shared/service/faqService';
import { useLoading } from '../shared/utils/useLoader.hook';
import BsButton from '../shared/components/ui/button/button';
import Translation from '../shared/data/translation';
import { ArrowBackIos } from '@mui/icons-material';
import FaqEntryShare from '../shared/components/custom/faq-entry-share/FaqEntryShare';
import { BsOrgChart } from '../shared/components/custom/org-chart/orgChart';

const FaqEntryPage = ({type}: {type: FaqCategoryGroup}) => {
  const [faqEntry, setFaqEntry] = useState<FAQEntryDTO | undefined>();
  const setLoading = useLoading();
  const navigate = useNavigate();
  const params = useParams();
  const faqEntryId = params.faqEntryId;

  const navigateBack = () => navigate(-1);

  const loadFaqEntry = useCallback(async () => {
    if (!faqEntryId) {
      return;
    }
    try {
      setLoading(true);
      const res = await FaqService.getFaqEntry(faqEntryId);
      setFaqEntry(res.data);
    } finally {
      setLoading(false);
    }
  }, [faqEntryId]);

  useEffect(() => {
    loadFaqEntry();
  }, [loadFaqEntry]);

  return (
    <div className="bs-faq">
      {faqEntry &&
        <div className="bs-faq__result bs-faq__result--single">
          <div className="bs-faq__result__header">
            <BsButton className="bs-faq__result__back" color="secondary" size="small" onClick={navigateBack}>
              <ArrowBackIos /> {Translation.hu.common.back}
            </BsButton>
          </div>
          <h4 className="bs-faq__result__title">
            {faqEntry.title}
            <FaqEntryShare id={faqEntry.id} type={type} />
          </h4>
          {faqEntry.content.includes('!!ORG-CHART!!') &&
            <BsOrgChart />}
          {!faqEntry.content.includes('!!ORG-CHART!!') &&
            <div className="bs-faq__result__content fr-view" dangerouslySetInnerHTML={{__html: faqEntry.content}} />
          }
          <FaqEntryShare className={"bs-faq__result__share"} id={faqEntry.id} type={type} />
        </div>
      }
    </div>
  );
};

export default FaqEntryPage;
