import { CvProjectDTO, ProjectDetailDTO } from "@bb-sanctuary/common";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import Translation from "../../../data/translation";
import BsButton from "../../ui/button/button";
import BsInput from "../../ui/input/input";
import { searchOnEnter } from '../../../utils/searchOnEnter';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { SnackbarIsOpen, SnackbarStatus } from '../../../../pages/CvGeneratorPage';
import { toISODateString } from "../../../utils/toISODateString";

export interface ProjectFormProps {
  onSetNewProject: (project?: CvProjectDTO) => void;
  editedProj?: CvProjectDTO;
  currentUserProjectList?: ProjectDetailDTO[];
  setSnackBarIsOpen: (val: SnackbarIsOpen) => void;
  onCancel: () => void;
}

const minDateYear = 1950;

enum ProjectEditorMode {
  Unknown,
  BBProject,
  CustomProject,
}

const CvProjectForm = ({
                         onSetNewProject,
                         editedProj,
                         currentUserProjectList,
                         setSnackBarIsOpen,
                         onCancel,
                       }: ProjectFormProps) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const companyRef = useRef<HTMLInputElement | null>(null);
  const descriptionRef = useRef<HTMLInputElement | null>(null);
  const [projectEditorMode, setProjectEditorMode] = useState<ProjectEditorMode>(ProjectEditorMode.Unknown);
  const [selectedBlackbeltProject, setSelectedBlackbeltProject] = useState<ProjectDetailDTO>();

  const isValidForm = () => {
    return !!startDate && title.length > 0 && description.length > 0 && company.length > 0 &&
      (
        (!!startDate && !!endDate && new Date(startDate).getFullYear() <= new Date(endDate).getFullYear()) ||
        (!!startDate && !endDate)
      );
  };

  const clear = (onlyFields?: boolean) => {
    setStartDate(null);
    setEndDate(null);
    setTitle('');
    setDescription('');
    setCompany('');
    if (!onlyFields) {
      setSelectedBlackbeltProject(undefined);
      setProjectEditorMode(ProjectEditorMode.Unknown);
    }
  };

  const handleSetNewCourse = () => {
    if (!isValidForm()) {
      return;
    }

    onSetNewProject({
      id: editedProj?.id || Math.floor(Math.random() * 1000000),
      description,
      fromDate: new Date(startDate as string),
      toDate: endDate ? new Date(endDate) : undefined,
      company,
      title,
      project: selectedBlackbeltProject,
    });

    clear();
    startDateRef.current?.focus();
  };

  const updateStartDate = (e: Date|null) => {
    setStartDate(toISODateString(e));
    if (e && e.getFullYear() > 1950 && e.getFullYear() < (new Date().getFullYear())) {
      endDateRef.current?.focus();
    }
  };

  const updateEndDate = (e: Date|null) => {
    setEndDate(toISODateString(e));
    if (e && e.getFullYear() > 1950 && e.getFullYear() < (new Date().getFullYear())) {
      titleRef.current?.focus();
    }
  };

  const cancelClicked = () => {
    onSetNewProject(undefined);
    onCancel();
    clear();
  };

  const hasVisibleProject = currentUserProjectList && currentUserProjectList.length > 0;

  const selectBlackbeltProject = (project: ProjectDetailDTO) => {
    if (editedProj) {
      setSnackBarIsOpen({isOpen: true, message: Translation.hu.cv.project.cannotEditProject, status: SnackbarStatus.warning});
      return;
    }

    setSelectedBlackbeltProject(project);
    setStartDate(null);
    setEndDate(null);
    setTitle(project.officialData.name || '');
    setCompany(project.officialData.projectOwner || '');
  };

  useEffect(() => {
    if (editedProj) {
      setStartDate(editedProj.fromDate ? new Date(editedProj.fromDate).toISOString() : null);
      setEndDate(editedProj.toDate ? new Date(editedProj.toDate).toISOString() : null);
      setTitle(editedProj.title);
      setDescription(editedProj.description);
      setCompany(editedProj.company);

      setProjectEditorMode(!!editedProj.project?.id ? ProjectEditorMode.BBProject : ProjectEditorMode.CustomProject);
      if (editedProj.project?.id) {
        setSelectedBlackbeltProject(editedProj.project);
      } else {
        setSelectedBlackbeltProject(undefined);
      }
    } else {
      setProjectEditorMode(ProjectEditorMode.Unknown);
    }
  }, [editedProj]);

  return (
    <div className="bs-cv-form bs-cv-project-form">

      <h4 className="bs-form__legend">
        {editedProj ? Translation.hu.cv.project.headerEditProject : Translation.hu.cv.project.headerCreateProject}

        <div className="bs-form__legend__controls">
          {!editedProj && (
            <div className="bs-cv-project-form__mode-selector">
              <div>
                <div className="bs-button__group">
                  <BsButton
                    color={projectEditorMode === ProjectEditorMode.BBProject ? 'primary' : 'secondary'}
                    size={'small'}
                    onClick={() => {
                      setProjectEditorMode(ProjectEditorMode.BBProject);
                      setSelectedBlackbeltProject(undefined);
                      clear(true);
                    }}
                    disabled={!currentUserProjectList || currentUserProjectList.length === 0}
                  >
                    {Translation.hu.cv.project.selectBBProject}
                  </BsButton>
                  <BsButton
                    color={projectEditorMode === ProjectEditorMode.CustomProject ? 'primary' : 'secondary'}
                    size={'small'}
                    onClick={() => {
                      setProjectEditorMode(ProjectEditorMode.CustomProject);
                      setSelectedBlackbeltProject(undefined);
                      clear(true);
                    }}
                  >
                    {Translation.hu.cv.project.createProject}
                  </BsButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </h4>

      {hasVisibleProject && projectEditorMode === ProjectEditorMode.BBProject && (
        <div className={"bs-cv-project-form__table-wrapper"}>
          <table cellPadding={0} cellSpacing={0}>
            <thead>
            <tr>
              <td></td>
              <td>{Translation.hu.cv.project.sanctuaryProjectName}</td>
              <td>{Translation.hu.cv.project.sanctuaryProjectOwner}</td>
            </tr>
            </thead>
            <tbody>
            {(currentUserProjectList || []).map(project => (
              <tr
                key={project.id}
                className={selectedBlackbeltProject?.id === project.id ? 'bs-cv-project-form__table__selected-project' : ''}
                onClick={() => selectBlackbeltProject(project)}
              >
                <td>
                  {selectedBlackbeltProject?.id === project.id &&
                    <RadioButtonCheckedIcon />
                  }
                  {selectedBlackbeltProject?.id !== project.id &&
                    <RadioButtonUncheckedIcon />
                  }
                </td>
                <td>{project.officialData.name}</td>
                <td>{project.officialData.projectOwner}</td>
              </tr>
            ))}
            </tbody>
          </table>
          <p className="hint" dangerouslySetInnerHTML={{__html: Translation.hu.cv.project.missingYourProject}} />
        </div>
      )}

      {projectEditorMode !== ProjectEditorMode.Unknown && (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={Translation.hu.cv.project.startDate}
              className="bs-cv-form-datepicker bs-cv-form-datepicker--small bs-cv-form__input--25"
              value={startDate}
              minDate={new Date(minDateYear)}
              maxDate={new Date()}
              views={['year']}
              onChange={updateStartDate}
              inputRef={startDateRef}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={Translation.hu.cv.project.endDate}
              className="bs-cv-form-datepicker bs-cv-form-datepicker--small bs-cv-form__input--25"
              value={endDate}
              views={['year']}
              minDate={startDate ? new Date(startDate) : new Date(1950, 0, 1)}
              disableFuture
              onChange={updateEndDate}
              inputRef={endDateRef}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <BsInput
            className="bs-cv-form__input bs-cv-form__input--25"
            size="small"
            label="Project name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            inputRef={titleRef}
            onKeyUp={searchOnEnter(() => companyRef.current?.focus())}
            disabled={!!selectedBlackbeltProject}
          />

          <BsInput
            className="bs-cv-form__input bs-cv-form__input--25"
            size="small"
            label="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            inputRef={companyRef}
            onKeyUp={searchOnEnter(() => descriptionRef.current?.focus())}
            disabled={!!selectedBlackbeltProject}
          />
        </>
      )}

      {projectEditorMode === ProjectEditorMode.BBProject && selectedBlackbeltProject && (
        <p className="bs-cv-project-form__blackbelt-project-description">
          {selectedBlackbeltProject.officialData.description}
        </p>
      )}

      {projectEditorMode !== ProjectEditorMode.Unknown && (
        <TextField
          label={!!selectedBlackbeltProject ? Translation.hu.cv.project.extendSummary : Translation.hu.cv.project.addSummary}
          className="bs-cv-form-textarea bs-cv-form-textarea--full"
          multiline
          rows={!!selectedBlackbeltProject ? 3 : 6}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      )}

      {projectEditorMode !== ProjectEditorMode.Unknown &&
        <BsButton
          disabled={!isValidForm()}
          className="bs-cv-btn"
          size="small"
          onClick={handleSetNewCourse}
        >
          {editedProj ? Translation.hu.cv.form.updateProject : Translation.hu.cv.form.addProject}
        </BsButton>
      }

      <BsButton
        className="bs-cv-btn"
        size="small"
        onClick={cancelClicked}
        color={'secondary'}
      >
        {Translation.hu.cv.form.cancel}
      </BsButton>
    </div>
  );
};

export default CvProjectForm;
