import { FaqCategoryGroup } from '@bb-sanctuary/common';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { BsRoutes } from '../../shared/data/bsRoutes';
import GeneralAdminPage from './GeneralAdminPage';
import AdminFaqPage from './AdminFaqPage';
import AdminFaqCategoryPage from './AdminFaqCategoryPage';
import AdminFaqEditPage from './AdminFaqEditPage';
import AdminFaqCategoryEditPage from './AdminFaqCategoryEditPage';
import AdminMedalListPage from './AdminMedalListPage';
import AdminUserEditPage from './AdminUserEditPage';
import AdminUserPage from './AdminUserPage';
import AdminRolesEditPage from './AdminRolesEditPage';
import AdminRolesPage from './AdminRolesPage';
import AdminProjectsPage from './AdminProjectsPage';
import AdminProjectsEditPage from './AdminProjectsEditPage';
import AdminTechPage from './AdminTechPage';
import AdminTechEditPage from './AdminTechEditPage';
import AdminCvColleagueListPage from './AdminCvColleagueListPage';
import AdminCvListPage from './AdminCvListPage';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAdminAccessibleForUser } from '../../contexts/currentUserSlicer';

/**
 * Removes '/admin/' from the given path
 */
function stripAdminPath(url: string): string {
  return url.replace(`${BsRoutes.admin._}/`, '');
}

function AdminPage() {
  const isAdminAccessibleForUser = useSelector(selectAdminAccessibleForUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminAccessibleForUser) {
      navigate(BsRoutes.hirek);
    }
  }, [isAdminAccessibleForUser]);

  return (
    <div className="bs-admin">
      <Routes>
        <Route path={stripAdminPath(BsRoutes.admin.user._)}>
          <Route path="edit/:id" element={<AdminUserEditPage/>}/>
          <Route path="" element={<AdminUserPage/>}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.cv._)} >
          <Route path="list/:id" element={<AdminCvListPage />} />
          <Route path="" element={<AdminCvColleagueListPage />} />
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.roles)}>
          <Route path="edit/:id" element={<AdminRolesEditPage/>}/>
          <Route path="add" element={<AdminRolesEditPage/>}/>
          <Route path="" element={<AdminRolesPage/>}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.tech._)}>
          <Route path="edit/:id" element={<AdminTechEditPage/>}/>
          <Route path="add" element={<AdminTechEditPage/>}/>
          <Route path="" element={<AdminTechPage/>}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.projects._)}>
          <Route path="edit/:id" element={<AdminProjectsEditPage/>}/>
          <Route path="add" element={<AdminProjectsEditPage/>}/>
          <Route path="" element={<AdminProjectsPage/>}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.faq._)}>
          <Route path="edit/:id" element={<AdminFaqEditPage subject={FaqCategoryGroup.GENERAL} />}/>
          <Route path="add" element={<AdminFaqEditPage subject={FaqCategoryGroup.GENERAL} />}/>
          <Route path="" element={<AdminFaqPage subject="GENERAL" />}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.bbMedals._)}>
          <Route path="" element={<AdminMedalListPage />}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.faqCategory._)}>
          <Route path="edit/:id" element={<AdminFaqCategoryEditPage subject={FaqCategoryGroup.GENERAL} />}/>
          <Route path="add" element={<AdminFaqCategoryEditPage subject={FaqCategoryGroup.GENERAL} />}/>
          <Route path="" element={<AdminFaqCategoryPage subject="GENERAL" />}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.bbFaq._)}>
          <Route path="edit/:id" element={<AdminFaqEditPage subject={FaqCategoryGroup.BB_SITE} />}/>
          <Route path="add" element={<AdminFaqEditPage subject={FaqCategoryGroup.BB_SITE} />}/>
          <Route path="" element={<AdminFaqPage subject="BB_SITE" />}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.bbFaqCategory._)}>
          <Route path="edit/:id" element={<AdminFaqCategoryEditPage subject={FaqCategoryGroup.BB_SITE} />}/>
          <Route path="add" element={<AdminFaqCategoryEditPage subject={FaqCategoryGroup.BB_SITE}/>}/>
          <Route path="" element={<AdminFaqCategoryPage subject="BB_SITE" />}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin.admin)}>
          <Route path="" element={<GeneralAdminPage/>}/>
        </Route>
        <Route path={stripAdminPath(BsRoutes.admin._)} element={<></>} />
      </Routes>

      <Outlet/>
    </div>
  );
}

export default AdminPage;
