import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColleagueDetailsDTO, TechnologyDTO, UserTechnologyDTO } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import BsTechTag from '../../ui/tech-tag/tech-tag';

export interface ColleagueTechsProps {
  selectedColleague: ColleagueDetailsDTO;
  editable?: boolean;
}

export interface ColleagueTechList {
  persisted: Array<UserTechnologyDTO>;
  created?: TechnologyDTO;
}

function ColleagueTechs({
  selectedColleague,
  editable = false,
}: ColleagueTechsProps) {
  const [userTechList, setUserTechList] = useState<ColleagueTechList>();

  const getTechCount = useMemo(() => {
    if (!userTechList) {
      return 0;
    }
    return userTechList.persisted.length;
  }, [userTechList]);

  const createTechTag = useCallback((data: UserTechnologyDTO) => {
    if (!userTechList) {
      return;
    }
    setUserTechList((prev) => ({
      persisted: [...(prev?.persisted || []), data],
    }));
  }, [userTechList]);

  const deletePersistedTechTag = useCallback((techId: number) => {
    if (!userTechList) {
      return;
    }
    setUserTechList((prev) => ({
      persisted: [...(prev?.persisted || []).filter(userTech => userTech.technology.id !== techId)],
    }));
  }, [userTechList]);

  const deleteCreatingTag = useCallback(() => {
    if (!userTechList) {
      return;
    }
    setUserTechList(prev => ({
      persisted: prev?.persisted || [],
      created: undefined,
    }));
  }, [userTechList]);

  useEffect(() => {
    if (!userTechList) {
      return;
    }
    if (editable) {
      setUserTechList(prev => ({
        persisted: prev?.persisted || [],
        created: {} as TechnologyDTO,
      }));
    } else {
      setUserTechList(prev => ({
        persisted: prev?.persisted || [],
        created: undefined,
      }));
    }
  }, [editable]);

  useEffect(() => {
    if (!userTechList) {
      setUserTechList(() => ({
        persisted: selectedColleague.technologies || [],
        created: editable ? {} as TechnologyDTO : undefined,
      }));
    } else if (!userTechList.created && editable) {
      setUserTechList(prev => ({
        persisted: prev?.persisted || [],
        created: {} as TechnologyDTO,
      }));
    }
  }, [userTechList, editable]);

  return (
    <Fragment>
      <div className="bs-colleague__techs">
        <div className="bs-colleague__techs__header">
          <h2 className="bs-colleague__techs__title">
            {Translation.hu.profile.techs}
          </h2>
          <span className="bs-colleague__techs__count">
            {getTechCount} {Translation.hu.common.piece}
          </span>
        </div>
        {userTechList &&
          <div className="bs-colleague__techs__list">
            {getTechCount > 0 ?
              userTechList.persisted.map((tech) => (
                <BsTechTag
                  key={tech.technology.id}
                  tech={tech.technology}
                  level={tech.level}
                  newTech={false}
                  onlyShow={!editable}
                  onDelete={(id) => deletePersistedTechTag(id)}
                />
              ))
            :
              <div className="bs-no-result">
                <h4 className="bs-no-result__label">
                  {editable ? Translation.hu.label.noOwnTechYet : Translation.hu.label.noTechYet(selectedColleague.firstName)}
                </h4>
              </div>
            }
            {editable && userTechList.created &&
              <BsTechTag
                tech={userTechList.created}
                newTech={true}
                onlyShow={!editable}
                userTechList={userTechList.persisted}
                createTechTag={(data: UserTechnologyDTO) => createTechTag(data)}
                onDelete={() => deleteCreatingTag()}
              />
            }
          </div>
        }
      </div>
    </Fragment>
  );
}

export default ColleagueTechs;
