import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ArticleDTO, ArticleStatus, ArticleType, AuthRight, NewsPageItem } from '@bb-sanctuary/common';

import { BsRoutes } from '../../../data/bsRoutes';
import { formatLocaleDate } from '../../../utils/formatters';
import BsArticleCard from '../../ui/article-card/article-card';
import { BsArticleModerationTools } from './ArticleModerationTools';
import BsNotificationCard from '../../ui/notification-card/notification-card';
import { BsUploaderType } from '../../ui/uploader/uploader';
import { getBadgeIcon, getBadgeReceivedText } from '../../../data/badge-kit';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../contexts/currentUserSlicer';
import { Translation } from '../../../data/translation';
import BsButton from '../../ui/button/button';
import { useCurrentUserRights } from '../../../utils/useRight.hook';

export interface ArticleListingProps {
  articleList: NewsPageItem[];
  approveDraft: (id: number) => Promise<void>;
  deleteDraft: (id: number) => Promise<void>;
  discardDraft: (id: number) => Promise<void>;
  editPost: (item: ArticleDTO) => void;
  openArticle: (item: ArticleDTO) => void;
}

function renderArticleEntry(item: NewsPageItem,
                            index: number,
                            openArticle: (item: ArticleDTO) => void,
                            editArticle?: (item: ArticleDTO) => void) {
  return <>
    {item.entryType === 'article' && item.type === ArticleType.Newsletter &&
      <Link
        key={index}
        to={BsRoutes.hir(item.id)}
        onClick={() => openArticle(item)}
        className="bs-news-feed__item"
      >
        <BsArticleCard
          title={item.title}
          text={item.lead}
          newsPhoto={`/api/file/${item.picture}?s=thumbnail`}
          tags={item.tags}
          uploader={item.createdBy}
          uploaderName={item.createdBy.firstName}
          uploaderTime={formatLocaleDate(item.createdAt)}
          entryType={item.entryType}
          pinned={item.status === ArticleStatus.approved && !!item.pinnedUntilDate}
        />
      </Link>
    }
    {item.entryType === 'article' && item.type === ArticleType.Post &&
      <a
        key={index}
        href={item.url}
        target="_blank"
        className="bs-news-feed__item"
      >
        <BsArticleCard
          title={item.title}
          text={item.lead}
          newsPhoto={`/api/file/${item.picture}?s=thumbnail`}
          tags={item.tags}
          uploader={item.createdBy}
          uploaderName={item.createdBy.firstName}
          uploaderTime={formatLocaleDate(item.createdAt)}
          entryType={item.entryType}
          pinned={item.status === ArticleStatus.approved && !!item.pinnedUntilDate}
        />
      </a>
    }
    {editArticle &&
      <div className="bs-news-card-editor">
        <BsButton size="small" color="secondary" onClick={() => editArticle(item as ArticleDTO)}>{Translation.hu.label.editArticle}</BsButton>
      </div>
    }
  </>;
}

function renderBadgeHistory(item: NewsPageItem,
                            index: number) {
  return <>
    {item.entryType === 'badgeHistory' &&
      <BsNotificationCard
        key={index}
        text={getBadgeReceivedText(item)}
        icon={getBadgeIcon(item)}
        uploaderType={BsUploaderType.USER}
        uploader={item.from}
        uploaderName={`${item.from.lastName} ${item.from.firstName}`}
        uploaderTime={formatLocaleDate(item.createdAt)}
        explicitTime={item.createdAt}
        message={item.message}
      />
    }
  </>;
}

function renderGeneratedHistory(item: NewsPageItem,
                                index: number) {
  return <>
    {item.entryType === 'generated' &&
      <Link
        key={index}
        to={BsRoutes.hir(item.id)}
        className="bs-news-feed__item"
      >
        <BsArticleCard
          title={item.title}
          text={item.lead}
          newsPhoto={`/assets/images/blackbelt.png`}
          tags={[]}
          uploaderName={'BlackBelt'}
          uploaderTime={formatLocaleDate(item.createdAt)}
          entryType={item.entryType}
        />
      </Link>
    }
  </>;
}

function BsArticleListing({
                            articleList,
                            approveDraft,
                            deleteDraft,
                            discardDraft,
                            editPost,
                            openArticle,
                          }: ArticleListingProps) {
  const currentUser = useSelector(selectCurrentUser);
  const [ hasRight ] = useCurrentUserRights();

  return (
    <div className="bs-news-feed__list">
      {articleList.map((item, index) =>
        <Fragment key={index}>
          {(item.entryType === 'article' && [ArticleStatus.pending, ArticleStatus.rejected].includes(item.status)) ?
            <BsArticleModerationTools
              item={item}
              approveDraft={approveDraft}
              deleteDraft={deleteDraft}
              discardDraft={discardDraft}
              editPost={editPost}
            >
              {renderArticleEntry(item, index, openArticle)}
              {renderBadgeHistory(item, index)}
              {renderGeneratedHistory(item, index)}
            </BsArticleModerationTools>
            :
            <Fragment>
              {renderArticleEntry(item, index, openArticle,
                item.entryType === 'article' && (item.createdBy.email === currentUser?.email || hasRight(AuthRight.ArticleModerator)) ? editPost : undefined)}
              {renderBadgeHistory(item, index)}
              {renderGeneratedHistory(item, index)}
            </Fragment>
          }
        </Fragment>,
      )}
    </div>
  );
}

export default BsArticleListing;
