import { axiosInstance } from '../service/axiosInstance';

export const uploadFile = (fileInputId: string, imageType: string) => {
  const formData = new FormData();
  const imagefile: any = document.getElementById(fileInputId);
  formData.append('file', imagefile.files[0]);
  return axiosInstance
    .post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Image-Type': imageType,
      },
    })
    .then((uploadResult) => {
      return `/file/${uploadResult.data.data.file.filename}`;
    });
};

export const convertBase64: (file: any) => Promise<ArrayBuffer | null | string> = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
