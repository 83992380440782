import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaqCategoryGroup, FAQEntryDTO } from '@bb-sanctuary/common';
import { ArrowBackIos } from '@mui/icons-material';

import { BsRoutes } from '../../../data/bsRoutes';
import Translation from '../../../data/translation';
import BsButton from '../../ui/button/button';

export interface FaqSearchListingProps {
  subject: FaqCategoryGroup;
  searchResult: Array<FAQEntryDTO>;
}

const FaqSearchListing = ({subject, searchResult}: FaqSearchListingProps) => {
  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);
  return (
    <>
      <div className="bs-faq__result bs-faq__result--list">
        <div className="bs-faq__result__header">
          <BsButton className="bs-faq__result__back" color="secondary" size="small" onClick={navigateBack}>
            <ArrowBackIos /> {Translation.hu.common.back}
          </BsButton>
        </div>
        {!searchResult.length && <p>{Translation.hu.faq.noResult}</p>}
        {searchResult.map((item, index) =>
          <Fragment key={index}>
            <Link
              key={index}
              to={subject === FaqCategoryGroup.GENERAL ?
                BsRoutes.altalanosTudnivalok.bejegyzes(item.id) :
                BsRoutes.masterOfTechnology.bejegyzes(item.id)}
              className="bs-faq__result__item"
            >
              <h3 className="bs-faq__result__title" dangerouslySetInnerHTML={{__html: item.title}} />
              <p className="bs-faq__result__content" dangerouslySetInnerHTML={{__html: item.content}}/>
            </Link>
          </Fragment>,
        )}
      </div>
    </>
  );
};

export default FaqSearchListing;
