import InnovationContent from '../shared/components/custom/innovation/InnovationContent';
import InnovationHeader from '../shared/components/custom/innovation/InnovationHeader';
import InnovationMenu from '../shared/components/custom/innovation/InnovationMenu';

function InnovationPage() {

  return (
    <div className="bs-innovation">
      <InnovationHeader />
      <InnovationMenu />
      <InnovationContent />
    </div>
  );
}

export default InnovationPage;
