import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { AuthRight, ColleagueDetailsDTO, ProjectDetailDTO, ProjectStatus } from '@bb-sanctuary/common';

import Translation from '../shared/data/translation';
import BsTag from '../shared/components/ui/tag/tag';
import BsButton from '../shared/components/ui/button/button';
import Loading from '../shared/components/ui/loading/loading';
import { useNotyBar } from '../contexts/notybar.context';
import { ColleagueBadges } from '../shared/components/custom/colleague-badges/ColleagueBadges';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';
import { ColleagueService } from '../shared/service/colleagueService';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { ColleagueMedals } from '../shared/components/custom/colleague-medals/ColleagueMedals';
import ColleagueInfo from '../shared/components/custom/colleague-info/ColleagueInfo';
import ColleagueTechs from '../shared/components/custom/colleague-techs/ColleagueTechs';
import ColleagueProjects from '../shared/components/custom/colleague-projects/ColleagueProjects';
import ColleagueChat from '../shared/components/custom/colleague-chat/ColleagueChat';
import { setNewsPageDefaults } from '../contexts/appSlicer';
import { useDispatch } from 'react-redux';
import BsWelcomeTutorial from "../shared/components/custom/welcome-tutorial/welcome-tutorial";
import BirthdayDecoration from '../shared/components/custom/birthday-decoration/birthday-decoration';
import ColleagueProjectsHeader from '../shared/components/custom/colleague-projects/colleague-projects-header';
import { useCurrentUserRights } from '../shared/utils/useRight.hook';
import { Link } from 'react-router-dom';
import { SendableBadges } from '../shared/components/custom/sendable-badges/SendableBadges';

function ProfilePage() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const {setNotyBarState} = useNotyBar();
  const [loading, setLoading] = useState(false);
  const [selectedColleague, setSelectedColleague] = useState<ColleagueDetailsDTO>();
  const [projects, setProjects] = useState<Array<ProjectDetailDTO>>([]);
  const [displayedProjects, setDisplayedProjects] = useState<Array<ProjectDetailDTO>>([]);
  const [isBirthdayWeek, setIsBirthdayWeek] = useState<boolean | undefined>(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [hasRight] = useCurrentUserRights();

  const getColleague = async () => {
    if (!currentUser) {
      return;
    }
    setLoading(true);
    try {
      const response = await ColleagueService.getColleagueByEmail(currentUser.email);
      if (response) {
        setSelectedColleague(response.data);
        setProjects(response.data.projects || []);
      }
    } finally {
      setLoading(false);
    }
  };

  const onOpenHelp = () => {
    setIsHelpOpen(true);
  };

  const onCloseHelp = () => {
    setIsHelpOpen(false);
  };

  useEffect(() => {
    getColleague();

    if (currentUser) {
      setIsBirthdayWeek(currentUser?.birthWeek?.thisWeek);
    }

    // this page is the only one you cannot access from the menu
    dispatch(setNewsPageDefaults(''));
  }, []);

  const onProjectStatusSelected = (selectedStatus?: ProjectStatus) => {
    if (!selectedStatus) {
      setDisplayedProjects(projects);
    } else {
      const filteredProjects = projects.filter(project => project.status === selectedStatus);
      setDisplayedProjects(filteredProjects);
    }
  };

  const handleDownloadCv = () => {
    if (currentUser) {
      return `/api/cv-generator/generate-by-email/${currentUser.email}`;
    }
    return '';
  };

  useEffect(() => {
    onProjectStatusSelected(ProjectStatus.Active);
  }, [projects]);

  return (
    <div className="bs-colleague">
      <BsContentHeader></BsContentHeader>
      {loading && <Loading/>}
      {selectedColleague &&
        <Fragment>
          <div className="bs-colleague__header">
            <BsTag label={Translation.hu.profile.myProfile} readonly={true}/>
            <ColleagueChat myProfile={true} selectedColleague={selectedColleague}/>
          </div>
          <ColleagueInfo selectedColleague={selectedColleague} isBirthWeek={isBirthdayWeek}/>
          <div className="bs-colleague__actions">
            <BsButton
              size="normal"
              color="secondary"
              onClick={() => setNotyBarState(true)}
            >
              {Translation.hu.label.myNotifications}
            </BsButton>

            <BsButton
              size="normal"
              color="secondary"
              onClick={onOpenHelp}
            >
              {Translation.hu.common.help}
            </BsButton>

            {hasRight(AuthRight.CvCreate) &&
              <Link to={handleDownloadCv()} target="_blank" download><BsButton color='secondary'
                                                                               size="normal">{Translation.hu.admin.cv.download}</BsButton></Link>
            }
            {isHelpOpen && <BsWelcomeTutorial open={isHelpOpen} onClose={onCloseHelp}/>}
          </div>
          {selectedColleague.medals !== undefined && selectedColleague.medals?.length > 0 &&
            <ColleagueMedals selectedColleague={selectedColleague}/>
          }
          <ColleagueBadges selectedColleague={selectedColleague} myProfile={true}/>

          <div className="bs-colleague__badges">
            <div className="bs-colleague__badges__header">
              <h2 className="bs-colleague__badges__title">
                {Translation.hu.profile.sendableBadges}
              </h2>
            </div>
            <SendableBadges />
          </div>
          <ColleagueTechs selectedColleague={selectedColleague} editable={true}/>
          <ColleagueProjectsHeader
            allProjectsCount={projects.length}
            displayedProjectsCount={displayedProjects.length}
            onStatusFilterSelected={onProjectStatusSelected}/>
          <ColleagueProjects selectedColleague={selectedColleague}
                             userProjectList={displayedProjects}
                             myProfile={true}
                             projectPage={false}
          />
          {isBirthdayWeek && <BirthdayDecoration/>}
        </Fragment>
      }
    </div>
  );
}

export default ProfilePage;
