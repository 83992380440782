import { ChangeEvent, Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Autocomplete, Box, Chip, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import { ArticleDetailsDTO, ArticleDTO } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import BsButton from '../../ui/button/button';
import BsInput from '../../ui/input/input';
import BaseWysiwygEditor from '../../ui/base-wysiwyg-editor/BaseWysiwygEditor';
import { ArticleService } from '../../../service/articleService';
import { useLoading } from '../../../utils/useLoader.hook';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../../../contexts/appSlicer';
import { ArticleOutlined, Language } from '@mui/icons-material';
import { AxiosError } from 'axios';
export interface BsPostArticleProps {
  className?: string;
  articleForEdit?: ArticleDTO;
  active?: boolean;
  onClose?: (event: BsPostArticleCloseEvent) => void;
}

export interface BsPostArticleCloseEvent { wasSubmitted: boolean }

export interface BsSelectedImageData {
  width: number;
  height: number;
  ratio: number;
  size: number;
}

export const editorId = 'article-editor';

export enum BsPostArticleView {
  create,
  edit,
}

function BsPostArticle({
  className,
  articleForEdit,
  active = false,
  onClose,
}: BsPostArticleProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const setLoading = useLoading();
  const isLoading = useSelector(selectIsLoading);
  const [isVisible, setIsVisible] = useState(active);
  const [article, setArticle] = useState<ArticleDTO>({} as ArticleDTO);
  const [viewState, setViewState] = useState<BsPostArticleView>(!!articleForEdit ? BsPostArticleView.edit : BsPostArticleView.create);
  const [previouslyUsedTags, setPreviouslyUsedTags] = useState<string[]>([]);
  const [isHubspotNewsletter, setHubspotNewsletter] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLinkPosting, setLinkPosting] = useState(false);
  const [articleLead, setArticleLead] = useState<string | undefined>(undefined);
  const [pinnedDate, setPinnedDate] = useState<string>('');
  const [articleContent, setArticleContent] = useState<string>('');
  const [isContentEditAllowed, setContentEditAllowed] = useState(viewState === BsPostArticleView.create);
  const [isCoverSelected, setIsCoverSelected] = useState(viewState === BsPostArticleView.edit);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string|undefined>(articleForEdit?.picture ? `/api/file/${articleForEdit.picture}` : undefined);
  const [coverData, setCoverData] = useState<BsSelectedImageData>();
  const coverPreviewRef = useRef(null);

  const getTagList = useCallback( async () => {
    const tagList = await ArticleService.getTagList();
    if (tagList) {
      setPreviouslyUsedTags(tagList.data);
    }
  }, []);

  const createPost = async ($event: React.SyntheticEvent) => {
    setLoading(true);
    let succeeded = false;
    try {
      if (!articleLead) {
        throw new Error(Translation.hu.article.missingLead);
      }

      await ArticleService.createPost({
        id: article.id,
        tags: JSON.stringify(article.tags),
        lead: articleLead,
        pinnedUntilDate: pinnedDate as unknown as Date, // sad casting noise here
      });
      succeeded = true;
    } catch (error) {
      if (error instanceof AxiosError) {
        enqueueSnackbar(error.response?.data?.message || Translation.hu.form.error);
      } else {
        enqueueSnackbar((error as Error).message || Translation.hu.form.error);
      }
    } finally {
      setLoading(false);
    }

    if (succeeded) {
      onSuccess($event);
      onClose?.({ wasSubmitted: true });
    }
  };

  const postNewArticle = async ($event: React.SyntheticEvent) => {
    $event.preventDefault();
    if (isLinkPosting) {
      createPost($event);
      return;
    }

    let succeeded = false;
    try {
      setLoading(true);
      const formData = new FormData($event.target as HTMLFormElement);
      formData.append('tags', JSON.stringify(article.tags));
      if (!articleLead) {
        throw new Error(Translation.hu.article.missingLead);
      } else if (!isCoverSelected && !article.id) {
        throw new Error(Translation.hu.article.missingCover);
      }
      formData.append('lead', articleLead);
      if (pinnedDate) {
        formData.append('pinnedUntilDate', pinnedDate);
      }
      if (isContentEditAllowed) {
        if (isHubspotNewsletter) {
          if (!article.url) {
            throw new Error(Translation.hu.article.missingUrl);
          }
          formData.append('url', article.url);
        } else {
          if (!articleContent) {
            throw new Error(Translation.hu.article.missingContent);
          }
          formData.append('content', articleContent);
        }
      }

      const req = viewState === BsPostArticleView.edit ? ArticleService.updateArticle : ArticleService.createArticle;
      await req(formData);
      succeeded = true;
    } catch (error) {
      if (error instanceof AxiosError) {
        enqueueSnackbar(error.response?.data?.message || Translation.hu.form.error);
      } else {
        enqueueSnackbar((error as Error).message || Translation.hu.form.error);
      }
    } finally {
      setLoading(false);
    }
    if (succeeded) {
      onSuccess($event);
      onClose?.({ wasSubmitted: true });
    }
  };

  const handleCoverData = useCallback(() => {
    if (coverPreviewRef) {
      const cover = coverPreviewRef.current as unknown as HTMLImageElement;
      if (coverPreviewRef.current) {
        (coverPreviewRef.current as HTMLImageElement).addEventListener('load', () => {
          setCoverData({
            width: cover.naturalWidth,
            height: cover.naturalHeight,
            ratio: cover.naturalWidth / cover.naturalHeight,
            size: selectedFile?.size || 0,
          });
        });
      }
    }
  }, [selectedFile]);

  const handleFileRead = async (e: ChangeEvent<HTMLInputElement>) => {
    const imageTarget = (e.target as HTMLInputElement);
    const imageFile = imageTarget && imageTarget.files && imageTarget.files[0];
    if (imageFile) {
      setSelectedFile(imageFile);
    } else {
      setSelectedFile(undefined);
    }
    setIsCoverSelected(true);
  };

  const onSuccess = (event: React.SyntheticEvent) => {
    if (!article.id) {
      (event.target as HTMLFormElement).reset();
    }
    enqueueSnackbar(Translation.hu.form.success, {variant: 'success'});
  };

  const cancelForm = () => {
    setIsVisible(false);
    onClose?.({ wasSubmitted: false });
  };

  useEffect(() => {
    getTagList();
  }, [getTagList]);

  useEffect(() => {
    setIsVisible(active);
  }, [active]);

  useEffect(() => {
    if (articleForEdit) {
      setViewState(BsPostArticleView.edit);
      setArticle(articleForEdit);
      setArticleLead(articleForEdit.lead);
      const pinnedUntilDate = !!articleForEdit.pinnedUntilDate ? new Date(articleForEdit.pinnedUntilDate).toISOString().split('T')[0] : '';
      setPinnedDate(pinnedUntilDate);
      if ((articleForEdit as unknown as ArticleDetailsDTO).newsletterContent) {
        setArticleContent((articleForEdit as unknown as ArticleDetailsDTO).newsletterContent as string);
        setContentEditAllowed(true);
        setHubspotNewsletter(false);
      }
    } else {
      setViewState( BsPostArticleView.create);
      setArticle({} as ArticleDTO);
      setArticleLead('');
    }
  }, [articleForEdit]);

  useEffect(() => {
    handleCoverData();
  }, [selectedFile, preview, handleCoverData]);

  useEffect(() => {
    if (articleForEdit?.id) {
      return;
    }
    if (!selectedFile) {
        setPreview(undefined);
        return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Fragment>
      {isVisible && <div className={`bs-post-article__backdrop`} />}
      {isVisible &&
        <div
          className={`bs-post-article${className ? ` ${className}` : ``}`}
          id={editorId}>
          <h4 className="bs-form__legend">
            {viewState === BsPostArticleView.edit ? Translation.hu.article.legendEditArticle : Translation.hu.article.legendNewArticle}
            <div className="bs-form__legend__controls">
              <div className="bs-button__group">
                <BsButton
                  onClick={() => setLinkPosting(true)}
                  size={'small'}
                  color={isLinkPosting ? 'primary' : 'secondary'}>
                  <Tooltip title={Translation.hu.article.createFromWeb}>
                    <Language />
                  </Tooltip>
                </BsButton>
                <BsButton
                  onClick={() => setLinkPosting(false)}
                  size={'small'}
                  color={!isLinkPosting ? 'primary' : 'secondary'}>
                  <Tooltip title={Translation.hu.article.createFromArticle}>
                    <ArticleOutlined />
                  </Tooltip>
                </BsButton>
              </div>
            </div>
          </h4>

          <form
            className="bs-form"
            onSubmit={($event) => postNewArticle($event)}>
            {article &&
              <input
                type="hidden"
                name="id"
                value={article.id}
              />
            }
            {!isLinkPosting && <div className="bs-form__field">
              <BsInput
                required
                name="title"
                className="bs-input--spread"
                startAdornment={`${Translation.hu.article.title}:`}
                value={article.title || ''}
                onChange={e => setArticle({...article, title: e.target.value})}
              />
            </div>}
            <div className="bs-form__field">
              <Autocomplete
                multiple
                id="tags"
                className="bs-input bs-input--spread"
                data-label={`${Translation.hu.article.tags}:`}
                options={previouslyUsedTags}
                value={article.tags || []}
                freeSolo
                onChange={(_, value) => setArticle({...article, tags: value})}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />
            </div>
            {!isLinkPosting &&
              <div className={`bs-form__field bs-file-input${(isCoverSelected) ? ` bs-file-input--selected` : ``}`}>
                <div className="bs-file-input__selector-and-feedback">
                  <label className="bs-file-input__input" htmlFor="new-article-cover">
                    {isCoverSelected ? Translation.hu.article.cover : Translation.hu.article.selectCover}
                  </label>
                  <p className="bs-file-input__note">{Translation.hu.article.selectCoverNotes}</p>
                  {coverData && coverData.size > 26214400 &&
                    <p className="bs-file-input__note bs-file-input__note--warning">
                      {Translation.hu.article.errorCoverSizeTooBig}
                    </p>
                  }
                  {coverData && (coverData.width < 420 || coverData.height < 420) &&
                    <p className="bs-file-input__note bs-file-input__note--warning">
                      {Translation.hu.article.errorCoverResTooLow}
                    </p>
                  }
                  {coverData && (coverData.ratio < 0.75 || coverData.ratio > 1.5) &&
                    <p className="bs-file-input__note bs-file-input__note--warning">
                      {Translation.hu.article.errorCoverRatioTooOff}
                    </p>
                  }
                </div>
                {(selectedFile || preview) &&
                  <img className="bs-file-input__preview" ref={coverPreviewRef} src={preview} alt={Translation.hu.article.selectCover} />
                }
                <input
                  id="new-article-cover"
                  name="file"
                  className="bs-file-input__hidden"
                  type="file"
                  accept={'image/*'}
                  onChange={(e) => handleFileRead(e)}
                  size={26214400}
                />
              </div>
            }

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} onChange={(_, page) => setSelectedTab(page)}>
                <Tab label={(isLinkPosting ? Translation.hu.article.leadForPost : Translation.hu.article.lead)} />
                {!isLinkPosting && <Tab label={Translation.hu.article.content} />}
                <Tab label={Translation.hu.article.settings} />
              </Tabs>
            </Box>
            {selectedTab === 0 &&
              <Box>
                <div className="bs-form__field">
                  {articleLead !== undefined &&
                    <BaseWysiwygEditor
                      height={200}
                      value={articleLead}
                      onChange={e => setArticleLead(e)}/>
                  }
                </div>
              </Box>
            }
            {selectedTab === 1 &&
              <Box sx={{marginTop: 1}}>
                {!isLinkPosting &&
                  <>
                    {(viewState === BsPostArticleView.create ||
                        (viewState === BsPostArticleView.edit && isContentEditAllowed)) &&
                      <div className="bs-button__group">
                        <BsButton
                          onClick={() => setHubspotNewsletter(true)}
                          color={isHubspotNewsletter ? 'primary' : 'secondary'}>{Translation.hu.article.contentTypeHubspot}</BsButton>
                        <BsButton
                          onClick={() => setHubspotNewsletter(false)}
                          color={!isHubspotNewsletter ? 'primary' : 'secondary'}>{Translation.hu.article.contentTypeCustom}</BsButton>
                      </div>
                    }
                    {
                      (viewState === BsPostArticleView.edit && !isContentEditAllowed) &&
                      <div>
                        <BsButton onClick={() => setContentEditAllowed(true)}>Tartalom módosítása</BsButton>
                      </div>
                    }

                    {isContentEditAllowed &&
                      <>
                        {isHubspotNewsletter &&
                          <div className="bs-form__field">
                            <BsInput
                              required
                              type="url"
                              placeholder="https://a-hirlevel-pontos-url-je.hu"
                              className="bs-input--spread"
                              startAdornment={`Hubspot ${Translation.hu.article.url}:`}
                              value={article.url}
                              onChange={e => setArticle({...article, url: e.target.value})}
                            />
                          </div>
                        }
                        {!isHubspotNewsletter &&
                          <div className="bs-form__field">
                            <BaseWysiwygEditor
                              value={articleContent}
                              onChange={setArticleContent}/>
                          </div>
                        }
                      </>
                    }
                  </>
                }
              </Box>
            }
            {(selectedTab === 2 || (isLinkPosting && selectedTab === 1)) &&
              <Box sx={{marginTop: 1}}>
                <div className="bs-form__field">
                  <legend className="bs-dialog__legend">{Translation.hu.label.pinPost}</legend>
                  <input type="date"
                         className="bs-input bs-input--date-selector"
                         id="pinDate"
                         name="pinDate"
                         value={pinnedDate}
                         onChange={(evt) => setPinnedDate(evt.target.value)}/>
                </div>
              </Box>
            }

            <div className="bs-form__action">
              <BsButton
                color="secondary"
                className="bs-button--one-liner"
                onClick={cancelForm}
              >
                {Translation.hu.form.cancel}
              </BsButton>
              <BsButton
                type="submit"
                className="bs-button--one-liner"
                disabled={isLoading}
              >
                {viewState === BsPostArticleView.edit ? Translation.hu.label.editPost : Translation.hu.label.newPost}
              </BsButton>
            </div>
          </form>
        </div>
      }
    </Fragment>
  );
}

export default BsPostArticle;
