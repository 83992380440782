import {
  SendBadgeDto,
  BadgeHistoryDTO,
  BadgeConfigListDTO,
  CustomPointTransactionDto,
} from '@bb-sanctuary/common';
import { axiosInstance } from './axiosInstance';

const url = {
  badgeApi: `/api/badge`,
};

export const BadgeService = {
  async getBadgeHistoryForUser(email: string): Promise<Array<BadgeHistoryDTO>> {
    return (await axiosInstance.get(`${url.badgeApi}/history/${email}`)).data;
  },
  async getBadgesForUser(email: string): Promise<BadgeConfigListDTO> {
    return (await axiosInstance.get(`${url.badgeApi}/badges/${email}`)).data;
  },
  async getBadgesForColleagues(): Promise<BadgeConfigListDTO> {
    return (await axiosInstance.get(`${url.badgeApi}/badges/colleague`)).data;
  },
  async sendBadgeToUser(params: SendBadgeDto): Promise<{}> {
    return (await axiosInstance.post(`${url.badgeApi}/send`, params)).data;
  },
  async updatePointsToUser(params: CustomPointTransactionDto): Promise<{}> {
    try {
      const r = await axiosInstance.post(`${url.badgeApi}/points`, params);
      return r.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};
