import { Fragment, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ArticleDTO, ArticleStatus, AuthRight } from '@bb-sanctuary/common';

import { ReactComponent as BsTalkToTheHand } from '../../../../assets/icon/talk-to-the-hand.svg';
import Translations from '../../../data/translation';
import BsButton from '../../ui/button/button';
import { selectCurrentUser } from '../../../../contexts/currentUserSlicer';

export interface ArticleModerationToolsProps {
  item: ArticleDTO;
  approveDraft: (id: number) => Promise<void>;
  deleteDraft: (id: number) => Promise<void>;
  discardDraft: (id: number) => Promise<void>;
  editPost: (item: ArticleDTO) => void;
  children: ReactElement | ReactElement[],
}

export function BsArticleModerationTools({
  item,
  approveDraft,
  deleteDraft,
  discardDraft,
  editPost,
  children,
}: ArticleModerationToolsProps) {
  const currentUser = useSelector(selectCurrentUser);
  const userRights: AuthRight[] = currentUser?.rights || [];

  return (
    <Fragment>
      {(
          userRights.includes(AuthRight.ArticleCreate) ||
          userRights.includes(AuthRight.ArticleModerator)
        ) &&
        <div className="bs-news-feed__draft">
          <div className="bs-news-feed__note">
            {userRights.includes(AuthRight.ArticleCreate) ?
              <Fragment>
                <BsTalkToTheHand className="bs-news-feed__note__icon" />
                <span className="bs-news-feed__note__text">
                  {item.status === ArticleStatus.rejected && (
                    currentUser?.email === item.createdBy.email ?
                      Translations.hu.label.itHasBeenRejected :
                      Translations.hu.label.thisIsARejectedArticle
                  )}
                  {item.status === ArticleStatus.pending && (
                    currentUser?.email === item.createdBy.email ?
                      Translations.hu.label.itHasNotBeenApproved :
                      Translations.hu.label.thisIsAPendingArticle
                  )}
                </span>
              </Fragment>
            :
              <Fragment>
                <BsTalkToTheHand className="bs-news-feed__note__icon" />
                <span className="bs-news-feed__note__text">
                  {Translations.hu.label.thisIsDraft}
                </span>
              </Fragment>
            }
          </div>
          {children}
          <div className="bs-news-feed__action">
            {userRights.includes(AuthRight.ArticleCreate) && currentUser?.email === item.createdBy.email ?
              <Fragment>
                <BsButton size="small" color="secondary" onClick={() => deleteDraft(item.id)}>{Translations.hu.common.delete}</BsButton>
                <BsButton size="small" onClick={() => editPost(item)}>{Translations.hu.common.edit}</BsButton>
              </Fragment>
              :
              <Fragment>
                <BsButton size="small" color="secondary" onClick={() => discardDraft(item.id)} disabled={item.status === ArticleStatus.rejected}>{Translations.hu.label.discardDraft}</BsButton>
                <BsButton size="small" onClick={() => approveDraft(item.id)}>{Translations.hu.label.acceptDraft}</BsButton>
              </Fragment>
            }
          </div>
        </div>
      }
    </Fragment>
  );
}
