export enum DevelopmentMethodology {
  Agile = 'AGILE',
  Scrum = 'SCRUM',
  NonAgile = 'NON_AGILE',
  SAFeAgile = 'SAFe_AGILE',
  Waterfall = 'WATERFALL',
  Kanban = 'KANBAN',
  Extreme = 'EXTREME',
  Lean = 'LEAN',
}
