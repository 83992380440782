import { ProjectStatus } from "@bb-sanctuary/common";
import { Fragment, useState } from "react";
import Translation from "../../../data/translation";
import BsTag from "../../ui/tag/tag";

interface FilterItem {
  label: string;
  key?: ProjectStatus;
}

const statusFilterOptions: Array<FilterItem> = [
  { label: "Mind" },
  {
    label: "Aktív",
    key: ProjectStatus.Active,
  },
  {
    label: "Inaktív",
    key: ProjectStatus.Inactive,
  },
];

interface ColleagueProjectsHeaderProps {
  allProjectsCount: number;
  displayedProjectsCount: number;
  onStatusFilterSelected: (value?: ProjectStatus) => void;
}

function ColleagueProjectsHeader({
  allProjectsCount,
  displayedProjectsCount,
  onStatusFilterSelected,
}: ColleagueProjectsHeaderProps) {
  const [projectStatusFilter, setProjectStatusFilter] = useState<
    ProjectStatus | undefined
  >(ProjectStatus.Active);
  return (
    <Fragment>
      <div className="bs-colleague__projects__header">
        <h2 className="bs-colleague__projects__title">
          {Translation.hu.profile.projects}
        </h2>
        <span className="bs-colleague__projects__count">
          {displayedProjectsCount} / {allProjectsCount} {Translation.hu.common.piece}
        </span>
      </div>
      <div className="bs-projects__filters">
        {statusFilterOptions.map((filter, index) => (
          <BsTag
            key={index}
            label={filter.label}
            active={projectStatusFilter === filter.key}
            onClick={() => {
              setProjectStatusFilter(filter.key);
              onStatusFilterSelected(filter.key);
            }}
          />
        ))}
      </div>
    </Fragment>
  );
}
export default ColleagueProjectsHeader;
