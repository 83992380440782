import { Accordion, AccordionDetails, AccordionSummary, Collapse } from '@mui/material';
import Translation from '../../../data/translation';
import CvProfessionalBgForm from '../cv-form/CvProfessionalBgForm';
import CvProfessionalBackground from '../cv-form/CvProfessionalBg';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { CvProfessionalBackgroundDTO } from '@bb-sanctuary/common';
import { useState } from 'react';
import BsButton from '../../ui/button/button';

export type CvGeneratorAccordionProfessionalBackgroundProps = {
  professionalBackgrounds: CvProfessionalBackgroundDTO[],
  maxCvItemsProfessionalBackground: number;
  onAddNewProfessionalBg: (newProfessionalBg: CvProfessionalBackgroundDTO | null) => void;
  editedBg?: CvProfessionalBackgroundDTO;
  onRemoveProfessionalBg: (id: number) => void;
  onEditProfessionalBg: (id: number) => void;
};

export const CvGeneratorAccordionProfessionalBackground = ({
                                                             professionalBackgrounds,
                                                             maxCvItemsProfessionalBackground,
                                                             onAddNewProfessionalBg,
                                                             editedBg,
                                                             onRemoveProfessionalBg,
                                                             onEditProfessionalBg,
                                                           }: CvGeneratorAccordionProfessionalBackgroundProps) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const addProfBg = (newProfessionalBg: CvProfessionalBackgroundDTO | null) => {
    onAddNewProfessionalBg(newProfessionalBg);
    setIsEditorOpen(false);
  };

  const editProfBg = (id: number) => {
    onEditProfessionalBg(id);
    setIsEditorOpen(true);
  };

  return (
    <Accordion onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.professionalBackgrounds}</p>
          {isAccordionOpen && !isEditorOpen &&
            <BsButton
              size={'small'}
              onClick={(e) => {
                setIsEditorOpen(true);
                e.stopPropagation();
              }}>
              {Translation.hu.cv.accordionTitle.addProfBg}
            </BsButton>
          }
          <p>{Translation.hu.cv.accordionTitle.max(professionalBackgrounds.length, maxCvItemsProfessionalBackground)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {isEditorOpen && <div className="bs-cv-generator__form-backdrop"/>}
          <Collapse in={isEditorOpen}>
            <div className={`bs-cv-generator__form__modal bs-cv-generator__form--open`}>
              {professionalBackgrounds.length < maxCvItemsProfessionalBackground &&
                <CvProfessionalBgForm
                  isFirst={professionalBackgrounds.length < 1}
                  onSetNewProfessionalBg={addProfBg}
                  editedBg={editedBg}
                  onCancel={() => setIsEditorOpen(false)}
                />
              }
            </div>
          </Collapse>
          {professionalBackgrounds.length >= maxCvItemsProfessionalBackground &&
            <p className="warning">{Translation.hu.cv.form.maxWarning}</p>
          }

          <div className="professional-backgrounds-grid">
            <p>{Translation.hu.cv.form.header.startDate}</p>
            <p>{Translation.hu.cv.form.header.endDate}</p>
            <p>{Translation.hu.cv.form.header.company}</p>
            <p>{Translation.hu.cv.form.header.companytitle}</p>
          </div>
          {
            professionalBackgrounds.map((pfbg, index) => {
              return (
                <CvProfessionalBackground
                  onDelete={onRemoveProfessionalBg}
                  onEdit={editProfBg}
                  cvProfessionalBg={pfbg}
                  key={index}
                />
              );
            })
          }
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
