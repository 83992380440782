import { ReactElement } from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

export enum BsFAQCategorySelectorLevel {
  MAIN = 'main',
  SUB = 'sub',
}

export interface BsFAQCategorySelectorProps extends ButtonBaseProps {
  level?: BsFAQCategorySelectorLevel;
  active?: boolean;
}

const BsFAQCategorySelector = ({
  level = BsFAQCategorySelectorLevel.MAIN,
  active = false,
  className,
  ...props
}: BsFAQCategorySelectorProps): ReactElement => {
  return (
    <ButtonBase
      type="button"
      className={`
        bs-faq-category-selector
        bs-faq-category-selector--${level}
        bs-faq-category-selector--${active ? 'active' : 'inactive'}
        ${className || ''}
      `}
      {...props}
    ></ButtonBase>
  );
};

export default BsFAQCategorySelector;
