import { AuthRight, ColleagueDetailsDTO, getUserImage } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import ManagementStars from '../../../../assets/images/management-stars.gif';
import DiscoBall from '../../../../assets/images/disco_ball_gif.gif';
import { useCurrentUserRights } from '../../../utils/useRight.hook';

export interface ColleagueInfoProps {
  selectedColleague: ColleagueDetailsDTO;
  isBirthWeek?: boolean;
}

function ColleagueInfo({
                         selectedColleague,
                         isBirthWeek,
                       }: ColleagueInfoProps) {
  const profileAvatar = getUserImage(selectedColleague, true);
  const [hasRight] = useCurrentUserRights();

  return (
    <div className={!isBirthWeek ? "bs-colleague__info" : "bs-colleague__info bs-colleague__info--birthweek"}>
      {isBirthWeek &&
        <div className="bs-colleague__info__stars">
          <div className="bs-colleague__info__star bs-colleague__info__star--1">
            <img src={ManagementStars} alt=""/>
          </div>
          <div className="bs-colleague__info__star  bs-colleague__info__star--2">
            <img src={ManagementStars} alt=""/>
          </div>
          <div className="bs-colleague__info__star bs-colleague__info__star--3">
            <img src={ManagementStars} alt=""/>
          </div>
        </div>
      }
      {isBirthWeek &&
        <div className="bs-colleague__info__disco">
          <img src={DiscoBall} alt=""/>
        </div>
      }
      <div className="bs-colleague__info__photo">
        <img
          className={`${profileAvatar.includes(`default-avatar`) ? ' bs-fallback-avatar' : ''}`}
          src={profileAvatar}
          alt={`${selectedColleague?.lastName} ${selectedColleague?.firstName}`}
        />
      </div>
      <div className="bs-colleague__info__details">
        <h2>
          {selectedColleague?.lastName} {selectedColleague?.firstName}
        </h2>
        <div className="bs-colleague__info__block">
          <p className="bs-colleague__info__label">
            {Translation.hu.profile.position}:
          </p>
          <p className="bs-colleague__info__item">
            {selectedColleague?.unit} - {selectedColleague?.positionTitle}
          </p>
        </div>
        <div className="bs-colleague__info__block">
          <p className="bs-colleague__info__label">
            {Translation.hu.profile.email}:
          </p>
          <p className="bs-colleague__info__item">
            <a href={`mailto:${selectedColleague?.email}`}>{selectedColleague?.email}</a>
          </p>
        </div>
        <div className="bs-colleague__info__block">
          <p className="bs-colleague__info__label">
            {Translation.hu.profile.phone}:
          </p>
          <p className="bs-colleague__info__item">
            {selectedColleague?.phone ?
              <a href={`tel:${selectedColleague?.phone}`}>{selectedColleague?.phone}</a>
              :
              '-'
            }
          </p>
        </div>

        {hasRight(AuthRight.AdminOfCv) &&
          <div className="bs-colleague__info__block">
            <p className="bs-colleague__info__label">
              {Translation.hu.profile.cvId}:
            </p>
            <p className="bs-colleague__info__item">
              {selectedColleague.cvCode || '-'}
            </p>
          </div>
        }
      </div>
    </div>
  );
}

export default ColleagueInfo;
