import { BaseColleagueDTO, SexEnum } from '../colleague';

export function getUserAvatar(user: { email: string }): string {
  return `/api/file/${user.email}.jpg?s=avatar`;
}

export function getUserThumbnail(user: {email: string}): string {
  return `/api/file/${user.email}.jpg?s=thumbnail`;
}

export function getUserImage(user?: BaseColleagueDTO,
                             thumbnail?: boolean) {
  if (user && user.image) {
    if (thumbnail) {
      return getUserThumbnail(user);
    }
    return getUserAvatar(user);
  } else if (user && user.sex === SexEnum.Female) {
    return '/assets/svg/default-avatar-female.svg';
  } else {
    return '/assets/svg/default-avatar-male.svg';
  }
}
