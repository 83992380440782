import { Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  CreateTechnologyDTO, TechnologyDTO,
} from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityEditor from "../../shared/components/admin/GenericEntityEditor";
import { createRequiredField } from "../../shared/utils/formUtils";
import { TechService } from "../../shared/service/techService";

const ADMIN_TECH_URL = `/admin/tech`;

const formSchema = {
  fields: [
    createRequiredField('name')
      .label('Name of the technology', '')
      .sizeColumns(6)
      .build(),
  ],
};

function AdminTechEditPage() {
  const params = useParams();
  const id = params.id;

  const loadData = async () => {
    if (id) {
      return AdminService.getTech(+id);
    }
    return {};
  };

  const saveTech = async (params: TechnologyDTO ) => {
    if (params.id) {
      return AdminService.updateTech(params.id,{name: params.name});
    }
    return TechService.postTech({name: params.name} as CreateTechnologyDTO);
  };

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__editor">
        {formSchema ?
          <GenericEntityEditor
            id={id}
            saveFn={(el) => saveTech(el)}
            entityLabel={Translation.hu.label.tech}
            loadEntityFn={loadData}
            listingUrl={ADMIN_TECH_URL}
            formSchema={formSchema}
            hideCancel={false}
          />
        :
          <Fragment>
            {Translation.hu.common.loading}
          </Fragment>
        }
      </div>
    </Fragment>
  );
}

export default AdminTechEditPage;
