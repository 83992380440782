import React, { createContext, useState } from "react";

type NotyBarStateContextProps =
  | { notyBarState: boolean; setNotyBarState: (state: boolean) => void }
  | undefined
type NotyBarProviderProps = { children: React.ReactNode }

export const NotyBarContext = createContext<NotyBarStateContextProps>(undefined);

export const NotyBarProvider = ({ children }: NotyBarProviderProps) => {
  const [notyBarState, setNotyBarState] = useState(false);
  return <NotyBarContext.Provider value={{ notyBarState, setNotyBarState }}>{children}</NotyBarContext.Provider>;
};

export const useNotyBar = () => {
  const context = React.useContext(NotyBarContext);
  if (context === undefined) {
    throw new Error(`useNotyBar must be used within a NotyBarProvider`);
  }
  return context;
};
