import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AccountDTO, AuthRight } from '@bb-sanctuary/common';

const stateName = 'currentUser';

export interface CurrentUserState {
  isAuthenticated: null | boolean;
  currentUser?: AccountDTO;
}

export const currentUserSlice = createSlice<CurrentUserState, SliceCaseReducers<CurrentUserState>, string>({
  name: stateName,
  initialState: {
    isAuthenticated: null,
  },
  reducers: {
    setCurrentUserData(state, value: PayloadAction<AccountDTO>) {
      if (value.payload) {
        state.isAuthenticated = true;
        state.currentUser = value.payload;
      } else {
        state.isAuthenticated = false;
        state.currentUser = undefined;
      }
    },
    setWelcomeDialogDisplayed(state) {
      if (state.currentUser) {
        state.currentUser.settings!.welcomeDialogDisplayed = true;
      }
    },
  },
});

export const { setCurrentUserData, setWelcomeDialogDisplayed } = currentUserSlice.actions;

export const selectCurrentUser = (state: {[stateName]: CurrentUserState}) => state[stateName].currentUser;
export const selectIsAuthenticated = (state: {[stateName]: CurrentUserState}) => state[stateName].isAuthenticated;
export const selectAdminAccessibleForUser = (state: {[stateName]: CurrentUserState}) => {
  const rights = state[stateName].currentUser?.rights || [];
  return (rights || []).some(r => r.toLowerCase().includes(`admin_of`) || r === AuthRight.Administrator);
};

export const currentUserSlicer = currentUserSlice.reducer;
