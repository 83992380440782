import { CvCompetenceDTO, TechnologyLevel, UserDTO, UserTechnologyDTO } from "@bb-sanctuary/common";
import { Autocomplete, Popover, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Translation from "../../../data/translation";
import { TechService } from "../../../service/techService";
import BsButton from "../../ui/button/button";
import { BsSlider } from "./BsSlider";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { CvGeneratorService } from '../../../service/cvGeneratorService';

interface GroupedTechList {
  group: string;
  techName: string;
}

const sliderMarks = [
  {value: 1, label: '1'},
  {value: 10, label: '10'},
];

const levelMapping = {
  [TechnologyLevel.Novice]: 3,
  [TechnologyLevel.Confident]: 5,
  [TechnologyLevel.Pro]: 7,
  [TechnologyLevel.Master]: 10,
};

export interface CvCompetenceFormParams {
  competencies: CvCompetenceDTO[];
  onSetNewCompetence: (competence: CvCompetenceDTO[]) => void;
  onRemoveCompetence: (id: number) => void;
  onUpdateCompetence: (updatedCompetence: CvCompetenceDTO) => void;
  disabled: boolean;
}

const CvCompetenceForm = ({
                            competencies,
                            onSetNewCompetence,
                            onRemoveCompetence,
                            onUpdateCompetence,
                            disabled,
                          }: CvCompetenceFormParams) => {
  const [userSkillList, setUserSkillList] = useState<UserTechnologyDTO[]>();
  const [autocompleteTechList, setAutocompleteTechList] = useState<GroupedTechList[]>([]);
  const [tech, setTech] = useState<GroupedTechList | null>(null);
  const [level, setLevel] = useState<number>(1);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [editedCompetence, setEditedCompetence] = useState<CvCompetenceDTO>();
  const [editorVisible, setEditorVisible] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setEditedCompetence(undefined);
  };

  const fetchTechList = useCallback(async () => {
    const response = await TechService.getTechList();
    const userTechList = await CvGeneratorService.getUserTechList();
    setUserSkillList([...userTechList, ...(competencies.filter(comp => !userTechList.find(ut => ut.technology.name === comp.description)).map(comp => ({
      technology: {
        id: comp.id,
        name: comp.description,
        createdAt: new Date(),
        createdBy: {} as UserDTO,
      },
      level: TechnologyLevel.Master,
    })))]);

    const techNameList: GroupedTechList[] = response.data.technologyList.map(tech => {
      return {
        group: tech.technology.name[0].toUpperCase(),
        techName: tech.technology.name,
      };
    }).sort((a, b) => -b.group.localeCompare(a.group));

    setAutocompleteTechList(techNameList);
  }, []);

  const getDisplayedSkillList = () => {
    const skillsFromCompetencies = competencies
      .filter(comp => !(userSkillList || []).find(ut => ut.technology.name === comp.description))
      .map(comp => ({
      technology: {
        id: comp.id,
        name: comp.description,
        createdAt: new Date(),
        createdBy: {} as UserDTO,
      },
      level: TechnologyLevel.Master,
    }));
    return [...(userSkillList || []), ...skillsFromCompetencies];
  };

  const isValidTech = (): boolean => {
    return !!tech && tech.techName.length > 0;
  };

  const handleSaveNewCompetence = () => {
    if (!tech) {
      return;
    }

    onSetNewCompetence([{
      id: Math.floor(Math.random() * 1000000),
      description: tech.techName,
      level,
    }]);

    setTech({group: '', techName: ''});
    setLevel(1);
  };

  const getIsUserSkillInCompetence = (userSkill: UserTechnologyDTO): CvCompetenceDTO | undefined => {
    return (competencies || []).find(competence => competence.description === userSkill.technology.name);
  };

  const addUserSkill = (userSkill: UserTechnologyDTO) => {
    onSetNewCompetence([{
      id: Math.floor(Math.random() * 1000000),
      description: userSkill.technology.name,
      level: levelMapping[userSkill.level],
    }]);
  };

  const editCompetenceLevel = (event: React.MouseEvent<HTMLSpanElement>,
                               userSkillInCompetence: CvCompetenceDTO | undefined) => {
    if (!userSkillInCompetence) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setEditedCompetence(userSkillInCompetence);
  };

  const setCompetenceLevel = (newLevel: number) => {
    if (!editedCompetence) {
      return;
    }
    const updatedCompetence = {...editedCompetence, level: newLevel};
    setEditedCompetence(updatedCompetence);
    onUpdateCompetence(updatedCompetence);
  };

  const addAllSkill = () => {
    onSetNewCompetence((userSkillList || []).map(userSkill => ({
      id: Math.floor(Math.random() * 1000000),
      description: userSkill.technology.name,
      level: levelMapping[userSkill.level],
    })));
  };

  const getAutocompleteTechList = () => {
    if (tech && !autocompleteTechList.find(te => te.techName === tech.techName)) {
      return [tech, ...autocompleteTechList];
    }
    return autocompleteTechList;
  };

  useEffect(() => {
    fetchTechList();
  }, []);

  return (
    <div className="bs-cv-form bs-cv-form__competence">
      <div className="bs-colleague__techs__list">
        {
          getDisplayedSkillList().map((userSkill) => {
            const userSkillInCompetence = getIsUserSkillInCompetence(userSkill);

            return <div
              className={`bs-tech-tag ${userSkillInCompetence ? 'bs-tech-tag--included' : 'bs-tech-tag--not-included'}`}
              key={userSkill.technology.id}
            >
              <span className="bs-tech-tag__action">
                {userSkillInCompetence ?
                  <CheckCircleOutlineIcon
                    onClick={() => onRemoveCompetence(userSkillInCompetence?.id)}
                  /> :
                  <RadioButtonUncheckedIcon
                    onClick={() => addUserSkill(userSkill)}
                  />
                }
              </span>
              <label className="bs-tech-tag__tech">
                {userSkill.technology.name}
              </label>
              <span
                className="bs-tech-tag__level"
                onClick={(e) => editCompetenceLevel(e, userSkillInCompetence)}
              >
                {userSkillInCompetence ? `Lvl ${userSkillInCompetence.level}` : userSkill.level}
              </span>

              {userSkillInCompetence &&
                <Popover
                  id={userSkillInCompetence.id.toString()}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <p style={{textAlign: 'center'}}>{userSkillInCompetence.description}</p>
                  <BsSlider
                    aria-labelledby='input-slider'
                    className="bs-cv-slider"
                    valueLabelDisplay="auto"
                    value={userSkillInCompetence.level}
                    defaultValue={1}
                    step={1}
                    marks={sliderMarks}
                    min={1}
                    max={10}
                    onChange={(_, value) => setCompetenceLevel(Array.isArray(value) ? value[0] : value)}
                  />
                </Popover>
              }
            </div>;
          })
        }

        {!disabled &&
          <>
            <div
              className="bs-tech-tag__button"
              onClick={() => setEditorVisible(true)}
            >
              <ControlPointIcon/>
            </div>

            <div
              className="bs-tech-tag__button"
              onClick={() => addAllSkill()}
            >
              <DoneAllIcon/>
            </div>
          </>
        }
      </div>


      {editorVisible && !disabled &&
        <>
          <Autocomplete
            className="bs-cv-form-autocomplete bs-cv-form-autocomplete__competence"
            data-label={`Skill`}
            options={getAutocompleteTechList()}
            getOptionLabel={option => option.techName}
            value={tech}
            groupBy={option => option.group}
            onChange={(_, value) => {
              if (value !== null) {
                setTech(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => setTech({group: e.target.value[0].toUpperCase(), techName: e.target.value})}
              />
            )}
            isOptionEqualToValue={(option, value) => option.techName === value.techName}
          />
          <p>{Translation.hu.cv.form.header.competenceLevel}</p>
          <BsSlider
            aria-labelledby='input-slider'
            className="bs-cv-slider"
            valueLabelDisplay="auto"
            value={level}
            defaultValue={1}
            step={1}
            marks={sliderMarks}
            min={1}
            max={10}
            onChange={(_, value) => setLevel(Array.isArray(value) ? value[0] : value)}
          />

          <BsButton
            disabled={!isValidTech()}
            onClick={handleSaveNewCompetence}
            size={'small'}
          >{Translation.hu.cv.form.add}</BsButton>
        </>
      }
    </div>
  );
};

export default CvCompetenceForm;
