import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ColleagueDetailsDTO,
  CreateProjectDTO,
  getUserImage,
  ProjectStatus,
  TechnologyDTO,
  UpdateProjectDTO,
  UserDTO,
} from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityEditor from "../../shared/components/admin/GenericEntityEditor";
import { createField, createRequiredField, createSelectField } from "../../shared/utils/formUtils";
import { ColleagueService } from "../../shared/service/colleagueService";
import { TechService } from "../../shared/service/techService";
import { AutocompleteRenderGetTagProps, Avatar, Chip } from '@mui/material';

const ADMIN_PROJECTS_URL = `/admin/projects`;

const createFormSchema = (
  userList: ColleagueDetailsDTO[],
  techList: TechnologyDTO[],
) => ({
  fields: [
    createRequiredField('name')
      .label('Project Name used in Sanctuary', 'This will be displayed in the sanctuary profile')
      .sizeColumns(6)
      .build(),
    createSelectField('status',
      'Status',
      Object.keys(ProjectStatus).map((status) => ({
        label: status,
        value: ProjectStatus[status as keyof typeof ProjectStatus],
      })),
      6)
      .build(),

    createField('officialData.name')
      .label('Project name used in CV', 'Project name to be displayed in colleague CV')
      .sizeColumns(6)
      .build(),
    createField('officialData.projectOwner')
      .label('Project owner used in CV (company)', 'Project owner to be displayed in colleague CV')
      .sizeColumns(6)
      .build(),
    createField('officialData.description')
      .label('Description/Project scope used in CV', 'Project description: industry, project focus, used technologies to be displayed in colleague CV')
      .component('textarea')
      .sizeColumns(12)
      .build({minRows: 5, maxRows: 15}),
    createRequiredField('description')
      .label('Description displayed in Sanctuary', '')
      .component('textarea')
      .sizeColumns(6)
      .build({minRows: 5, maxRows: 15}),
    createField('link')
      .label('Link', '')
      .sizeColumns(6)
      .build(),
    createSelectField('users',
      'Members',
      userList.map((user) => ({...user})),
      12)
      .component('autocomplete')
      .build({
        multiple: true,
        renderTags: (option: UserDTO,
                     index: number,
                     getTagProps: AutocompleteRenderGetTagProps) => {
          return <Chip variant="outlined"
                       avatar={<Avatar alt={option.email} src={getUserImage(option)} />}
                       label={`${option.lastName} ${option.firstName}`}
                       {...getTagProps({ index })} />;
        },
        renderOption: (props: React.HTMLAttributes<HTMLLIElement>,
                       option: UserDTO,
                       selected: { selected: boolean }) => {
          return <li {...props} key={option.email}><Avatar style={{opacity: selected.selected ? 0.2 : 1}} alt={option.email} src={getUserImage(option)} />&nbsp;{option.lastName} {option.firstName}</li>;
        },
        getOptionLabel: (option: UserDTO) => `${option.lastName} ${option.firstName}`,
        getValue: (option: UserDTO) => option.email,
        isEqual: (option: UserDTO, selection: UserDTO) => option.email === selection?.email,
      }),
    createSelectField('technologies',
      'Technologies',
      techList.map((tech) => ({...tech, label: tech.name})),
      6)
      .component('autocomplete')
      .build({
        multiple: true,
        getOptionLabel: (option: TechnologyDTO) => option.name,
        isEqual: (option: TechnologyDTO, selection: TechnologyDTO) => option.id === selection?.id,
      }),
    createField('logo', 'logo')
      .component('image')
      .sizeColumns(12)
      .build({disableUploadOnSelect: true}),
  ],
});

function AdminProjectsEditPage() {
  const params = useParams();
  const id = params.id;
  const [formSchema, setFormSchema] = useState<any>();

  const getUserList = async () => await ColleagueService.getColleagues('', 0, false, 1000, true);
  const getTechList = async () => await TechService.getTechList();
  const loadData = async () => {
    if (id) {
      const project = await AdminService.getProject(+id);
      return {
        ...project,
        users: project.users.map((user: UserDTO) => ({...user})),
        technologies: project.technologies.map((tech: TechnologyDTO) => ({...tech})),
      } as CreateProjectDTO;
    }
    return {};
  };
  const saveProject = async (params: CreateProjectDTO | UpdateProjectDTO ) => {
    if (id) {
      return AdminService.updateProject(params as UpdateProjectDTO);
    } else {
      return AdminService.createProject(params as CreateProjectDTO);
    }
  };

  useEffect(() => {
    Promise.all([
      getUserList(),
      getTechList(),
    ]).then((res) => {
      setFormSchema(createFormSchema(
        res[0].data.colleagueList.resultList,
        res[1].data.technologyList.map(_ => _.technology),
      ));
    });
  }, []);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__editor">
        {formSchema ?
          <GenericEntityEditor
            id={id}
            saveFn={saveProject}
            entityLabel={Translation.hu.label.projects}
            loadEntityFn={loadData}
            listingUrl={ADMIN_PROJECTS_URL}
            formSchema={formSchema}
            hideCancel={false}
          />
        :
          <Fragment>
            {Translation.hu.common.loading}
          </Fragment>
        }
      </div>
    </Fragment>
  );
}

export default AdminProjectsEditPage;
