import { BadgeType } from './badgeType';

export enum MedalType {
  HelpfulHero = 'HelpfulHero',
  CommunityMaster = 'CommunityMaster',
  ExcellentPerformer = 'ExcellentPerformer',
  TopInfluencer = 'TopInfluencer',
  MasterOfInterviews = 'MasterOfInterviews',
  MostSociableNinja = 'MostSociableNinja',
}

export const MedalTypeMap: {[key in BadgeType]?: MedalType} = {
  [BadgeType.ThankYou]: MedalType.HelpfulHero,
  [BadgeType.Teamwork]: MedalType.CommunityMaster,
  [BadgeType.GreatJob]: MedalType.ExcellentPerformer,
  [BadgeType.Inspiring]: MedalType.TopInfluencer,
  [BadgeType.GreatInterview]: MedalType.MasterOfInterviews,
  [BadgeType.HighFive]: MedalType.MostSociableNinja,
};
