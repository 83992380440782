import { ColleagueDTO, CvFileType, GenerateCvsParams, getUserImage, UserDTO } from "@bb-sanctuary/common";
import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BsButton from "../../shared/components/ui/button/button";
import Translation from "../../shared/data/translation";
import { CvGeneratorService } from "../../shared/service/cvGeneratorService";
import { AdminService } from './AdminService';
import { useSnackbar } from 'notistack';
import { useLoading } from '../../shared/utils/useLoader.hook';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../contexts/appSlicer';


const AdminCvColleagueListPage = () => {
  const [allColleague, setAllColleague] = useState<ColleagueDTO[]>([]);
  const setLoading = useLoading();
  const isLoading = useSelector(selectIsLoading);
  const [selectedColleagues, setSelectedColleagues] = useState<ColleagueDTO[]>([]);
  const [isAnonimName, setisAnonimName] = useState<boolean>(false);
  const [isAnonimCompany, setIsAnonimCompany] = useState<boolean>(false);
  const [selectedFileType, setSelectedFileType] = useState<CvFileType>(CvFileType.pdf);
  const [zipId, setZipId] = useState<string>('');
  const invisibleLinkRef = useRef<HTMLAnchorElement>(null);
  const {enqueueSnackbar} = useSnackbar();

  const newSelectedColleague = (newColleague: ColleagueDTO[]) => {
    setSelectedColleagues(newColleague);
  };

  const changeHandlerFileType = (isDocx: boolean) => {
    setSelectedFileType(isDocx ? CvFileType.docx : CvFileType.pdf);
  };

  const download = async () => {
    const data: GenerateCvsParams = {
      emails: selectedColleagues.map(_ => _.email),
      withPersonalCode: isAnonimName,
      withCompanyName: isAnonimCompany,
      cvFileType: selectedFileType,
    };
    try {
      setLoading(true);
      const response = await CvGeneratorService.generateZip(data, selectedFileType);
      setZipId(response);

      if (invisibleLinkRef && invisibleLinkRef.current) {
        console.log(`http://localhost:8080/api/cv-generator/download/${response}`);

      }
    } catch (err) {
      setZipId('');
    } finally {
      setLoading(false);
    }
  };

  const getDownloadLink = () => {
    return `/api/cv-generator/download/${zipId}`;
  };

  useEffect(() => {
    if (zipId && invisibleLinkRef.current) {
      invisibleLinkRef.current.click();
    }
  }, [zipId]);


  const handleFileRead = async (e: ChangeEvent<HTMLInputElement>) => {
    const imageTarget = (e.target as HTMLInputElement);
    const templateFile = imageTarget && imageTarget.files && imageTarget.files[0];
    if (templateFile) {
      const formData = new FormData();
      formData.append('file', templateFile);
      try {
        setLoading(true);
        await AdminService.uploadNewTemplate(templateFile);
        enqueueSnackbar(Translation.hu.cv.template.uploadSuccess);
      } catch (err) {
        enqueueSnackbar(Translation.hu.cv.template.uploadFailed);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const {data} = await CvGeneratorService.getCvUser();
        setAllColleague(data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Fragment>
      <Link
        ref={invisibleLinkRef}
        to={getDownloadLink()}
        style={{display: 'none'}}
        target="_blank"
        download
      ></Link>

      <div className="bs-admin__section">
        <h3>{Translation.hu.cv.generate}</h3>
        <div className="bs-admin__sub-section">
          <Autocomplete
            disabled={isLoading}
            multiple={true}
            options={allColleague}
            data-label={'Kollégák'}
            value={selectedColleagues}
            getOptionLabel={(option) => `${option.lastName} ${option.firstName}`}
            onChange={(_, value) => {
              newSelectedColleague(value);
            }}
            renderInput={(params) => (
              <TextField {...params} />
            )}
            renderTags={(optionList: readonly ColleagueDTO[], getTagProps) =>
              optionList.map((option, index) =>
                <Chip variant="outlined" avatar={<Avatar alt={option.email} src={getUserImage(option)}/>}
                      label={`${option.lastName} ${option.firstName}`}{...getTagProps({index})} />,
              )
            }
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>,
                           option: UserDTO,
                           selected: { selected: boolean }) => {
              return <li {...props} key={option.email}><Avatar style={{opacity: selected.selected ? 0.2 : 1}}
                                                               alt={option.email}
                                                               src={getUserImage(option)}/>&nbsp;{option.lastName} {option.firstName}
              </li>;
            }}
          />
          <div>
            <FormControlLabel label={'név nélkül'} control={
              <Checkbox
                disabled={isLoading}
                checked={isAnonimName}
                onChange={() => setisAnonimName(!isAnonimName)}/>
            }/>
            <FormControlLabel label={'cégnév nélkül'} control={
              <Checkbox
                disabled={isLoading}
                checked={isAnonimCompany}
                onChange={() => setIsAnonimCompany(!isAnonimCompany)}/>
            }/>
          </div>

          <div>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>PDF</Typography>
              <Switch onChange={(_, val) => changeHandlerFileType(val)}/>
              <Typography>Docx</Typography>
            </Stack>
          </div>

        </div>
        <div className="bs-admin__actions">
          <BsButton
            disabled={isLoading}
            onClick={download}>{Translation.hu.admin.cv.download}</BsButton>
        </div>
      </div>

      <div className="bs-admin__section">
        <h3>{Translation.hu.cv.template.header}</h3>
        <div className="bs-admin__actions">
          <input
            id="new-template"
            name="file"
            className="bs-file-input__hidden"
            type="file"
            accept={'application/vnd.openxmlformats'}
            onChange={(e) => handleFileRead(e)}
            size={26214400}
          />
          <label className="bs-file-input__input" htmlFor="new-template">
            {Translation.hu.cv.template.upload}
          </label>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminCvColleagueListPage;
