import {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { AnyAction } from '@reduxjs/toolkit';
import { ArrowUpward, CloseOutlined, Search } from '@mui/icons-material';
import {
  ArticleDTO,
  ArticleFilterDTO,
  ArticleModerateDTO,
  ArticleStatus,
  ArticleType,
  AuthRight,
} from '@bb-sanctuary/common';
import { IStore } from '../store';
import { useCurrentUserRights } from '../shared/utils/useRight.hook';
import { Translation } from '../shared/data/translation';
import BsInput from '../shared/components/ui/input/input';
import BsTag from '../shared/components/ui/tag/tag';
import BsButton from '../shared/components/ui/button/button';
import BsPostArticle, { BsPostArticleCloseEvent, editorId } from '../shared/components/custom/post-article/post-article';
import BsArticleListing from '../shared/components/custom/article-listing/ArticleListing';
import BsArticleSearchListing from '../shared/components/custom/article-search-listing/ArticleSearchListing';
import BsNotificationBar from '../shared/components/ui/notification-bar/notification-bar';
import { ArticleService } from '../shared/service/articleService';
import { useNavigate } from 'react-router-dom';
import { BsRoutes } from '../shared/data/bsRoutes';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';
import { searchOnEnter } from '../shared/utils/searchOnEnter';
import { useLoading } from '../shared/utils/useLoader.hook';
import {
  fetchNews,
  selectNewsList,
  selectNewsPageActiveTagList, selectNewsPageArticleTypeFilter,
  selectNewsPageOffset,
  selectNewsPageScrollPosition,
  selectNewsPageSearchKeyword,
  selectNewsTotalSize,
  selectTagList,
  setNewsPageActiveTagList, setNewsPageArticleTypeFilter,
  setNewsPagePageIndex,
  setNewsPageScrollPosition,
  setNewsPageSearchKeyword,
} from '../contexts/appSlicer';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import BirthdayDecoration from '../shared/components/custom/birthday-decoration/birthday-decoration';

const pageSize = 15;

function HomePage() {
  const dispatch = useDispatch();
  const newsStatus = useSelector((state: IStore) => state.app.news.status);
  const totalSize = useSelector(selectNewsTotalSize);
  const news = useSelector(selectNewsList);
  const newsTags = useSelector(selectTagList);
  const scrollPosition = useSelector(selectNewsPageScrollPosition);
  const articleTypeFilter = useSelector(selectNewsPageArticleTypeFilter);
  const offset = useSelector(selectNewsPageOffset);
  const searchKeyword = useSelector(selectNewsPageSearchKeyword);
  const activeTagList = useSelector(selectNewsPageActiveTagList);

  const [searchTerm, setSearchTerm] = useState<string>(searchKeyword || '');
  const [forceSearchLater, setForceSearchLater] = useState(false);
  const [isPostArticleView, setIsPostArticleView] = useState<boolean>(false);
  const [isEditArticleView, setIsEditArticleView] = useState<boolean>(false);
  const [articleForEdit, setArticleForEdit] = useState<ArticleDTO | undefined>(undefined);
  const [getToKnowAndSendBadge, setGetToKnowAndSendBadge] = useState(true);
  const currentUser = useSelector(selectCurrentUser);
  const [isBirthdayWeek, setIsBirthdayWeek] = useState<boolean | undefined>(false);
  const {enqueueSnackbar} = useSnackbar();
  const [hasRight] = useCurrentUserRights();
  const setLoading = useLoading();
  const navigate = useNavigate();
  const [showGoTopButton, setShowGoTopButton] = useState(false);

  const scrollToLastPosition = useCallback(() => {
    if (scrollPosition !== undefined) {
      dispatch(setNewsPageScrollPosition(undefined));
      window.scroll({top: scrollPosition, behavior: 'auto'});
    }
  }, [dispatch,scrollPosition]);

  const getNews = useCallback(async (force?: boolean) => {
    if (newsStatus === 'loading') { // do not start a new request during loading
      return;
    }
    if (!force && news?.length > 0) { // if there is any data in the store and this is not a force refresh cancel
      return;
    }
    const getTotal = (!totalSize || offset === 0);
    const params: ArticleFilterDTO = {
      ...(searchKeyword && { searchText: searchKeyword }),
      ...(activeTagList && { tags: activeTagList }),
      ...(articleTypeFilter && {type: articleTypeFilter}),
      offset,
      minimumPageSize: pageSize,
      includeTotalCount: getTotal,
    };
    // unfortunate casting because of dependency conflicts
    dispatch(fetchNews(params) as unknown as AnyAction);
  }, [activeTagList, articleTypeFilter, dispatch, news?.length, newsStatus, offset, searchKeyword, totalSize]);

  const searchFeed = () => {
    resetPaging();
    dispatch(setNewsPageSearchKeyword(searchTerm));
    setForceSearchLater(true);
  };

  const clearSearch = () => {
    resetPaging();
    setSearchTerm('');
    dispatch(setNewsPageSearchKeyword(''));
    setForceSearchLater(true);
  };

  const filterFeed = (tag?: string) => {
    resetPaging();
    if ((tag === undefined) ||
      (activeTagList.length === 1 && activeTagList[0] === tag)) {
      dispatch(setNewsPageActiveTagList([]));
    } else {
      const ind = activeTagList.indexOf(tag);
      if (ind === -1) {
        // dispatch(setNewsPageActiveTagList([...activeTagList, tag]));
        dispatch(setNewsPageActiveTagList([tag]));
      } else {
        const newTagList = [...activeTagList];
        newTagList.splice(ind, 1);
        dispatch(setNewsPageActiveTagList(newTagList));
      }
    }
    dispatch(setNewsPageArticleTypeFilter(undefined));
    setForceSearchLater(true);
  };

  const filterFeedByType = (needTypeFilter: boolean) => {
    resetPaging();
    dispatch(setNewsPageActiveTagList([]));
    dispatch(setNewsPageArticleTypeFilter(needTypeFilter ? ArticleType.BlackbeltHazaTaja : undefined));
    setForceSearchLater(true);
  };

  const isMoreToLoad = (): boolean => {
    return !!totalSize && totalSize > (news.length);
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
    dispatch(setNewsPageScrollPosition(undefined));
  };

  const resetPaging = () => {
    dispatch(setNewsPagePageIndex(0));
  };

  const loadMore = () => {
    if (isMoreToLoad()) {
      dispatch(setNewsPagePageIndex(new Date(news[news.length - 1].createdAt).getTime()));
      setForceSearchLater(true);
    }
  };

  const createPost = () => {
    setArticleForEdit({} as ArticleDTO);
    setIsPostArticleView(true);
  };

  const deleteDraft = async (id: number) => {
    if (window.confirm(Translation.hu.label.confirmAction)) {
      setLoading(true);
      try {
        await ArticleService.deleteDraft(id);
        await getNews(true);
      } catch {
        enqueueSnackbar(Translation.hu.article.errorDelete);
      } finally {
        setLoading(false);
      }
    }
  };

  const editPost = (article: ArticleDTO) => {
    setArticleForEdit(article);
    setIsEditArticleView(true);
    // setScrollPositionBeforeEdit(window.scrollY);
    dispatch(setNewsPageScrollPosition(window.scrollY));
    setTimeout(() => {
      document.getElementById(editorId)?.scrollIntoView({behavior: 'smooth'});
    }, 100);
  };

  const discardDraft = async (id: number) => {
    setLoading(true);
    try {
      const statusChange: ArticleModerateDTO = {id, status: ArticleStatus.rejected};
      await ArticleService.moderateArticle(statusChange);
      await getNews(true);
    } catch {
      enqueueSnackbar(Translation.hu.article.errorDiscard);
    } finally {
      setLoading(false);
    }
  };

  const approveDraft = async (id: number) => {
    setLoading(true);
    try {
      const statusChange: ArticleModerateDTO = { id, status: ArticleStatus.approved };
      await ArticleService.moderateArticle(statusChange);
      await getNews(true);
    } catch {
      enqueueSnackbar(Translation.hu.article.errorApprove);
    } finally {
      setLoading(false);
    }
  };

  const handlePostArticleClose = (event: BsPostArticleCloseEvent) => {
    setIsPostArticleView(false);
    if (event.wasSubmitted) {
      getNews(true);
    }
  };

  const handleEditArticleClose = (event: BsPostArticleCloseEvent) => {
    setIsEditArticleView(false);
    if (event.wasSubmitted) {
      getNews(true);
    } else {
      scrollToLastPosition();
    }
  };

  const hideGetToKnowAndSendBadgeCard = () => {
    localStorage.setItem('hideGetToKnowAndSendBadge', `true`);
    setGetToKnowAndSendBadge(false);
  };

  const openArticle = () => {
    dispatch(setNewsPageScrollPosition(window.scrollY));
  };

  const shouldShowBackToTopButton = useCallback(() => {
    setShowGoTopButton(window.scrollY > 500);
  }, []);

  useEffect(() => {
    if (newsStatus === 'fulfilled') {
      setLoading(false);
    }
    if (newsStatus === 'error') {
      setLoading(false);
      enqueueSnackbar(Translation.hu.article.errorLoading);
    }
  }, [newsStatus]);

  useEffect(() => {
    getNews(forceSearchLater);
    setForceSearchLater(false);
    scrollToLastPosition();
  }, [forceSearchLater]);

  useEffect(() => {
    if(isEditArticleView) {
      setIsPostArticleView(false);
    }
    if(isPostArticleView) {
      setIsEditArticleView(false);
    }
  }, [isEditArticleView]);

  useEffect(() => {
    if (currentUser) {
      setIsBirthdayWeek(currentUser.birthWeek?.thisWeek);
    }
  }, [currentUser]);

  useEffect(() => {
    const hideGetToKnowAndSendBadge = !(localStorage.getItem('hideGetToKnowAndSendBadge') === `true`);
    if (localStorage.getItem('hideGetToKnowAndSendBadge')) {
      setGetToKnowAndSendBadge(hideGetToKnowAndSendBadge);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', shouldShowBackToTopButton);
    return () =>
      window.removeEventListener('scroll', shouldShowBackToTopButton);
  });

  return (
    <div className="bs-news-feed">
      <BsContentHeader>
        <BsInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={Translation.hu.placeholder.searchFeed}
          onKeyUp={searchOnEnter(searchFeed)}
          endAdornment={
            <div>
              {(searchKeyword || '').length > 0 &&
                <div className="bs-button__group">
                  <BsButton onClick={searchFeed} color="secondary" className="bs-helper-mobile-icon">
                    <span className="text">{Translation.hu.common.search}</span>
                    <Search className="icon"/>
                  </BsButton>
                  <BsButton color="secondary" onClick={clearSearch}><CloseOutlined/></BsButton>
                </div>
              }
              {(searchKeyword || '').length === 0 &&
                <BsButton onClick={searchFeed} color="secondary" className="bs-helper-mobile-icon">
                  <span className="text">{Translation.hu.common.search}</span>
                  <Search className="icon"/>
                </BsButton>
              }
            </div>
          }
        />
      </BsContentHeader>

      <div className="bs-page__header" id="bs-page__header">
        <div className="bs-page__filters">
          <BsTag
            onClick={() => filterFeed()}
            label={Translation.hu.tag.everything}
            active={activeTagList.length === 0 && !articleTypeFilter}
          />
          {newsTags?.map((tag, index) =>
            <BsTag
              key={index}
              onClick={() => filterFeed(tag)}
              label={tag}
              active={activeTagList.includes(tag)}
            />,
          )}
          <BsTag
            onClick={() => filterFeedByType(!articleTypeFilter)}
            label={Translation.hu.label.newsAboutBlackBelt}
            active={!!articleTypeFilter}
          />
        </div>

        {hasRight(AuthRight.ArticleCreate) &&
          <div className="bs-page__actions">
            <BsButton size="small" className="bs-button--one-liner" onClick={createPost}>
              {Translation.hu.label.newPost}
            </BsButton>
          </div>
        }
      </div>

      {isPostArticleView && <BsPostArticle active={isPostArticleView} onClose={handlePostArticleClose} />}
      {isEditArticleView && <BsPostArticle active={isEditArticleView} onClose={handleEditArticleClose} articleForEdit={articleForEdit} />}

      {getToKnowAndSendBadge &&
        <BsNotificationBar
          text={Translation.hu.label.getToKnowAndSendBadges}
          actionArray={[
            <BsButton
              color={`secondary-alt`}
              size={`small`}
              children={Translation.hu.label.hideMessage}
              onClick={() => hideGetToKnowAndSendBadgeCard()}
            />,
            <BsButton
              color={`secondary`}
              size={`small`}
              children={Translation.hu.label.jumpToColleagues}
              onClick={() => navigate(BsRoutes.kollegak)}
            />,
          ]}
        />
      }

      {!!searchKeyword &&
        <BsArticleSearchListing
          articleList={news} />
      }
      {!searchKeyword &&
        <BsArticleListing
          articleList={news}
          approveDraft={approveDraft}
          deleteDraft={deleteDraft}
          discardDraft={discardDraft}
          editPost={editPost}
          openArticle={openArticle}
        />
      }

      {isBirthdayWeek && <BirthdayDecoration /> }

      <div className="bs-news-feed__navigation">
        {isMoreToLoad() &&
          <div className="bs-load-more">
            <BsButton color="secondary" onClick={loadMore}>{Translation.hu.common.loadMore}</BsButton>
          </div>
        }
        <div className={`bs-back-to-top ${showGoTopButton ? 'bs-back-to-top--show' : 'bs-back-to-top--hidden'}`}>
          <BsButton  color="secondary" onClick={scrollToTop}> <ArrowUpward /></BsButton>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
