import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const stateName = 'menu';

export interface MenuState {
  activeMenu: string;
  isAdminMenuVisible: boolean;
}

export const menuSlice = createSlice<MenuState, SliceCaseReducers<MenuState>, string>({
  name: stateName,
  initialState: {
    activeMenu: window.location.pathname,
    isAdminMenuVisible: window.location.pathname.startsWith('/admin'),
  },
  reducers: {
    setAdminMenuVisible: (state, value: PayloadAction<boolean>) => {
      state.isAdminMenuVisible = value.payload;
    },
    setActiveMenu: (state, value: PayloadAction<string>) => {
      state.activeMenu = value.payload;
    },
  },
});

export const { setAdminMenuVisible, setActiveMenu } = menuSlice.actions;

export const selectIsAdminMenuVisible = (state: {[stateName]: MenuState}) => state[stateName].isAdminMenuVisible;
export const selectActiveMenu = (state: {[stateName]: MenuState}) => state[stateName].activeMenu;

export const menuSlicer = menuSlice.reducer;
