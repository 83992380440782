export enum CvDomain {
  Financial = 'Financial',
  Telecom = 'Telecom',
  Retail = 'Retail',
  FMCG = 'FMCG',
  Logistics = 'Logistics',
  PublicSector = 'PublicSector',
  BusinessServices = 'BusinessServices',
  Industrials = 'Industrials',
  Energy = 'Energy',
  HealthCare = 'HealthCare',
  Manufacturing = 'Manufacturing',
  Media = 'Media',
  RealEstate = 'RealEstate',
  ManufacturingIT = 'ManufacturingIT',
}
