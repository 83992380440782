import { UserDTO } from "../account";
import { NewsPageEntry } from '../article';
import { BadgeType } from './badgeType';
import { BadgeUserGroup } from './badgeUserGroupType';

export enum BadgeHistoryType {
  Received = 'Received',
  Sent = 'Sent',
}

export interface BadgeHistoryDTO extends NewsPageEntry<'badgeHistory'> {
  message: string;
  badgeId: number;
  badgeType: BadgeType;
  badgeReceivedFromGroup: BadgeUserGroup;
  from: UserDTO;
  to: UserDTO;
  type: BadgeHistoryType;
  createdAt: Date;
}
