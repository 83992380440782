import { Fragment, ReactElement } from 'react';

export interface BsNotificationBarProps {
  text: string;
  actionArray?: ReactElement[];
}

const BsNotificationBar = ({
  text,
  actionArray,
}: BsNotificationBarProps): ReactElement => {
  return (
    <div className="bs-notification-bar">
      <p
        className="bs-notification-bar__text"
        dangerouslySetInnerHTML={{__html: text}}
      />
      <div className="bs-notification-bar__action">
        {actionArray && actionArray.map((action, index) => (
          <Fragment key={index}>
            {action}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default BsNotificationBar;
