import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const stateName = 'headerElement';

export interface HeaderElementState {
  component: number;
}

export const headerElementSlice = createSlice<HeaderElementState, SliceCaseReducers<HeaderElementState>, string>({
  name: stateName,
  initialState: {
    component: 0,
  },
  reducers: {
    setHeaderElement(state, value: PayloadAction<number>) {
      if (value.payload) {
        state.component = value.payload;
      }
    },
  },
});

export const { setHeaderElement } = headerElementSlice.actions;

export const selectHeaderElement = (state: {[stateName]: HeaderElementState}) => {
  return state[stateName].component;
};

export const headerElementSlicer = headerElementSlice.reducer;
