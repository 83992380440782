import { ArticleDetailsDTO, ArticleDTO, ArticleType, NewsPageItem } from '@bb-sanctuary/common';
import { Fragment } from 'react';
import { BsRoutes } from '../../../data/bsRoutes';
import { Link } from 'react-router-dom';
import { GeneratedArticleDTO } from '@bb-sanctuary/common';

export interface ArticleSearchListingProps {
  articleList: NewsPageItem[];
}

const searchMatchClass = `class="search-highlight"`;

function getSearchContent(article: ArticleDTO | GeneratedArticleDTO): string {
  if (article.lead.includes(searchMatchClass)) {
    return article.lead;
  }
  if ((article as ArticleDetailsDTO).newsletterContent?.includes(searchMatchClass)) {
    return (article as ArticleDetailsDTO).newsletterContent as string;
  }
  return article.lead;
}

function BsArticleSearchListing({articleList}: ArticleSearchListingProps) {
  return (
    <div className="bs-search-result__list">
      {articleList.map((item, index) =>
        <Fragment key={index}>
          {item.type === ArticleType.Newsletter &&
            <Link
              key={index}
              to={BsRoutes.hir(item.id)}
              className="bs-search-result__item"
            >
              <h3 className="bs-search-result__item__title" dangerouslySetInnerHTML={{__html: item.title}} />
              <p className="bs-search-result__item__content" dangerouslySetInnerHTML={{__html: getSearchContent(item)}}/>
            </Link>
          }
        </Fragment>,
      )}
    </div>
  );
}

export default BsArticleSearchListing;
