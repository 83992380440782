import { AuthRight, RoleDTO } from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityListing, { Column } from "../../shared/components/admin/GenericEntityListing";
import { Fragment } from "react";

const ADMIN_URL = `/admin/`;

function AdminRolesPage() {
  const columns: Array<Column> = [
    {
      header: `Name`,
      field: `name`,
      template: (item: RoleDTO) => <>{item.name}</>,
    },
    {
      header: `Rights`,
      field: `rights`,
      template: (item: RoleDTO) => <>{item.rights
          ?.map(r => Object.keys(AuthRight)[Object.values(AuthRight).indexOf(r.name as AuthRight)])
          .join(`, `)
        }</>,
    },
  ];

  const getRoleList = () => AdminService.getRoles().then((result) => result.roleList);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        <GenericEntityListing
          title={Translation.hu.label.roles}
          addButtonLabel={Translation.hu.label.addRole}
          createUrl={`${ADMIN_URL}roles/add`}
          editUrl={`${ADMIN_URL}roles/edit/:id`}
          getListFn={getRoleList}
          isEditable={true}
          columns={columns}
        />
      </div>
    </Fragment>
  );
}

export default AdminRolesPage;
