import { CvCourseDTO } from "@bb-sanctuary/common";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

export interface CvCourseProps {
  course: CvCourseDTO,
  onDelete: (id: number) => void
  onEdit: (id: number) => void
}

const CvCourse = ({ course, onDelete, onEdit }: CvCourseProps) => {
  return (
    <div className="course-grid course-grid__item" >
      <p>{new Date(course.startDate).getFullYear()}</p>
      <p>{course.endDate ? new Date(course.endDate).getFullYear() : ''}</p>
      <p>{course.description}</p>
      <p>{course.type ?? ''}</p>
      <p>{course.itSecurityCertificate || ''}</p>
      <div className="close-icon">
        <EditIcon onClick={() => onEdit(course.id as number)} />
        <DeleteOutlineIcon onClick={() => onDelete(course.id as number)} />
      </div>
    </div>
  );
};

export default CvCourse;
