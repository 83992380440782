import { CvProfessionalBackgroundDTO } from "@bb-sanctuary/common";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { format } from "date-fns";
import Translation from "../../../data/translation";

export interface CvProfessionalBackgroundParams {
  cvProfessionalBg: CvProfessionalBackgroundDTO,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
}

const CvProfessionalBackground = ({ cvProfessionalBg, onEdit, onDelete }: CvProfessionalBackgroundParams) => {

  return (
    <div className="bs-cv-professional-bg">
      <div className="bs-cv-professional-bg__form-data">
        <span>{format(new Date(cvProfessionalBg.fromDate),'yyyy.MM.')}</span>
        {
          cvProfessionalBg.toDate ? <span>{format(new Date(cvProfessionalBg.toDate),'yyyy.MM.')}</span>
            : <span>-</span>
        }
        <span>{cvProfessionalBg.company}</span>
        <span>{cvProfessionalBg.title}</span>
        <div className="close-icon">
          <EditIcon onClick={() => onEdit(cvProfessionalBg.id as number)} />
          <DeleteOutlineIcon onClick={() => onDelete(cvProfessionalBg.id as number)} />
        </div>
      </div>
      <div className="bs-cv-professional-bg__descriptions">
        <p>{Translation.hu.cv.form.header.backgroundDescriptionItem}:</p>
        <ul>
        {
          cvProfessionalBg.description.map((desc,i) => <li key={desc+i}>{desc}</li>)
        }
        </ul>
      </div>
    </div>
  );
};

export default CvProfessionalBackground;
