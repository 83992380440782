import { ReactElement } from 'react';
import { ButtonBase, ButtonBaseProps } from "@mui/material";

export interface BsCtaProps extends ButtonBaseProps {
  size?: `small` | `normal` | `large`;
}

const BsCta = ({
  size = `normal`,
  className,
  ...props
}: BsCtaProps): ReactElement => {
  return (
    <ButtonBase
      type="button"
      className={`
        bs-cta
        bs-cta--${size}
        ${className ? ` ${className}` : ``}
      `}
      {...props}
    >
      {props.children}
    </ButtonBase>
  );
};

export default BsCta;
