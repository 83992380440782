import {
  RoleDTO,
  FindRoleResponseDTO,
  RoleListResponseDTO,
  CreateRoleDTO,
  UpdateRoleRightsDTO,
  UserListForAdminDTO,
  UserForAdminDTO,
  CreateProjectDTO,
  ProjectDTO,
  UpdateProjectDTO,
  ProjectDetailDTO,
  ScheduledJobDTO,
  UpdateUserDTO,
  TechnologyWithCountDTO, TechnologyDTO, CreateTechnologyDTO,
} from '@bb-sanctuary/common';

import { axiosInstance } from '../../shared/service/axiosInstance';
import { AxiosResponse } from 'axios';

const url = {
  apiRoot: `/api`,
  userApi: `/api/admin/user`,
  roleApi: `/api/admin/role`,
  hubspotAdmin: `/hubspot-admin`,
};

export const AdminService = {
  getRiportUrl() {
    return `${url.apiRoot}/badge/riport`;
  },
  async showHubspotSyncHistory(): Promise<string> {
    return (await axiosInstance.get(`${url.apiRoot}${url.hubspotAdmin}/hubspot-sync-history`)).data;
  },
  async getMedalList() {
    return await (await axiosInstance.get(`${url.apiRoot}/admin/medals/medal-list`)).data;
  },
  async startHubspotMigration(): Promise<void> {
    return (await axiosInstance.post(`${url.apiRoot}${url.hubspotAdmin}/hubspot-sync`)).data;
  },
  async downloadOldPhotos(): Promise<void> {
    return (await axiosInstance.post(`${url.apiRoot}${url.hubspotAdmin}/download-old-photos`)).data;
  },
  async migrateNewsLetter(fd: FormData): Promise<void> {
    return (await axiosInstance.post(`${url.apiRoot}${url.hubspotAdmin}/upload-newsLetter`, fd)).data;
  },
  async reindexNews() {
    return (await axiosInstance.get(`${url.apiRoot}/article/posts/index/load`)).data;
  },
  async reindexFAQ() {
    return (await axiosInstance.post(`${url.apiRoot}/admin/faq/reindex`)).data;
  },
  async getTechs(): Promise<Array<TechnologyWithCountDTO>> {
    return (await axiosInstance.get(`${url.apiRoot}/technology`)).data.technologyList;
  },
  async getTech(id: number): Promise<TechnologyDTO> {
    return (await axiosInstance.get(`${url.apiRoot}/technology/${id}`)).data;
  },
  async deleteTech(id: number): Promise<{}> {
    return (await axiosInstance.delete(`${url.apiRoot}/technology/${id}`)).data;
  },
  async mergeTech(targetId: number, baseId: number): Promise<{}> {
    return (await axiosInstance.post(`${url.apiRoot}/technology/merge`, {oldTechnologyId: baseId, newTechnologyId: targetId})).data;
  },
  async updateTech(id: number, createTechnology: CreateTechnologyDTO): Promise<AxiosResponse<TechnologyDTO>> {
    return axiosInstance.put(`/api/technology/${id}`, {...createTechnology});
  },
  async getProjects(): Promise<Array<ProjectDTO>> {
    return (await axiosInstance.get(`${url.apiRoot}/project`)).data;
  },
  async getProject(id: number): Promise<ProjectDetailDTO> {
    return (await axiosInstance.get(`${url.apiRoot}/project/byid/${id}`)).data;
  },
  async createProject(params: CreateProjectDTO): Promise<ProjectDTO> {
    return (await axiosInstance.post(`${url.apiRoot}/project/create`, params)).data;
  },
  async updateProject(params: UpdateProjectDTO): Promise<ProjectDTO> {
    return (await axiosInstance.put(`${url.apiRoot}/project/update`, params)).data;
  },
  async deleteProject(id: number): Promise<{}> {
    return (await axiosInstance.delete(`${url.apiRoot}/project/${id}`)).data;
  },
  async getRoles(): Promise<RoleListResponseDTO> {
    return (await axiosInstance.get(`${url.roleApi}`)).data;
  },
  async getRole(id: string): Promise<FindRoleResponseDTO> {
    return (await axiosInstance.get(`${url.roleApi}/${id}`)).data;
  },
  async createRole(params: CreateRoleDTO): Promise<RoleDTO> {
    return (await axiosInstance.post(`${url.roleApi}`, params)).data;
  },
  async updateRole(params: UpdateRoleRightsDTO): Promise<RoleDTO> {
    return (await axiosInstance.put(`${url.roleApi}`, params)).data;
  },
  async getUsersOfRoles(): Promise<UserListForAdminDTO> {
    return (await axiosInstance.get(`${url.userApi}`)).data;
  },
  async getUserOfRoles(email: string): Promise<UserForAdminDTO | null> {
    return (await axiosInstance.get(`${url.userApi}/${email}`)).data;
  },
  async updateUserOfRoles(params: UpdateUserDTO): Promise<UserForAdminDTO> {
    return (await axiosInstance.put(`${url.userApi}`, params)).data;
  },
  async getScheduledJobsList(): Promise<Array<ScheduledJobDTO>> {
    return (await axiosInstance.get(`${url.apiRoot}/admin/scheduler-admin/job`)).data;
  },
  runScheduledJobsForce() {
    return axiosInstance.post(`${url.apiRoot}/admin/scheduler-admin/force-run`);
  },
  clearUserPhotos() {
    return axiosInstance.post(`${url.apiRoot}/admin/user/clear-image-cache`);
  },
  uploadNewTemplate(templateFile: File) {
    return axiosInstance.post(`${url.apiRoot}/cv-generator/template`, {file: templateFile}, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getGoogleAuthUrl() {
    return axiosInstance.get(`${url.apiRoot}/admin/user/gauth-url`);
  },
};
