import { Fragment, useCallback } from 'react';
import { FAQCategoryAdminDTO, FaqCategoryGroup } from "@bb-sanctuary/common";

import Translation from "../../shared/data/translation";
import GenericEntityListing, { Column } from "../../shared/components/admin/GenericEntityListing";
import { BsRoutes } from '../../shared/data/bsRoutes';
import { FaqService } from '../../shared/service/faqService';

function AdminFaqCategoryPage({subject}: any) {
  const columns: Array<Column> = [
    {
      header: `Title`,
      field: `title`,
      template: (item: FAQCategoryAdminDTO) => <>{item.title}</>,
    },
    {
      header: `Order`,
      template: (item: FAQCategoryAdminDTO) => <>{item.order}</>,
    },
  ];

  const getFaqCategoryList = useCallback(async () => {
    const res = await FaqService.getAllCategory();

    return res.data
      .filter((item: any) => item.categoryGroup === subject)
      .sort((l, r) => l.order - r.order);
  }, [subject]);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        <GenericEntityListing
          title={subject === FaqCategoryGroup.GENERAL ? Translation.hu.label.faqCategory : Translation.hu.label.bbFaqCategory}
          addButtonLabel={Translation.hu.label.addFaq}
          createUrl={subject === FaqCategoryGroup.GENERAL ? `${BsRoutes.admin.faqCategory._}/add` : `${BsRoutes.admin.bbFaqCategory._}/add`}
          editUrl={subject === FaqCategoryGroup.GENERAL ? `${BsRoutes.admin.faqCategory._}/edit/:id` : `${BsRoutes.admin.bbFaqCategory._}/edit/:id`}
          getListFn={getFaqCategoryList}
          isEditable={true}
          columns={columns}
        />
      </div>
    </Fragment>
  );
}

export default AdminFaqCategoryPage;
