import { formatLocaleDateTime } from '../../../utils/formatters';

export interface BsDatetimeTagProps {
  time: string;
  explicitTime?: Date;
}

const BsDatetimeTag = ({
  time,
  explicitTime,
  ...props
}: BsDatetimeTagProps) => {
  return (
    <div 
      className="bs-datetime-tag" {...props}
      title={explicitTime ? formatLocaleDateTime(explicitTime) : time}
    >
      {time}
    </div>
  );
};

export default BsDatetimeTag;
