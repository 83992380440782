import { useEffect, useState } from "react";
import { Autocomplete, Chip, TextField } from '@mui/material';
import { CvDomain } from '@bb-sanctuary/common';
import { DomainKnowledge } from '../cv-generator-accordion-domain/CvGeneratorAccordionDomain';

interface DomainKnowledgeFormParams {
  domainKd: DomainKnowledge[];
  onChangeHandle: (domains: DomainKnowledge[]) => void,
}

const options = Object.values(CvDomain).map(_ => ({id: Math.random(), title: _}));

const CvDomainKnowledgeForm = ({domainKd, onChangeHandle}:DomainKnowledgeFormParams) => {
  const [domains, setDomains] = useState<DomainKnowledge[]|null>(null);

  const onAddNewDomainKnowledge = (val: DomainKnowledge[]) => {
    setDomains(val);
    onChangeHandle(val);
  };

  useEffect(() => {
    setDomains(domainKd);
  }, [domainKd]);

  return (
    <div>
      <Autocomplete
        multiple
        className="bs-input"
        data-label={`Methodology`}
        value={domains || []}
        options={options}
        onChange={(_, value) => {
          console.log('onchange', value);
          if (value !== null) {
            onAddNewDomainKnowledge(value);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} />
        )}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => {
          return option.title === value.title;
        }}
      />
    </div>
  );
};
export default CvDomainKnowledgeForm;
