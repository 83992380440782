import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FAQAdminListResponseDTO, FAQCategoryAdminDTO, FaqCategoryGroup, FAQEntryAdminDTO } from "@bb-sanctuary/common";

import Translation from "../../shared/data/translation";
import GenericEntityEditor from "../../shared/components/admin/GenericEntityEditor";
import { createRequiredField, createSelectField, patternValidation } from "../../shared/utils/formUtils";
import { BsRoutes } from '../../shared/data/bsRoutes';

const FAQ_API = `/api/admin/faq/`;

const createFormSchema = (categoryList: Array<FAQCategoryAdminDTO>) => ({
  fields: [
    createRequiredField('title')
      .label('Title', '')
      .sizeColumns(6)
      .build(),
    createRequiredField('order')
      .label('Order', '')
      .sizeColumns(6)
      .validate(patternValidation(/[0-9]+/))
      .build(),
    createSelectField('category',
      'Category',
      categoryList.map((category) => ({label: category.title, value: category.id})),
      6)
      .build(),
    createRequiredField('content')
      .label('Content', '')
      .component('wysiwyg')
      .sizeColumns(6)
      .build(),
  ],
});

interface AdminFaqEditPageProps {
  subject: FaqCategoryGroup;
}

function AdminFaqEditPage({subject}: AdminFaqEditPageProps) {
  const params = useParams();
  const id = params.id;
  const [formSchema, setFormSchema] = useState<any>();

  const getCategoryList = async () => await axios.get<Array<FAQCategoryAdminDTO>>(`${FAQ_API}category`);
  const loadData = async () => {
    const result = await axios.get<FAQAdminListResponseDTO>(`${FAQ_API}`);
    const item = result.data.entries.find((e) => e.id.toString() === (id as string));
    return {...item, category: item?.category.id};
  };

  const createEntity = () => {
    return {};
  };

  const saveFaq = async (params: Omit<FAQEntryAdminDTO, 'category'> & {category: number}) => {
    const toSave = { ...params, category: { id: params.category }};
    return axios.post(`${FAQ_API}entry`, toSave);
  };

  useEffect(() => {
    getCategoryList().then((e) => {
      const categoryList = e.data.filter(category => category.categoryGroup === subject);
      setFormSchema(createFormSchema(categoryList));
    });
  }, []);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__editor">
        {formSchema ?
          <GenericEntityEditor
            id={id}
            saveFn={saveFaq}
            entityLabel={subject === FaqCategoryGroup.GENERAL ? Translation.hu.label.faq : Translation.hu.label.bbFaq}
            loadEntityFn={loadData}
            listingUrl={subject === FaqCategoryGroup.GENERAL ? BsRoutes.admin.faq._ : BsRoutes.admin.bbFaq._}
            formSchema={formSchema}
            hideCancel={false}
            newEntity={createEntity}
          />
        :
          <Fragment>
            {Translation.hu.common.loading}
          </Fragment>
        }
      </div>
    </Fragment>
  );
}

export default AdminFaqEditPage;
