import { AxiosResponse } from 'axios';
import {
  ColleagueDetailsDTO,
  ColleagueDetailsListPageDTO,
  TechnologyLevel,
  ColleagueFilterDTO,
  ColleagueUnitTeamPositionListDTO,
  ColleagueDTO,
} from '@bb-sanctuary/common';
import { axiosInstance } from './axiosInstance';

export const colleaguePageSize = 8;

export const ColleagueService = {
  getColleagues(
    searchTerm: string,
    fromPage: number,
    withPoints: boolean,
    pageSize?: number,
    includeTotalCount?: true,
    searchTeam?: string | null,
    searchUnit?: string | null,
    searchTechnologyId?: Array<number> | null,
    searchPos?: string | null,
  ): Promise<AxiosResponse<ColleagueDetailsListPageDTO>> {
    return axiosInstance.post(`/api/colleague`, {
      searchText: searchTerm,
      fromPage: fromPage,
      pageSize: pageSize ? pageSize : colleaguePageSize,
      includeTotalCount: includeTotalCount ? includeTotalCount : true,
      withPoints,
      searchTeam,
      searchUnit,
      searchTechnologyId,
      searchPos,
    } as ColleagueFilterDTO);
  },

  getMyDepartmentColleagues(searchTerm: string,
                            fromPage: number,
                            withPoints: boolean): Promise<AxiosResponse<ColleagueDetailsListPageDTO>> {
    return axiosInstance.post(`/api/colleague`, {
      searchText: searchTerm,
      fromPage: fromPage,
      unit: true,
      pageSize: colleaguePageSize,
      includeTotalCount: true,
      withPoints,
    } as ColleagueFilterDTO);
  },

  getMyTeamColleagues(searchTerm: string,
                            fromPage: number,
                            withPoints: boolean): Promise<AxiosResponse<ColleagueDetailsListPageDTO>> {
    return axiosInstance.post(`/api/colleague`, {
      searchText: searchTerm,
      fromPage: fromPage,
      team: true,
      pageSize: colleaguePageSize,
      includeTotalCount: true,
      withPoints,
    } as ColleagueFilterDTO);
  },

  getColleagueByEmail(email: string): Promise<AxiosResponse<ColleagueDetailsDTO>> {
    return axiosInstance.get(`/api/colleague/${email}`);
  },

  postColleagueTech(email: string, tech: number, level: TechnologyLevel): Promise<AxiosResponse<{}>> {
    return axiosInstance.post(`/api/colleague/technology/${email}/${tech}`, {level});
  },

  editColleagueTech(email: string, tech: number, level: TechnologyLevel): Promise<AxiosResponse<{}>> {
    return axiosInstance.put(`/api/colleague/technology/${email}/${tech}`, {level});
  },

  deleteColleagueTech(email: string, tech: number): Promise<AxiosResponse<{}>> {
    return axiosInstance.delete(`/api/colleague/technology/${email}/${tech}`);
  },

  getUnitsTeamsAndPositions():Promise<AxiosResponse<ColleagueUnitTeamPositionListDTO>>{
    return axiosInstance.get('/api/colleague/units');
  },
  getAllColleague():Promise<AxiosResponse<ColleagueDTO[]>>{
    return axiosInstance.get('/api/colleague/all');
  },
};
