import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { FaqCategoryGroup } from '@bb-sanctuary/common';

import HomePage from "./pages/HomePage";
import ColleaguesPage from "./pages/ColleaguesPage";
import ProfilePage from "./pages/ProfilePage";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from './pages/LoginPage';
import ColleaguePage from './pages/ColleaguePage';
import BsFrame from "./shared/components/layout/frame/frame";
import ArticlePage from "./pages/ArticlePage";
import AdminPage from "./pages/admin/AdminPage";
import FaqPage from './pages/FaqPage';
import { selectIsAuthenticated } from './contexts/currentUserSlicer';
import Loading from './shared/components/ui/loading/loading';
import { BsRoutes } from './shared/data/bsRoutes';
import FaqEntryPage from "./pages/FaqEntryPage";
import InnovationPage from './pages/InnovationPage';
import ProjectsPage from "./pages/ProjectsPage";
import { useTheme } from "./contexts/theme.context";
import { selectIsLoading } from './contexts/appSlicer';
import CvGeneratorPage from "./pages/CvGeneratorPage";

interface LayoutProps {
  isAuthenticated: boolean;
}

const Layout = ({ isAuthenticated }: LayoutProps) => {
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (isAuthenticated && window.location.pathname === '/') {
      navigate(BsRoutes.hirek);
    }
  }, [isAuthenticated]);

  return (
    <Fragment>
      {isLoading && <Loading />}
      {isAuthenticated ?
        <BsFrame children={<Outlet />} /> :
        <Outlet />
      }
    </Fragment>
  );
};

const ProtectedRoute = ({ auth, children }: any) => {
  if (!auth) {
    return <Navigate to={BsRoutes.login} replace />;
  }

  return children;
};

const AppRouting = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { themeState } = useTheme();
  const [mode, setMode] = useState<'light' | 'dark'>(themeState);
  const darkPalette = useMemo(() => ({background: {default: `hsl(267, 17%, 0%)`, paper: `hsl(267, 17%, 0%)`}}), []);
  const theme = useMemo(() => createTheme({palette: {mode, ...(mode === `dark` && darkPalette)}}), [mode, darkPalette]);
  
  useEffect(() => {
    setMode(themeState);
  }, [themeState]);

  return (
    <ThemeProvider theme={theme}>
      { isAuthenticated !== null &&
        <Routes>
          <Route path="/" element={<Layout isAuthenticated={isAuthenticated} />} >
            <Route index element={<ProtectedRoute auth={isAuthenticated}><HomePage /></ProtectedRoute>} />
            <Route path={BsRoutes.hirek}>
               <Route path=":newsId" element={<ProtectedRoute auth={isAuthenticated}><ArticlePage /></ProtectedRoute>} />
               <Route path="" element={<ProtectedRoute auth={isAuthenticated}><HomePage /></ProtectedRoute>} />
            </Route>
            <Route path={BsRoutes.kollegak}>
               <Route path=":colleague" element={<ProtectedRoute auth={isAuthenticated}><ColleaguePage /></ProtectedRoute>} />
               <Route path="" element={<ProtectedRoute auth={isAuthenticated}><ColleaguesPage /></ProtectedRoute>} />
            </Route>
            <Route path={BsRoutes.profil._}>
              <Route path="" element={<ProtectedRoute auth={isAuthenticated}><ProfilePage /></ProtectedRoute>} />
            </Route>
            <Route path={BsRoutes.admin._}>
              <Route path="*" element={<ProtectedRoute auth={isAuthenticated}><AdminPage /></ProtectedRoute>} />
            </Route>

            <Route path={BsRoutes.altalanosTudnivalok._}>
              <Route path=":faqEntryId" element={<ProtectedRoute auth={isAuthenticated}><FaqEntryPage type={FaqCategoryGroup.GENERAL} /></ProtectedRoute>}/>
              <Route path="" element={<ProtectedRoute auth={isAuthenticated}><FaqPage subject={FaqCategoryGroup.GENERAL} /></ProtectedRoute>}/>
            </Route>
            <Route path={BsRoutes.masterOfTechnology._}>
              <Route path=":faqEntryId" element={<ProtectedRoute auth={isAuthenticated}><FaqEntryPage type={FaqCategoryGroup.BB_SITE} /></ProtectedRoute>}/>
              <Route path="" element={<ProtectedRoute auth={isAuthenticated}><FaqPage subject={FaqCategoryGroup.BB_SITE} /></ProtectedRoute>}/>
            </Route>

            <Route path={BsRoutes.innovacio} element={<ProtectedRoute auth={isAuthenticated}><InnovationPage /></ProtectedRoute>} />
            <Route path={BsRoutes.projektek} element={<ProtectedRoute auth={isAuthenticated}><ProjectsPage/></ProtectedRoute>} />
            <Route path={BsRoutes.cvGenerator} element={<ProtectedRoute auth={isAuthenticated}><CvGeneratorPage /></ProtectedRoute>}/>


            <Route path={BsRoutes.login} element={<LoginPage />}  />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      }
      {isAuthenticated === null && <Loading />}
     </ThemeProvider>
   );
};

export default AppRouting;
