import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { AuthRight } from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityListing, { Column } from "../../shared/components/admin/GenericEntityListing";
import BsButton from "../../shared/components/ui/button/button";
import { useCurrentUserRights } from "../../shared/utils/useRight.hook";

const ADMIN_URL = `/admin/`;

function AdminProjectsPage() {
  const [ hasRight ] = useCurrentUserRights();
  const { enqueueSnackbar } = useSnackbar();
  const [ updateList, setUpdateList ] = useState(1);
  const columns: Array<Column> = [
    {
      header: `Name`,
      field: `name`,
      template: (item: any) => <>{item.name}</>,
    },
    {
      header: `Date`,
      field: `createdAt`,
      template: (item: any) => <>{item.createdAt}</>,
    },
    {
      header: ``,
      template: (item: any) => <>
        {hasRight(AuthRight.ProjectDelete) &&
          <div style={{display: `flex`, justifyContent: 'flex-end', gap: '6px'}}>
            <BsButton size="small" color="secondary" onClick={() => deleteTech(item.id, item.name)}>
              {Translation.hu.common.delete}
            </BsButton>
          </div>
        }
        </>,
    },
  ];

  const deleteTech = async (id: number, name: string) => {
    try {
      if (window.prompt(`Biztosan törölni szeretnéd ezt a projektet "${name}"? Ha igen írd be, hogy "igen". NINCS visszaállítási lehetőség!`) !== 'igen') {
        return;
      }
      const result = await AdminService.deleteProject(id);
      if (result) {
        enqueueSnackbar(Translation.hu.form.successDelete, {variant: 'success'});
        setUpdateList(updateList + 1);
      }
    } catch {
      enqueueSnackbar(Translation.hu.form.error, {variant: 'error'});
    }
  };

  const getProjectList = () => AdminService.getProjects().then((result) => result);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        {updateList &&
         <GenericEntityListing
            title={Translation.hu.label.projects}
            addButtonLabel={Translation.hu.label.addProject}
            createUrl={`${ADMIN_URL}projects/add`}
            editUrl={`${ADMIN_URL}projects/edit/:id`}
            getListFn={getProjectList}
            isEditable={true}
            columns={columns}
          />
        }
      </div>
    </Fragment>
  );
}

export default AdminProjectsPage;
