import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import {
  ColleagueUnitListDTO,
  ColleagueUnitTeamPositionListDTO,
  TechnologyDTO,
  unique,
} from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import { ColleagueService } from '../../../service/colleagueService';
import { TechService } from '../../../service/techService';

export interface BsColleaguesFilterProps {
  searchUnit: string|null;
  searchTeam: string|null;
  searchPosition: string|null;
  searchTechnologyId: Array<number> | null;
  setSearchUnit: (value: string) => void;
  setSearchTeam: (value: string) => void;
  setSearchPos: (value: string) => void;
  setSearchTech: (value: Array<number>) => void;
}

const BsColleaguesFilter = ({
  searchUnit,
  searchTeam,
  searchPosition,
  searchTechnologyId,
  setSearchUnit,
  setSearchTeam,
  setSearchTech,
  setSearchPos,
}: BsColleaguesFilterProps) => {
  const [unitsTeamsPositions, setUnitsTeamsPositions] = useState<ColleagueUnitTeamPositionListDTO>();
  // const [units, setUnits] = useState<Array<ColleagueTeamDTO>>([]);
  const [techs, setTechs] = useState<Array<TechnologyDTO>>([]);
  // const [positionList, setPositionList] = useState<Array<string>>([]);
  const [selectedTechnology, setSelectedTechnology] = useState<Array<TechnologyDTO>>([]);

  const getUnits = useCallback(async () => {
    const data = (await ColleagueService.getUnitsTeamsAndPositions()).data;
    setUnitsTeamsPositions(data);
  },[]);

  const getTechs = useCallback(async () => {
    const techs = (await TechService.getTechList()).data.technologyList.map(_ => _.technology);
    setTechs(techs);
  },[]);

  const syncTech = useCallback(async () => {
    let techArray: Array<TechnologyDTO> = [];
    searchTechnologyId?.forEach(st => {
      const findTech = techs.find(t => t.id === st);
      if (findTech) {
        techArray.push(findTech);
      }
    });
    setSelectedTechnology(techArray);
  },[techs]);

  const getUnitsOptions = useCallback(() => {
    if (!unitsTeamsPositions) {
      return [];
    }
    return unitsTeamsPositions.unitList
      .sort((l: string, r: string) => l.localeCompare(r));
  }, [unitsTeamsPositions]);

  const getTeamsOptions = useCallback((selectedUnit: string | null): string[] => {
    if (!unitsTeamsPositions) {
      return [];
    }
    if (selectedUnit) {
      return unitsTeamsPositions.list
        .find((_: ColleagueUnitListDTO) => _.unit === selectedUnit)!.teams
        .map(team => team.team)
        .reduce(unique, [])
        .sort((l, r) => l.localeCompare(r));
    }
    return unitsTeamsPositions.teamList
      .sort((l, r) => l.localeCompare(r));
  }, [unitsTeamsPositions]);

  const getTechsOptions = useCallback(() => {
    return techs
      .sort((l, r) => l.name.localeCompare(r.name));
  }, [techs]);

  const getPositionOptions = useCallback((selectedUnit: string|null, selectedTeam: string | null) => {
    if (!unitsTeamsPositions) {
      return [];
    }
    if (selectedUnit || selectedTeam) {
      return unitsTeamsPositions.list
        .reduce((previousValue, currentValue) => {
          if (!selectedUnit || currentValue.unit === selectedUnit) {
            const posList = currentValue.teams
              .filter(team => !selectedTeam || team.team === selectedTeam)
              .map(team => team.positionList);
            previousValue.push(...posList.flat());
          }
          return previousValue;
        }, [] as Array<string>)
        .reduce(unique, [])
        .sort((l, r) => l.localeCompare(r));
    }
    return unitsTeamsPositions.positionList
      .sort((l, r) => l.localeCompare(r));
  }, [unitsTeamsPositions]);

  useEffect(() => {
    syncTech();
  }, [techs]);

  useEffect(() => {
    getUnits();
    getTechs();
  }, [getUnits, getTechs]);

  return (
    <div className="bs-colleagues__filters__options__box">
      <Autocomplete
        id="searchUnit"
        className="bs-input"
        data-label={`${Translation.hu.label.searchUnit}:`}
        options={getUnitsOptions()}
        value={searchUnit}
        onChange={(_, value) => {
          setSearchUnit(value || '');
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
      <Autocomplete
        id="searchTeam"
        className="bs-input"
        data-label={`${Translation.hu.label.searchTeam}:`}
        options={getTeamsOptions(searchUnit)}
        value={searchTeam}
        onChange={(_, value) => {
          setSearchTeam(value || '');
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
      <Autocomplete
        multiple
        id="searchTech"
        className="bs-input"
        data-label={`${Translation.hu.label.searchTech}:`}
        options={getTechsOptions()}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.name[0].toUpperCase()}
        value={selectedTechnology}
        onChange={(_, value) => {
          setSelectedTechnology(value);
          setSearchTech(value.map(t => t.id) || null);
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
      <Autocomplete
        id="searchPosition"
        className="bs-input"
        data-label={`${Translation.hu.label.searchPosition}:`}
        options={getPositionOptions(searchUnit, searchTeam)}
        value={searchPosition}
        onChange={(_, value) => {
          setSearchPos(value || '');
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
    </div>
  );
};

export default BsColleaguesFilter;
