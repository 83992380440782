import { CvProjectDTO } from "@bb-sanctuary/common";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

export interface CvProjectProps {
  project: CvProjectDTO;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

const CvProject = ({ project, onDelete, onEdit }: CvProjectProps) => {
  return (
    <div className="project-list-grid project-list-grid__item">
      <p>{new Date(project.fromDate).getFullYear()}</p>
      <p>{project.toDate ? new Date(project.toDate).getFullYear() : '-'}</p>
      <p>{project.company}</p>
      <p>{project.title}</p>
      <p>{project.description}</p>
      <div className="close-icon" >
        <EditIcon onClick={() => onEdit(project.id as number)} />
        <DeleteOutlineIcon onClick={() => onDelete(project.id as number)} />
      </div>

    </div>
  );
};

export default CvProject;
