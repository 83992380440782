import { LegacyRef, MutableRefObject, ReactElement, ReactEventHandler, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { ColleagueDTO, getUserImage } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import BsButton from '../button/button';
import { BsMedalKit, getMedalLabel } from '../../../data/medal-kit';
import { ReactComponent as ImagePlaceholder } from './../../../../assets/anim/image-placeholder.svg';

export enum BsProfileCardType {
  DEFAULT = `default`,
  MASTER = `master`,
}

export interface BsProfileCardWrapperProps {
  condition?: boolean | string;
  children: ReactElement;
  wrapper: (c: ReactElement) => ReactElement;
}

export interface BsProfileCardProps extends ColleagueDTO {
  type?: BsProfileCardType;
  className?: string;
  masterPosition?: number;
  masterPoints?: number;
  tech?: string;
  link?: string;
  onClick?: () => void;
  sendBadge?: () => void;
  isSendBadgeButtonAvailable?: boolean;
}

const BsProfileCardWrapper = ({ condition, wrapper, children }: BsProfileCardWrapperProps) =>
  condition ? wrapper(children) : children;

export const useImageLoaded: () => [MutableRefObject<HTMLImageElement | undefined>, boolean, ReactEventHandler<HTMLImageElement>, (v: boolean) =>void] = () => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<HTMLImageElement>();

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  return [ref || null, loaded, onLoad, setLoaded];
};

const BsProfileCard = ({
  image,
  email,
  firstName,
  lastName,
  positionTitle,
  team,
  tech,
  medals,
  phone,
  masterPoints,
  masterPosition,
  sex,
  type = BsProfileCardType.DEFAULT,
  link,
  onClick,
  sendBadge,
  isSendBadgeButtonAvailable,
  className,
}: BsProfileCardProps): ReactElement => {
  const [ref, loaded, onLoad, setLoaded] = useImageLoaded();
  const profileAvatar = getUserImage({image, email, sex}, true);

  useEffect(() => {
    setLoaded(false);
  }, [setLoaded, image]);

  return (
    <div className={`bs-profile-card bs-profile-card--${type} ${className}`}>
      <BsProfileCardWrapper
        condition={link}
        wrapper={children => <Link to={link || ``}>{children}</Link>}
      >
        <div className="bs-profile-card__box">
          <div className="bs-profile-card__content" {...(onClick ? {onClick} : {})}>
            <div className="bs-profile-card__info">
              <div className="bs-profile-card__avatar">
                {medals && medals.length > 0 &&
                  <ul className="bs-profile-card__medals">
                    {medals.map((medal, index) => (
                      <Tooltip title={getMedalLabel(medal)} key={index}>
                        <li className="bs-profile-card__medals__item">
                          {BsMedalKit[medal]()}
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                }
                <img
                  className={`bs-profile-card__image${!loaded ? ' bs-profile-card__image--hidden' : ''}${profileAvatar.includes(`default-avatar`) ? ' bs-fallback-avatar' : ''}`}
                  ref={ref as LegacyRef<any>}
                  onLoad={onLoad}
                  src={profileAvatar}
                  alt={`${lastName} ${firstName}`}
                />
                <ImagePlaceholder className={`bs-profile-card__image bs-profile-card__image--placeholder ${loaded ? 'bs-profile-card__image--hidden' : ''}`} />
              </div>
              <div className="bs-profile-card__details">
                {tech && type === BsProfileCardType.DEFAULT &&
                  <div className="bs-profile-card__tech">{tech}</div>
                }
                <Tooltip title={`${lastName} ${firstName}`}>
                  <h3 className="bs-profile-card__name">{lastName} {firstName}</h3>
                </Tooltip>
                <p className="bs-profile-card__position">{positionTitle}</p>
                {type === BsProfileCardType.DEFAULT &&
                  <p className="bs-profile-card__department">{team}</p>
                }
              </div>
            </div>
            {phone && type === BsProfileCardType.DEFAULT &&
              <div className="bs-profile-card__extra">
                <p className="bs-profile-card__phone">{phone}</p>
              </div>
            }
            {masterPosition !== undefined && type === BsProfileCardType.MASTER &&
              <div className={masterPoints !== undefined && masterPoints > 0 ? "bs-profile-card__extra" : "bs-profile-card__extra bs-profile-card__extra--hide"}>
               {masterPosition > 0 &&
                <div className="bs-profile-card__extra-wrapper">
                   <i className={`
                    bs-profile-card__master-position
                    ${[1, 2, 3].includes(masterPosition) ? ` bs-profile-card__master-position--top` : ``}
                  `}>
                  {masterPosition}
                </i>
                <p className="bs-profile-card__master-points">{`${masterPoints} ${`pont`}`}</p>
                </div>
               }
              </div>
            }
          </div>
        </div>
      </BsProfileCardWrapper>
      {sendBadge && isSendBadgeButtonAvailable &&
        <BsButton
          size="small"
          color="secondary"
          onClick={sendBadge}
          className="bs-profile-card__send-badge"
        >
          {Translation.hu.label.sendBadge}
        </BsButton>
      }
    </div>
  );
};

export default BsProfileCard;
