import { useState } from "react";
import Lottie from "react-lottie-player";
import confettiAnim from "../../../../assets/anim/confetti.json";
import balloonAnim from "../../../../assets/anim/balloons.json";
import garlandAnim from "../../../../assets/anim/garland.json";
import labelAnim from "../../../../assets/anim/happy_birthday.json";

function BirthdayDecoration() {
  const [showConfettiAnim, setShowConfettiAnim] = useState(true);
  const [showBalloonsAnim, setShowBalloonsAnim] = useState(true);
  const [showLabelAnim, setShowLabelAnim] = useState(true);
  const [showGarlandAnim] = useState(true);
  const [garlandAnimCount] = useState(4);

  return (
    <div className="bs-bd-decoration">
      {showConfettiAnim && (
        <Lottie
          className="bs-bd-decoration bs-bd-decoration__confetti"
          animationData={confettiAnim}
          play={showConfettiAnim}
          onLoopComplete={() => setShowConfettiAnim(false)}
        />
      )}
      {showBalloonsAnim && (
        <Lottie
          className="bs-bd-decoration bs-bd-decoration__balloons"
          animationData={balloonAnim}
          play={showBalloonsAnim}
          onLoopComplete={() => setShowBalloonsAnim(false)}
        />
      )}
      {showLabelAnim && (
        <Lottie
          className="bs-bd-decoration bs-bd-decoration__label"
          animationData={labelAnim}
          play={showLabelAnim}
          onLoopComplete={() => setShowLabelAnim(false)}
        />
      )}
      {[...Array(garlandAnimCount)].map((_, i) => (
        <Lottie
          key={i}
          className={`bs-bd-decoration bs-bd-decoration__garland bs-bd-decoration__garland--${i}`}
          animationData={garlandAnim}
          play={showGarlandAnim}
          speed={1 - i / 5}
        />
      ))}
    </div>
  );
}

export default BirthdayDecoration;
