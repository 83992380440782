import { useState } from "react";
import { styled, Button } from '@mui/material';
import { FormFieldGrid } from "@data-driven-forms/mui-component-mapper";
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';

import { toBase64 } from "../../../utils/document-resource";

const Input = styled('input')({
  display: 'none',
});

const DocumentSelector = (props: any) => {
  const {input, label} = useFieldApi(props);
  const [hasValue, setHasValue] = useState(input.value?.length > 1);

  const documentOnchange = async () => {
    const fileInput: any = document.getElementById(input.name);
    if (fileInput) {
      const documentBase64 = await toBase64(fileInput.files[0]);
      input.onChange(documentBase64);
      setHasValue(false);
    }
  };

  return (
    <FormFieldGrid {...props.FormFieldGridProps}>
      <label htmlFor={input.name}>
        <p>{label}</p>
        <Input id={input.name} onChange={documentOnchange} type="file"/>
        {hasValue && <p><em>Uploaded on {new Date(input.value).toDateString()}</em></p>}
        {!hasValue && <p>&nbsp;</p>}
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label>
    </FormFieldGrid>
  );
};

export default DocumentSelector;
