import { ReactElement, useState } from 'react';
import { InputBase, InputBaseProps } from '@mui/material';
import * as React from 'react';

export interface BsInputProps extends InputBaseProps {
  className?: string;
  size?: 'small' | 'medium';
  label?: string;
}

const BsInput = ({
                   className,
                   size,
                   label,
                   ...props
                 }: BsInputProps): ReactElement => {
  const [isFocused, setFocused] = useState(false);

  const id = props.id || ('input' + Math.floor(Math.random() * 100000));
  const onElementFocused: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (evt) => {
    setFocused(true);
    if (props.onFocus) {
      props.onFocus(evt);
    }
  };
  const onElementBlurred: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (evt) => {
    setFocused(false);
    setFocused(false);
    if (props.onBlur) {
      props.onBlur(evt);
    }
  };

  return (
    <>
      {label && <style>
        {
          `#${id}:before {
              content: "${label}";
            }`
        }
      </style>
      }

      <InputBase
        className={`bs-input${className ? ` ${className}` : ``} bs-input--${size} ${!!label ? 'bs-input--has-label' : ''} ${(isFocused || !!props.value) ? 'bs-input--label-off' : ''}`}
        onFocus={onElementFocused}
        onBlur={onElementBlurred}
        componentsProps={{root: {id}}}
        {...props}
      />
    </>
  );
};

export default BsInput;
