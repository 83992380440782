import { BsBadgeKit } from '../../../data/badge-kit';
import Translation from '../../../data/translation';
import { useNavigate } from 'react-router-dom';
import { BsRoutes } from '../../../data/bsRoutes';
import { useSendableBadges } from '../../../service/useSendableBadges';

export const SendableBadges = () => {
  const {sendableBadges} = useSendableBadges();

  const navigate = useNavigate();

  return (
    <div className="bs-sendable-badges-wrapper">
      <p>{Translation.hu.profile.sendableBadges}</p>
      <div
        className={`bs-sendable-badges ${sendableBadges ? 'bs-sendable-badges--visible' : ''}`}
      >
        <ul>
          {(sendableBadges?.badgeList || []).map(badge => {
            const perc = Math.min(Math.round((badge.sentInMonth || 0) * 100 / badge.monthlyLimit), 100);

            return (
              <li
                className="bs-sendable-badges__badge-container"
                key={badge.id}
                title={`Küldhető ${badge.type} badgek száma a hónapban: ${badge.monthlyLimit - (badge.sentInMonth || 0)}`}
              >
                <div
                  className="bs-sendable-badges__badge-container__badge-icon"
                  onClick={() => navigate(BsRoutes.kollegak)}
                >
                  {BsBadgeKit[badge.type].icon}
                </div>

                <div className="hint">
                  {badge.sentInMonth || 0} / {badge.monthlyLimit}
                </div>

                <svg width="100%" height="100%" viewBox="0 0 52 52"
                     className="bs-sendable-badges__badge-container__badge-donut">
                  <circle className="donut-hole" cx="26" cy="26" r="15.91549430918954" fill="#fff"></circle>
                  <circle className="donut-ring" cx="26" cy="26" r="15.91549430918954" fill="transparent"
                          stroke="#d2d3d4"
                          strokeWidth="3"></circle>

                  <circle className="donut-segment" cx="26" cy="26" r="15.91549430918954" fill="transparent"
                          stroke="#F56929"
                          strokeWidth="3" strokeDasharray={`${100 - perc} ${perc}`} strokeDashoffset="0"></circle>
                </svg>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
