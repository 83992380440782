import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ArticleDetailsDTO, ArticleDTO, ArticleType, GeneratedArticleDetailsDTO } from "@bb-sanctuary/common";
import { ArrowBackIos, ArrowUpward } from "@mui/icons-material";

import Translation from "../shared/data/translation";
import BsTag from "../shared/components/ui/tag/tag";
import BsUploader, { BsUploaderType } from "../shared/components/ui/uploader/uploader";
import BsButton from "../shared/components/ui/button/button";
import { formatLocaleDate } from '../shared/utils/formatters';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';

const ARTICLE_API = `/api/article/posts`;

const articleTypeLabels = {
  [ArticleType.Newsletter]: Translation.hu.tag.newsletter,
  [ArticleType.Post]: Translation.hu.tag.post,
  [ArticleType.BlackbeltHazaTaja]: Translation.hu.tag.blackbeltHazaTaja,
};

function ArticlePage() {
  const [article, setArticle] = useState<ArticleDetailsDTO | GeneratedArticleDetailsDTO | undefined>();
  const [showGoTopButton, setShowGoTopButton] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const navigateBack = useCallback(() => navigate('/'), [navigate]);

  const getArticle = useCallback( async () => {
    const newsId = params.newsId;
    const response = await axios.get(`${ARTICLE_API}/${newsId}`);
    if (response) {
      setArticle(response.data);
    }
  }, [params.newsId]);

  const shouldShowBackToTopButton = useCallback(() => {
    setShowGoTopButton(window.scrollY > 500);
  }, []);

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    getArticle();
  }, [getArticle]);

  useEffect(() => {
    window.addEventListener('scroll', shouldShowBackToTopButton);
    return () =>
      window.removeEventListener('scroll', shouldShowBackToTopButton);
  });

  return (
    <div className="bs-article">
      <BsContentHeader></BsContentHeader>
      {article &&
        <Fragment>
          <div className="bs-article__info">
            <span>
              <BsButton className="bs-article__back" color="secondary" size="small" onClick={navigateBack}>
                <ArrowBackIos /> {Translation.hu.common.back}
              </BsButton>
            </span>
            <span>
              {(article as ArticleDTO).tags && (article as ArticleDTO).tags.length > 0 &&
                <BsTag label={(article as ArticleDTO).tags[0]} active={false} />
              }
              {(article as GeneratedArticleDetailsDTO).type === ArticleType.BlackbeltHazaTaja &&
                <BsTag label={articleTypeLabels[article.type]} active={false} />
              }
            </span>
            <span>
              {article.entryType === 'article' &&
                <BsUploader
                  user={article.createdBy}
                  name={article.createdBy.firstName}
                  time={formatLocaleDate(article.createdAt)}
                />
              }
              {article.entryType === 'generated' &&
                <BsUploader
                  name={'BlackBelt'}
                  time={formatLocaleDate(article.createdAt)}
                  type={BsUploaderType.SYSTEM}
                />
              }
            </span>
          </div>
          {article.entryType === 'article' && article.picture && article.type !== ArticleType.Newsletter &&
            <div className="bs-article__cover">
              <img src={article.picture} alt={article.title} />
            </div>
          }
          {article.entryType === 'generated' && article.type !== ArticleType.Newsletter &&
            <div className="bs-article__cover">
              <img src={'/assets/images/blackbelt_cover.png'} alt={article.title} />
            </div>
          }
          <h2 className="bs-article__title">{article.title}</h2>
          {article.newsletterContent &&
            <div className={`fr-view bs-article__content bs-article__content--${article.entryType}${(article as ArticleDetailsDTO).url ? ' bs-article__content--hs-article' : ''}`} dangerouslySetInnerHTML={{__html: article.newsletterContent}} />
          }

          <div className={`bs-back-to-top ${showGoTopButton ? 'bs-back-to-top--show' : 'bs-back-to-top--hidden'}`}>
            <BsButton  color="secondary" onClick={scrollToTop}> <ArrowUpward /></BsButton>
          </div>
        </Fragment>
      }
    </div>
  );
}

export default ArticlePage;
