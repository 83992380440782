import { useState } from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import BsButton from '../shared/components/ui/button/button';
import { Navigate } from 'react-router-dom';
import { ReactComponent as BbLogo } from './../assets/svg/bb_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated, setCurrentUserData } from '../contexts/currentUserSlicer';
import Translation from '../shared/data/translation';
import { axiosInstance } from '../shared/service/axiosInstance';
import { LoginDTO } from '@bb-sanctuary/common';

function LoginPage() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [loginError, setLoginError] = useState('');
  const [loaded] = useState(true);
  const dispatch = useDispatch();

  const onGoogleLoginSuccess = async (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    try {
      const sanctuaryLoginRes = await axiosInstance.post('/api/account', {email: '', accessToken: tokenResponse.access_token} as LoginDTO);
      dispatch(setCurrentUserData(sanctuaryLoginRes.data));
    } catch (err: any) {
      if (err.response?.data?.statusCode === 403) {
        setLoginError(Translation.hu.login.loginError403);
      } else {
        setLoginError(err.message);
      }
    }
  };

  const onGoogleLoginFail = (err: any) => {
    console.log('Google login failed! Loaded: ', loaded, err);
    setLoginError('Nem sikerült a Google autentikáció. Próbáld törölni a böngésző cache-t, és/vagy engedélyezni a szentélynek a 3rd party cookiekat');
  };

  const signIn = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFail,
  });

  return (
    <div>
      {!isAuthenticated && loaded &&
        <div className="bs-login-page">
          <div className="bs-login-box">
            <BbLogo />
            <span className="bs-login-box__logo-text">{Translation.hu.meta.name}</span>
            { loginError && <p className="bs-login-box__error-text">{loginError}</p> }
            { loaded && <BsButton color={'primary'} onClick={() => signIn()}>{Translation.hu.login.login}</BsButton> }
          </div>
        </div>
      }
      {isAuthenticated &&
        <Navigate to={'/'} replace />
      }
    </div>
  );
}

export default LoginPage;
