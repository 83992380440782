import { ColleagueDetailsDTO } from '@bb-sanctuary/common';

import { BsMedalKit, getMedalLabel } from '../../../data/medal-kit';
import BsBadge, { BsBadgeType } from '../../ui/badge/badge';
import Translation from '../../../data/translation';

export interface ColleagueMedalsProps {
  selectedColleague: ColleagueDetailsDTO
}

export function ColleagueMedals({selectedColleague}: ColleagueMedalsProps) {
  return (
    <div className="bs-colleague__badges">
    <div className="bs-colleague__badges__header">
      <h2 className="bs-colleague__badges__title">
        {Translation.hu.profile.medals}
      </h2>
      <span className="bs-colleague__badges__count">
        {selectedColleague.medals?.length} {Translation.hu.common.piece}
      </span>
    </div>
      <div className="bs-colleague__badges__list">
        {selectedColleague?.medals?.map((medal, index) =>
          <div className="bs-colleague__badges__item" key={index}>
            <BsBadge key={index} icon={BsMedalKit[medal]()} label={getMedalLabel(medal)} type={BsBadgeType.MEDAL} />
          </div>,
        )}
      </div>
    </div>
  );
}
