import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthRight, BadgeUserGroup, RoleDTO, UpdateUserDTO } from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityEditor from "../../shared/components/admin/GenericEntityEditor";
import { createField, createRequiredField, createSelectField } from "../../shared/utils/formUtils";
import { BsRoutes } from '../../shared/data/bsRoutes';

const createFormSchema = (roleList: RoleDTO[]) => ({
  fields: [
    createRequiredField('email')
      .label('E-mail', '')
      .sizeColumns(12)
      .build({disabled: true}),
    createSelectField('roles',
      'Roles',
      roleList.map((role) => ({label: role.name, value: role.id})),
      6)
      .build({multiple: true}),
    createSelectField('rights',
      'Rights',
      Object.keys(AuthRight).map((right) => ({
        label: right,
        value: AuthRight[right as keyof typeof AuthRight],
      })),
      6)
      .build({multiple: true}),
    createSelectField('badgeUserGroup',
      'User Group',
      Object.keys(BadgeUserGroup).map((userGroup) => ({
        label: userGroup,
        value: BadgeUserGroup[userGroup as keyof typeof BadgeUserGroup],
      })),
      6)
      .build(),
    createField('image', 'Photo')
      .component('image')
      .sizeColumns(12)
      .build({disableUploadOnSelect: true}),
  ],
});

function AdminUserEditPage() {
  const params = useParams();
  const id = params.id;
  const [formSchema, setFormSchema] = useState<any>();

  const getRoleList = async () => await AdminService.getRoles();
  const loadData = async () => {
    if (id) {
      const result = await AdminService.getUserOfRoles(id);
      return {...result, roles: result?.roles.map(r => r.id), rights: result?.rights.map(r => r.name)};
    }
    return {};
  };
  const saveUser = async (params: UpdateUserDTO) => {
    return AdminService.updateUserOfRoles(params);
  };

  useEffect(() => {
    getRoleList().then((e) => setFormSchema(createFormSchema(e.roleList)));
  }, []);

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__editor">
        {formSchema ?
          <GenericEntityEditor
            id={id}
            saveFn={saveUser}
            loadEntityFn={loadData}
            listingUrl={BsRoutes.admin.user._}
            formSchema={formSchema}
            hideCancel={false}
          />
        :
          <Fragment>
            {Translation.hu.common.loading}
          </Fragment>
        }
      </div>
    </Fragment>
  );
}

export default AdminUserEditPage;
