import {ChangeEvent, useState} from 'react';
import Translation from '../../../data/translation';
import BsInput from '../../ui/input/input';
import BsButton from '../../ui/button/button';
import {Dialog} from '@mui/material';
import {BadgeService} from '../../../service/badgeService';
import {ColleagueDetailsDTO, CustomPointTransactionDto} from '@bb-sanctuary/common';
import {ReactComponent as CloseIcon} from './../../../../assets/icon/plus.svg';
import {useSnackbar} from 'notistack';

export interface PointTransactionProps {
  open: boolean;
  onClose: (update?: boolean) => void;
  totalPoints?: number;
  selectedColleague: ColleagueDetailsDTO;
}

function BsProfilePointTransactionModal({
  open, 
  onClose, 
  selectedColleague,
}: PointTransactionProps) {
  const {enqueueSnackbar} = useSnackbar();
  const [enteredPoints, setEnteredPoints] = useState(0);
  const [action, setAction] = useState<'add' | 'deduct'>('add');
  const [message, setMessage] = useState('');

  const onValueSet = (points: number) => {
    setEnteredPoints(points);
  };

  const onMessageSet = (msg: string) => {
    setMessage(msg);
  };

  const onActionSelected = (action: string) => {
    if (action === 'deduct') {
      setAction('deduct');
    } else {
      setAction('add');
    }
    console.log(enteredPoints);
  };

  const onCalculatePoints = async () => {
    const params: CustomPointTransactionDto = {
      point: action === 'add' ? enteredPoints : -enteredPoints,
      message: message,
      reciever_email: selectedColleague.email,
    };
    try {
      const result = await BadgeService.updatePointsToUser(params);
      if (result) {
        enqueueSnackbar(Translation.hu.profile.pointTransactionSuccess, {variant: 'success'});
        onClose(true);
      }
    } catch (err) {
      enqueueSnackbar(Translation.hu.profile.pointTransactionError + (err as any).response?.data?.message, {variant: 'error'});
    }
  };

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={open} className="bs-dialog">
      <div className="bs-dialog__close" onClick={handleClose}><CloseIcon/></div>
      <div className="bs-point-transaction">
        <div className="bs-point-transaction__header">
          <h3 className="bs-point-transaction__header__title">{Translation.hu.profile.pointTransaction}</h3>
          <h4
            className="bs-point-transaction__header__subtitle">{Translation.hu.profile.totalPoints}: {selectedColleague.usablePoints}</h4>
        </div>

        <div className="bs-point-transaction__action">
          <div className="bs-button__group">
            <BsButton
              onClick={() => onActionSelected('add')}
              color={action === 'add' ? 'primary' : 'secondary'}>{Translation.hu.profile.add}</BsButton>
            <BsButton
              onClick={() => onActionSelected('deduct')}
              color={action === 'deduct' ? 'primary' : 'secondary'}>{Translation.hu.profile.deduct}</BsButton>
          </div>
          <BsInput type="number" className="bs-point-transaction__action__input"
                   placeholder={Translation.hu.profile.point.charAt(0).toUpperCase() + Translation.hu.profile.point.slice(1)}
                   color="secondary"
                   onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onValueSet(+e.target.value)}/>
        </div>

        <BsInput className="bs-point-transaction__message-input" placeholder={Translation.hu.profile.message}
                 color="secondary"
                 onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onMessageSet(e.target.value)}/>

        <h4
          className="bs-point-transaction__subtitle">{Translation.hu.profile.newPoints}: {action === 'add' ? (Number(selectedColleague.usablePoints) ?? Number(0)) + enteredPoints : (Number(selectedColleague.usablePoints) ?? Number(0)) - enteredPoints}</h4>

        <BsButton onClick={onCalculatePoints}>{Translation.hu.profile.calculate}</BsButton>
      </div>
    </Dialog>
  );
}

export default BsProfilePointTransactionModal;
