import { CvEducationDTO, EducationLevel } from "@bb-sanctuary/common";
import { Autocomplete, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { addYears } from "date-fns";
import { useEffect, useRef, useState } from "react";
import Translation from "../../../data/translation";
import BsButton from "../../ui/button/button";
import BsInput from "../../ui/input/input";
import { searchOnEnter } from '../../../utils/searchOnEnter';
import { toISODateString } from '../../../utils/toISODateString';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface EducationFormProps {
  onSetNewEducation: (education?: CvEducationDTO) => boolean;
  editedEdu?: CvEducationDTO;
  onCancel: () => void;
}

const minDateYear = 1950;

const CvGeneratorEducationForm = ({
                                    onSetNewEducation,
                                    editedEdu,
                                    onCancel,
                                  }: EducationFormProps) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [educationInstitution, setEducationInstitution] = useState<string>('');
  const [educationLevel, setEducationLevel] = useState<EducationLevel>(EducationLevel.BSc);
  const [faculty, setFaculty] = useState<string>('');
  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  const instiutionRef = useRef<HTMLInputElement | null>(null);
  const facultyRef = useRef<HTMLInputElement | null>(null);
  const levelRef = useRef<HTMLInputElement | null>(null);

  const isNotValidForm = (): boolean => {
    return (!startDate || educationInstitution.length === 0 ||
      (!!startDate && !!endDate && (new Date(startDate)).getTime() > (new Date(endDate).getTime()))
    );
  };

  const clear = () => {
    setStartDate(null);
    setEndDate(null);
    setEducationInstitution('');
    setFaculty('');
  };

  const handleSetNewEducation = () => {
    if (startDate && educationInstitution) {
      if (onSetNewEducation({
        id: Math.random(),
        title: educationInstitution,
        startDate: new Date(startDate),
        endDate: endDate ? new Date(endDate) : undefined,
        level: educationLevel,
        faculty,
      })) {
        clear();
        startDateRef.current?.focus();
      }
    }
  };

  const cancel = () => {
    onSetNewEducation(undefined);
    clear();
    onCancel();
  };

  const updateStartDate = (e: any) => {
    setStartDate(toISODateString(e));
    if (e && e.toString() !== 'Invalid Date') {
      endDateRef.current?.focus();
    }
  };

  const updateEndDate = (e: any) => {
    setEndDate(toISODateString(e));
    if (e && e.toString() !== 'Invalid Date') {
      instiutionRef.current?.focus();
    }
  };

  useEffect(() => {
    if (editedEdu) {
      setStartDate(toISODateString(editedEdu.startDate));
      setEndDate(toISODateString(editedEdu.endDate));
      setEducationInstitution(editedEdu.title);
      setFaculty(editedEdu.faculty);
      setEducationLevel(editedEdu.level);
    }
  }, [editedEdu]);


  return (
    <div>
      <h4 className="bs-form__legend">
        {editedEdu ? Translation.hu.cv.edu.headerEdit : Translation.hu.cv.edu.headerCreate}
      </h4>
      <div className="bs-cv-form">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={Translation.hu.cv.form.header.startDate}
            className="bs-cv-form-datepicker__education bs-cv-form-datepicker--small bs-cv-form__input--25"
            value={startDate}
            minDate={new Date(minDateYear)}
            maxDate={new Date()}
            views={['year']}
            onChange={updateStartDate}
            renderInput={(params: any) => <TextField {...params} />}
            inputRef={startDateRef}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={Translation.hu.cv.form.header.endDate}
            className="bs-cv-form-datepicker__education bs-cv-form-datepicker--small bs-cv-form__input--25"
            value={endDate}
            views={['year']}
            minDate={startDate ?? new Date(minDateYear)}
            maxDate={addYears(new Date(startDate ?? new Date()), 8)}
            onChange={updateEndDate}
            inputRef={endDateRef}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <BsInput
          required
          className="bs-cv-form__input--full"
          size="small"
          label={Translation.hu.cv.form.header.educationalInstitutionName}
          value={educationInstitution}
          onChange={(e) => setEducationInstitution(e.target.value)}
          onKeyUp={searchOnEnter(() => facultyRef.current?.focus())}
          inputRef={instiutionRef}
        />
        <BsInput
          required
          className="bs-cv-form__input bs-cv-form__input--25"
          size="small"
          label={Translation.hu.cv.form.header.faculty}
          value={faculty}
          onChange={(e) => setFaculty(e.target.value)}
          onKeyUp={searchOnEnter(() => levelRef.current?.focus())}
          inputRef={facultyRef}
        />
        <Autocomplete
          className="bs-cv-form-autocomplete"
          data-label={`Level`}
          options={Object.values(EducationLevel)}
          value={educationLevel}
          onChange={(_, value) => {
            if (value !== null) {
              setEducationLevel(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyPress={e => e.preventDefault()}
              onKeyDown={e => e.preventDefault()}
              onKeyUp={searchOnEnter(() => handleSetNewEducation())}
              inputRef={levelRef}
            />
          )}
          disableClearable
        />

        <span className="bs-cv-form__input--full"></span>

        <BsButton
          disabled={isNotValidForm()}
          className="bs-cv-btn"
          size="small"
          onClick={handleSetNewEducation}
        >{editedEdu ? Translation.hu.cv.form.updateEdu : Translation.hu.cv.form.addEdu}</BsButton>

        <BsButton
          className="bs-cv-btn"
          size="small"
          onClick={cancel}
          color={'secondary'}
        >{Translation.hu.cv.form.cancel}</BsButton>
      </div>
    </div>
  );
};

export default CvGeneratorEducationForm;
