import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from './../../../../assets/icon/plus.svg';
import Translation from '../../../data/translation';
import BsButton from "../../ui/button/button";
import { LegacyRef, useEffect, useRef, useState } from "react";
import { ColleagueDTO, getUserThumbnail, SexEnum } from "@bb-sanctuary/common";
import { useLoading } from "../../../utils/useLoader.hook";
import { ColleagueService } from "../../../service/colleagueService";
import Dummy from '../../../../assets/images/dummy.png';
import Rookie from '../../../../assets/images/rookie.png';
import Senior from '../../../../assets/images/senior.png';
import Sensei from '../../../../assets/images/sensei.png';
import { useImageLoaded } from '../../ui/profile-card/profile-card';
import { ReactComponent as ImagePlaceholder } from './../../../../assets/anim/image-placeholder.svg';

export interface BsGameProps {
  open: boolean;
  onClose: () => void;
}

export enum BsGameLevel {
  DUMMY = `dummy`,
  ROOKIE = `rookie`,
  SENIOR = `senior`,
  SENSEI = `sensei`,
}

function shuffleArray<T>(array: Array<T>) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

enum RenderPhase {
  SelectLevel = 'SelectLevel',
  QuestionDisplayed = 'QuestionDisplayed',
  TimebarWidthSetTo100 = 'TimebarWidthSetTo100',
  TimebarWidthSetTo0 = 'TimebarWidthSetTo0',
  QuestionAnswered = 'QuestionAnswered',
  GameOver = 'GameOver',
}

type Game = {
  currentQuestionNumber: number;
  correctAnswers: number;
  level?: BsGameLevel,
  randomColleague?: ColleagueDTO;
  colleagues: ColleagueDTO[];
  femaleColleagues: ColleagueDTO[];
  maleColleagues: ColleagueDTO[];
  colleaguesWithAvatar: ColleagueDTO[];
  options?: ColleagueDTO[];
  selectedAnswer?: string;
  questionDisplayed?: number;
  renderPhase?: RenderPhase;
  timeSpentOnThinking: number;

  timeUpTimeout?: NodeJS.Timeout;
  showNextQuestionAfterTimeUpTimeout?: NodeJS.Timeout;
}

function getName(colleague?: ColleagueDTO) {
  return `${colleague?.lastName} ${colleague?.firstName}`;
}

export const BsGame = (props: BsGameProps) => {
  const {onClose, open} = props;
  const setLoading = useLoading();
  const game = useRef<Game>();
  const [rerender, setRerender] = useState<number>(0);
  const timeBar = useRef<HTMLDivElement|undefined>();

  const forceRerender = () => {
    setRerender(v => v + 1);
  };
  const forceRerenderLater = () => {
    setTimeout(() => {
      console.log(`Rerender later!`);
      setRerender(v => v + 1);
    },50);
  };

  const initGame = async () => {
    try {
      setLoading(true);
      const allColleagues = game.current ? game.current.colleagues : (await ColleagueService.getAllColleague()).data;
      if (!allColleagues) {
        throw new Error('No colleague');
      }
      const colleaguesWithAvatar = (allColleagues.filter(col => col.image));
      const femaleColleagues: Array<ColleagueDTO> = [];
      const maleColleagues: Array<ColleagueDTO> = [];

      allColleagues.forEach((colleague) => {
        if(colleague.sex === SexEnum.Male) {
          maleColleagues.push(colleague);
        } else if (colleague.sex === SexEnum.Female) {
          femaleColleagues.push(colleague);
        }
      });

      shuffleArray(femaleColleagues);
      shuffleArray(maleColleagues);
      shuffleArray(colleaguesWithAvatar);

      game.current = {
        currentQuestionNumber: 0,
        correctAnswers: 0,
        timeSpentOnThinking: 0,
        colleagues: allColleagues,
        femaleColleagues,
        maleColleagues,
        colleaguesWithAvatar,
      };

      startGame();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initGame();
  }, []);

  const totalQuestions = 6;
  const [ref, loaded, onLoad, setLoaded] = useImageLoaded();
  const sadEmojiList = ['😭', '😢', '😞', '😕', '🙁', '🫤', '😩', '👀', '💔', '🫠', '🤦', '😱', '🫣', '🤫', '😵‍💫', '😿', '🤕', '😳', '🙄', '🎢', '😒'];
  const happyEmojiList = ['🧠', '🥹', '😊', '🥰', '🤩', '🥳', '🤗', '🤑', '😇', '🙂', '🙃', '👻', '🫶🏼', '🙌🏼', '👏🏻', '🤝', '👍', '🥷', '🙏', '🤴', '🕺', '❤️', '💎', '🎉', '🎊'];

  const getTimeLimit = () => {
    if (!game.current) {
      throw new Error();
    }
    if(game.current.level === BsGameLevel.DUMMY) {
      return 30000;
    } else if(game.current.level === BsGameLevel.ROOKIE) {
      return 10000;
    } else if(game.current.level === BsGameLevel.SENIOR) {
      return 5000;
    } else {
      return 3000;
    }
  };

  const getOptionClass = (option: ColleagueDTO) => {
    if (getName(option) === game.current?.selectedAnswer) {
      if(getName(option) === getName(game.current?.randomColleague)) {
        return 'bs-game__option bs-game__option--correct';
      } else {
        return 'bs-game__option bs-game__option--wrong';
      }
    }

    return 'bs-game__option';
  };

  const handleClose = () => {
    onClose();
  };

  const isTimeUp = () => {
    return false;
  };

  const startGame = () => {
    if (!game.current) {
      throw new Error();
    }

    game.current.renderPhase = RenderPhase.SelectLevel;
    game.current.level = undefined;
    game.current.currentQuestionNumber = 0;
    forceRerender();
  };

  const selectLevel = (level: BsGameLevel) => {
    if (!game.current) {
      throw new Error();
    }

    game.current.level = level;

    showNextQuestion();
  };

  const onSubmit = (colleague?: ColleagueDTO) => {
    if (!game.current) {
      throw new Error();
    }

    timeBar!.current!.style.opacity = '0';
    if (game.current.renderPhase !== RenderPhase.QuestionAnswered) {
      game.current.selectedAnswer = getName(colleague);
      game.current.renderPhase = RenderPhase.QuestionAnswered;
    }

    if (game.current.selectedAnswer === getName(game.current.randomColleague)) {
      game.current.correctAnswers += 1;
      game.current.timeSpentOnThinking += Math.min(Date.now() - game.current.questionDisplayed!, getTimeLimit());
    } else {
      game.current.timeSpentOnThinking += getTimeLimit();
    }

    clearTimeout(game.current.timeUpTimeout);
    clearTimeout(game.current.showNextQuestionAfterTimeUpTimeout);

    game.current.showNextQuestionAfterTimeUpTimeout = setTimeout(() => {
      showNextQuestion();
    }, 1000);

    forceRerender();
  };

  const showNextQuestion = () => {
    if (!game.current) {
      throw new Error();
    }
    clearTimeout(game.current.timeUpTimeout);
    clearTimeout(game.current.showNextQuestionAfterTimeUpTimeout);

    if (game.current.currentQuestionNumber === totalQuestions) {
      game.current.currentQuestionNumber = Number.MAX_SAFE_INTEGER;
      game.current.renderPhase = RenderPhase.GameOver;
      forceRerender();
      return;
    }

    game.current.currentQuestionNumber = game.current.currentQuestionNumber + 1;
    game.current.renderPhase = RenderPhase.QuestionDisplayed;
    game.current.selectedAnswer = '';

    game.current.randomColleague = game.current.colleaguesWithAvatar[game.current.currentQuestionNumber];

    const options = [];
    const genderMatchColleagues = (game.current.randomColleague.sex === SexEnum.Female) ? game.current.femaleColleagues : game.current.maleColleagues;
    shuffleArray(genderMatchColleagues);
    options.push(genderMatchColleagues[0], genderMatchColleagues[1]);
    const duplicateIndex = options.findIndex(option => getName(option) === getName(game.current!.randomColleague));
    if (duplicateIndex > -1) {
      options.splice(duplicateIndex, 1, genderMatchColleagues[2]);
    }
    options.push(game.current!.randomColleague);
    shuffleArray(options);
    game.current.options = options;

    setLoaded(false);
    forceRerender();

    // time up
    game.current.timeUpTimeout = setTimeout(() => {
      game.current!.renderPhase = RenderPhase.QuestionAnswered;
      game.current!.selectedAnswer = '';
      timeBar!.current!.style.opacity = '0';
      onSubmit();
      forceRerender();
    }, getTimeLimit());
  };

  const getPoints = () => {
    if (!game.current) {
      return 0;
    }

    const level = game.current.level === BsGameLevel.SENSEI ? 1000 :
      game.current.level === BsGameLevel.SENIOR ? 600 :
      game.current.level === BsGameLevel.ROOKIE ? 300 : 100;

    const correction = game.current.level === BsGameLevel.DUMMY ? 1 : (game.current.correctAnswers / totalQuestions);

    const timeLimit = Math.pow(totalQuestions * getTimeLimit(), 2);
    const timeSpent = Math.pow(game.current.timeSpentOnThinking, 2);

    return Math.ceil((level - ((timeSpent / timeLimit) * level)) * correction);
  };

  useEffect(() => {
    if (!game.current) {
      return;
    }

    // console.log(`${new Date().toISOString()} Rerendering(${rerender}) with phase ${game.current?.renderPhase}`);

    if (game.current.currentQuestionNumber > 0) {
      const renderPhase = game.current.renderPhase;
      switch (renderPhase) {
        case RenderPhase.QuestionDisplayed:
          timeBar!.current!.style.transition = 'none';
          timeBar!.current!.style.opacity = '1';
          timeBar!.current!.style.width = '100%';
          game.current.renderPhase = RenderPhase.TimebarWidthSetTo100;
          // need to ask a rerender so timebar width will be set to 0 on next rerender
          forceRerenderLater();
          break;
        case RenderPhase.TimebarWidthSetTo100:
          timeBar!.current!.style.transition = `width ${getTimeLimit()}ms linear`;
          timeBar!.current!.style.width = '0%';
          game.current.renderPhase = RenderPhase.TimebarWidthSetTo0;
          game.current.questionDisplayed = Date.now();
          break;
      }
    }
  }, [rerender]);

  return (
    <Dialog onClose={handleClose} open={open} className="bs-dialog bs-dialog--game">
      <div className="bs-dialog__close" onClick={handleClose}><CloseIcon/></div>
      <div className='bs-game'>
        {game.current && game.current.currentQuestionNumber === 0 &&
          <div>
            <h3>{Translation.hu.game.selectLevel}</h3>
            <div className='bs-game__options'>
              <BsButton size={'small'}
                        color={'secondary'}
                        onClick={() => selectLevel(BsGameLevel.DUMMY)}
              >
                <img className='bs-game__option__pic' src={Dummy} />
                {BsGameLevel.DUMMY}
              </BsButton>
              <BsButton size={'small'}
                        color={'secondary'}
                        onClick={() => selectLevel(BsGameLevel.ROOKIE)}
              >
                <img className='bs-game__option__pic' src={Rookie} />
                {BsGameLevel.ROOKIE}
              </BsButton>
              <BsButton size={'small'}
                        color={'secondary'}
                        onClick={() => selectLevel(BsGameLevel.SENIOR)}
              >
                <img className='bs-game__option__pic' src={Senior} />
                {BsGameLevel.SENIOR}
              </BsButton>
              <BsButton size={'small'}
                        color={'secondary'}
                        onClick={() => selectLevel(BsGameLevel.SENSEI)}
              >
                <img className='bs-game__option__pic' src={Sensei} />
                {BsGameLevel.SENSEI}
              </BsButton>
            </div>
          </div>
        }

        {game.current && game.current.currentQuestionNumber > 0 && game.current.currentQuestionNumber <= totalQuestions &&
          <div>
            <div className='bs-game__baby'>
              <img src={game.current.level === BsGameLevel.SENSEI ?
                Sensei : game.current.level === BsGameLevel.SENIOR ?
                  Senior : game.current.level === BsGameLevel.ROOKIE ?
                    Rookie : Dummy }
                   alt='level-ninja' />
            </div>

            <div className={isTimeUp() ? 'bs-game__time bs-game__time--isOver' : 'bs-game__time'}>
              <div id={`${game.current.currentQuestionNumber}durationBar`}
                   ref={timeBar as LegacyRef<any>}
                   className='bs-game__time__remaining'>
              </div>
            </div>

            <h3>{Translation.hu.game.title}</h3>
            <p className='bs-game__score'>{game.current.currentQuestionNumber} / {totalQuestions}</p>
            <div className='bs-game__photo'>
              {game.current.randomColleague &&
                <>
                  <img
                    ref={ref as LegacyRef<any>}
                    onLoad={onLoad}
                    src={getUserThumbnail(game.current.randomColleague)}
                    alt={`random-colleague`}
                  />
                  <ImagePlaceholder className={`bs-profile-card__image bs-profile-card__image--placeholder ${loaded ? 'bs-profile-card__image--hidden' : ''}`} />
                </>
              }

              {game.current?.renderPhase === RenderPhase.QuestionAnswered &&
                <div className='bs-game__feedback-emoji'>
                  {getName(game.current?.randomColleague) === game.current?.selectedAnswer ?
                    <p>{happyEmojiList[Math.floor(Math.random() * happyEmojiList.length)]}</p> :
                    <p>{sadEmojiList[Math.floor(Math.random() * sadEmojiList.length)]}</p>
                  }
                </div>
              }
            </div>
            <div className='bs-game__options'>
              {(game.current.options || []).map((option, index) =>
                <div key={index}
                     className={getOptionClass(option)}
                     onClick={() => onSubmit(option)}
                >
                  {getName(option)}
                </div>,
              )}
            </div>
          </div>
        }

        {game.current && game.current?.renderPhase === RenderPhase.GameOver &&
          <div className='bs-game__game-over'>
            <img src={`https://media.giphy.com/media/zVtphYOEKyttWEdOlv/giphy.gif`} />
            <p className='bs-game__game-over__correct'>{game.current.correctAnswers} / {totalQuestions}</p>
            <p className='bs-game__game-over__points'>{getPoints()} {Translation.hu.game.points}</p>
            <div className='bs-game__submit'>
              <BsButton size={'small'} onClick={() => initGame()}>{Translation.hu.game.restart}</BsButton>
            </div>
          </div>
        }
        </div>
    </Dialog>
  );
};
