import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Autocomplete, Chip, Dialog, TextField } from '@mui/material';
import { TechnologyWithCountDTO } from '@bb-sanctuary/common';

import Translation from '../../data/translation';
import BsButton from '../ui/button/button';
import { ReactComponent as CloseIcon } from './../../../assets/icon/plus.svg';
import { AdminService } from '../../../pages/admin/AdminService';
import { TechService } from '../../service/techService';

export interface BsMergeDialogProps {
  open: boolean;
  onClose: () => void;
  selectedTechId: number;
  selectedTechName: string;
}

const BsMergeDialog = (props: BsMergeDialogProps): ReactElement => {
  const { open, onClose, selectedTechId, selectedTechName } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [ baseTech, setBaseTech ] = useState<TechnologyWithCountDTO>();
  const [ previouslyAddedTechs, setPreviouslyAddedTechs ] = useState<Array<TechnologyWithCountDTO>>([]);

  const getTechList = useCallback( async () => {
    const techList = (await TechService.getTechList()).data.technologyList;
    if (techList) {
      setPreviouslyAddedTechs(techList);
    }
  }, []);

  const mergeTech = async () => {
    if (baseTech) {
      try {
        const result = await AdminService.mergeTech(baseTech.technology.id, selectedTechId);
        if (result) {
          enqueueSnackbar(Translation.hu.form.success, {variant: 'success'});
          onClose();
        }
      } catch {
        enqueueSnackbar(Translation.hu.form.error, {variant: 'error'});
        onClose();
      }
    }
  };

  const cancelMerge = () => {
    onClose();
  };

  useEffect(() => {
    getTechList();
  }, []);

  return (
    <Dialog onClose={cancelMerge} open={open} className="bs-dialog">
      <div className="bs-dialog__close" onClick={cancelMerge}><CloseIcon /></div>
      <div className="bs-dialog__wrap">
        <div className="bs-dialog__header">
          <h3>{Translation.hu.label.mergeTech}</h3>
        </div>
        <div className="bs-dialog__content">
          <p className="bs-dialog__legend"><strong>{Translation.hu.label.baseTechLabel}</strong> {selectedTechName}</p>
          <div className="bs-dialog__field">
            <p><strong>{Translation.hu.label.newTechLabel}</strong></p>
            <Autocomplete
              id="tech"
              className="bs-input bs-input--spread"
              data-label={``}
              options={previouslyAddedTechs.sort((a, b) => a.technology.name.localeCompare(b.technology.name))}
              getOptionLabel={(option: TechnologyWithCountDTO) => option.technology.name}
              groupBy={(option) => option.technology.name.toUpperCase().charAt(0)}
              autoHighlight={true}
              onChange={(_, value) => value && setBaseTech(value)}
              renderTags={(value: TechnologyWithCountDTO[], getTagProps) =>
                value.map((option: TechnologyWithCountDTO, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (<TextField {...params} />)}
            />
          </div>
        </div>
        <div className="bs-dialog__action">
          <BsButton color="secondary" onClick={cancelMerge}>
            {Translation.hu.form.cancel}
          </BsButton>
          <BsButton color="primary" onClick={mergeTech}>
            {Translation.hu.form.save}
          </BsButton>
        </div>
      </div>
    </Dialog>
  );
};

export default BsMergeDialog;
