export interface WysiwygConfigProps {
  height?: number;
}

export const WysiwygConfig = (props?: WysiwygConfigProps) => ({
  immediateReactModelUpdate: true,
  heightMin: props?.height || 450,
  // image upload params
  imageUploadParam: 'file',
  imageUploadParams: {
    type: 'blog-image',
  },
  imageUploadURL: '/api/file/image',
  imageUploadMethod: 'POST',
  imageStyles: {
    'img-margin-top-s': 'Margin Top - Small',
    'img-margin-top-m': 'Margin Top - Medium',
    'img-margin-top-l': 'Margin Top - Large',
    'img-margin-right-s': 'Margin Right - Small',
    'img-margin-right-m': 'Margin Right - Medium',
    'img-margin-right-l': 'Margin Right - Large',
    'img-margin-bottom-s': 'Margin Bottom - Small',
    'img-margin-bottom-m': 'Margin Bottom - Medium',
    'img-margin-bottom-l': 'Margin Bottom - Large',
    'img-margin-left-s': 'Margin Left - Small',
    'img-margin-left-m': 'Margin Left - Medium',
    'img-margin-left-l': 'Margin Left - Large',
  },

  events: {
    'image.error': () => {
      console.error('Err!');
    },
  },

  toolbarButtons: {
    // Key represents the more button from the toolbar.
    moreText: {
      // List of buttons used in the  group.
      buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
      // Alignment of the group in the toolbar.
      align: 'left',
      // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
      buttonsVisible: 3,
    },

    moreParagraph: {
      buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
      align: 'left',
      buttonsVisible: 3,
    },

    moreRich: {
      buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
      align: 'left',
      buttonsVisible: 2,
    },

    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help', 'Stílustanalítás', 'Színtelenítés', 'addContactUsButton'],
      align: 'right',
      buttonsVisible: 2,
    },
  },

  // Change buttons for XS screen.
  toolbarButtonsXS: [['undo', 'redo'], ['bold', 'italic', 'underline']],

  // license key
  key: window.location.href.match(/^http:\/\/localhost/) ?
    '' :
    (window.location.href.match(/^http:\/\/bbwebtest\.blackbelt\.hu/) ?
      'Kb3A3pC3D2G2A4B3I4ovwarzmC-7hhB5A-16sabiaairA6e1C-11vA1E1I1C2B8C7B6E1F5G4==' :
      'wFE7nF4B4G4E3B11C6exwA-9C-8uH-7emvE4lpzG2B1A2A2D6B1F2C4G1G4=='),
});
