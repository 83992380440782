import { ContentCopy, Share } from '@mui/icons-material';
import BsButton from '../../ui/button/button';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../../assets/icon/plus.svg';
import Translation from '../../../data/translation';
import BsInput from '../../ui/input/input';
import { FaqCategoryGroup } from '@bb-sanctuary/common';

interface FaqEntryShareProps {
  id: number;
  type: FaqCategoryGroup;
  className?: string;
}

const FaqEntryShare = (props: FaqEntryShareProps) => {
  const [url] = useState(`${window.location.origin}/${props.type === FaqCategoryGroup.GENERAL ? 'altalanos-tudnivalok' : 'master-of-technology'}/${props.id}`);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const copyToClipboard = () => {
    const copyText = document.getElementById('copy-url');
    if (!copyText) {
      return;
    }

    /* Select the text field */
    (copyText as HTMLInputElement).select();
    (copyText as HTMLInputElement).setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(url);
  };
  const openShareDialog = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Dialog onClose={() => setDialogOpen(false)} open={isDialogOpen} className="bs-dialog">
        <div className="bs-dialog__close" onClick={() => setDialogOpen(false)}><CloseIcon /></div>
        <div className="bs-dialog__wrap">
          <div className="bs-dialog__header">
            <h3>{Translation.hu.label.copy}</h3>
          </div>
          <div className="bs-dialog__content">
            <div className="bs-dialog__field">
              <BsInput
                id={'copy-url'}
                required
                name="title"
                className="bs-input--spread"
                startAdornment={`${Translation.hu.label.url}:`}
                endAdornment={
                  <BsButton
                    onClick={copyToClipboard}
                    color="secondary"
                    className="bs-helper-mobile-icon"
                    style={{marginRight: -16}}>
                    <ContentCopy />
                  </BsButton>
                }
                value={url}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <BsButton
        color={'secondary-alt'}
        size={'small'}
        className={props.className}
        onClick={openShareDialog}>
        <Share />
      </BsButton>
    </>
  );
};

export default FaqEntryShare;
