
export interface CreateTechnologyDTO {
  name: string;
}

export enum TechnologyLevel {
  Novice = 'Novice',
  Confident = 'Confident',
  Pro = 'Pro',
  Master = 'Master'
}

export interface TechnologyForUserDTO {
  level: TechnologyLevel,
}

export interface MergeTechnologyDTO {
  oldTechnologyId: number;
  newTechnologyId: number;
}