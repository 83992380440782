import { axiosInstance } from './axiosInstance';
import {
  FAQAdminListResponseDTO,
  FAQCategoryAdminDTO,
  FaqCategoryGroup,
  FAQEntryDTO,
  FAQListResponseDTO,
} from '@bb-sanctuary/common';
import { AxiosResponse } from 'axios';

function getUrlBySubject(subject: FaqCategoryGroup) {
  return subject === FaqCategoryGroup.GENERAL ? 'general-list' : 'bb-list';
}

export const FaqService = {
  getAll(): Promise<AxiosResponse<FAQAdminListResponseDTO>> {
    return axiosInstance.get(`/api/admin/faq`);
  },
  getAllCategory(): Promise<AxiosResponse<Array<FAQCategoryAdminDTO>>> {
    return axiosInstance.get(`/api/admin/faq/category`);
  },
  getFaq(subject: FaqCategoryGroup): Promise<AxiosResponse<FAQListResponseDTO>> {
    const url = getUrlBySubject(subject);

    return axiosInstance.get(`/api/faq/${url}`);
  },
  searchFaq(subject: FaqCategoryGroup, searchText: string): Promise<AxiosResponse<FAQListResponseDTO>> {
    const url = getUrlBySubject(subject);

    return axiosInstance.post(`/api/faq/${url}`, { searchText });
  },
  getFaqEntry(faqEntryId: string): Promise<AxiosResponse<FAQEntryDTO>> {
    return axiosInstance.get(`/api/faq/${faqEntryId}`);
  },
  deleteEntry(faqEntryId: number) {
    return axiosInstance.delete(`/api/admin/faq/${faqEntryId}`);
  },
};
