import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";

import BsLogo from '../../ui/logo/logo';
import BsMenu from '../../ui/menu/menu';
import BsProfileTag from '../../ui/profile-tag/profile-tag';
import BsThemeSwitch from '../../ui/theme-switch/theme-switch';
import BsNotifications from '../notifications/notifications';
import { ReactComponent as BsSanctuary } from './../../../../assets/svg/bb-sanctuary-logo.svg';
import { selectCurrentUser } from '../../../../contexts/currentUserSlicer';
import { BsContentHeader } from './bsContentHeader';
import { BsRoutes } from '../../../data/bsRoutes';

function BsHeader(): ReactElement {
  const currentUser = useSelector(selectCurrentUser);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = !useMediaQuery(`(min-width:768px)`);
  const isCompactMenu = !useMediaQuery(`(min-width:1024px)`);

  const menuStateHandler = (close?: boolean) => {
    const action = close ? false : !isMenuOpen;
    setIsMenuOpen(action);
  };

  const handleHeaderState = useCallback( async (sticky: boolean) => {
    const header = document.querySelector('.bs-header');
    const appbar = document.querySelector('.bs-appbar');
    if (header && appbar) {
      if (isMobile || sticky) {
        header.classList.add('bs-header--sticky');
        appbar.classList.add('bs-appbar--sticky');
      } else {
        header.classList.remove('bs-header--sticky');
        appbar.classList.remove('bs-appbar--sticky');
      }
    }
  }, [isMobile]);

  const isSticky = useCallback( async () => {
    const scrollTop = window.scrollY;
    handleHeaderState(scrollTop > 10);
  }, [handleHeaderState]);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  useEffect(() => {
    isSticky();
  }, [isSticky]);

  return (
    <Fragment>
      <div className="bs-header-logo-bg">
        <div className="bs-header-logo-bg__circle bs-header-logo-bg__circle--small"></div>
        <div className="bs-header-logo-bg__circle bs-header-logo-bg__circle--medium"></div>
        <div className="bs-header-logo-bg__circle bs-header-logo-bg__circle--large"></div>
      </div>
      <div className="bs-header">
        <div className="bs-header__bg"></div>
        <div className="bs-header__branding">
          <div className="bs-header__logo"><BsLogo /></div>
          <div className="bs-header__sitename"><BsSanctuary /></div>
        </div>
        <div className="bs-header__actions">
          <div className="bs-header__profile">
            <Link to={BsRoutes.profil._}>
              <BsProfileTag user={currentUser} name={currentUser?.firstname || ``} />
            </Link>
          </div>
          <div className="bs-header__search"><BsContentHeader render={true} /></div>
        </div>
        <div className="bs-header__options">
          {!isCompactMenu &&
            <Fragment>
              <div className="bs-header__notifications"><BsNotifications /></div>
              <div className="bs-header__theme"><BsThemeSwitch /></div>
            </Fragment>
          }
          {isCompactMenu &&
            <Fragment>
              <div className={`bs-header__mobile-menu${!isMenuOpen ? `` : ` bs-header__mobile-menu--open`}`}>
                <div className="bs-header__mobile-menu__header">
                  <BsProfileTag
                    user={currentUser}
                    email={currentUser?.email}
                    name={currentUser?.firstname || ``}
                    onClick={() => setIsMenuOpen(false)}
                  />
                  <BsNotifications />
                </div>
                <BsMenu onNavigate={() => setIsMenuOpen(false)} />
                <BsThemeSwitch />
              </div>
              <label
                className={`bs-header__mobile-menu-toggle${isMenuOpen ? ` bs-header__mobile-menu-toggle--open` : ``}`}
                onClick={() => menuStateHandler()}
              >
                <span></span>
                <span></span>
                <span></span>
              </label>
            </Fragment>
          }
          </div>
      </div>
    </Fragment>
  );
}

export default BsHeader;
