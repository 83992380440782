import { ReactElement } from 'react';
import BsMenu from '../../ui/menu/menu';

function BsAppbar(): ReactElement {
  return (
    <div className="bs-appbar">
      <div className="bs-appbar__menu">
        <BsMenu />
      </div>
    </div>
  );
}

export default BsAppbar;
