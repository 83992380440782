import { BadgeUserGroup, UserForAdminDTO } from '@bb-sanctuary/common';

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityListing, { Column } from "../../shared/components/admin/GenericEntityListing";
import { BsRoutes } from '../../shared/data/bsRoutes';
import { Fragment } from 'react';

function AdminUserPage() {
  const columns: Array<Column> = [
    {
      header: `E-mail`,
      field: `email`,
      template: (item: UserForAdminDTO) => <>{item.email}</>,
    },
    {
      header: `User Group`,
      template: (item: UserForAdminDTO) => <>{item.badgeUserGroup || BadgeUserGroup.Colleague}</>,
    },
    {
      header: `Roles`,
      template: (item: UserForAdminDTO) => <>{item.roles.map(r => r.name).join(`, `)}</>,
    },
  ];

  const getUserList = async () => {
    const result = await AdminService.getUsersOfRoles();

    return result.users.map(u => ({...u, id: u.email}))
      .sort((l, r) => l.email.localeCompare(r.email));
  };

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        <GenericEntityListing
          title={Translation.hu.admin.user.manageUsers}
          createUrl={``}
          editUrl={`${BsRoutes.admin.user._}/edit/:id`}
          getListFn={getUserList}
          isEditable={true}
          columns={columns}
        />
      </div>
    </Fragment>
  );
}

export default AdminUserPage;
