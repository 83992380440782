import { Fragment, useCallback } from 'react';
import { FaqCategoryGroup, FAQEntryAdminDTO } from "@bb-sanctuary/common";

import Translation from "../../shared/data/translation";
import GenericEntityListing, { Column } from "../../shared/components/admin/GenericEntityListing";
import { BsRoutes } from '../../shared/data/bsRoutes';
import { FaqService } from '../../shared/service/faqService';

function AdminFaqPage({subject}: any) {

  const columns: Array<Column> = [
    {
      header: `Title`,
      field: `title`,
      template: (item: FAQEntryAdminDTO) => <>{item.title}</>,
    },
    {
      header: `Category`,
      template: (item: FAQEntryAdminDTO) => <>{item.category.title}</>,
    },
    {
      header: `Order`,
      template: (item: FAQEntryAdminDTO) => <>{item.order}</>,
    },
  ];

  const getFaqList = useCallback(async () => {
    const res = await FaqService.getAll();
    return res.data.entries
      .filter((item: any) => item.category.categoryGroup === subject)
      .sort((l, r) => {
        if (l.category.title === r.category.title) {
          return l.order > r.order ? 1 : -1;
        }
        return l.category.title.localeCompare(r.category.title);
      });
  }, [subject]);

  const deleteFaqEntry = (item: FAQEntryAdminDTO) => {
    if (prompt('Biztosan törlöd? Írd be, hogy igen, ha folytatod!') === 'igen') {
      return FaqService.deleteEntry(item.id);
    }
    return Promise.resolve(false);
  };

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        <GenericEntityListing
          title={subject === FaqCategoryGroup.GENERAL ? Translation.hu.label.faq : Translation.hu.label.bbFaq}
          addButtonLabel={Translation.hu.label.addFaq}
          createUrl={subject === FaqCategoryGroup.GENERAL ? `${BsRoutes.admin.faq._}/add` : `${BsRoutes.admin.bbFaq._}/add`}
          editUrl={subject === FaqCategoryGroup.GENERAL ? `${BsRoutes.admin.faq._}/edit/:id` : `${BsRoutes.admin.bbFaq._}/edit/:id`}
          getListFn={getFaqList}
          deleteEntity={deleteFaqEntry}
          isEditable={true}
          columns={columns}
        />
      </div>
    </Fragment>
  );
}

export default AdminFaqPage;
