import { Fragment, useCallback, useEffect, useRef, useState, createRef } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-lottie-player";
import { useSnackbar } from "notistack";
import { Autocomplete, Chip, TextField } from '@mui/material';
import { AddRounded, CheckRounded, DeleteOutline } from '@mui/icons-material';
import { TechnologyDTO, TechnologyLevel, UserTechnologyDTO } from "@bb-sanctuary/common";

import { selectCurrentUser } from "../../../../contexts/currentUserSlicer";
import { ColleagueService } from "../../../service/colleagueService";
import { TechService } from "../../../service/techService";
import Translation from "../../../data/translation";
import successAnim from "./../../../../assets/anim/check.json";
import { useCreateConfirmModal } from '../../../utils/useConfirm.hook';

export interface BsTechTagProps {
  tech: TechnologyDTO;
  level?: TechnologyLevel;
  newTech?: boolean;
  onlyShow?: boolean;
  userTechList?: Array<UserTechnologyDTO>;
  createTechTag?: (data: UserTechnologyDTO) => void;
  onDelete?: (id: number) => void;
  onSelectTech?: (id: number) => void;
}

export enum TechView {
  Add = 'add',
  Edit = 'edit',
}

export enum TechProcessState {
  Add = 'add',
  Select = 'select',
  Rate = 'rate',
  Show = 'show',
}

const BsTechTag = ({
  tech,
  level,
  newTech = false,
  onlyShow = false,
  userTechList,
  createTechTag,
  onDelete,
  onSelectTech,
  ...props
}: BsTechTagProps) => {

  const inputRef = createRef();
  const { enqueueSnackbar } = useSnackbar();
  const isFirstRender = useRef(true);
  const currentUser = useSelector(selectCurrentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessAnim, setIsSuccessAnim] = useState(false);
  const [previouslyAddedTechs, setPreviouslyAddedTechs] = useState<Array<TechnologyDTO>>([]);
  const [techViewState, setTechViewState] = useState<TechView>();
  const [techProcessState, setTechProcessState] = useState<TechProcessState>();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedTech, setSelectedTech] = useState<string>();
  const [selectedLevel, setSelectedLevel] = useState<TechnologyLevel>();
  const [selectedTechList, setSelectedTechList] = useState<Array<UserTechnologyDTO>>();
  const {dialog, openConfirmModal} = useCreateConfirmModal();

  const getTechList = useCallback( async () => {
    const techList = await TechService.getTechList();
    if (techList) {
      setPreviouslyAddedTechs(techList.data.technologyList.map(_ => _.technology));
    }
  }, []);

  const rateSelectedTech = useCallback( async () => {
    if (currentUser && selectedId && selectedLevel) {
      setIsLoading(true);
      try {
        const response = await (techViewState === TechView.Add ? 
          ColleagueService.postColleagueTech(currentUser.email, selectedId, selectedLevel) :
          ColleagueService.editColleagueTech(currentUser.email, selectedId, selectedLevel)
        );
        if (response) {
          if (techViewState === TechView.Add && createTechTag) {
            setTechViewState(TechView.Edit);
            createTechTag({technology: {id: selectedId, name: selectedTech}, level: selectedLevel} as UserTechnologyDTO);
          }
          setIsSuccess(true);
          setTechProcessState(TechProcessState.Show);
        }
      } catch (error) {
        enqueueSnackbar((error as Error).message || Translation.hu.form.error, {variant: 'error'});
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentUser, enqueueSnackbar, selectedId, selectedTech, selectedLevel, createTechTag, techViewState]);

  const selectTech = useCallback(async () => {
    if (selectedTech && selectedTechList?.map(e => e.technology.name.trim().toLowerCase()).includes(selectedTech.trim().toLowerCase())) {
      enqueueSnackbar(Translation.hu.warning.techAlreadySelected);
      return;
    }
    if (currentUser && selectedTech) {
      setIsLoading(true);
      try {
        const response = await TechService.postTech({name: selectedTech});
        if (response) {
          setSelectedId(response.data.id);
          setTechProcessState(TechProcessState.Rate);
          if (onSelectTech) {
            onSelectTech(response.data.id);
          }
        }
      } catch (error) {
        enqueueSnackbar((error as Error).message || Translation.hu.form.error, {variant: 'error'});
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedTech, selectedTechList, currentUser, onSelectTech, enqueueSnackbar]);

  const editTech = () => {
    if (!selectedTech || !selectedId) {
      setSelectedId(tech.id);
      setSelectedTech(tech.name);
    }
    setTechProcessState(TechProcessState.Rate);
  };

  const deleteTech = useCallback(async () => {
    if (currentUser) {
      setIsLoading(true);
      try {
        if (tech.id) {
          await openConfirmModal({confirmation: Translation.hu.label.confirmDeleteTech});
          await ColleagueService.deleteColleagueTech(currentUser.email, tech.id);
        }
        if (onDelete) {
          onDelete(tech.id);
        }
      } catch (error) {
        enqueueSnackbar((error as Error).message || Translation.hu.form.error, {variant: 'error'});
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentUser, tech, onDelete, openConfirmModal, enqueueSnackbar]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    rateSelectedTech();
  }, [rateSelectedTech]);

  useEffect(() => {
    if (techViewState === TechView.Add) {
      getTechList();
    }
  }, [getTechList, techViewState]);

  useEffect(() => {
    setTechViewState(newTech ? TechView.Add :  TechView.Edit);
    setTechProcessState(newTech ? TechProcessState.Add :  TechProcessState.Show);
  }, [newTech]);

  useEffect(() => {
    setSelectedTechList(userTechList);
  }, [userTechList]);

  useEffect(() => {
    if (!isSuccessAnim) {
      setTimeout(() => setIsSuccess(isSuccessAnim), 200);
    }
  }, [isSuccessAnim]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccessAnim(isSuccess), 300);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!newTech) {
      setTechProcessState(TechProcessState.Show);
      setSelectedTech(undefined);
    }
  }, [tech, newTech]);

  useEffect(() => {
    selectTech();
  }, [selectedTech, selectTech]);

  return (
    <Fragment>
      <div 
        className={`bs-tech-tag${
          techProcessState && [TechProcessState.Select, TechProcessState.Rate].includes(techProcessState) ? ` bs-tech-tag--active` : ``
        }`}
        {...props}
      >
        {dialog}
          {(!onlyShow && techProcessState === TechProcessState.Rate) ?
            <Fragment>
              <span className="bs-tech-tag__tech">{selectedTech}</span>
              <ul className="bs-tech-tag__level-group">
                {Object.values(TechnologyLevel).map((item) => (
                  <li 
                    key={item}
                    className={`
                      bs-tech-tag__level
                      ${techViewState === TechView.Edit && item === (selectedLevel ? selectedLevel : level)  ? ` bs-tech-tag__level--selected` : ``}
                    `} 
                    onClick={() => setSelectedLevel(item)}
                  >
                    {Translation.hu.level[item]}
                  </li>
                ))}
                <li className="bs-tech-tag__level bs-tech-tag__level--icon">
                  <span onClick={deleteTech}><DeleteOutline/></span>
                </li>
              </ul>
            </Fragment>
          : (!onlyShow && techProcessState === TechProcessState.Select) ?
            <Fragment>
              <Autocomplete
                id="tech"
                className="bs-input bs-input--spread"
                data-label={``}
                options={previouslyAddedTechs.map(e => e.name).sort((a, b) => a.localeCompare(b))}
                groupBy={(option) => option[0].toUpperCase()}
                freeSolo
                autoHighlight={true}
                onChange={(_, value) => value && setSelectedTech(value)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField 
                    {...params}
                    inputRef={inputRef}
                    onKeyDown={e => {
                      if (['Enter', 'enter'].includes(e.code) && (e.target as HTMLInputElement).value) {
                        setSelectedTech((e.target as HTMLInputElement).value);
                      }
                    }}
                  />
                )}
              />
              <div
                className="bs-tech-tag__button"
                onClick={() => setSelectedTech((inputRef.current as HTMLInputElement).value)}>
                <CheckRounded />
              </div>
            </Fragment>
          : (!onlyShow && techProcessState === TechProcessState.Add) ? 
            <div className="bs-tech-tag__button" onClick={() => setTechProcessState(TechProcessState.Select)}>
              <AddRounded />
            </div>
          :
            <Fragment>
              <label className="bs-tech-tag__tech">
                {selectedTech ? selectedTech : tech.name}
              </label>
              <span className="bs-tech-tag__level" onClick={editTech}>
                {Translation.hu.level[selectedLevel ? selectedLevel : level || TechnologyLevel.Novice]}
              </span>
            </Fragment>
          }
          <div className={`bs-tech-tag__loading${isLoading ? ` bs-tech-tag__loading--active` : ``}`}>
            <div className="bs-tech-tag__loading__indicator"></div>
          </div>
          <div className={`bs-tech-tag__success${isSuccess ? ` bs-tech-tag__success--active` : ``}`}>
            <div className="bs-tech-tag__success__bg"></div>
            <Lottie
              className="bs-tech-tag__success__anim"
              animationData={successAnim}
              play={isSuccessAnim}
              onLoopComplete={() => setIsSuccessAnim(false)}
            />
          </div>
      </div>
    </Fragment>
  );
};

export default BsTechTag;
