import { ReactElement } from 'react';

import BsDatetimeTag from '../datetime-tag/datetime-tag';
import BsUploader, { BsUploaderType } from './../uploader/uploader';

export interface BsNotificationCardProps {
  text: string;
  icon?: ReactElement;
  uploaderType?: BsUploaderType;
  uploaderName: string;
  uploaderTime: string;
  explicitTime?: Date;
  hideUploader?: boolean;
  uploader: { email: string, image?: string };
  message?: string;
}

const BsNotificationCard = ({
  text,
  icon,
  uploaderType,
  uploader,
  uploaderName,
  uploaderTime,
  explicitTime,
  hideUploader,
  message,
}: BsNotificationCardProps): ReactElement => {
  return (
    <div className="bs-notification-card">
      <div className="bs-notification-card__box">
        <div className="bs-notification-card__content">
          <span
          className="bs-notification-card__text"
          dangerouslySetInnerHTML={{__html: text}}
        />

          {message &&
            <span className="bs-notification-card__message">„{message}”</span>
          }
        </div>

        {icon &&
          <i className="bs-notification-card__icon">
            {icon}
          </i>
        }
      </div>
      <div className="bs-notification-card__uploader">
        {!hideUploader ?
          <BsUploader
            type={uploaderType}
            user={uploader}
            name={uploaderName}
            time={uploaderTime}
            explicitTime={explicitTime}
          />
          :
          <BsDatetimeTag
            time={uploaderTime}
            explicitTime={explicitTime}
          />
        }
      </div>
    </div>
  );
};

export default BsNotificationCard;
