import { DetailedHTMLProps, HTMLAttributes, ReactElement, useEffect } from 'react';
import { ButtonBase, ButtonBaseProps } from "@mui/material";

import { ReactComponent as ThemeSwitchIconDark } from './../../../../assets/icon/theme-switch-dark.svg';
import { ReactComponent as ThemeSwitchIconLightL } from './../../../../assets/icon/theme-switch-light-l.svg';
import { ReactComponent as ThemeSwitchIconLightD } from './../../../../assets/icon/theme-switch-light-d.svg';
import { useTheme, ThemeState } from '../../../../contexts/theme.context';

export interface BsThemeSwitchProps extends ButtonBaseProps {
  className?: string;
}

const BsThemeSwitch = ({
  className,
  ...props
}: BsThemeSwitchProps): ReactElement => {

  const { themeState, setThemeState } = useTheme();

  const switchTheme = () => {
    setThemeState((themeState === ThemeState.LIGHT) ? ThemeState.DARK : ThemeState.LIGHT);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (themeState === ThemeState.DARK) {
        body.classList.remove('bs-theme--light');
        body.classList.add('bs-theme--dark');
      } else {
        body.classList.remove('bs-theme--dark');
        body.classList.add('bs-theme--light');
      }
    }
  }, [themeState]);

  return (
    <div
      className={`
        bs-theme-switch
        ${className ? ` ${className}` : ``}
        ${themeState ? ` bs-theme-switch--${themeState}` : ``}
      `}
      {...props as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>}
    >
      <ButtonBase type="button" disableRipple={true} className={`bs-theme-switch__theme-light`} onClick={switchTheme}>
        <ThemeSwitchIconLightL className="bs-theme-switch__icon-light-light" />
        <ThemeSwitchIconLightD className="bs-theme-switch__icon-light-dark" />
      </ButtonBase>
      <ButtonBase type="button" disableRipple={true} className={`bs-theme-switch__theme-dark`} onClick={switchTheme}>
        <ThemeSwitchIconDark className="bs-theme-switch__icon-dark" />
      </ButtonBase>
    </div>
  );
};

export default BsThemeSwitch;
