import {ChangeEvent, ReactElement, useEffect, useRef, useState} from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, FormControlLabel, Switch } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import {
  BadgeConfigDTO,
  BadgeConfigListDTO,
  ColleagueDTO,
  SendBadgeDto,
  BadgeType,
} from '@bb-sanctuary/common';

import { ReactComponent as CloseIcon } from './../../../../assets/icon/plus.svg';
import { BadgeService } from '../../../service/badgeService';
import { BsBadgeKit } from '../../../data/badge-kit';
import BsProfileTag from '../../ui/profile-tag/profile-tag';
import Translation from '../../../data/translation';
import BsButton from '../../ui/button/button';
import { useSendableBadges } from '../../../service/useSendableBadges';
import BsInput from "../../ui/input/input";
import SmsIcon from '@mui/icons-material/Sms';
import CheckIcon from '@mui/icons-material/Check';

export interface BsSendBadgeProps {
  open: boolean;
  selectedUser?: ColleagueDTO;
  onClose: (updated: boolean) => void;
}

export type BsSortedBadgeListProps = {
  [key in BadgeType]: Array<BadgeConfigDTO>;
}

const BsSendBadge = (props: BsSendBadgeProps): ReactElement => {
  const { onClose, selectedUser, open } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isBadgeLoading, setIsBadgeLoading] = useState(false);
  const [sortedBadgeList, setSortedBadgeList] = useState<BsSortedBadgeListProps|undefined|null>();
  const [selectedBadge, setSelectedBadge] = useState<BadgeConfigDTO>();
  const [selectWithPoints, setSelectWithPoints] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isSendMessageActive, setIsSendMessageActive] = useState<boolean>(false);
  const [isWarningActive, setIsWarningActive] = useState<boolean>(false);
  const {updateSendableBadges} = useSendableBadges();
  const messageInputRef = useRef<HTMLInputElement>();

  const isInLimit = (badges: Array<BadgeConfigDTO>): boolean => {
    return badges.every(b => !b.monthlyLimit) ||
      badges.some(b => b.sentInMonth === undefined) ||
      badges.some(b => b.sentInMonth !== undefined && b.monthlyLimit > b.sentInMonth);
  };

  const isBadgeTypeSelected = (badges: Array<BadgeConfigDTO>): boolean => {
    return badges.map(b => b.id).includes(selectedBadge?.id || 0);
  };

  const isWithPointOptions = (badges: Array<BadgeConfigDTO>): boolean => {
    return badges.some(b => !!b.points);
  };

  const isPointOnlyOption = (badges: Array<BadgeConfigDTO>): boolean => {
    return isWithPointOptions(badges) && badges.length === 1;
  };

  const remainingBadges = (badges: Array<BadgeConfigDTO>): number | undefined => {
    return badges.every(b => !b.monthlyLimit) ? undefined : badges.reduce((s, c) => s + (c.monthlyLimit - (c.sentInMonth || 0)), 0);
  };

  const handleClose = (updated?: boolean) => {
    setSelectedBadge(undefined);
    setIsWarningActive(false);
    setIsSendMessageActive(false);
    setMessage('');
    onClose(!!updated);
  };

  const selectBadge = (badges: Array<BadgeConfigDTO>) => {
    const selected = badges.find(b => selectWithPoints ? !!b.points : isPointOnlyOption(badges) ? true : !b.points);
    setSelectedBadge(selected);
  };

  const selectPoints = (checked: boolean) => {
    setSelectWithPoints(checked);
    if (sortedBadgeList) {
      const badgeOptions = Object.values(sortedBadgeList).find(b => b[0].type === selectedBadge?.type);
      setSelectedBadge(badgeOptions?.find(i => checked ? !!i.points : !i.points ));
    }
  };

  const sendBadge = async () => {
    if (selectedUser && selectedBadge) {
      const params: SendBadgeDto = {
        badgeConfigId: selectedBadge.id,
        to: selectedUser,
        message,
      };
      try {
        setIsBadgeLoading(true);
        const result = await BadgeService.sendBadgeToUser(params);
        if (result) {
          updateSendableBadges();
          enqueueSnackbar(Translation.hu.label.sendBadgeSuccess, {variant: 'success'});
          handleClose(true);
        }
      } catch {
        enqueueSnackbar(Translation.hu.label.sendBadgeFailed, {variant: 'error'});
      } finally {
        setIsBadgeLoading(false);
        setMessage('');
      }
    }
  };

  const onSendMessage = () => {
    if(isSendMessageActive) {
      if(!selectedBadge) {
        setIsWarningActive(true);
      } else {
        sendBadge();
      }
    } else {
      setIsSendMessageActive(true);
      setTimeout(() => messageInputRef.current?.focus(), 100);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      if ((Date.now() < new Date(2023,11,8).getTime() ||
      Date.now() > new Date(2024,0,1).getTime())) {
        BadgeService.getBadgesForUser(selectedUser.email)
          .then((result: BadgeConfigListDTO) => {
            const transformedBadgeList = result.badgeList.reduce((r, a) => {
              r[a.type] = r[a.type] || [];
              r[a.type].push(a);
              return r;
            }, Object.create(null));
            setSortedBadgeList(transformedBadgeList);
          });
      } else {
        setSortedBadgeList(null);
      }
    }
  }, [selectedUser]);

  return (
    <Dialog onClose={() => handleClose(false)} open={open} className="bs-dialog">
      <div className="bs-dialog__close" onClick={() => handleClose(false)}><CloseIcon /></div>
      <div className="bs-send-badge">
        <div className="bs-send-badge__wrap">
          <div className="bs-send-badge__header">
            {selectedUser &&
              <div className="bs-send-badge__user">
                <BsProfileTag user={selectedUser} name={selectedUser.firstName} />
              </div>
            }
            {sortedBadgeList !== null &&
              <h1 className={!isWarningActive ? "bs-send-badge__title" : "bs-send-badge__title bs-send-badge__title--warning"}>
                {Translation.hu.label.selectABadgeForUser}
              </h1>
            }
          </div>
          {sortedBadgeList === null &&
            <p className="bs-send-badge__user-warning">Lezártuk az idei Mesterek Csarnoka<br/>  versenyt, ezért már nem tudsz több badge-t küldeni.<br /> Gyere vissza január elsején!</p>
          }
          {sortedBadgeList &&
            <ul className="bs-send-badge__options">
              {Object.values(sortedBadgeList).map((badgeConfig, i) => (
                <li key={i} className={`bs-send-badge__options__item ${isWithPointOptions(badgeConfig) ? ` bs-send-badge__options__item--with-options` : ``}`}>
                  <div
                    {...(isInLimit(badgeConfig) && { onClick: () => selectBadge(badgeConfig) })}
                    className={`bs-send-badge__selector ${isBadgeTypeSelected(badgeConfig) ? ` bs-send-badge__selector--active` : ``} ${!isInLimit(badgeConfig) ? ` bs-send-badge__selector--disabled` : ``}`}
                  >
                    <i className="bs-send-badge__selector__icon">{BsBadgeKit[(badgeConfig[0] as BadgeConfigDTO).type].icon}</i>
                    <span className="bs-send-badge__selector__text">{Translation.hu.badge.type[badgeConfig[0].type as keyof typeof Translation.hu.badge.type]}</span>
                    <span className="bs-send-badge__selector__counter">{remainingBadges(badgeConfig)}</span>
                    <CheckRounded className="bs-send-badge__selector__check" />
                  </div>
                  {isWithPointOptions(badgeConfig) && isBadgeTypeSelected(badgeConfig) &&
                    <div className="bs-send-badge__points">
                      <FormControlLabel
                        labelPlacement="start"
                        label={<span>{`
                          ${badgeConfig.find((i) => !!i.points)?.points} 
                          ${Translation.hu.label.withPoints}${isPointOnlyOption(badgeConfig) ? `.` : `?`}
                        `}</span>}
                        control={
                          <Switch
                            className={`bs-switch${isPointOnlyOption(badgeConfig) ? ` not-visible` : ``}`}
                            disabled={isPointOnlyOption(badgeConfig)}
                            checked={isPointOnlyOption(badgeConfig) ? true : selectWithPoints}
                            onChange={(event) => selectPoints(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                      />
                    </div>
                  }
                </li>
              ))}
            </ul>
          }
        </div>
        {sortedBadgeList !== null &&
          <div className={`bs-send-badge__action${isBadgeLoading ? ` bs-helper--disabled` : ``}`}>
            <div className='bs-send-badge__action__buttons'>
              <BsButton className={!isSendMessageActive ?
                'bs-send-badge__action__message-button' :
                'bs-send-badge__action__message-button bs-send-badge__action__message-button--active'}
                        onClick={onSendMessage}>
                <i className='bs-send-badge__action__message-icon'>
                  {isSendMessageActive ? <CheckIcon /> :  <SmsIcon/>}
                </i>
              </BsButton>
              {isSendMessageActive ?
                <BsInput
                  placeholder={Translation.hu.label.sendMessage}
                  size={'medium'}
                  className='bs-send-badge__action__message-input'
                  inputRef={messageInputRef}
                  value={message}
                  inputProps={{maxLength: 160}}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setMessage(e.target.value)}
                />
                :
                <BsButton
                  className="bs-button--spread"
                  onClick={sendBadge}
                >
                  {Translation.hu.label.sendBadge}
                </BsButton>
              }
              {isSendMessageActive &&
                <p className='bs-send-badge__action__message-info'>
                  {message.length} / 160
                </p>
              }
            </div>
          </div>
        }
      </div>
    </Dialog>
  );
};

export default BsSendBadge;
