import { configureStore } from '@reduxjs/toolkit';
import { menuSlicer } from './contexts/menuSlice';
import { currentUserSlicer } from './contexts/currentUserSlicer';
import { headerElementSlicer } from './contexts/headerElementSlicer';
import { appSlicer, AppState } from './contexts/appSlicer';

const store = configureStore({
  reducer: {
    currentUser: currentUserSlicer,
    menu: menuSlicer,
    headerElement: headerElementSlicer,
    app: appSlicer,
  },
});

export type IStore = {
  app: AppState,
};

export default store;
