import { useDispatch } from 'react-redux';
import { setLoading } from '../../contexts/appSlicer';

let setLoadingFn: (isLoading: boolean) => void;

export const useLoading:() => (isLoading: boolean) => void = () => {
  const dispatch = useDispatch();
  if (!setLoadingFn) {
    setLoadingFn = (value: boolean) => {
      dispatch(setLoading(value));
    };
  }

  return setLoadingFn;
};
