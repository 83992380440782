import { ReactElement } from 'react';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';

const ReadonlyFormTemplate = ({ formFields, schema }: { formFields: ReactElement; schema: Object}) => {
  const { handleSubmit } = useFormApi();
  return (
    <form onSubmit={handleSubmit}>
      {(schema as any).title}
      {formFields}
      <FormSpy>
        {() => (
          <></>
        )}
      </FormSpy>
    </form>
  );
};

export default ReadonlyFormTemplate;
