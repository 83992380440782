import { Link } from 'react-router-dom';
import BsButton from '../../ui/button/button';

export const CvGeneratorHeader = () => {
  return (
    <>
      <p className="bs-cv-generator__title">Kérlek a szakmai képzettségedre és tudásodra vonatkozó pontos és hiteles
        adatokkal <em>angol</em> nyelven töltsd fel a rendszert, és fél évente,
        de legalább évente egyszer a teljesítményértékelésed előtt nézd meg, hogy naprakész-e és frissítsd, amennyiben
        szükséges.
        Az adatbázisból készült szakmai önéletrajzokon feltüntetett információk helyességéért és valóságtartalmáért a
        Blackbelt veled együtt felelősséget vállal.
        Önéletrajzodat partnereink és potenciális ügyfeleink számára továbbíthatjuk, adataidat az adatkezelési szabályzatunkban megjelölt módon
        kezeljük és védjük.

        <Link to={'/assets/CV_Completion_Guide.pdf'} target={'_blank'}>
          <BsButton color={'secondary'} size={'small'}>Kitöltési útmutató</BsButton>
        </Link>
      </p>
    </>
  );
};
