/*
  NOTE:
  This object sole purpose is to gather the static strings together
  and serve as a base for a possible future multi language support.
*/
import { MedalType } from '@bb-sanctuary/common';

export const Translation = {
  hu: {
    meta: {
      name: `Belső Szentély`,
      title: `BB Sanctuary`,
      description: `BB Sanctuary`,
    },
    article: {
      errorLoading: `Hiba a hírfolyam betöltésekor`,
      errorDelete: `Hiba a bejegyzés törlése közben`,
      errorDiscard: `Hiba a bejegyzés elvetése közben`,
      errorApprove: `Hiba a bejegyzés jóváhagyása közben`,
      contentTypeHubspot: `Hubspot hírlevél`,
      contentTypeCustom: `Egyedi bejegyzés`,
      legendNewArticle: `Új bejegyzés hozzáadása`,
      legendEditArticle: `Bejegyzés szerkesztése`,
      title: `Cím`,
      lead: `Előnézet (a kártyán)`,
      content: 'Tartalom (a cikk)',
      settings: 'Beállítások',
      leadForPost: `Megosztandó URL`,
      url: `URL`,
      tags: `Tagek`,
      cover: `Borító`,
      selectCover: `Válassz borítót`,
      selectCoverNotes: `A kiválasztott kép aránya ne legyen túl nyújtott, illetve érje el a 420x420-as minimális felbontást.`,
      missingLead: `A bevezető nincs kitöltve`,
      missingUrl: `A hubspot URL nincs kitöltve`,
      missingContent: `Az egyedi hírlevél nincs kitöltve`,
      createFromWeb: `Bejegyzés létrehozása külső forrásból`,
      createFromArticle: `Egyedi bejegyzés létrehozása`,
      missingCover: `Nem lett borító kiválasztva`,
      errorCoverSizeTooBig: `A borító mérete túl nagy`,
      errorCoverResTooLow: `A borító felbontása túl alacsony`,
      errorCoverRatioTooOff: `A borító képaránya túl nyújtott`,
    },
    login: {
      login: `Belépés`,
      loginError403: `Nincs jogosultságod az oldalhoz`,
    },
    common: {
      next: `Következő`,
      cancel: `Mégsem`,
      send: `Küldés`,
      search: `Keresés`,
      loading: `Betöltés`,
      filter: `Szűrés`,
      filters: `Szűrők`,
      delete: `Törlés`,
      edit: `Szerkesztés`,
      close: `Bezárás`,
      loadMore: `Tovább`,
      piece: `db`,
      back: `Vissza`,
      backToTop: `Vissza a tetejére`,
      backToList: `Vissza a listához`,
      backToColleagues: `Vissza a munkatársak oldalhoz`,
      noResult: `Nincs találat`,
      step: `lépés`,
      confirm: 'Megerősítés',
      ok: 'OK',
      yes: 'Igen',
      no: 'Nem',
      help: `Súgó`,
    },
    label: {
      copy: 'Másolás',
      uploader: `Feltöltő`,
      uploadDate: `Feltöltés ideje`,
      category: `Kategória`,
      newPost: `Bejegyzés létrehozása`,
      editPost: `Bejegyzés módosítása`,
      pinPost: `Bejegyzés kiemelése (kiválasztott nap éjfélig)`,
      admin: `Admin`,
      roles: `Jogkörök`,
      addRole: `Jogkör hozzáadása`,
      tech: `Technológiák`,
      addTech: `Új technológia`,
      projects: `Projektek`,
      addProject: `Projekt hozzáadása`,
      faq: `Általános tudnivaló bejegyzések`,
      faqCategory: `Általános tudnivaló kategóriák`,
      bbFaq: `BlackBeltes tudnivaló bejegyzések`,
      bbFaqCategory: `BlackBeltes tudnivaló kategóriák`,
      addFaq: `Új hozzáadása`,
      searchPosition: `Pozíció`,
      searchUnit: `Egység`,
      searchProjectMember: `Projekttag`,
      searchTeam: `Csapat`,
      searchTech: `Technológia`,
      discardDraft: `Visszaküldés szerkesztésre`,
      editArticle: `Bejegyzés szerkesztése`,
      acceptDraft: `Jóváhagyás`,
      thisIsDraft: `Ez a bejegyzés még nem lett jóváhagyva, csak te láthatod.`,
      itHasNotBeenApproved: `A bejegyzésedet még nem hagyták jóvá.`,
      thisIsAPendingArticle: `Ez egy jóváhagyásra váró bejegyzés.`,
      itHasBeenRejected: `A bejegyzésedet nem fogadták el, kérjük szerkeszd át.`,
      thisIsARejectedArticle: `Ezt a bejegyzést nem hagyták jóvá.`,
      confirmAction: `Biztosan végrehajtod a műveletet?`,
      selectABadgeForUser: `Válassz ki egy badget:`,
      sendBadge: `Badge küldése`,
      sendMessage: `Üzenet a badge mellé`,
      withPoints: `ponttal együtt`,
      sendBadgeSuccess: `A badge sikeresen elküldve!`,
      sendBadgeFailed: `Nem tudtuk elküldeni a badge-t!`,
      getToKnowAndSendBadges: `Ismerd meg kollégáidat és küldj nekik badgeket!`,
      jumpToColleagues: `Irány a munkatársaim oldal!`,
      hideMessage: `Üzenet elrejtése`,
      myNotifications: `Értesítéseim`,
      downloadCv: `CV Letöltése`,
      noNotificationsYet: `Nincs értesítésed az elmúlt időszakból`,
      noBadgesYet: (name: string) => `${name} az idén még nem kapott badget, változtass ezen!`,
      noOwnBadgesYet: `Az idén még nem kaptál badget.`,
      noTechYet: (name: string) => `${name} még nem adta meg, milyen készségeket használ.`,
      noOwnTechYet: `Még nem adtál hozzá általad használt készséget.`,
      noProjectYet: (name: string) => `${name} még nem lett hozzáadva egy projekthez sem.`,
      noOwnProjectYet: `Még nem lettél hozzáadva egy projekthez sem.`,
      noProjectsFound: `Nem találtunk egy projektet sem :(`,
      noColleaguesFound: `Nem találtunk egy kollégát sem :(`,
      userDoesNotUseSelectedChat: (name: string) => `${name} nem használja ezt a szolgáltatást.`,
      editChat: `Chat szerkesztése`,
      chooseChatService: `Válassz egy chat szolgáltatást`,
      setChatLink: `Add meg az elérhetőségedet (URL)`,
      chatPreconfigureGoogle: `Blackbelt Google chat`,
      chatPreconfigureSlack: `Blackbelt Slack`,
      chatPreconfigureTeams: `Teams-en elérhető vagyok`,
      chatLinkPlaceholder: `https://...`,
      chatLinkPrefillSlackUrl: `Blackbelt Slack URL beállítása`,
      chatLinkPrefillTeamsUrl: `Klikkelj ide ha MS Teams-en elérhető vagy`,
      openChatLinkConfirmTitle: 'Külső elérhetőség megnyitása',
      openChatLinkConfirmContent: 'Olyan elérhetőséget találtunk, amit nem tudtunk ellenőrizni:',
      newsAboutBlackBelt: `BlackBelt Háza Tája`,
      mergeTech: `Összevonás`,
      baseTechLabel: `Megszűnő technológia:`,
      newTechLabel: `Átalakul erre a technológiára:`,
      deleteTech: `Törlés`,
      indulas: 'Indulás!',
      url: `URL`,
      confirmDeleteTech: `Biztosan törlöd?`,
      editorModeContentLost: `Biztosan módot váltasz? A mentés nélküli váltással elvesznek az eddig hozzáadott változtatásaid.`,
    },
    cta: {
      applyForAPosition: `Jelentkezz pozícióra`,
      knowMore: `Tudj meg többet`,
      innovate: `Innoválj`,
      cv: 'Generálj CV-t',
      game: `Játssz!`,
    },
    placeholder: {
      searchFeed: `Keress a hírfolyamban`,
      searchColleagues: `Keress a névre, helyre, elérhetőségre`,
      searchFaq: `Keress a tudnivalók között`,
      searchProjects: `Keress a projektek között`,
    },
    level: {
      Novice: `Novice`,
      Confident: `Confident`,
      Pro: `Pro`,
      Master: `Master`,
    },
    badge: {
      type: {
        ThankYou: `Thank you!`,
        Teamwork: `Teamwork`,
        GreatJob: `Great job!`,
        Inspiring: `Inspiring`,
        GreatInterview: `Great interview`,
        MeetupMaster: `Meetup master`,
        ExceptionalPerformance: `Exceptional performance`,
        HighFive: `High five`,
      },
      history: {
        youSentABadge: `Jelvényt küldtél: `,
        youGotABadge: `Új jelvényt kaptál: `,
        youGotABadgeFromMgmt: `Új jelvényt kaptál a managementtől: `,
        youGotABadgeFromTL: `Új jelvényt kaptál egy team leadertől: `,
        youGotABadgeFromCEO: `Új jelvényt kaptál a CEO-tól: `,
        youGotABadgeFromHR: `Új jelvényt kaptál a HR-től: `,
      },
      sender: `Küldte: `,
      senders: {
        colleague: `Kolléga`,
        recruitment: `a recruitment`,
        TL: `csapat vezető`,
        HR: `a HR csapat`,
        MGMT: `a management`,
        CEO: `a CEO`,
      },
    },
    medal: {
      type: {
        [MedalType.HelpfulHero]: 'Helpful hero',
        [MedalType.CommunityMaster]: 'Community master',
        [MedalType.ExcellentPerformer]: 'Excellent performer',
        [MedalType.MasterOfInterviews]: 'Master of interviews',
        [MedalType.TopInfluencer]: 'Top influencer',
        [MedalType.MostSociableNinja]: 'Most sociable ninja',
      },
    },
    tag: {
      everything: `Minden`,
      newsletter: `Hírlevél`,
      post: `Szakmai`,
      blackbeltHazaTaja: `Blackbelt Háza Tája`,
    },
    form: {
      cancel: `Mégsem`,
      save: `Mentés`,
      error: `Küldés közben hiba történt!`,
      success: `Sikeres mentés!`,
      successDelete: `Sikeres törlés!`,
    },
    warning: {
      techAlreadySelected: `Ezt már korábban hozzáadtad!`,
    },
    error: {
      chatLinkMissing: `Nem adtad meg a chat URL-t!`,
      orgChartNotAvailableInSafari: `Sajnos a Safari nem támogatja az SVG-be ágyazott HTML elemeket, így itt nem tudjuk megjeleníteni az org-chartot...`,
    },
    menu: {
      faqCategory: `Ált. tudnivaló kategóriák`,
      bbFaqCategory: `Bb tudnivaló kategóriák`,
      users: `Munkatársak`,
      admin: `Admin`,
      faq: `Általános tudnivalók`,
      masterOfTechnology: `Master of Technology`,
      colleagues: `Munkatársak`,
      news: `Hírfolyam`,
      projects: `Projektek`,
      cvAdmin: `CV Admin`,
      exitAdmin: `Kilépés`,
    },
    header: {},
    footer: {},
    social: {
      linkedin: `LinkedIn`,
      twitter: `Twitter`,
      facebook: `Facebook`,
    },
    faq: {
      topic: `Melyik témakör részletei érdekelnek?`,
      subtopic: `Ezen belül melyik téma érdekel?`,
      noResult: `Nincs találat :(`,
      selectTopic: `Válassz ki egy témakört`,
      selectSubTopic: `Válassz ki egy témát`,
    },
    innovation: {
      headerTitle: `Innovation`,
      headerSubtitle: `Innovációs szervezet és folyamat`,
      menu1Roles: `Innovációs szervezet szerepkörök`,
      menu2Structure: `Innovációs szervezet felépítése`,
      menu3Flow: `Innovációs folyamat`,
      showYourIdea: `Mutasd be saját ötleted`,
    },
    profile: {
      myProfile: `Saját profilom`,
      name: `Név`,
      position: `Pozíció`,
      email: `E-mail`,
      cvId: `CV azonosító`,
      phone: `Telefon`,
      chat: `Chat`,
      link: `Link`,
      service: `Szolgáltató`,
      sendBadge: `Badge küldése`,
      pointTransaction: `Pontok könyvelése`,
      pointTransactionSuccess: `Pontok könyvelése sikeres!`,
      pointTransactionError: `Pontok könyvelése nem sikerült: `,
      totalPoints: `Összes pont`,
      addPoint: `Pontok hozzáadása`,
      deductPoint: `Pontok levonása`,
      newPoints: `Új totál`,
      calculate: `Könyvel`,
      medals: `Medálok`,
      badges: `Jelvények`,
      sendableBadges: `Küldhető jelvények`,
      hallOfMastersPoints: `Mesterek csarnoka pontok száma`,
      add: `Jóváírás`,
      deduct: `Terhelés`,
      point: `pont`,
      recently: `Mostanában kapott`,
      thisYear: `Idei összes`,
      message: `Üzenet`,
      techs: `Készségek`,
      projects: `Projektek`,
      inactive: `Inaktív`,
    },
    game: {
      selectLevel: `Válassz nehézségi szintet`,
      title: `Ki a képen látható kollégád?`,
      restart: `Játszom még`,
      points: `pont`,
    },
    admin: {
      response: {
        done: 'Kész',
        err: 'Hiba történt',
      },
      user: {
        title: `Munkatársak kezelése`,
        handleRoles: `Jogkörök kezelése`,
        manageUsers: `Munkatársak kezelése`,
      },
      users: {
        title: `Felhasználók`,
        updateFromHubspot: `Blackbeltesek frissítése hubspotból`,
        showHistory: `Elozo frissites riportja`,
        clearUserPhotos: `Fotók törlése cache-ből`,
        syncGooglePhone: `Telefonszámok google szinkronizálása`,
      },
      news: {
        title: `Hírek kezelése`,
        reindex: `Hírek újraindexelése`,
      },
      faq: {
        title: `Általános tudnivalók`,
        entries: `Bejegyzések kezelése`,
        categories: `Kategóriák kezelése`,
      },
      tech: {
        title: `Technológiák kezelése`,
        techList: `Technológia lista`,
      },
      projects: {
        title: `Projektek kezelése`,
        projectList: `Projekt lista`,
      },
      medals: {
        medalList: `Medál lista`,
        medalOwners: `Medál tulajdonosok`,
      },
      jobs: {
        title: 'Időzített feladatok',
        runForce: 'Kényszerített futtatás',
        list: 'Előzmények',
      },
      cv: {
        list: 'A kolléga mentett CV-ei',
        download: 'CV Letöltése',
        active: 'aktív',
        inactive: 'archivált',
      },
    },
    cv: {
      languageLevel:{
        basic:'Basic',
        intermediate: 'Intermediate',
        upperIntermediate:'Upper intermerdiate',
        fluent: 'Fluent',
        nativ: 'Native',
      },
      accordionTitle: {
        shortSummary: 'Short summary (min 100 character)',
        addProfBg: 'Add experience',
        addEducation: 'Add education',
        professionalBackgrounds: 'Experience (min 1)',
        domainKnowledge: 'Domain experience',
        language: 'Language (min 1)',
        developmentMethodology: 'Development methodologies',
        education: 'Education (min 1)',
        competence: 'Competencies (min 1)',
        course: 'Courses',
        projectSummary: 'Projects (min 1)',
        addCourse: 'Add course',
        max: (current: number, max?: number) => max ? `${current}/${max}` : current,
      },
      form: {
        header: {
          startWithBlackbelt: 'Start with your experience in BlackBelt',
          startDate: 'Start date',
          endDate: 'End date',
          description: 'Description',
          company: 'Company',
          PO: 'Project Owner',
          projectName: 'Project',
          companytitle: 'Position',
          competenceLevel: 'Skill level:',
          backgroundDescription: 'Describe your job',
          backgroundDescriptionItem: 'Job description',
          educationalInstitutionName: 'Education institution',
          educationType: 'Type',
          faculty: 'Faculty',
          courseName: 'Course',
          courseType: 'Course type',
          year: 'Year',
          ITSecNumber: 'IT Security Certificate',
          languageLevel: 'Language level',
          language: 'Language',
        },
        add: 'Add',
        addCourse: 'Add course',
        updateCourse: 'Update course',
        addProject: 'Add project',
        updateProject: 'Update project',
        addEdu: 'Add education',
        updateEdu: 'Update education',
        addBacgroundDescription: 'Add job description',
        updateExperience: 'Update experience',
        addExperience: 'Add experience',
        addDomain: 'Add domain experience',
        save: 'Mentés',
        yes: 'Igen',
        maxWarning: 'You have reached the maximum number of items that can be added',
        cancel: 'Cancel',
        ok: 'Ok',
        notCompletedForm: 'You need to fill all the required fields. You have missed: ',
        notValidForm: 'You need to fill all the required fields',
        eduDuplicateError: 'This education is already added',
        eduOverlapError: 'The BSc and MSc faculty cannot overlap',
      },
      download: 'CV letöltés',
      generate: 'Csoportos CV generálás',
      template: {
        header: 'CV sablon',
        upload: 'Sablon cseréje',
        uploadSuccess: 'Sablon lecserélve!',
        uploadFailed: 'Hiba történt a sablon cseréje közben',
      },
      apiResponse: {
        successLoadCv: 'Sikeres CV letöltés',
        failedLoadCv: 'Sikertelen CV letöltés',
        successCvSave: 'Sikeres CV mentés',
        failedCvSave: 'Sikertelen CV mentés',
      },
      project: {
        addProject: 'Add project',
        extendSummary: 'Specify your responsibilities and your role in this project',
        addSummary: 'Project description',
        missingYourProject: `Don't see your BlackBelt project? Contact <a href="mailto:hr@blackbelt.hu">HR</a>!`,
        sanctuaryProjectName: 'Name',
        sanctuaryProjectOwner: 'Project Owner',
        sanctuaryProjectDate: 'From - To',
        selectBBProject: 'Select a BlackBelt project',
        createProject: 'Create your own project',
        startDate: 'Start working date',
        endDate: 'End working date',
        cannotEditProject: `Project cannot be changed`,
        headerCreateProject: 'Create project',
        headerEditProject: 'Edit project',
      },
      profbg: {
        headerCreate: 'Add experience',
        headerEdit: 'Update experience',
      },
      edu: {
        headerCreate: 'Add education',
        headerEdit: 'Update education',
      },
      course: {
        headerCreate: 'Add course',
        headerEdit: 'Update course',
      },
    },
  },
};

export default Translation;
