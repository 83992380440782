import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ArticleFilterDTO, ArticleListPageDTO, ArticleType, BadgeHistoryDTO, NewsPageItem } from '@bb-sanctuary/common';
import { BadgeService } from '../shared/service/badgeService';
import { ArticleService } from '../shared/service/articleService';
import { IStore } from '../store';

const stateName = 'app';

export type LoadingStatus = 'idle' | 'loading' | 'fulfilled' | 'error';

export interface AppState {
  isLoading: boolean;
  notifications: {
    list: Array<BadgeHistoryDTO>,
    status: LoadingStatus,
  },
  news: {
    list: Array<NewsPageItem>,
    tagList?: Array<string>,
    status: LoadingStatus,
    totalSize?: number,
  },
  newsPage: {
    scrollPosition?: number,
    searchKeyword?: string,
    activeTagList: Array<string>,
    offset: number,
    articleTypeFilter?: ArticleType,
  },
}

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async (email: string) => {
  return await BadgeService.getBadgeHistoryForUser(email);
});

export const fetchNews = createAsyncThunk<[ArticleListPageDTO, undefined | string[]], ArticleFilterDTO>('news/fetchNews',
  async (params: ArticleFilterDTO,
         thunkAPI) => {
    const state = thunkAPI.getState() as IStore;
    if (!state.app.news.tagList) {
      const res = await Promise.all([ArticleService.getNews(params), ArticleService.getTagList()]);
      return [res[0].data, res[1].data];
    }
    return [(await ArticleService.getNews(params)).data, undefined];
  });

export const appSlice = createSlice<AppState, SliceCaseReducers<AppState>, string>({
  name: stateName,
  initialState: {
    isLoading: false,
    notifications: {
      list: [],
      status: 'idle',
    },
    news: {
      list: [],
      tagList: undefined,
      totalSize: undefined,
      status: 'idle',
    },
    newsPage: {
      scrollPosition: undefined,
      activeTagList: [],
      offset: 0,
      searchKeyword: undefined,
      articleTypeFilter: undefined,
    },
  },
  reducers: {
    setLoading(state, value: PayloadAction<boolean>) {
      state.isLoading = value.payload;
    },
    setNewsPageScrollPosition(state, value: PayloadAction<number|undefined>) {
      state.newsPage.scrollPosition = value.payload;
    },
    setNewsPageActiveTagList(state, value: PayloadAction<string[]>) {
      state.newsPage.activeTagList = value.payload;
    },
    setNewsPagePageIndex(state, value: PayloadAction<number>) {
      state.newsPage.offset = value.payload;
    },
    setNewsPageSearchKeyword(state, value: PayloadAction<string | undefined>) {
      state.newsPage.searchKeyword = value.payload;
    },
    setNewsPageArticleTypeFilter(state, value: PayloadAction<ArticleType | undefined>) {
      state.newsPage.articleTypeFilter = value.payload;
    },
    setNewsDefaults(state) {
      state.news.list = [];
      state.news.tagList = undefined;
      state.news.totalSize = undefined;
      state.news.status = 'idle';
    },
    setNewsPageDefaults(state) {
      state.newsPage.offset = 0;
      state.newsPage.searchKeyword = '';
      state.newsPage.scrollPosition = undefined;
      state.newsPage.activeTagList = [];
      state.newsPage.articleTypeFilter = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.notifications.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications.status = 'fulfilled';
        state.notifications.list = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.notifications.status = 'error';
      });

    builder
      .addCase(fetchNews.pending, (state) => {
        state.news.status = 'loading';
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.news.status = 'fulfilled';
        const newsPageFetchData = action.payload;
        // set news data
        const newsPageData = newsPageFetchData[0];
        if (action.meta.arg.offset === 0) {
          state.news.list = newsPageData.articleList.resultList;
        } else {
          state.news.list.push(...newsPageData.articleList.resultList);
        }

        // set total count
        if (newsPageData.articleList.totalCount) {
          state.news.totalSize = newsPageData.articleList.totalCount;
        }

        // set tags
        if (newsPageFetchData[1]) {
          state.news.tagList = newsPageFetchData[1];
        }
      })
      .addCase(fetchNews.rejected, (state) => {
        state.news.status = 'error';
      });
  },
});

export const {
  setLoading,
  setNewsPageScrollPosition,
  setNewsPageActiveTagList,
  setNewsPagePageIndex,
  setNewsPageSearchKeyword,
  setNewsPageArticleTypeFilter,
  setNewsDefaults,
  setNewsPageDefaults,
} = appSlice.actions;

export const selectIsLoading = (state: { [stateName]: AppState }) => state[stateName].isLoading;
export const selectNotifications = (state: { [stateName]: AppState }) => state[stateName].notifications.list;

export const selectNewsPageScrollPosition = (state: { [stateName]: AppState }) => state[stateName].newsPage.scrollPosition;
export const selectNewsPageOffset = (state: { [stateName]: AppState }) => state[stateName].newsPage.offset;
export const selectNewsPageActiveTagList = (state: { [stateName]: AppState }) => state[stateName].newsPage.activeTagList;
export const selectNewsPageSearchKeyword = (state: { [stateName]: AppState }) => state[stateName].newsPage.searchKeyword;
export const selectNewsPageArticleTypeFilter = (state: { [stateName]: AppState }) => state[stateName].newsPage.articleTypeFilter;

export const selectNewsList = (state: { [stateName]: AppState }) => state[stateName].news.list;
export const selectNewsTotalSize = (state: { [stateName]: AppState }) => state[stateName].news.totalSize;
export const selectTagList = (state: { [stateName]: AppState }) => state[stateName].news.tagList;

export const appSlicer = appSlice.reducer;
