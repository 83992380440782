import { BadgeType, BadgeUserGroup } from '../badge';
import { PageableResponse } from '../search';
import { MedalType } from '../badge/medalType';
import { UserTechnologyDTO } from '../technology';
import { UserChat } from '../account';
import { ProjectDetailDTO } from '../project';

export enum SexEnum {
  Male = 'Male',
  Female = 'Female',
}

export interface ColleagueBadgeDTO {
  receivedFromGroup: BadgeUserGroup;
  count: number;
  type: BadgeType;
  points: number;
}

export interface BaseColleagueDTO {
  email: string;
  image?: string;
  sex?: SexEnum;
}

export interface ColleagueDTO extends BaseColleagueDTO {
  slug: string;
  firstName: string;
  lastName: string;
  phone: string;
  positionTitle: string;
  unit: string;
  team: string;
  highlightedTechnology?: string;
  medals?: Array<MedalType>;
}

export interface ColleagueBadgeGroups {
  recently: Array<ColleagueBadgeDTO>;
  thisYear: Array<ColleagueBadgeDTO>;
}

export interface ColleagueDetailsDTO extends ColleagueDTO {
  badges?: ColleagueBadgeGroups;
  order?: number;
  usablePoints?: number;
  allPoints: number;
  technologies?: Array<UserTechnologyDTO>;
  chat?: UserChat;
  projects?: Array<ProjectDetailDTO>;
  cvCode?: string;
}

export interface ColleagueTeamListDTO {
  team: string;
  positionList: Array<string>;
}

export interface ColleagueUnitListDTO {
  unit: string;
  teams: Array<ColleagueTeamListDTO>;
}

export interface ColleagueUnitTeamPositionListDTO {
  list: ColleagueUnitListDTO[];
  unitList: Array<string>;
  teamList: Array<string>;
  positionList: Array<string>;
}

export interface ColleagueListPageDTO {
  colleagueList: PageableResponse<ColleagueDTO>;
}

export interface ColleagueDetailsListPageDTO {
  colleagueList: PageableResponse<ColleagueDetailsDTO>;
}
