import { ReactElement } from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icon/plus.svg';
import { Dialog } from '@mui/material';
import BsButton from '../../ui/button/button';
import Translation from '../../../data/translation';

export interface BsConfirmProps {
  open: boolean;
  displayConfirmOnly?: boolean;
  title?: string;
  confirmation: string;
  subContent?: string;
  onClose: (result?: boolean) => void;
}

const BsConfirm = ({
  onClose,
  open,
  title,
  confirmation,
  subContent,
  displayConfirmOnly,
}: BsConfirmProps): ReactElement => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="bs-dialog">
      <div className="bs-dialog__close" onClick={handleClose}><CloseIcon /></div>
      <div className="bs-dialog__content">
        <h3>{title || Translation.hu.common.confirm}</h3>
        <p>{confirmation}</p>
        {subContent && <p>{subContent}</p>}
      </div>
      <div className="bs-dialog__action">
        <span className="bs-dialog__action__spacer"></span>
        {displayConfirmOnly &&
          <BsButton size="small" color="primary" onClick={() => onClose(true)}>{Translation.hu.common.ok}</BsButton>
        }
        {!displayConfirmOnly &&
          <>
            <BsButton size="small" color="primary" onClick={() => onClose(true)}>{Translation.hu.common.yes}</BsButton>
            <BsButton size="small" color="secondary" onClick={() => onClose(false)}>{Translation.hu.common.no}</BsButton>
          </>
        }
      </div>
    </Dialog>
  );
};

export default BsConfirm;
