import { ReactElement } from 'react';
import { ReactComponent as BbIcon } from './../../../../assets/svg/blackbelt-logo-icon.svg';

export interface BsLogoProps {
  onClick?: () => void;
}

const BsLogo = ({
  onClick,
}: BsLogoProps): ReactElement => {
  return (
    <div className={`bs-logo`} {...(onClick ? {onClick} : {})}>
      <BbIcon />
    </div>
  );
};

export default BsLogo;
