import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Tooltip} from '@mui/material';
import {BaseColleagueDTO, getUserImage} from "@bb-sanctuary/common";

import {BsRoutes} from "../../../data/bsRoutes";

export interface BsProfileTagProps {
  name: string;
  size?: `big` | `normal` | `small`;
  user?: BaseColleagueDTO;
  hideName?: boolean;
  email?: string;
  onClick?: () => void;
}

const BsProfileTag = ({
  name,
  size = `normal`,
  user,
  hideName,
  email,
  onClick,
  ...props
}: BsProfileTagProps) => {
  const [image, setImage] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    setImage(getUserImage(user));
  }, [user]);

  const onNavigateToUser = (user: any) => {
    if (onClick) {
      onClick();
    }
    navigate(`${BsRoutes.kollegak}/${user}`);
  };

  return (
    <div
      className={`bs-profile-tag bs-profile-tag--${size}`}
      onClick={() => email && onNavigateToUser(email)}
      {...props}
    >
      <div className="bs-profile-tag__avatar">
        {image &&
          <Tooltip title={name}>
            <img
              className={`bs-profile-tag__image${image.includes(`default-avatar`) ? ' bs-profile-tag__image--placeholder bs-fallback-avatar' : ''}`} 
              src={image}
              alt={name}
            />
          </Tooltip>
        }
      </div>
      {!hideName &&
        <div className="bs-profile-tag__info">
          <div className="bs-profile-tag__name">
            {name}
          </div>
        </div>
      }
    </div>
  );
};

export default BsProfileTag;
