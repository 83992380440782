import React, {ReactElement, useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, CircularProgress } from '@mui/material';

export interface Column {
  header: string;
  template: (item: any) => ReactElement;
  field?: string;
}

export interface SortedByField {
  field: string;
  direction: `ASC` | `DESC`;
}

export interface GenericEntityListingProps<T> {
  title: string;
  addButtonLabel?: string;
  getListFn: () => Promise<any>;
  createUrl: string;
  editUrl: string;
  columns: Array<Column>;
  isEditable?: boolean;
  deleteEntity?: (item: T) => Promise<any>;
}

// TODO: Specify `any` types

/**
 * @param title
 * @param addButtonLabel
 * @param getListFn
 * @param createUrl
 * @param editUrl
 * @param columns [{header: string, template: (item) => JSX}, field?: string]
 * @param isEditable
 * @param {function(string): Promise<any>} [deleteEntity] if specified the entities will be deletable, calling this function
 * @returns {JSX.Element}
 * @constructor
 */
const GenericEntityListing = <T extends unknown>({
  title,
  addButtonLabel,
  getListFn,
  createUrl,
  editUrl,
  columns,
  isEditable = true,
  deleteEntity,
}: GenericEntityListingProps<T>): ReactElement => {
  const itemsPerPage = 20;
  const navigate = useNavigate();
  const [entityList, setEntityList] = useState<any | undefined>();
  const [displayedList, setDisplayedList] = useState<any | undefined>();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sortedByField, setSortedByField] = useState<SortedByField | undefined>();

  const loadEntityList = async () => await getListFn();

  function reloadEntityList() {
    loadEntityList()
      .then(entityList => {
        const list = entityList;
        setEntityList(list);
        setDisplayedList(list);
        setPage(0);
        setPageCount(Math.ceil(list.length / itemsPerPage));
      })
      .catch();
  }

  function editEntity(entity: any) {
    navigate(editUrl.replace(':id', entity.id));
  }

  function onDeleteEntity(entity: any) {
    if (deleteEntity) {
      deleteEntity(entity)
        .then(reloadEntityList);
    }
  }

  // function goNextPage() {
  //   setPage(page + 1);
  // }
  // function goPrevPage() {
  //   setPage(page - 1);
  // }

  function getPagination() {
    const pages = [];
    for(let i = 0; i < pageCount; i++) {
      pages.push({
        ind: i,
        active: (i === page),
      });
    }

    return (<div className="paginator">
      {pages.map((p, i) => {
        return <span className={"pager" + (p.active ? ' pager--active' : '')}
              key={i}
              onClick={() => setPage(i)}>
          <span>{(p.ind + 1)}</span>
        </span>;
      })}
    </div>);
  }

  function sortBy(column: any) {
    const field = column.field;
    if (field) {
      const direction = sortedByField ? (sortedByField.direction === 'ASC' ? 'DESC': 'ASC') : 'DESC';
      setSortedByField({field, direction});
      if (entityList) {
        const sortedList = entityList
          .sort((l: any, r: any) => l[field] > r[field] ? -1 : 1);
        if (direction === 'DESC') {
          sortedList.reverse();
        }
        setDisplayedList(sortedList);
        setPage(0);
      }
    }
  }

  function search(pQuery: any) {
    const query = pQuery.toUpperCase();
    if (entityList) {
      if (query.trim()) {
        const resultList = entityList
          .filter((item: any) => columns.some(col => col.field && item[col.field] && item[col.field].toUpperCase().includes(query)));
        setDisplayedList(resultList);
      } else {
        setDisplayedList(entityList);
      }
      setPage(0);
    }
  }

  useEffect(() => {
    reloadEntityList();
  }, [getListFn]);

  return (
    <div>
      <div className="box">
        <div className="columns is-vcentered">
          <div className="column">
            <h2 className="is-size-3">{title}</h2>
          </div>
          <div className="column is-flex is-justify-content-flex-end">
            <Link to={createUrl} className="button is-primary">{addButtonLabel}</Link>
          </div>
        </div>
      </div>

      <div className="box">
        {!displayedList && <CircularProgress />}
        {displayedList &&
          <>
            <div className="mb-5">
              <input className="input" type="text" placeholder="Search" onChange={(e) => search(e.target.value)} />
            </div>

            <table className="table is-hoverable is-fullwidth">
              <thead>
              <tr>
                {columns && columns.map((column, colIndex) =>
                  <th key={colIndex}>
                    <span onClick={() => sortBy(column)}>{column.header}</span>
                    {sortedByField && sortedByField.field === column.field && sortedByField.direction === 'ASC' && <i className="fas fa-sort-amount-up" />}
                    {sortedByField && sortedByField.field === column.field && sortedByField.direction === 'DESC' && <i className="fas fa-sort-amount-down" />}
                  </th>)
                }
                {isEditable && <th/>}
              </tr>
              </thead>
              <tbody>
              {(displayedList as any).map((entityItem: any, index: any) => (
                <React.Fragment key={index}>
                  {(index >= (page * itemsPerPage)) && (index < ((page + 1) * itemsPerPage)) &&
                    <tr>
                      {columns && columns.map((column, colIndex) => <td key={colIndex}>{column.template(entityItem)}</td>)}

                      {isEditable &&
                      <td>
                        <div className="is-flex is-justify-content-flex-end">
                          {deleteEntity && <Button color="error" onClick={() => onDeleteEntity(entityItem)}>Delete</Button>}
                          <Button color="primary" onClick={() => editEntity(entityItem)}>Edit</Button>
                        </div>
                      </td>
                      }
                    </tr>
                  }
                </React.Fragment>
              ))}
              </tbody>
            </table>

            <div className="is-flex is-justify-content-space-between">
              {getPagination()}
              <div className="has-text-grey-dark">
                {entityList &&
                  <React.Fragment>
                    {displayedList.length}
                    {entityList.length === displayedList.length && <> items</>}
                    {entityList.length !== displayedList.length && <> items displayed</>}
                  </React.Fragment>
                }
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default GenericEntityListing;
