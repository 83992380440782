import React, {ReactElement, useEffect, useState, Suspense} from 'react';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { bb3ComponentMapper } from "../../utils/formUtils";
import ReadonlyFormTemplate from "./form/ReadonlyFormTemplate";
import { Schema } from '@data-driven-forms/react-form-renderer';

const FormRenderer = React.lazy(() => import("@data-driven-forms/react-form-renderer/form-renderer"));
const FormTemplate = React.lazy(() => import("@data-driven-forms/mui-component-mapper/form-template"));

export interface GenericEntityEditorProps {
  children?: ReactElement;
  entityLabel?: string;
  entityLabelFn?: (item: any) => string;
  saveFn: (item: any) => Promise<any>;
  loadEntityFn?: () => Promise<any>;
  listingUrl: string;
  id?: string;
  formSchema: object;
  hideCancel?: boolean;
  hideHeaderBox?: boolean;
  navigateToListingWhenSaved?: boolean;
  onSaved?: (item: any) => void;
  onCancelled?: () => void;
  editUrl?: string;
  readonly?: boolean;
  warningElement?: ReactElement;
  newEntity?: () => object;
}

// TODO: Specify `any` types

/**
 * @param {string} entityLabel Used for displaying what is beeing edited
 * @param {function(object): string} entityLabelFn Used for displaying title of the edited/created item
 * @param {function(object): Promise<any>} saveFn function to save the edited entity
 * @param {function(): Promise<any>} loadEntityFn function to retrieve the displayed entity
 * @param {string} [listingUrl] will be redirected to this url after save if specified
 * @param {string} [editUrl] will be redirected to this url after save if specified and #navigateToListingWhenSaved is false
 * @param {string} id undefined or the id of the entity, used only to determine if it's a create or an update event
 * @param {boolean} [hideHeaderBox] display the editor's header, default is true
 * @param {boolean} [readonly] make the editor read only, default is false
 * @param {object} formSchema form schema
 * @param {boolean} [hideCancel] set true to hide the cancel button
 * @param {boolean} navigateToListingWhenSaved set true to navigate to listing page after save (if listingUrl is defined)
 * @param {function(object): void} onSaved
 * @param {function(): void} onCancelled
 * @param {JSX.Element} children
 * @param {JSX.Element} warningElement displayed between the header and the editor
 * @param {function(): object} newEntity factory function for new entity
 * @returns {JSX.Element}
 * @constructor
 */
const GenericEntityEditor = ({
  children,
  entityLabel,
  entityLabelFn,
  saveFn,
  loadEntityFn,
  listingUrl,
  id,
  formSchema,
  hideCancel,
  hideHeaderBox,
  navigateToListingWhenSaved = true,
  onSaved,
  onCancelled,
  editUrl,
  readonly = false,
  warningElement,
  newEntity,
}: GenericEntityEditorProps): ReactElement => {
  const navigate = useNavigate();
  const [entityData, setEntityData] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  function onSubmit(updatedEventData: any) {
    saveFn(updatedEventData)
      .then((result) => {
        enqueueSnackbar(`${entityLabel} saved!`, {variant: 'success'});
        if (onSaved) {
          onSaved(result);
        }
        if (navigateToListingWhenSaved && listingUrl) {
          navigate(listingUrl);
        } else if (editUrl) {
          navigate(editUrl.replace(':id', result.data.id));
        }
      })
      .catch((err) => {
        const message = err?.response?.data?.message || err.message;
        enqueueSnackbar(`Failed to save ${entityLabel}: ${message}`, {variant: 'error'});
      });
  }

  function onCancel() {
    if (onCancelled) {
      onCancelled();
    }
    if (listingUrl) {
      navigate(listingUrl);
    }
  }

  useEffect(() => {
    if (id === undefined) {
      if (newEntity) {
        setEntityData(newEntity());
      } else {
        setEntityData({});
      }
    } else {
      if (loadEntityFn !== undefined) {
        loadEntityFn()
          .then(res => {
            setEntityData(res);
          });
      }
    }
  }, []);

  return (
    <>
      {entityData && <div className="generic-entity-editor">
        {!hideHeaderBox &&
          <div className="box">
            <div className="columns is-vcentered">
              <div className="column">
                {entityData._id && <h2 className="is-size-3">Update {entityLabel}{entityLabelFn && entityLabelFn(entityData)}</h2>}
                {!entityData._id && <h2 className="is-size-3">Create {entityLabel}{entityLabelFn && entityLabelFn(entityData)}</h2>}
              </div>
            </div>
          </div>
        }

        {warningElement && warningElement}

        <div className="box">
          <div className="columns">
            <div className="column">
              <Suspense fallback={<div>Loading...</div>}>
                <FormRenderer
                  initialValues={entityData}
                  schema={formSchema as Schema}
                  componentMapper={bb3ComponentMapper}
                  FormTemplate={readonly ? (ReadonlyFormTemplate as unknown as React.ComponentType<any>) : FormTemplate}
                  onCancel={hideCancel ? undefined : onCancel}
                  onSubmit={onSubmit}
                />
              </Suspense>
            </div>
          </div>
        </div>

        {children && children}
      </div>
      }
    </>
  );
};

export default GenericEntityEditor;
