
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper";
import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types";

import ImageSelector from "../components/admin/form/ImageSelector";
import TabLayout from "../components/admin/form/TabLayout";
import FieldSpacer from "../components/admin/form/FieldSpacer";
import WysiwygEditor from "../components/admin/form/WysiwygEditor";
import DateSelector from "../components/admin/form/DateSelector";
import DocumentSelector from "../components/admin/form/DocumentSelector";
import AutocompleteField from '../components/admin/form/AutocompleteField';

export const bb3ComponentMapper = {
  ...ComponentMapper,
  'image': ImageSelector,
  'document':DocumentSelector,
  'tabLayout': TabLayout,
  'spacer': FieldSpacer,
  'wysiwyg': WysiwygEditor,
  'date': DateSelector,
  'autocomplete': AutocompleteField,
};

// TODO: Specify `any` types

export function createField(name: any, of?: any) {
  const res = {
    component: 'text-field',
    name,
    ...of,
  };
  const obj = {
    component(value: any) {
      res.component = value;
      return obj;
    },

    name(value: any) {
      res.name = value;
      return obj;
    },

    label(label: any, helperText: any) {
      res.label = label;
      if (helperText) {
        res.helperText = helperText;
      }
      return obj;
    },

    helperText(value: any) {
      res.helperText = value;
      return obj;
    },

    required() {
      res.validate = res.validate || [];
      res.validate.push({ type: validatorTypes.REQUIRED, message: 'This is required' });
      return obj;
    },

    /**
     * Add validation to the field (https://data-driven-forms.org/schema/length-validator)
     * @param validationList Array of the validation elements
     * @returns {any}
     */
    validate(validationList: any) {
      res.validate = (res.validate || []).concat(validationList);
      return obj;
    },

    /**
     * Sets the width of the column, for example:
     * sizeColumns(6) or sizeColumns(6, 'xs') will be:
     * {
     *   FormFieldGridProps: { xs: 6 },
     * }
     */
    sizeColumns(columns: any, resolution = undefined) {
      res.FormFieldGridProps = res.FormFieldGridProps || {};
      const resol = resolution || 'xs';
      res.FormFieldGridProps[resol] = columns;
      return obj;
    },

    build(additionalParams?: any) {
      if (additionalParams) {
        return {...res, ...additionalParams };
      }
      return res;
    },
  };
  return obj;
}

export function createRequiredField(name: any) {
  return createField(name)
    .required();
}

export function createSelectField(
  name: any,
  label: any,
  options: any,
  size: any,
) {
  return createField(name, {
    component: 'select',
    label,
    options,
    FormFieldGridProps: { xs: size },
    isOptionEqualToValue: (option: any, value: any) => {
      return option.value === value;
    },
  });
}

export function patternValidation(pattern: RegExp) {
  return [{
    type: validatorTypes.PATTERN,
    pattern,
  }];
}
