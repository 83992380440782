import { Fragment } from 'react';
import { ArrowUpward, ArrowUpwardRounded} from '@mui/icons-material';
import { FAQCategoryDTO, FaqCategoryGroup, FAQEntryDTO } from '@bb-sanctuary/common';

import { BsOrgChart } from "../org-chart/orgChart";
import BsFAQCategorySelector, { BsFAQCategorySelectorLevel } from '../../ui/faq-category-selector/faq-category-selector';
import BsButton from '../../ui/button/button';
import Translation from '../../../data/translation';
import FaqEntryShare from '../faq-entry-share/FaqEntryShare';

export interface FaqListingProps {
  faqData: Array<FAQCategoryDTO>;
  topicName?: string;
  topicData?: FAQCategoryDTO;
  subtopicData?: FAQEntryDTO;
  subtopicName: string;
  isScrollToTopActive?: boolean;
  getSelectedTopicData: (topic: string) => void;
  getSelectedSubtopicData: (topic: string) => void;
  scrollToTop: () => void;
  type: FaqCategoryGroup;
}

const FaqListing = ({
  faqData,
  topicName,
  topicData,
  subtopicName,
  subtopicData,
  getSelectedTopicData,
  getSelectedSubtopicData,
  isScrollToTopActive = true,
  scrollToTop,
  type,
}: FaqListingProps) => {
  return (
    <Fragment>
      <div className="bs-faq__selector">
        <h3 className="bs-faq__subtitle">
          {Translation.hu.faq.topic}
        </h3>
        <div className="bs-faq__topics">
          {faqData && faqData.map((topic: FAQCategoryDTO, i: number) =>
            <BsFAQCategorySelector
              key={i}
              active={topic.title === topicName}
              onClick={() => getSelectedTopicData(topic.title)}
              level={BsFAQCategorySelectorLevel.MAIN}
              className="bs-faq__topic">
              {topic.title}
            </BsFAQCategorySelector>,
          )}
        </div>
      </div>
      {!topicName &&
        <div className="bs-faq__indicator">
          <div className="bs-faq__indicator__box">
            <ArrowUpwardRounded className="bs-faq__indicator__icon" />
            <span className="bs-faq__indicator__text">
              <b>1. {Translation.hu.common.step}:</b> {Translation.hu.faq.selectTopic}
            </span>
          </div>
        </div>
      }

      {topicName &&
        <Fragment>
          <div className="bs-faq__selector">
            <h3 className="bs-faq__subtitle">
              {Translation.hu.faq.subtopic}
            </h3>
            <div className="bs-faq__topics">
              {topicData && topicData.entries.map((subtopic, i: number) =>
                <BsFAQCategorySelector
                  key={i}
                  active={subtopic.title === subtopicName}
                  onClick={() => getSelectedSubtopicData(subtopic.title)}
                  level={BsFAQCategorySelectorLevel.SUB}
                  className="bs-faq__topic">
                  {subtopic.title}
                </BsFAQCategorySelector>,
              )}
            </div>
          </div>
          {!subtopicName &&
            <div className="bs-faq__indicator">
              <div className="bs-faq__indicator__box">
                <ArrowUpwardRounded className="bs-faq__indicator__icon" />
                <span className="bs-faq__indicator__text">
                  <b>2. {Translation.hu.common.step}:</b> {Translation.hu.faq.selectSubTopic}
                </span>
              </div>
            </div>
          }
        </Fragment>
      }

      {subtopicData &&
        <Fragment>
          <div className="bs-faq__result">
            <h4 className="bs-faq__result__title">
              {subtopicName || ``}
              <FaqEntryShare id={subtopicData.id} type={type} />
            </h4>
            {subtopicData.content.includes('!!ORG-CHART!!') &&
              <BsOrgChart />}
            {!subtopicData.content.includes('!!ORG-CHART!!') &&
              <div className="bs-faq__result__content fr-view" dangerouslySetInnerHTML={{__html: subtopicData.content}}/>
            }
          </div>
          {isScrollToTopActive &&
            <BsButton className="bs-faq__top-button" color="secondary" size="small" onClick={scrollToTop}>
              <ArrowUpward /> {Translation.hu.common.backToTop}
            </BsButton>
          }
          <FaqEntryShare className={"bs-faq__result__share"} id={subtopicData.id} type={type}/>
        </Fragment>
      }
    </Fragment>
  );
};

export default FaqListing;
