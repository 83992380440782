import { axiosInstance } from './axiosInstance';
import {
  ArticleFilterDTO,
  ArticleListPageDTO,
  ArticleModerateDTO,
  CreateNewsletterDTO,
  UpdateNewsletterDTO,
} from '@bb-sanctuary/common';
import { AxiosResponse } from 'axios';

const ARTICLE_API = `/api/article`;

export const ArticleService = {
  getNews(params: ArticleFilterDTO): Promise<AxiosResponse<ArticleListPageDTO>> {
    return axiosInstance.post(`${ARTICLE_API}/posts/search`, params);
  },
  getTagList(): Promise<AxiosResponse<string[]>> {
    return axiosInstance.get(`${ARTICLE_API}/tags`);
  },
  deleteDraft(id: number): Promise<AxiosResponse<void>> {
    return axiosInstance.delete(`${ARTICLE_API}/posts/${id}`);
  },
  moderateArticle(statusChange: ArticleModerateDTO) {
    return axiosInstance.put(`${ARTICLE_API}/posts/moderate`, statusChange);
  },
  createArticle(formData: FormData) {
    return axiosInstance.post(`${ARTICLE_API}/posts`, formData);
  },
  updateArticle(formData: FormData) {
    return axiosInstance.put(`${ARTICLE_API}/posts`, formData);
  },
  createPost(data: CreateNewsletterDTO|UpdateNewsletterDTO) {
    return axiosInstance.post(`${ARTICLE_API}/posts2`, data);
  },
};
