import React, {useState} from 'react';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { Tabs, Tab, Paper, Grid } from '@mui/material';

const TabLayout = ({ tabs }: { tabs: any}) => {
  const [value, setValue] = useState(0);

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const { renderForm } = useFormApi();
  return (
    <div className="is-flex is-flex-direction-column" style={{'flex': '1 1'}}>
      <Paper>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable">
          {tabs && tabs.map((tab: any, index: number) => <Tab label={tab.label} key={index} />)}
        </Tabs>
      </Paper>

      <div className="pt-4 is-flex is-flex-direction-column">
        <Grid container spacing={3}>
          {tabs && tabs.map((tab: any, index: number) => {
            return (<React.Fragment key={index}>
              {index === value && renderForm(tab.fields) }
            </React.Fragment>);
          })}
        </Grid>
      </div>
    </div>
  );
};

export default TabLayout;
