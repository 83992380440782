import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import Translation from '../../../data/translation';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';

export type CvGeneratorAccordionSummaryProps = {
  shortSummary: string;
  maxCvItemsShortSummary: number;
  updateSummary: (v: string) => void;
};

export const CvGeneratorAccordionSummary = ({
                                        shortSummary,
                                        maxCvItemsShortSummary,
                                        updateSummary,
                                      }: CvGeneratorAccordionSummaryProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.shortSummary}</p>
          <p>{Translation.hu.cv.accordionTitle.max(shortSummary.trim().length, maxCvItemsShortSummary)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="bs-cv-form">
          <TextField label={Translation.hu.cv.accordionTitle.shortSummary}
                     className="bs-cv-form-textarea bs-cv-form-textarea--full" multiline rows={8} value={shortSummary}
                     onChange={(e) => e.target.value.trim().length <= 500 ? updateSummary(e.target.value) : e}/>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
