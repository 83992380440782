import { addHours, startOfMonth, startOfYear } from 'date-fns';

export const toISODateString = (input: undefined|null|string|Date,
                                start?: 'month' | 'year'): string|null => {
  if (!input || input.toString() === 'Invalid Date') {
    return null;
  }
  const date = new Date(input);
  if (!start || start === 'month') {
    return addHours(startOfMonth(date), 24).toDateString();
  }
  return addHours(startOfYear(date), 24).toDateString();
};
