import { Dialog } from '@mui/material';
import Translation from '../../../data/translation';
import BsButton from '../../ui/button/button';

export interface ConfirmationModalProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  isCompletedForm: boolean;
  notCompletedForm: string[];
}

const ConfirmationModal = ({ open, onClose, isCompletedForm,notCompletedForm }: ConfirmationModalProps) => {
  return (
    <Dialog open={open} className="bs-dialog">
      {
        isCompletedForm ?
          <div className="bs-cv-dialog-content">
            <p>Az adatbázisból készült szakmai önéletrajzokon feltüntetett információk helyességéért és valóságtartalmáért felelősséget vállalok.</p>
            <div className="bs-cv-dialog-content__buttons">
              <BsButton size="small" color="secondary" onClick={() => onClose(false)} >{Translation.hu.cv.form.cancel}</BsButton>
              <BsButton size="small" onClick={() => onClose(true)} >{Translation.hu.cv.form.yes}</BsButton>
            </div>
          </div>
          :
          <div className="bs-cv-dialog-content">
            <p>{Translation.hu.cv.form.notCompletedForm} {notCompletedForm.join(",")}</p>
            <div className="bs-cv-dialog-content__buttons">
              <BsButton size="small" color="primary" onClick={() => onClose(false)} >{Translation.hu.cv.form.ok}</BsButton>
            </div>
          </div>
      }

    </Dialog>
  );
};

export default ConfirmationModal;