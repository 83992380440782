import { CvEducationDTO } from "@bb-sanctuary/common";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

export interface CvEducationProps {
  education: CvEducationDTO;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

const CvEducation = ({ education, onDelete, onEdit }: CvEducationProps) => {
  return (
    <div className="education-grid education-grid__item">
      <p>{new Date(education.startDate).getFullYear()}</p>
      <p>{education.endDate ? new Date(education.endDate).getFullYear(): ''}</p>
      <p>{education.title}</p>
      <p>{education.faculty}</p>
      <p>{education.level}</p>
      <div className="close-icon">
        <EditIcon onClick={() => onEdit(education.id as number)} />
        <DeleteOutlineIcon onClick={() => onDelete(education.id as number)} />
      </div>
    </div>
  );
};

export default CvEducation;
