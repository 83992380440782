import BsUploader, { BsUploaderType } from '../uploader/uploader';
import BsTag from '../tag/tag';
import { BaseColleagueDTO } from '@bb-sanctuary/common';

export interface BsArticleCardProps {
  newsPhoto?: string;
  title: string;
  text: string;
  tags: string[];
  uploader?: BaseColleagueDTO;
  uploaderName: string;
  uploaderTime: string;
  entryType?: string;
  pinned?: boolean;
}

const BsArticleCard = ({
  newsPhoto,
  tags,
  title,
  text,
  uploader,
  uploaderName,
  uploaderTime,
  entryType,
  pinned,
}: BsArticleCardProps) => {
  return (
    <div className={`bs-news-card bs-news-card--${entryType || 'article'} ${pinned ? 'bs-news-card--pinned' : ''}`}>
      <div className="bs-news-card__photo">
        <img src={newsPhoto} alt={title} />
      </div>
      <div className="bs-news-card__content">
        <div className="bs-news-card__content__title">{title}</div>
        <div className="bs-news-card__content__text" dangerouslySetInnerHTML={{__html: text}} />
        <div className="bs-news-card__content__tag">
          {tags.map((tags, index) => (
            <BsTag key={index} label={tags} active={false} />
          ))}
        </div>
      </div>
      <div className="bs-news-card__uploader">
        <BsUploader user={uploader} name={uploaderName} time={uploaderTime} type={!!uploader ? BsUploaderType.USER : BsUploaderType.SYSTEM} />
      </div>
    </div>
  );
};

export default BsArticleCard;
