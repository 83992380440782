import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { TechnologyDTO } from "@bb-sanctuary/common";

import { AdminService } from './AdminService';
import Translation from "../../shared/data/translation";
import GenericEntityListing, { Column } from "../../shared/components/admin/GenericEntityListing";
import { formatLocaleDate } from "../../shared/utils/formatters";
import BsButton from "../../shared/components/ui/button/button";
import BsMergeDialog from "../../shared/components/admin/mergeDialog";

const ADMIN_URL = `/admin/`;

export interface TechnologyExtendedDTO extends TechnologyDTO {
  howManyUse: number;
}

function AdminTechPage() {
  const columns: Array<Column> = [
    {
      header: `Name`,
      field: `name`,
      template: (item: TechnologyExtendedDTO) => <>{item.name}</>,
    },
    {
      header: `Created by`,
      field: `createdBy`,
      template: (item: TechnologyExtendedDTO) => <>{`${item.createdBy.lastName} ${item.createdBy.firstName}`}</>,
    },
    {
      header: `Created at`,
      field: `createdAt`,
      template: (item: TechnologyExtendedDTO) => <>{formatLocaleDate(item.createdAt)}</>,
    },
    {
      header: `User count`,
      field: `userCount`,
      template: (item: TechnologyExtendedDTO) => <>{item.howManyUse}</>,
    },
    {
      header: ``,
      template: (item: TechnologyExtendedDTO) =>
      <div style={{display: `flex`, justifyContent: 'flex-end', gap: '6px'}}>
        <BsButton size="small" color="secondary" onClick={() => openMergeDialog(item.id, item.name)}>
          {Translation.hu.label.mergeTech}
        </BsButton>
        <BsButton size="small" color="secondary" onClick={() => deleteTech(item.id, item.name)}>
          {Translation.hu.label.deleteTech}
        </BsButton>
      </div>,
    },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const [ updateList, setUpdateList ] = useState(1);
  const [ isMergeDialogActive, setIsMergeDialogActive ] = useState(false);
  const [ mergeTechId, setMergeTechId ] = useState(0);
  const [ mergeTechName, setMergeTechName ] = useState('');

  const getTechList = () => AdminService
    .getTechs()
    .then(
      (result) => result
        .map(r => ({...r.technology, howManyUse: r.howManyUse} as TechnologyExtendedDTO)),
    );

  const deleteTech = async (id: number, name: string) => {
    try {
      if (window.prompt(`Biztosan törölni szeretnéd ezt a technológiát "${name}"? Ha igen írd be, hogy igen. NINCS visszaállítási lehetőség!`) !== 'igen') {
        return;
      }
      const result = await AdminService.deleteTech(id);
      if (result) {
        enqueueSnackbar(Translation.hu.form.success, {variant: 'success'});
        setUpdateList(updateList + 1);
      }
    } catch {
      enqueueSnackbar(Translation.hu.form.error, {variant: 'error'});
    }
  };

  const openMergeDialog = (id: number, name: string) => {
    setMergeTechId(id);
    setMergeTechName(name);
    setIsMergeDialogActive(true);
  };

  const handleMergeDialogClose = () => {
    setMergeTechId(0);
    setIsMergeDialogActive(false);
    setUpdateList(updateList + 1);
  };

  return (
    <Fragment>
      <div className="bs-admin__content bs-admin__list">
        {updateList &&
          <GenericEntityListing
            title={Translation.hu.label.tech}
            addButtonLabel={Translation.hu.label.addTech}
            createUrl={`${ADMIN_URL}tech/add`}
            editUrl={`${ADMIN_URL}tech/edit/:id`}
            getListFn={getTechList}
            isEditable={true}
            columns={columns}
          />
        }
      </div>
      <BsMergeDialog
        open={isMergeDialogActive}
        onClose={handleMergeDialogClose}
        selectedTechId={mergeTechId}
        selectedTechName={mergeTechName}
      />
    </Fragment>
  );
}

export default AdminTechPage;
