import Translation from '../../../data/translation';
import { LinearProgress } from '@mui/material';

const Loading = () => {
  return (
    <div className="bs-loading__wrapper">
      <div className="bs-loading__wrapper__progress">
        <LinearProgress />
      </div>
      <p>{Translation.hu.common.loading}</p>
    </div>
  );
};

export default Loading;
