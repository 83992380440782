import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ColleagueDetailsDTO, TechnologyDTO } from '@bb-sanctuary/common';

import Translation from '../../../data/translation';
import { ColleagueService } from '../../../service/colleagueService';
import { TechService } from '../../../service/techService';

export interface BsProjectsFilterProps {
  searchMembers: Array<string> | null;
  searchTechnologyIds: Array<number> | null;
  onSearchMembers: (value: string[]) => void;
  onSearchTechs: (value: Array<number>) => void;
}

const BsProjectsFilter = ({
  searchTechnologyIds,
  onSearchMembers,
  onSearchTechs,
}: BsProjectsFilterProps) => {
  const [colleagues, setColleagues] = useState<Array<ColleagueDetailsDTO>>([]);
  const [techs, setTechs] = useState<Array<TechnologyDTO>>([]);
  const [selectedMembers, setSelectedMembers] = useState<Array<ColleagueDetailsDTO>>([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState<Array<TechnologyDTO>>([]);

  const getColleagues = useCallback(async () => {
    const colleagues = (await ColleagueService.getColleagues('', 0, false, 1000)).data;
    setColleagues(colleagues.colleagueList.resultList);
  },[]);

  const getTechs = useCallback(async () => {
    const techs = (await TechService.getTechList()).data.technologyList.map(_ => _.technology);
    setTechs(techs);
  },[]);

  const syncTech = useCallback(async () => {
    const techArray: Array<TechnologyDTO> = [];
    searchTechnologyIds?.forEach(st => {
      const findTech = techs.find(t => t.id === st);
      if (findTech) {
        techArray.push(findTech);
      }
    });
    setSelectedTechnologies(techArray);
  },[techs, searchTechnologyIds]);

  const getColleaguesOptions = () => {
    return colleagues.sort((l, r) => l.lastName.localeCompare(r.lastName));
  };

  const getTechsOptions = () => {
    return techs.sort((l, r) => l.name.localeCompare(r.name));
  };

  useEffect(() => {
    syncTech();
  }, [techs, syncTech]);

  useEffect(() => {
    getColleagues();
    getTechs();
  }, [getColleagues, getTechs]);

  return (
    <div className="bs-projects__filters__options__box">
      <Autocomplete
        multiple
        id="searchMember"
        className="bs-input"
        data-label={`${Translation.hu.label.searchProjectMember}:`}
        groupBy={(option) => option.lastName[0].toUpperCase()}
        options={getColleaguesOptions()}
        getOptionLabel={option => `${option.lastName} ${option.firstName}`}
        value={selectedMembers}
        onChange={(_, value) => {
          setSelectedMembers(value);
          onSearchMembers(value.map(m => m.email) || null);
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
      <Autocomplete
        multiple
        id="searchTech"
        className="bs-input"
        data-label={`${Translation.hu.label.searchTech}:`}
        options={getTechsOptions()}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.name[0].toUpperCase()}
        value={selectedTechnologies}
        onChange={(_, value) => {
          setSelectedTechnologies(value);
          onSearchTechs(value.map(t => t.id) || null);
        }}
        renderInput={(params) => (
          <TextField {...params}/>
        )}
      />
    </div>
  );
};

export default BsProjectsFilter;
