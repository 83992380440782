import { CvCourseDTO } from "@bb-sanctuary/common";
import { useEffect, useRef, useState } from "react";
import BsButton from "../../ui/button/button";
import BsInput from "../../ui/input/input";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Translation from "../../../data/translation";
import { searchOnEnter } from '../../../utils/searchOnEnter';
import { addYears } from 'date-fns';
import { toISODateString } from '../../../utils/toISODateString';

export interface CourseFormProps {
  onSetNewCourse: (course?: CvCourseDTO) => void;
  editedCourse?: CvCourseDTO;
  onCancel: () => void;
}

const CvCourseForm = ({
                        onSetNewCourse,
                        editedCourse,
                        onCancel,
                      }: CourseFormProps) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [itSecurityCertificate, setITSecurityCertificate] = useState<string>('');
  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  const descriptionRef = useRef<HTMLInputElement | null>(null);
  const courseTypeRef = useRef<HTMLInputElement | null>(null);
  const certificateRef = useRef<HTMLInputElement | null>(null);

  const isValidForm = () =>
    !!startDate && !!endDate && !!description && !!description && !!type &&
    (new Date(startDate)).getTime() <= (new Date(endDate).getTime());

  const clear = () => {
    setStartDate(null);
    setEndDate(null);
    setDescription('');
    setType('');
    setITSecurityCertificate('');
  };

  const handleSetNewCourse = () => {
    if (!isValidForm()) {
      return;
    }

    onSetNewCourse({
      id: Math.floor(Math.random() * 1000000),
      description,
      startDate: new Date(`${startDate}`),
      endDate: new Date(`${endDate}`),
      type,
      itSecurityCertificate,
    });

    clear();
    startDateRef.current?.focus();
  };

  const updateStartDate = (e: any) => {
    setStartDate(e ? toISODateString(e) : '');
    if (e && e.toString() !== 'Invalid Date') {
      endDateRef.current?.focus();
    }
  };

  const updateEndDate = (e: any) => {
    setEndDate(e ? toISODateString(e) : '');
    if (e && e.toString() !== 'Invalid Date') {
      descriptionRef.current?.focus();
    }
  };

  const cancel = () => {
    onSetNewCourse(undefined);
    clear();
    onCancel();
  };

  useEffect(() => {
    if (editedCourse) {
      setStartDate(editedCourse.startDate.toString());
      setEndDate(editedCourse.endDate?.toString() || null);
      setDescription(editedCourse.description);
      setType(editedCourse.type || '');
      setITSecurityCertificate(editedCourse.itSecurityCertificate);
    }
  }, [editedCourse]);

  return (
    <div>
      <h4 className="bs-form__legend">
        {editedCourse ? Translation.hu.cv.course.headerEdit : Translation.hu.cv.course.headerCreate}
      </h4>

      <div className="bs-cv-form">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={Translation.hu.cv.form.header.startDate}
            className="bs-cv-form-datepicker bs-cv-form-datepicker--small bs-cv-form__input--25"
            value={startDate}
            views={['year']}
            onChange={updateStartDate}
            inputRef={startDateRef}
            renderInput={(params: any) => <TextField {...params} />}
            minDate={new Date(1950, 0, 1)}
            maxDate={addYears(new Date(), 1)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={Translation.hu.cv.form.header.endDate}
            className="bs-cv-form-datepicker bs-cv-form-datepicker--small bs-cv-form__input--25"
            value={endDate}
            views={['year']}
            onChange={updateEndDate}
            inputRef={endDateRef}
            minDate={startDate ? new Date(startDate) : new Date(1950, 0, 1)}
            maxDate={addYears(startDate ? new Date(startDate) : new Date(), 8)}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <span className="bs-cv-form__input--full"></span>

        <BsInput
          className="bs-cv-form__input bs-cv-form__input--25"
          size="small"
          label={Translation.hu.cv.form.header.courseName}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyUp={searchOnEnter(() => courseTypeRef.current?.focus())}
          inputRef={descriptionRef}
        />
        <BsInput
          className="bs-cv-form__input bs-cv-form__input--25"
          size="small"
          label={Translation.hu.cv.form.header.courseType}
          value={type}
          onChange={(e) => setType(e.target.value)}
          onKeyUp={searchOnEnter(() => certificateRef.current?.focus())}
          inputRef={courseTypeRef}
        />
        <BsInput
          className="bs-cv-form__input bs-cv-form__input--25"
          size="small"
          label={Translation.hu.cv.form.header.ITSecNumber}
          value={itSecurityCertificate}
          inputRef={certificateRef}
          onChange={(e) => setITSecurityCertificate(e.target.value)}
          onKeyUp={searchOnEnter(() => handleSetNewCourse())}
        />

        <span className="bs-cv-form__input--full"></span>

        <BsButton
          disabled={!isValidForm()}
          className="bs-cv-btn"
          size="small"
          onClick={handleSetNewCourse}
        >
          {editedCourse ? Translation.hu.cv.form.updateCourse : Translation.hu.cv.form.addCourse}
        </BsButton>
        <BsButton
          className="bs-cv-btn"
          size="small"
          color={'secondary'}
          onClick={cancel}
        >
          {Translation.hu.cv.form.cancel}
        </BsButton>
      </div>
    </div>
  );
};

export default CvCourseForm;
