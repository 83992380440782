import { useDispatch, useSelector } from 'react-redux';
import { selectHeaderElement, setHeaderElement } from '../../../../contexts/headerElementSlicer';
import { ReactNode, useEffect } from 'react';

const searchElement: {element: null | ReactNode | ReactNode[]} = {
  element: null,
};

export interface BsContentHeaderProps {
  render?: boolean;
  children?: ReactNode | ReactNode[];
}

export function BsContentHeader(props: BsContentHeaderProps) {
  const dispatch = useDispatch();
  const headerElementCounter = useSelector(selectHeaderElement);

  useEffect(() => {
    if (!props.render && props.children !== searchElement) {
      dispatch(setHeaderElement(headerElementCounter + 1));
      searchElement.element = props.children;
    }
    // eslint-disable-next-line
  }, [props.children, props.render, dispatch]);

  if (props.render && searchElement.element) {
    return <>{searchElement.element}</>;
  }
  return <></>;
}
