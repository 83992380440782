import { Fragment } from 'react';
import Translations from '../../../data/translation';
import { ReactComponent as BsNinja } from './../../../../assets/svg/ninja.svg';

const InnovationHeader = () => {
  return (
    <Fragment>
      <div className="bs-innovation-header">
        <div className="bs-innovation-header__text">
          <h1>{Translations.hu.innovation.headerTitle}</h1>
          <h3>{Translations.hu.innovation.headerSubtitle}</h3>
        </div>

        <div className="bs-innovation-header__ninja">
          <BsNinja />
        </div>
      </div>
    </Fragment>
  );
};

export default InnovationHeader;
