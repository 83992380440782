import { ReactElement, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, Tooltip } from '@mui/material';
import { ColleagueDetailsDTO, UserChat } from '@bb-sanctuary/common';
import Translation from '../../../data/translation';
import BsButton from '../../ui/button/button';
import BsInput from '../../ui/input/input';
import { ChatOption, chatServices } from '../colleague-chat/ColleagueChat';
import { ReactComponent as CloseIcon } from './../../../../assets/icon/plus.svg';
import { AccountService } from '../../../service/accountService';
import { AutoFixHigh, Check, Clear, Edit } from '@mui/icons-material';

export const blackbeltSlackUrl = 'https://blackbelttechnology.slack.com';
export const blackbeltGoogleChatUrl = 'https://chat.google.com';
export const teamsUrl = 'https://teams.microsoft.com';

export interface BsSetChatProps {
  userChat?: UserChat;
  open: boolean;
  onClose: (update?: UserChat) => void;
  selectedColleague: ColleagueDetailsDTO;
}

const BsSetChat = (props: BsSetChatProps): ReactElement => {
  const {open, onClose} = props;
  const [userChat, setUserChat] = useState<UserChat>(props.userChat || ({} as UserChat));
  const [slackEditorVisible, setSlackEditorVisible] = useState((props.userChat?.slack || '').length > 0 && props.userChat?.slack !== blackbeltSlackUrl);
  const {enqueueSnackbar} = useSnackbar();

  const updateChatServices = async () => {
    try {
      const result = await AccountService.updateChats(userChat);
      if (result) {
        enqueueSnackbar(Translation.hu.form.success, {variant: 'success'});
        onClose(userChat);
      }
    } catch {
      enqueueSnackbar(Translation.hu.form.error, {variant: 'error'});
      onClose();
    }
  };

  const setChatService = (option: ChatOption, value: string) => {
    setUserChat({...userChat, [option]: value});
  };

  const resetChatService = (option: ChatOption) => {
    const updatedUserChat = { ...userChat, [option]: '' };
    // delete updatedUserChat[option];
    setUserChat(updatedUserChat);
  };

  const cancelEdit = () => {
    onClose();
  };

  return (
    <Dialog onClose={cancelEdit} open={open} className="bs-dialog">
      <div className="bs-dialog__close" onClick={cancelEdit}><CloseIcon/></div>
      <div className="bs-dialog__wrap">
        <div className="bs-dialog__header">
          <h3>{Translation.hu.label.editChat}</h3>
        </div>
        <div className="bs-dialog__content">
          <div className="bs-colleague__chat__editor">
            {chatServices.map((chatService, ind) =>
              <div className={`bs-colleague__chat__editor__row ${!userChat[chatService.key] ? 'bs-colleague__chat__editor__row--unknown' : ''}`} key={ind}>
                <div className={`bs-colleague__chat__provider`}>
                  <Tooltip title={chatService.name}>
                    {chatService.icon}
                  </Tooltip>
                </div>
                <div className="bs-colleague__chat__value">
                  {(!slackEditorVisible && chatService.key === 'slack') &&
                    <>
                      <Tooltip title={Translation.hu.label.chatLinkPrefillSlackUrl}>
                        <BsButton color={'secondary'}
                                  onClick={() => setChatService('slack', blackbeltSlackUrl)}
                                  disabled={userChat['slack'] === blackbeltSlackUrl}>
                          {userChat['slack'] !== blackbeltSlackUrl && <AutoFixHigh/>}
                          {userChat['slack'] === blackbeltSlackUrl && <Check/>}
                          {Translation.hu.label.chatPreconfigureSlack}
                        </BsButton>
                      </Tooltip>

                      <span className="bs-colleague__chat__value__action">
                        <BsButton color={'secondary'}
                                  onClick={() => setSlackEditorVisible(true)}>
                          <Edit/>
                        </BsButton>
                      </span>
                    </>
                  }
                  {(chatService.key === 'teams') &&
                    <>
                      <Tooltip title={Translation.hu.label.chatLinkPrefillTeamsUrl}>
                        <BsButton color={'secondary'}
                                  onClick={() => setChatService('teams', teamsUrl)}
                                  disabled={userChat['teams'] === teamsUrl}>
                          {!userChat['teams'] && <AutoFixHigh/>}
                          {!!userChat['teams'] && <Check/>}
                          {Translation.hu.label.chatPreconfigureTeams}
                        </BsButton>
                      </Tooltip>
                    </>
                  }
                  {chatService.key === 'hangouts' &&
                    <>
                      <BsButton color={'secondary'}
                                onClick={() => setChatService('hangouts', blackbeltGoogleChatUrl)}
                                disabled={userChat['hangouts'] === blackbeltGoogleChatUrl}>
                        {userChat['hangouts'] !== blackbeltGoogleChatUrl && <AutoFixHigh/>}
                        {userChat['hangouts'] === blackbeltGoogleChatUrl && <Check/>}
                        {Translation.hu.label.chatPreconfigureGoogle}
                      </BsButton>
                    </>
                  }
                  {(
                      (chatService.key === 'other' || chatService.key === 'discord') ||
                      (chatService.key === 'slack' && slackEditorVisible)
                    ) &&
                    <BsInput
                      value={userChat[chatService.key]}
                      onChange={(e) => setChatService(chatService.key, e.target.value)}
                      placeholder={Translation.hu.label.chatLinkPlaceholder}
                    />
                  }

                  <span className="bs-colleague__chat__value__action">
                    <BsButton color={'secondary'}
                              onClick={() => resetChatService(chatService.key)}>
                      <Clear/>
                    </BsButton>
                  </span>
                </div>
              </div>)}
          </div>
        </div>
        <div className="bs-dialog__action">
          <BsButton color="secondary" onClick={cancelEdit}>
            {Translation.hu.form.cancel}
          </BsButton>
          <BsButton color="primary" onClick={updateChatServices}>
            {Translation.hu.form.save}
          </BsButton>
        </div>
      </div>
    </Dialog>
  );
};

export default BsSetChat;
