import {BadgeUserGroup} from "@bb-sanctuary/common";
import { Tooltip } from '@mui/material';
import CEOStar from '../../../../assets/images/ceo-highlight.gif';
import ManagementStars from '../../../../assets/images/management-stars.gif';
import Translation from "../../../data/translation";

export enum BsBadgeType {
  USER = `user`,
  SPECIAL = `special`,
  SYSTEM = `system`,
  MEDAL = `medal`,
}

export interface BsBadgeProps {
  iconOnly?: boolean;
  icon: React.ReactElement;
  label: string;
  type?: BsBadgeType;
  count?: number;
  receivedFrom?: string;
}

const BsBadge = ({
                   type = BsBadgeType.SYSTEM,
                   iconOnly = false,
                   icon,
                   label,
                   count,
                   receivedFrom,
                   ...props
                 }: BsBadgeProps) => {

  const receivedFromMap = {
    [BadgeUserGroup.Colleague]: Translation.hu.badge.senders.colleague,
    [BadgeUserGroup.Recruitment]: Translation.hu.badge.senders.recruitment,
    [BadgeUserGroup.TL]: Translation.hu.badge.senders.TL,
    [BadgeUserGroup.HR]: Translation.hu.badge.senders.HR,
    [BadgeUserGroup.MGMT]: Translation.hu.badge.senders.MGMT,
    [BadgeUserGroup.CEO]: Translation.hu.badge.senders.CEO,
  };

  return (
    <div
      className={`bs-badge${type ? ` bs-badge--${type}` : ``}${iconOnly ? ` bs-badge--icon-only` : ``}${receivedFrom ? ` bs-badge--${receivedFrom}` : ``}`} {...props}>

      { receivedFrom === BadgeUserGroup.CEO &&
        <div className="bs-badge__extra-gif">
            <img src={CEOStar} alt=""/>
        </div>
      }

      { receivedFrom === BadgeUserGroup.MGMT &&
        <div className="bs-badge__extra-gif">
          <img src={ManagementStars} alt=""/>
        </div>
      }

      <i className="bs-badge__icon">
        {icon}
      </i>

      {!iconOnly &&
        <Tooltip title={receivedFrom ? `${Translation.hu.badge.sender} ${receivedFromMap[receivedFrom as keyof typeof receivedFromMap]}` : ``}>
          <label className="bs-badge__label">{label}</label>
        </Tooltip>
      }
      {count && count > 1 && <span className="bs-badge__count">{count}</span>}
    </div>
  );
};

export default BsBadge;
