import { Accordion, AccordionDetails, AccordionSummary, Collapse } from '@mui/material';
import Translation from '../../../data/translation';
import CvCourseForm from '../cv-form/CvCourseForm';
import CvCourse from '../cv-form/CvCourse';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';
import { CvCourseDTO } from '@bb-sanctuary/common';
import { useState } from 'react';
import BsButton from '../../ui/button/button';

export type CvGeneratorAccordionCourseProps = {
  courses: CvCourseDTO[];
  maxCvItemsCourse: number;
  onAddNewCourse: (newCourse?: CvCourseDTO) => void;
  editedCourse?: CvCourseDTO;
  onRemoveCourse: (id: number) => void;
  onEditCourse: (id: number) => void;
};

export const CvGeneratorAccordionCourse = ({
                                             courses,
                                             maxCvItemsCourse,
                                             onAddNewCourse,
                                             editedCourse,
                                             onRemoveCourse,
                                             onEditCourse,
                                           }: CvGeneratorAccordionCourseProps) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const addCourse = (newCourse?: CvCourseDTO) => {
    onAddNewCourse(newCourse);
    setIsEditorOpen(false);
  };

  const editCourse = (id: number) => {
    onEditCourse(id);
    setIsEditorOpen(true);
  };

  return (
    <Accordion onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.course}</p>
          {isAccordionOpen && !isEditorOpen &&
            <BsButton
              size={'small'}
              onClick={(e) => {
                setIsEditorOpen(true);
                e.stopPropagation();
              }}>
              {Translation.hu.cv.accordionTitle.addCourse}
            </BsButton>
          }
          <p>{Translation.hu.cv.accordionTitle.max(courses.length, 10)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isEditorOpen && <div className="bs-cv-generator__form-backdrop"/>}
        <div className="bs-cv-generator__form">
          <Collapse in={isEditorOpen}>
            <div className={`bs-cv-generator__form__modal bs-cv-generator__form--open`}>
              {
                courses.length < maxCvItemsCourse &&
                <CvCourseForm
                  onSetNewCourse={addCourse}
                  editedCourse={editedCourse}
                  onCancel={() => setIsEditorOpen(false)}
                />
              }
            </div>
          </Collapse>
          {courses.length >= maxCvItemsCourse &&
            <p className="warning">{Translation.hu.cv.form.maxWarning}</p>
          }

          <div className="course-grid course-grid__header">
            <p>{Translation.hu.cv.form.header.startDate}</p>
            <p>{Translation.hu.cv.form.header.endDate}</p>
            <p>{Translation.hu.cv.form.header.courseName}</p>
            <p>{Translation.hu.cv.form.header.courseType}</p>
            <p>{Translation.hu.cv.form.header.ITSecNumber}</p>
            <p></p>
          </div>
          {
            courses
              .sort((l, r) => new Date(r.startDate).getTime() - new Date(l.startDate).getTime())
              .map(course => {
                return (
                  <CvCourse
                    onDelete={onRemoveCourse}
                    onEdit={editCourse}
                    course={course}
                    key={course.id}
                  />
                );
              })
          }

        </div>
      </AccordionDetails>
    </Accordion>
  );
};
