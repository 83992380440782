import { axiosInstance } from './axiosInstance';
import { AxiosResponse } from 'axios';
import {AccountDTO, UserChat} from "@bb-sanctuary/common";

export const AccountService = {
  updateChats(params: UserChat): Promise<AxiosResponse<AccountDTO>> {
    return axiosInstance.put(`/api/account/chats`, params);
  },
  updateWelcomeDialogDisplayed(): Promise<void> {
    return axiosInstance.post(`/api/account/welcome-dialog`);
  },
};
