import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Translation from '../../../data/translation';
import CvDomainKnowledgeForm from '../cv-form/CvDomainKnowledgeForm';
import { ReactComponent as BsArrow } from '../../../../assets/svg/arrow-down.svg';

export interface DomainKnowledge {
  id: number;
  title: string;
}

export type CvGeneratorAccordionDomainProps = {
  domainKd: DomainKnowledge[],
  onUpdateDomainKnowledge: (domainList: DomainKnowledge[]) => void;
  maxCvItemsDomainKnowledge: number;
};

export const CvGeneratorAccordionDomain = ({
                                             domainKd,
                                             onUpdateDomainKnowledge,
                                             maxCvItemsDomainKnowledge,
                                           }: CvGeneratorAccordionDomainProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<BsArrow className="bs-cv-generator__summary"/>} id="panel-header">
        <div className="bs-cv-generator__accordion-summary">
          <p>{Translation.hu.cv.accordionTitle.domainKnowledge}</p>
          <p>{Translation.hu.cv.accordionTitle.max(domainKd.length, 8)}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {
          domainKd.length < maxCvItemsDomainKnowledge
            ? <CvDomainKnowledgeForm domainKd={domainKd} onChangeHandle={onUpdateDomainKnowledge}/>
            : <p className="warning">{Translation.hu.cv.form.maxWarning}</p>
        }
      </AccordionDetails>
    </Accordion>
  );
};
