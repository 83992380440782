import { format } from 'date-fns';
import huLocale from 'date-fns/locale/hu';

export const formatLocaleDate = (date: string | Date) => {
  return format(new Date(date), `yyyy. MMM dd.`, {locale: huLocale});
};

export const formatLocaleDateTime = (date: string | Date) => {
  return format(new Date(date), `yyyy. MMM dd. HH:mm:ss`, {locale: huLocale});
};

export function formatDate(date: string | Date) {
  if (typeof date === 'string') {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth().toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
  }
  return date;
}

export function stringToSlug(str: string) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}
