import { Fragment, ReactElement, ReactNode } from 'react';

import BsHeader from '../header/header';
import BsAppbar from '../appbar/appbar';
import BsSidebar from '../sidebar/sidebar';

export enum BsLayout {
  Auth = 'auth',
  App = 'app',
}

export interface BsFrameProps {
  children: ReactNode;
  layout?: BsLayout;
}

function BsFrame({
  children,
  layout = BsLayout.App,
}: BsFrameProps): ReactElement {
  return (
    <Fragment>
      <div className={`bs-layout bs-layout--${layout}`}>
        <div className="bs-layout__header">
          <BsHeader />
        </div>
        <div className="bs-layout__wrap">
          <div className="bs-layout__appbar">
            <BsAppbar />
          </div>
          <main className="bs-layout__content">{children}</main>
          <div className="bs-layout__sidebar">
            <BsSidebar />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BsFrame;
