import { CvProfessionalBackgroundDTO } from "@bb-sanctuary/common";
import { Fragment, KeyboardEvent, useEffect, useRef, useState } from "react";
import BsInput from "../../ui/input/input";
import BsButton from "../../ui/button/button";
import Translation from "../../../data/translation";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from '@mui/icons-material/Close';
import { toISODateString } from '../../../utils/toISODateString';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface ProfessionalBgParams {
  onSetNewProfessionalBg: (professionalBg: CvProfessionalBackgroundDTO|null) => void;
  isFirst: boolean;
  editedBg?: CvProfessionalBackgroundDTO;
  onCancel: () => void;
}

interface Description {
  id: number;
  content: string;
}

const emptyDescriptionList = () => ({
  id: Math.random(),
  content: '',
});

const CvProfessionalBgForm = ({
                                onSetNewProfessionalBg,
                                isFirst,
                                editedBg,
                                onCancel,
                              }: ProfessionalBgParams) => {
  const [isBBExperience, setIsBBExperience] = useState(isFirst);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [company, setCompany] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [descriptions, setDescriptions] = useState<Description[]>([]);
  const startDateRef = useRef<HTMLInputElement|null>(null);
  const endDateRef = useRef<HTMLInputElement|null>(null);
  const companyInputRef = useRef<HTMLInputElement|null>(null);
  const positionInputRef = useRef<HTMLInputElement|null>(null);
  const jobDescriptionRef = useRef<Array<HTMLInputElement|null>>([]);

  const onDelete = (id: number) => {
    if (descriptions.length === 1) {
      setDescriptions([emptyDescriptionList()]);
    } else {
      const newArray = descriptions.filter(description => description.id !== id);
      if (newArray[newArray.length - 1].content !== '') {
        newArray.push(emptyDescriptionList());
      }
      setDescriptions(newArray);
    }
  };

  const addOrUpdateDescriptions = (value: string, ind: number) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[ind].content = value;

    if (updatedDescriptions[updatedDescriptions.length - 1].content === '') {
    } else {
      updatedDescriptions.push(emptyDescriptionList());
    }
    setDescriptions(updatedDescriptions);
  };

  const formIsValid = () => {
    if (isBBExperience) {
      return !!startDate && !!company && !!title && descriptions.length > 0;
    }
    return !!startDate && !!endDate && !!company && !!title && descriptions.length > 0 &&
      (new Date(startDate)).getTime() < (new Date(endDate).getTime());
  };

  const clear = () => {
    setStartDate(null);
    setEndDate(null);
    setCompany('');
    setTitle('');
    setDescriptions([emptyDescriptionList()]);
    setIsBBExperience(false);
  };

  const onSave = () => {
    if (!formIsValid()) {
      return;
    }

    onSetNewProfessionalBg({
      id: editedBg ? editedBg.id : Math.round(Math.random()*1000),
      company,
      title,
      fromDate: new Date(startDate!),
      toDate: endDate ? new Date(endDate) : undefined,
      description: descriptions.map(description => description.content).filter(_ => !!_),
    });
    clear();
  };

  const cancel = () => {
    onSetNewProfessionalBg(null);
    onCancel();
    clear();
  };

  const updateStartDate = (e: any) => {
    setStartDate(toISODateString(e));
    if (e && e.toString() !== 'Invalid Date') {
      if (isBBExperience) {
        positionInputRef.current?.focus();
      } else {
        endDateRef.current?.focus();
      }
    }
  };

  const updateEndDate = (e: any) => {
    setEndDate(toISODateString(e));
    if (e && e.toString() !== 'Invalid Date') {
      companyInputRef.current?.focus();
    }
  };

  const focusToTitleOnEnter = (evt: KeyboardEvent) => {
    if (evt.code === 'Enter') {
      positionInputRef.current?.focus();
    }
  };

  const focusToJobDescriptionOnEnter = (evt: KeyboardEvent) => {
    if (evt.code === 'Enter') {
      jobDescriptionRef.current[0]?.focus();
    }
  };

  const onJobDescKeyUp = (ind: number) => (evt: KeyboardEvent) => {
    if (evt.code === 'Enter' && jobDescriptionRef.current[ind + 1]) {
      jobDescriptionRef.current[ind + 1]?.focus();
    }
  };

  useEffect(() => {
    if (isBBExperience) {
      setCompany('BlackBelt');
      setEndDate(null);
    }
  }, [isBBExperience]);

  useEffect(() => {
    setDescriptions([emptyDescriptionList()]);
  }, []);

  useEffect(() => {
    if (!editedBg) {
      return;
    }
    
    setStartDate(toISODateString(editedBg.fromDate));
    setEndDate(toISODateString(editedBg.toDate));
    setCompany(editedBg.company);
    setTitle(editedBg.title);
    setDescriptions(editedBg.description.map(_ => ({id: Math.random(), content: _})));
    if (editedBg.company === 'BlackBelt') {
      setIsBBExperience(true);
    } else {
      setIsBBExperience(false);
    }
  }, [editedBg]);

  useEffect(() => {
    setIsBBExperience(isFirst);
  }, [isFirst]);


  return (
    <div className="bs-cv-professional-bg-form">
      <h4 className="bs-form__legend">
        {editedBg ? Translation.hu.cv.profbg.headerEdit : Translation.hu.cv.profbg.headerCreate}
      </h4>
      {isBBExperience && <p className="hint">{Translation.hu.cv.form.header.startWithBlackbelt}</p>}
      <div className="bs-cv-form">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={Translation.hu.cv.form.header.startDate}
            className="bs-cv-form-datepicker bs-cv-form-datepicker--small bs-cv-form-datepicker--with-month bs-cv-form__input--25"
            value={startDate}
            disableFuture
            views={['year', 'month']}
            onChange={updateStartDate}
            inputFormat={'yyyy.MM'}
            inputRef={startDateRef}
            minDate={new Date(1950,0,1)}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={Translation.hu.cv.form.header.endDate}
            className="bs-cv-form-datepicker bs-cv-form-datepicker--small bs-cv-form-datepicker--with-month bs-cv-form__input--25"
            value={endDate}
            views={['year', 'month']}
            minDate={startDate ? new Date(startDate) : new Date(1950,0,1)}
            onChange={updateEndDate}
            inputFormat={'yyyy.MM'}
            renderInput={(params: any) => <TextField {...params} />}
            inputRef={endDateRef}
            ignoreInvalidInputs={isBBExperience}
            disabled={isBBExperience}
          />
        </LocalizationProvider>
        <BsInput
          required
          size="small"
          className="bs-cv-form__input bs-cv-form__input--25"
          disabled={isBBExperience}
          value={company}
          label={Translation.hu.cv.form.header.PO}
          onChange={(e) => setCompany(e.target.value)}
          onKeyUp={focusToTitleOnEnter}
          inputRef={companyInputRef}
        />
        <BsInput
          required
          size="small"
          className="bs-cv-form__input bs-cv-form__input--25"
          value={title}
          label={Translation.hu.cv.form.header.companytitle}
          onChange={(e) => setTitle(e.target.value)}
          onKeyUp={focusToJobDescriptionOnEnter}
          inputRef={positionInputRef}
        />
      </div>

      <p className="hint">{Translation.hu.cv.form.header.backgroundDescription}</p>

      {descriptions.map((description, ind) =>
        <Fragment key={description.id}>
          <div className="bs-cv-form__new-work-description-form">
            <BsInput
              size="small"
              style={{flex: 1}}
              value={description.content}
              placeholder={Translation.hu.cv.form.header.backgroundDescriptionItem}
              onChange={(e) => addOrUpdateDescriptions(e.target.value, ind)}
              onKeyUp={onJobDescKeyUp(ind)}
              endAdornment={<CloseIcon onClick={() => onDelete(description.id)} />}
              inputRef={e => jobDescriptionRef.current[ind] = e}
            />
          </div>
        </Fragment>)}
      { !formIsValid() && <p className="warning">{Translation.hu.cv.form.notValidForm}</p> }

      <div className="bs-cv-form">
        <BsButton
          onClick={onSave}
          size="small"
          disabled={!formIsValid()}
        >
          {editedBg ? Translation.hu.cv.form.updateExperience : Translation.hu.cv.form.addExperience}
        </BsButton>

        <BsButton
          onClick={cancel}
          size="small"
          color="secondary">
          {Translation.hu.cv.form.cancel}
        </BsButton>
      </div>
    </div>
  );
};

export default CvProfessionalBgForm;
