import { CvFileType, CvListItemDTO } from "@bb-sanctuary/common";
import { format } from "date-fns/esm";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BsButton from "../../shared/components/ui/button/button";
import Translation from "../../shared/data/translation";
import { CvGeneratorService } from "../../shared/service/cvGeneratorService";
import { Stack, Switch, Typography } from '@mui/material';

const AdminCvListPage = () => {
  const [cvList, setCvList] = useState<CvListItemDTO[]>([]);
  const params = useParams();
  const email = params.id;
  useEffect(() => {
    if (email) {
      CvGeneratorService.getCvList(email).then(response => {
        setCvList(response);
      });
    }
  }, []);

  const [selectedFileType, setSelectedFileType] = useState<CvFileType>(CvFileType.pdf);

  const getDateString = (date: Date) => {
    return format(new Date(date), 'yyyy/MM/dd');
  };

  const download = (id: number) => {
    return `/api/cv-generator/generate-by-id/${id}/${selectedFileType === CvFileType.pdf ? 'pdf' : 'docx'}`;
  };

  const changeHandlerFileType = (isDocx: boolean) => {
    setSelectedFileType(isDocx ? CvFileType.docx : CvFileType.pdf);
  };

  return (
    <Fragment>
      <div className="bs-admin__section">
        <h3>{Translation.hu.admin.cv.list}</h3>

        <div>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>PDF</Typography>
            <Switch onChange={(_, val) => changeHandlerFileType(val)}/>
            <Typography>Docx</Typography>
          </Stack>
        </div>

        <div className="bs-admin__sub-section bs-admin__cv-list">
          <table className="table is-hoverable is-fullwidth">
            <tbody>
            {
              cvList.map((cv, i) => {
                return (
                  <tr className="bs-admin-cv-item" key={cv.id}>
                    <td><p>{cv.registrationCode}</p></td>
                    <td><p>{getDateString(cv.createdAt)}</p></td>
                    <td>
                      {
                        i === 0 ? Translation.hu.admin.cv.active : Translation.hu.admin.cv.inactive
                      }
                    </td>
                    <td align={"right"}>
                      <Link to={download(cv.id)} target="_blank" download>
                        <BsButton
                          size="small"
                          color={'secondary'}>
                          {Translation.hu.admin.cv.download}
                        </BsButton>
                      </Link>
                    </td>
                  </tr>
                );
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminCvListPage;
