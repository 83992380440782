import { ReactComponent as BadgeThankYouIcon } from '../../assets/badge/badge_thankyou.svg';
import { ReactComponent as BadgeTeamworkIcon } from '../../assets/badge/badge_teamwork.svg';
import { ReactComponent as BadgeGreatJobIcon } from '../../assets/badge/badge_great_job.svg';
import { ReactComponent as BadgeInspiringIcon } from '../../assets/badge/badge_inspiring.svg';
import { ReactComponent as BadgeGreatInterviewIcon } from '../../assets/badge/badge_great_interview.svg';
import { ReactComponent as BadgeMeetupMasterIcon } from '../../assets/badge/badge_meetup_master.svg';
import { ReactComponent as BadgeExceptionalPerformanceIcon } from '../../assets/badge/badge_exceptional_performance.svg';
import { ReactComponent as BadgeHighFiveIcon } from '../../assets/badge/badge_high_five.svg';
import { BadgeHistoryDTO, BadgeHistoryType, BadgeType, BadgeUserGroup } from '@bb-sanctuary/common';
import Translation from './translation';
import { ReactElement } from 'react';

export interface BsBadgeProps {
  icon: React.ReactElement;
}

export type BsBadgeKitProps = {
  [key in keyof typeof BadgeType]: BsBadgeProps;
}

export const BsBadgeKit: BsBadgeKitProps = {
  ThankYou: {
    icon: <BadgeThankYouIcon />,
  },
  Teamwork: {
    icon: <BadgeTeamworkIcon />,
  },
  GreatJob: {
    icon: <BadgeGreatJobIcon />,
  },
  Inspiring: {
    icon: <BadgeInspiringIcon />,
  },
  GreatInterview: {
    icon: <BadgeGreatInterviewIcon />,
  },
  MeetupMaster: {
    icon: <BadgeMeetupMasterIcon />,
  },
  ExceptionalPerformance: {
    icon: <BadgeExceptionalPerformanceIcon />,
  },
  HighFive: {
    icon: <BadgeHighFiveIcon />,
  },
};

const receivedFromGroupMap = {
  [BadgeUserGroup.Colleague]: Translation.hu.badge.history.youGotABadge,
  [BadgeUserGroup.Recruitment]: Translation.hu.badge.history.youGotABadge,
  [BadgeUserGroup.TL]: Translation.hu.badge.history.youGotABadgeFromTL,
  [BadgeUserGroup.HR]: Translation.hu.badge.history.youGotABadgeFromHR,
  [BadgeUserGroup.MGMT]: Translation.hu.badge.history.youGotABadgeFromMgmt,
  [BadgeUserGroup.CEO]: Translation.hu.badge.history.youGotABadgeFromCEO,
};

export const getBadgeReceivedText = (item: BadgeHistoryDTO): string => {
  if (item.type === BadgeHistoryType.Received) {
    return `${receivedFromGroupMap[item.badgeReceivedFromGroup]}<strong>${Translation.hu.badge.type[item.badgeType]}</strong>`;
  }
  return `${Translation.hu.badge.history.youSentABadge} <strong>${item.to.lastName} ${item.to.firstName}</strong>-nak <strong>${Translation.hu.badge.type[item.badgeType]}</strong>`;
};

export const getBadgeIcon = (item: BadgeHistoryDTO): ReactElement => {
  return BsBadgeKit[item.badgeType].icon;
};

export const getBadgeLabel = (badge: BadgeType) => Translation.hu.badge.type[badge];
